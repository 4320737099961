import * as Yup from "yup";

export const initialValues = {
    //Basic Information
    code: "",
    name: "",
    unique_code: "",
    creation_date: "",
    expiry_date: "",
    segment: "",
    kyc_status: "",
    kyc_check: "",
    app_per: true,
    tin_no: "",
    pan_no: "",
    gst_no: "",
    latitude: "",
    longitude: "",
    copy_to_ship_contact: false,
    copy_to_ship_address:false,
    // active_check:"",
    ship_gst_no:"",

    // contact-person
    prefix: "",
    cfname: "",
    cmname: "",
    clname: "",
    cphoneno: "",
    cmobileno: "",
    caadhar: "",
    cemail: "",
    // basic addrees
    bill_add1: "",
    bill_add2: "",
    bill_town: "",
    bill_state: "",
    bill_district: "",
    bill_zipcode: "",
    //finance
    tax_form: "",
    payment_term: "",
    tax_area: "",
    payment_mode: "",
    invoice_copy: "",
    credit_limit: "",
    trade_discount: "0.00",
    manual_segment_enter: "",
    //other_details
    min_order: "",
    max_order: "",
    transport_charges: "",
    priority: "",
    delivery_inst: "",
    billing_inst: "",
    p_day_of_delivery: "",
    credit_hold: "",
    // sales executive det----
    employeeSelect: "",
    beat_frequency: "",
    day_of_beat1: "",

    // shipping address
    ship_to_name: "",
    sadd1: "",
    sadd2: "",
    van_route: "",
    ship_gst: "",
    ship_town: "",
    ship_district: "",
    ship_state: "",
    ship_zipcode: "",
    active_check:true,
    // contact person
    ship_title: "",
    fname: "",
    mname: "",
    lname: "",
    phno: "",
    mob: "",
    ship_co_email: "",

    //photo identity
    cust_url:""
};
const emailRegex = new RegExp(
    /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
    "gm"
);

export const validationSchema = Yup.object({
    // code: Yup.string().required("Please enter code."),
    name: Yup.string().required("Please enter name."),
    cemail: Yup.string()
        .required("Please enter your Email.")
        .matches(emailRegex, "Email is not valid"),
    // unique_code: Yup.string().required("Please enter unique code."),
    // creation_date: Yup.string().required("Please enter creation date."),
    // segment: Yup.string().required("Please segment."),
    employeeSelect: Yup.string().required("Select Employee"),
    beat_frequency: Yup.string().required("Select Beat Frequency"),
    tax_form: Yup.string().required("Select Tax Form"),
    day_of_beat1: Yup.string().required("Select Day Of Beat."),
    bill_town: Yup.string().required("Please Enter Town."),
    ship_town: Yup.string().required("Please Enter Town."),
    cfname: Yup.string().required("Please Enter First Name."),
    clname: Yup.string().required("Please Enter Last Name."),
    bill_zipcode: Yup.string().required("Please Enter PinCode."),
    credit_hold: Yup.string().required("Please Select"),
    cmobileno: Yup.string()
        .required("Please Enter Mobile No.")
        .max(10, "Mobile No. should be 10 digits.")
        .matches(/^[6-9]\d{9}$/, "Please enter valid number."),
});

// cmobileno: Yup.string()
//     .max(10, "Mobile No. should be 10 digits.")
//     .matches(/^[6-9]\d{9}$/,"Please enter valid number." )

//       // excludeEmptyString: false,
// }),
// );
