import React from "react";
import { RadioButton } from "primereact/radiobutton";
import Modal from "react-bootstrap/Modal";
import { useCustMasterContext } from "../../context/CustomerMasterContext";

const InformationModal = () => {
    const {
        exportbtnpopup,
        setExportbtnpopup,
        downloadAllcustdata,
        setSelectedCategory,
        selectedCategory,
        categories,
    } = useCustMasterContext();

    const closebtnclick = () => {
        setExportbtnpopup(false);
    };

    return (
        <div className="col-12 pt-3 pb-3">
            <Modal
                show={exportbtnpopup}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
            Information
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="col-md-12 col-lg-12 col-sm-12 py-3">
                        <div className="mt-0 text-center">
                            {categories.map((category) => {
                                return (
                                    <div key={category.key} className="d-inline-flex pe-3">
                                        <RadioButton
                                            inputId={category.key}
                                            name="category"
                                            value={category}
                                            onChange={(e) => setSelectedCategory(e.value)}
                                            checked={selectedCategory.key === category.key}
                                        />
                                        <label htmlFor={category.key} className="mx-2">
                                            {category.name}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="float-end">
                        <button
                            type="button"
                            className="btn btn-primary me-3"
                            onClick={downloadAllcustdata}
                        >
              Ok
                        </button>
                        <button
                            type="button"
                            className="btn btn-cancel-red"
                            onClick={closebtnclick}
                        >
              Close
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default InformationModal;
