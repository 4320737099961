import React, { useContext,createContext } from "react";
import { useState } from "react";
import storage from "../Services/Storage";
import { toast } from "react-toastify";
import { EndpointWO, createAPIEndpointWO } from "../Services/WriteOff/WriteOffService";
import { useNavigate } from "react-router-dom";



const WriteOffContext = createContext();
const WriteOffProvider = ({ children }) => {

    const nav = useNavigate();
    const auth =storage.getLocal("user");
    const [maxAmtWO,setMaxAmtWO] = useState("");
    const [narration,setNarration] = useState("");
    const [advWriteOff,setAdvWriteOff] = useState(false);
    const [writeOff,setWriteOff] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const handleSave=(data)=>{
        if(writeOff.length==0){
            toast.error("Select at least one Write Off.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        }
        else{
            setIsLoading(true);
            const objAdvanceDetl = writeOff.map((a)=>({
                masK_RCVH_ID: a?.RCVH_ID,
                documenT_NO: a?.DOCUMENT_NO,
                masK_CVDV_NO: a?.MASK_CVDV_NO,
                masK_CURRENCY_ID: a?.MASK_CURRENCY_ID,
                masK_PARTY_ID: a?.MASK_CUST_ID,
                advance: a?.ADVANCE,
                documenT_DATE: a?.DOCUMENT_DATE
            }));
    
            const objOutStandingDetl = writeOff.map((a)=>({

                documenT_NO: a?.DOCUMENT_NO,
                documenT_DATE: a?.DOCUMENT_DATE,
                masK_DOC_TYPE: a?.MASK_DOC_TYPE,
                masK_APAR_TYPE: a?.MASK_APAR_TYPE,
                masK_DVCV_NO: a?.MASK_DVCV_NO,
                masK_CURRENCY_ID: a?.MASK_CURRENCY_ID,
                masK_CUST_ID: a?.MASK_CUST_ID,
                outstanding: a?.OUTSTANDING
            }));

            let SaveObj = {
                userId: auth.UserId,
                compId: auth.CompId,
                narration: data?.narration,
                strWriteOffFlag: "",
                chkparty: data?.advWriteOff,
                objAdvanceDetl: data?.advWriteOff ? objAdvanceDetl:[],
                objOutStandingDetl: !data?.advWriteOff ? objOutStandingDetl : []
            };

            // console.log("payload",SaveObj);
            createAPIEndpointWO()
                .post(`${EndpointWO.SaveWriteOff}`, JSON.stringify(SaveObj), {
                    headers: { "Content-Type": "application/json" },
                })
                .then((res)=>{
                    setWriteOff([]);
                    if(res?.data?.Status=="Success"){
                        toast.success(`${res?.data?.Message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3000,
                        });
                        nav("/creditmanagement/writeoff");
                        setIsLoading(false);
                       
                    }
                    else{
                        toast.warning(`${res?.data?.Message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3000,
                        });
                        setIsLoading(false);
                    }

                })
                .catch((error)=>{
                    setIsLoading(false);
                    toast.error(`${error.response.data.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                    setWriteOff([]);
                });
        }
    };

  

    return (
        <WriteOffContext.Provider
            value={{
                maxAmtWO,
                setMaxAmtWO,
                narration,
                setNarration,
                advWriteOff,
                setAdvWriteOff,
                writeOff,
                setWriteOff,
                handleSave,
                isLoading,
                setIsLoading
            }}
        >
            {children}
        </WriteOffContext.Provider>
    );
};
export const useWriteOffContext = () => {
    return useContext(WriteOffContext);
};

export { WriteOffProvider, WriteOffContext };