import Axios from "../../api/Axios";
const BASE_URL = "CreditMReports/";


export const EndpointCreditMReports = {
    GetExcelDataRcptInPrd : "GetExcelDataRcptInPrd",
    GetCustOutExcelData : "GetCustOutExcelData",
    GetParty : "GetParty",
    GetCustExcelData : "GetCustExcelData",
    GetExcelDataDebit : "GetExcelDataDebit",
    GetExcelCreditAdjData : "GetExcelCreditAdjData"
};

// /api/CreditMReports/GetExcelDataDebit
// GetExcelCreditAdjData
export const createAPIEndpointCreditMReports= () => {
    return {get:(Endpoint)=>Axios.get(BASE_URL+Endpoint)};
};