import Axios from "../../api/Axios";
const BASE_URL = "ProductMaster/";
export const Endpoint = {
    GetAllProductMastData: "GetAllProductMastData",
    GetAllClassificationData: "GetAllClassificationData",
    GetAllTaxData: "GetAllTaxData",
    GetExcelAllProductData: "GetExcelAllProductData",
    ViewProductMaster: "ViewProductMaster",
};

export const createAPIEndpointproduct = () => {
    return { get: (Endpoint) => Axios.get(BASE_URL + Endpoint) };
};
