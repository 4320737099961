import React from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import Modal from "react-bootstrap/Modal";
import { MdCurrencyRupee } from "react-icons/md";
import { usePurchaseReturnContext } from "../../context/PurchaseReturnContext";
import { useGlobalContext } from "../../context/SalesOrderContext";
import { useRef } from "react";
import PrintInvoicePopUp from "../../Components/PrintInvoicePopUp";

const PurchaseReturnPrint = () => {
    const {printData,printPopup,setPrintPopup}=usePurchaseReturnContext();
    const pdfExportComponent = useRef(null);
    const {orientation} = useGlobalContext();

    const printRef = useRef();
    function getUniqueNo() {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dateunique = date.valueOf();
        let uniqueNo = `PurchaseReturn-${day}-${month}-${year}-${dateunique}`;
        return uniqueNo;
    }
    if(orientation!=="portrait-primary"){
        return (
            <Modal className="print-popup" show={printPopup} size="lg" centered onHide={() => setPrintPopup(false)}>
                <Modal.Body>
                    <div id="printablediv">
                        <PDFExport fileName={getUniqueNo()} paperSize="A4" forcePageBreak=".page-break" ref={pdfExportComponent}>
                            <div>
                                <div className="mt-5">
                                    <div className="">
                                        <div>
                                            <div className="parent parent-invoice-logo-type">
                                                <span className="invoice-type child">
                                                    {printData?.objPrintHdr?.lblComp}
                                                </span>
                                            </div>

                                            <div className="parent  parent-invoice-terms-one">
                                                <div className="childOne  invoice-terms">
                                                    <p>
                                                        {printData?.objPrintHdr?.lblAdd1},
                                                        {printData?.objPrintHdr?.lblAdd2}
                                                        <br></br>
                                                        {printData?.objPrintHdr?.lblAdd3}
                                                        <br></br>
                                                        <b>PIN : </b>  {printData?.objPrintHdr?.lblAdd4}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="parent  parent-invoice-terms">
                                            <div className="childOne  invoice-terms">
                                                <p><span className="firstPara">Phone No.</span>: {printData?.objPrintHdr?.lblPhone}</p>
                                            </div>
                                        </div>
                                        <div className="title" style={{ textAlign: "center", borderTop: "1px solid #000", borderBottom: "1px solid #000", padding: "4px 0px", margin: "18px" }}>
                                            <p style={{ fontSize: "10px", marginBottom: "0px" }}>Debit Note Against GRN</p>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="parent  parent-invoice-terms-6">
                                                    <div className="childOne  invoice-terms">
                                                        <p><span className="firstPara">Supplier Code</span> : {printData?.objPrintHdr?.lblSuppCode}</p>
                                                        <p><span className="firstPara mb-2">Supplier Name</span> : {printData?.objPrintHdr?.lblSuppName}</p>
                                                        <p><span className="firstPara d-block mb-1">Billing Address</span>
                                                            {printData?.objPrintHdr?.lblEX1}
                                                            {printData?.objPrintHdr?.lblEX2}
                                                        
                                                            <br></br>
                                                            {printData?.objPrintHdr?.lblBillTown}{" "}
                                                            {printData?.objPrintHdr?.lblBillPin}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <div className="parent  parent-invoice-terms-6">
                                                    <div className="childOne  invoice-terms">
                                                        <p><span className="firstPara" style={{ width: "30%" }}>Debit Note No.</span>: {printData?.objPrintHdr?.lblDebitNoteNo}</p>
                                                        <p><span className="firstPara" style={{ width: "30%" }}>Debit Note Date</span>: {printData?.objPrintHdr?.lblDebitDate}</p>
                                                        <p><span className="firstPara" style={{ width: "30%" }}>Against</span>: {printData?.objPrintHdr?.lblAgainst}</p>
                                                        <p><span className="firstPara" style={{ width: "30%" }}>Dated</span>: {printData?.objPrintHdr?.lblDate}</p>
                                                        <p><span className="firstPara" style={{ width: "30%" }}>Created By</span>: {printData?.objPrintHdr?.lblBy}</p>
                                                        <p><span className="firstPara" style={{ width: "30%" }}>Reason</span>: {printData?.objPrintHdr?.lblReason}</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="parent parent-invoice-table">
                                            <table className="invoice-table-details" style={{ height: "auto !important", borderBottom: "none" }}>
                                                <thead>
                                                    <tr className="table-row-border">
                                                        <th width="20px"> Sr <br></br> No</th>
                                                        <th width="150px">Product Pack Description</th>
                                                        <th className="RightAlignTD">Batch No.</th>
                                                        <th className="RightAlignTD">Quantity(Packs)</th>
                                                        <th className="RightAlignTD">Quantity(EA/Ltrs/Kg)</th>
                                                        <th className="RightAlignTD">Unit Price</th>
                                                        <th className="RightAlignTD">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{ height: "auto !important" }}>
                                                    <div className="MainValue">
                                                        {printData?.objPrintDtl?.map((val, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <>
                                                                        <td width="20px">{val?.Sr_no}</td>
                                                                        <td width="150px">{val?.Prod_Code} <br></br>{val?.Prod_Desc}</td>
                                                                        <td className="RightAlignTD">{val?.Batch_No}</td>
                                                                        <td className="RightAlignTD">{val?.Qty_pack}</td>
                                                                        <td className="RightAlignTD">{val?.Qty_EA_Ltrs_Kg}</td>
                                                                        <td className="RightAlignTD">{val?.Unit_Price}</td>
                                                                        <td className="RightAlignTD">{val?.Amount}</td>
                                                                    </>
                                                                </tr>
                                                    
                                                            );
                                                        })}
                                                    </div>


                                                    <div>
                                                        <tr>
                                                            <>
                                                                <td width="20px"></td>
                                                                <td width="150px">Sub Total</td>
                                                                <td className="RightAlignTD"></td>
                                                                <td className="RightAlignTD">{printData?.objPrintDtlAmount?.SumQtyPack}</td>
                                                                <td className="RightAlignTD">{printData?.objPrintDtlAmount?.SumQtyLtr}</td>
                                                                <td className="RightAlignTD"></td>
                                                                <td className="RightAlignTD">{printData?.objPrintDtlAmount?.SumAmt}</td>
                                                            </>
                                                        </tr>
                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div>
                                            <div className="row m-auto">
                                                <div className="column111" style={{ width: "60%" }}>
                                                    <div className="rupeeBox">
                                                        <p>{printData?.objPrintDtlAmount?.AmtInWord}</p>
                                                        <p><span>Print Message :</span> {printData?.objPrintHdr?.lblMsg}</p>
                                                    </div>
                                                </div>
                                                <div className="column222" style={{ width: "40%" }}>
                                                    <div className="childOne  invoice-terms-one">
                                                        <p style={{ textAlign: "right", lineHeight: "6px" }}>
                                                            <span style={{ textAlign: "left", display: "block" }}>Trade Discount</span>
                                                            <MdCurrencyRupee /> {printData?.objPrintDtlAmount?.TradeDisc}
                                                        </p>
                                                        <p style={{ textAlign: "right", lineHeight: "6px" }}>
                                                            <span style={{ textAlign: "left", display: "block" }}>Value After Discount</span>
                                                            <MdCurrencyRupee /> {printData?.objPrintDtlAmount?.ValueDisc}
                                                        </p>
                                                        <p style={{ textAlign: "right", lineHeight: "6px" }}>
                                                            <span style={{ textAlign: "left", display: "block" }}>GST</span>
                                                            {printData?.objPrintDtlAmount?.Vat}
                                                        </p>
                                                        <hr style={{ height: "0rem", margin: "0.2rem 0" }}></hr>
                                                        <p style={{ textAlign: "right", lineHeight: "6px" }}>
                                                            <span style={{ textAlign: "left", display: "block" }}>Total Amount</span>
                                                            <MdCurrencyRupee /> {printData?.objPrintDtlAmount?.Total}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mt-5 pt-3 px-3">
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <hr style={{ height: "0rem", margin: "0.2rem 0" }}></hr>
                                                    <p style={{ fontSize: "8px", textAlign: "center", marginBottom: "0px" }}><b>Receiver</b></p>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                    <hr style={{ height: "0rem", margin: "0.2rem 0" }}></hr>
                                                    <p style={{ fontSize: "8px", textAlign: "center", marginBottom: "0px" }}><b>Collector</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PDFExport>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-cancel-red me-3" onClick={()=>setPrintPopup(false)}>Cancel</button>
                    <button className="btn btn-primary"
                        ref={printRef}
                        onClick={() => {
                            if (pdfExportComponent.current) {
                                pdfExportComponent.current.save();
                            }
                        }}
                    >Download</button>
                </Modal.Footer>
            </Modal >
        );}else{
        return(<PrintInvoicePopUp show={printPopup} hideShow = {setPrintPopup} name = "Purchase Return Print View"/>);
    }
};

export default PurchaseReturnPrint;