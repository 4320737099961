import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useGRNContext } from "../../context/GRNContext";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { MdCurrencyRupee } from "react-icons/md";
import "./Prints.scss";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useGlobalContext } from "../../context/SalesOrderContext";
import PrintInvoicePopUp from "../../Components/PrintInvoicePopUp";

const PrintGRNView = () => {
    const { printPopup, printData, setPrintPopup, mod_array, setMod_array } = useGRNContext();
    const {orientation} = useGlobalContext();
    const [view, setView] = useState(true);

    useEffect(() => {
        setView(true);
    }, [0]);
    const printRef = useRef();
    const pdfExportComponent = useRef(null);
    const handleClose = () => {
        setPrintPopup(close);
        setMod_array([]);
    };
    function getUniqueNo() {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dateunique = date.valueOf();
        let uniqueNo = `GRN-${day}-${month}-${year}-${dateunique}`;
        return uniqueNo;

    }
    if(orientation!=="portrait-primary"){
        if (view) {
            return (
                <Modal
                    className="print-popup"
                    show={printPopup}
                    size="lg" centered onHide={() => handleClose()}>
                    {/* <Modal.Header className="DashboardTodoListTitle">
                </Modal.Header> */}
                    <Modal.Body>
                        <div id="printablediv">
                            <PDFExport
                                fileName={getUniqueNo()}
                                paperSize="A4"
                                forcePageBreak=".page-break"
                                ref={pdfExportComponent}
                            > {
                                    printData.map((item, index) => {
                                        let actualData = [];
                                        actualData = printData[index];
                                        return (
                                            <>
                                                {mod_array[index].map((val, i) => {
                                                    return (
                                                        <div
                                                            key={i}
                                                            className={
                                                                i !== 0 && "page-break"
                                                            }
                                                        >
                                                            <div>
                                                                <div className="py-5 mt-4">
                                                                    <div className="">
                                                                        {i == 0 ? (
                                                                            <div>
                                                                                <div className="">
                                                                                    <p className="text-center mb-0" style={{ fontSize: "10px" }}><b>Goods Receipt Note</b></p>
                                                                                </div>
                                                                                <div className="parent parent-invoice-logo-type" style={{ height: "0.5cm", marginBottom: "4px" }}>
                                                                                    <span className="invoice-type child" style={{ fontSize: "10px" }}>
                                                                                        {
                                                                                            actualData
                                                                                                .objModelGRNPrintHeader
                                                                                                .Dist_Name
                                                                                        }
                                                                                    </span>
                                                                                </div>

                                                                                <div className="parent  parent-invoice-terms-one">
                                                                                    <div className="childOne  invoice-terms">
                                                                                        <p>
                                                                                            {actualData
                                                                                                .objModelGRNPrintHeader
                                                                                                .Add1 +
                                                                                            " " +
                                                                                            actualData
                                                                                                .objModelGRNPrintHeader
                                                                                                .Add2 +
                                                                                            " " +
                                                                                            actualData
                                                                                                .objModelGRNPrintHeader
                                                                                                .Add3 +
                                                                                            " " +
                                                                                            actualData
                                                                                                .objModelGRNPrintHeader
                                                                                                .Add4 +
                                                                                            " " +
                                                                                            actualData
                                                                                                .objModelGRNPrintHeader
                                                                                                .Cbill_Pin}
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <div className="parent  parent-invoice-terms" style={{ height: "auto", paddingBottom: "4px" }}>
                                                                            <div className="childOne  invoice-terms">
                                                                                <p>
                                                                                    <span>
                                                                                    Phone No
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Ph_No
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                    Other No
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Hdr1
                                                                                    }
                                                                                </p>
                                                                                <hr style={{ width: "90%", margin: "0.5rem 0" }}></hr>
                                                                                <p>
                                                                                    <span>
                                                                                    Division
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Hdr2
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                    Goods Receipt
                                                                                    No.
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Grn_No
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                    Goods Receipt
                                                                                    Date
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Grn_Date
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                    Supplier
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Supplier
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                    Supplier
                                                                                    Document No
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Supp_Inv_No
                                                                                    }
                                                                                </p>
                                                                                <p>
                                                                                    <span>
                                                                                    Supplier
                                                                                    Document Date
                                                                                    </span>{" "}
                                                                                :{" "}
                                                                                    {
                                                                                        actualData
                                                                                            .objModelGRNPrintHeader
                                                                                            .Supp_Inv_Date
                                                                                    }
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="parent parent-invoice-table">
                                                                            <table className="invoice-table-details" style={{ height: "455px" }}>
                                                                                <thead>
                                                                                    <tr className="table-row-border">
                                                                                        <th width="15px">
                                                                                        Sr
                                                                                            <br /> No
                                                                                        </th>
                                                                                        <th width="150px">
                                                                                        Product Description
                                                                                        </th>
                                                                                        <th>
                                                                                        Warehouse
                                                                                        </th>
                                                                                        <th className="RightAlignTD">
                                                                                        Quantity{" "}
                                                                                            <br />
                                                                                        (Packs)
                                                                                        </th>
                                                                                        <th className="RightAlignTD">
                                                                                        Quantity{" "}
                                                                                            <br />{" "}
                                                                                        (EA/Ltrs/Kg){" "}
                                                                                        </th>
                                                                                        <th className="RightAlignTD">Rate</th>
                                                                                        <th className="RightAlignTD">
                                                                                        Amount(<MdCurrencyRupee />){" "}
                                                                                        </th>
                                                                                        <th className="RightAlignTD">

                                                                                        GST(<MdCurrencyRupee />)
                                                                                        </th>
                                                                                        <th className="RightAlignTD">
                                                                                        GST(%)
                                                                                        </th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody style={{ borderBottom: "1px solid #fff" }}>
                                                                                    <div className="MainValue">
                                                                                        {i == 0
                                                                                            ? actualData.ListModelGRNPrintProdDetails.map(
                                                                                                (
                                                                                                    val,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <tr
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        {index <
                                                                                                        10 && (
                                                                                                            <>
                                                                                                                <td width="15px">
                                                                                                                    {
                                                                                                                        val.Sl_No
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td width="150px">
                                                                                                                    {
                                                                                                                        val.Product_Name
                                                                                                                    }
                                                                                                                    <br />
                                                                                                                    {
                                                                                                                        val.HDR3
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        val.Warehouse
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {
                                                                                                                        val.Qauntity_Packs
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {
                                                                                                                        val.Quantity_Ltr
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {Number(
                                                                                                                        val.Rate
                                                                                                                    ).toFixed(
                                                                                                                        2
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {/* <MdCurrencyRupee /> */}
                                                                                                                    {Number(
                                                                                                                        val.Amount
                                                                                                                    ).toFixed(
                                                                                                                        2
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {/* <MdCurrencyRupee /> */}
                                                                                                                    {Number(
                                                                                                                        val.Vat_GST
                                                                                                                    ).toFixed(
                                                                                                                        2
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {
                                                                                                                        Number(val.Vat_GST_Perc).toFixed(2)
                                                                                                                    }
                                                                                                                </td>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </tr>
                                                                                                )
                                                                                            )
                                                                                            : item.ListModelGRNPrintProdDetails.slice(
                                                                                                i * 10
                                                                                            ).map(
                                                                                                (
                                                                                                    val,
                                                                                                    index
                                                                                                ) => (
                                                                                                    <tr
                                                                                                        key={
                                                                                                            index
                                                                                                        }
                                                                                                    >
                                                                                                        {index <
                                                                                                        10 && (
                                                                                                            <>
                                                                                                                <td width="15px">
                                                                                                                    {
                                                                                                                        val.Sl_No
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td width="150px">
                                                                                                                    {
                                                                                                                        val.Product_Name
                                                                                                                    }
                                                                                                                    <br />
                                                                                                                    {
                                                                                                                        val.HDR3
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {
                                                                                                                        val.Warehouse
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {
                                                                                                                        val.Qauntity_Packs
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {
                                                                                                                        val.Quantity_Ltr
                                                                                                                    }
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {Number(
                                                                                                                        val.Rate
                                                                                                                    ).toFixed(
                                                                                                                        2
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    <MdCurrencyRupee />
                                                                                                                    {Number(
                                                                                                                        val.Amount
                                                                                                                    ).toFixed(
                                                                                                                        2
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    <MdCurrencyRupee />
                                                                                                                    {Number(
                                                                                                                        val.Vat_GST
                                                                                                                    ).toFixed(
                                                                                                                        2
                                                                                                                    )}
                                                                                                                </td>
                                                                                                                <td className="RightAlignTD">
                                                                                                                    {
                                                                                                                        Number(val.Vat_GST_Perc).toFixed(2)
                                                                                                                    }
                                                                                                                </td>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </tr>
                                                                                                )
                                                                                            )}
                                                                                    </div>

                                                                                    <div>
                                                                                        <>
                                                                                            <tr>
                                                                                                <td width="15px"></td>
                                                                                                <td width="150px"></td>
                                                                                                <td></td>
                                                                                                <td className="RightAlignTD"></td>
                                                                                                <td className="RightAlignTD"></td>
                                                                                                <td className="RightAlignTD"></td>
                                                                                                <td className="RightAlignTD"><b>Total</b></td>
                                                                                                <td className="RightAlignTD">{actualData.sumQty}</td>
                                                                                                <td className="RightAlignTD">{actualData.sumQtyLtr}</td>
                                                                                            </tr>
                                                                                        </>
                                                                                    </div>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {i ==
                                                                        mod_array[index]
                                                                            .length -
                                                                        1 ? (
                                                                                <div>
                                                                                    <div className="row border-bottom-black m-0">
                                                                                        <div className="row m-0">
                                                                                            <div className="column">
                                                                                                <div className="rupeeBox">
                                                                                                    <p>
                                                                                                        <span>
                                                                                                    Rupee
                                                                                                        </span>
                                                                                                :{" "}
                                                                                                        <span style={{ fontSize: "5px" }}>  {
                                                                                                            actualData.AmtInWord
                                                                                                        }
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <p>
                                                                                                        <span style={{ marginRight: "10px" }}>
                                                                                                    Supplier
                                                                                                    Payment
                                                                                                    Term
                                                                                                        </span>
                                                                                                :{" "}
                                                                                                        {
                                                                                                            actualData
                                                                                                                .objModelGRNPrintHeader
                                                                                                                .Payment_Tearm
                                                                                                        }
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="column">
                                                                                                <div className="childOne  invoice-terms-one">
                                                                                                    <p><span>Net Amount :</span>
                                                                                                        <span style={{ textAlign: "right" }}>  <MdCurrencyRupee />
                                                                                                            {Number(
                                                                                                                actualData.NetAmt
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <p><span>Received By :</span>
                                                                                                        <span style={{ textAlign: "right" }}>    {
                                                                                                            actualData
                                                                                                                .objModelGRNPrintHeader
                                                                                                                .Received_By
                                                                                                        }{" "}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <p><span>Received Date :</span>
                                                                                                        <span style={{ textAlign: "right" }}>   {
                                                                                                            actualData
                                                                                                                .objModelGRNPrintHeader
                                                                                                                .Grn_Date
                                                                                                        }
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <p><span>Inspected By :</span>
                                                                                                        <span style={{ textAlign: "right" }}>     {
                                                                                                            actualData
                                                                                                                .objModelGRNPrintHeader
                                                                                                                .Inspected_By
                                                                                                        }
                                                                                                        </span>
                                                                                                    </p>
                                                                                                    <p><span>Inspected Date :</span>
                                                                                                        <span style={{ textAlign: "right" }}>   {
                                                                                                            actualData
                                                                                                                .objModelGRNPrintHeader
                                                                                                                .Inspection_Date
                                                                                                        }
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        );
                                    })
                                }

                            </PDFExport>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        {/* <Button onClick={handleClose}>OK</Button> */}
                        <button className="btn btn-cancel-red me-3" onClick={handleClose} >
                        Close
                        </button>
                        <button
                            className="btn btn-primary"
                            ref={printRef}
                            onClick={() => {
                                if (pdfExportComponent.current) {
                                    pdfExportComponent.current.save();
                                }
                            }}
                        >
                        Download
                        </button>
                    </Modal.Footer>

                </Modal >

            );
        }
    }else{
        return(<PrintInvoicePopUp show={printPopup} hideShow = {setPrintPopup} name = "GRN Print View"/>);
    }


};
export default PrintGRNView;