import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import Form from "react-bootstrap/Form";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import Accordion from "react-bootstrap/Accordion";
// import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from "primereact/dialog";

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Axios from "../../api/Axios";
import storage from "../../Services/Storage";
import { useEffect } from "react";
import { useFormik } from "formik";
import Loader from "../../Components/Loader";
import { useCustMasterContext } from "../../context/CustomerMasterContext";
// import { ToastContainer } from "react-toastify";
// import { toast } from "react-toastify";
// import format from "date-fns/format";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Fi from "react-icons/fi";
let latitude = "";
let longitude = "";
import "./NewCustomer.css"; //for red error color only.
import { initialValues } from "../../Validations/NewCustomerValidation";
import SegmentModalNewCust from "./SegmentModalNewCust";
import * as Fa from "react-icons/fa";
const GetPaymentTerm_URL = "CustomerMast/GetPaymentTerm?";
const GetModeOfPayment_URL = "CustomerMast/GetModeOfPayment?";
const GetTaxEnvSettings_URL = "CustomerMast/GetTaxEnvSettings?";
const GetTaxArea_URL = "CustomerMast/GetTaxArea?";
const GetTaxForm_URL = "CustomerMast/GetTaxForm?";
const GetDistEmployee_URL = "CustomerMast/GetDistEmployee?";
const GetFrequency_URL = "CustomerMast/GetFrequency?";
const GetDayOfWeek_URL = "CustomerMast/GetDayOfWeek?";
const GetVanRoute_URL = "CustomerMast/GetVanRoute?";
const GetCompState_URL = "CustomerMast/GetCompState?";

function NewCustomer() {
    const auth = storage.getLocal("user");
    const [paymentTerm, setPaymentTerm] = useState([]);
    const [modeOfPayment, setModeOfPayment] = useState([]);
    const [taxArea, setTaxArea] = useState([]);
    const [taxForm, setTaxForm] = useState([]);
    const [employeeList, setEmployeeList] = useState([]);
    const [beatFrequency, setBeatFrequency] = useState([]);
    const [dayOfWeek, setDayOfWeek] = useState([]);
    const [nameStatus, setNameStatus] = useState(false);
    const [segStatus, setsegStatus] = useState(false);
    //   const [expiryStatus, setexpiryStatus] = useState(false);
    //   const [uniqueStatus, setuniqueStatus] = useState(false);
    const [fnameStatus, setFnameStatus] = useState(false);
    const [lnameStatus, setLnameStatus] = useState(false);
    const [address1Status, setAddress1Status] = useState(false);
    const [address2Status, setAddress2Status] = useState(false);
    const [townStatus, setTownStatus] = useState(false);
    const [creditHoldStatus, setCreditHoldStatus] = useState(false);
    const [paymentTermStatus, setPaymentTermStatus] = useState(false);
    const [taxAreaStatus, setTaxAreaStatus] = useState(false);
    const [paymentModeStatus, setPaymentModeStatus] = useState(false);
    // const [invoiceCopyStatus, setInvoiceCopyStatus] = useState(false);
    const [tradeDiscStatus, setTradeDiscStatus] = useState(false);
    const [preferedDayStatus, setPreferedDayStatus] = useState(false);
    const [address1Status1, setAddress1Status1] = useState(false);
    const [townStatus1, setTownStatus1] = useState(false);
    // const [vanRouteStatus, setVanRouteStatus] = useState(false);
    const [fnameStatus1, setFnameStatus1] = useState(false);
    const [lnameStatus1, setLnameStatus1] = useState(false);
    const [mobileStatus, setMobileStatus] = useState(false);
    const [mobileStatus1, setMobileStatus1] = useState(false);

    let taxEnvSettings = "";
    const [vanRoute, setVanRoute] = useState([]);

    const [tinStatus, setTinStatus] = useState(false);
    const [pannoStatus, setPannoStatus] = useState(false);
    const [gstnoStatus, setGstnoStatus] = useState(false);

    const [prefixStatus, setPrefixStatus] = useState(false);
    const [midnameStatus, setMidnameStatus] = useState(false);
    const [phnoStatus, setPhnoStatus] = useState(false);
    const [cemailStatus, setCemailStatus] = useState(false);
    const [creditlimStatus, setCreditlimStatus] = useState(false);
    // const [minorderStatus, setMinorderStatus] = useState(false);
    // const [maxorderStatus, setMaxorderStatus] = useState(false);
    // const [transchrgStatus, setTranschrgStatus] = useState(false);
    // const [priortyStatus, setPriortyStatus] = useState(false);
    const [delvinsStatus, setDelvinsStatus] = useState(false);
    const [billinsStatus, setBillinsStatus] = useState(false);
    const [shipaddStatus, setShipaddStatus] = useState(false);
    // const [shipgstStatus, setShipgstStatus] = useState(false);
    const [stitleStatus, setStitleStatus] = useState(false);
    const [smidnameStatus, setSmidnameStatus] = useState(false);
    const [sphnumStatus, setSphnumStatus] = useState(false);
    const [shipemailStatus, setShipemailStatus] = useState(false);
    const [fullcustimgpPopup, setFullcustimgpPopup] = useState(false);
    const [fulliIdPopup, setFullIdPopup] = useState(false);
    const [phoneValidationPopUp, setPhoneValidationPopUp] = useState(false);
    const [customerDetailsData, setCustomerDetailsData] = useState([]);
    // const [kycstatus, setKycstatus] = useState(false);

    const {
        editCustMastLoader,
        customerDetailsById,
        creationDate,
        expiryDate,
        townHandleChange,
        // townInput,
        townFilter,
        townSuggestion,
        billDistrict,
        billState,
        billZip,
        setShow,
        // billAddressLoader,
        getSegmentForBill,
        customerMasterSave,
        manualTownEnter,
        setManualTownEnter,
        setSelectedTown,
        // selectedTown,
        // townError,
        // billTown,
        segmentValue,
        manualSegmentEnterBtn,
        // townInitialVal,
        // selectedShipTown,
        setSelectedShipTown,
        shiptownFilter,
        shipTownSuggestion,
        shipTownHandleChange,
        manualShipTownEnter,
        shipDistrict,
        shipZip,
        shipState,
        // shipTownError,
        // shipTownInitailVal,
        // shipTown,
        townList,
        shipTownList,
        newCustomerSave,
        newCutomerUniCode,
        customerSaveDisable,
        // setCustomerSaveDisable,
        // errorLabel,
        setErrorLable,
        customerSaveUpdateLoader,
        partyCodeOnSave,
        setSegmentConfirmBtn,
        uniqueCodeBtn,
        setCopyToShip,
        // editView,
        setCompState,
        kycStatus,
        kycfieldDisabled,
        setKycfieldDisabled,
        checckPhoneValidation,
    // kysStatus
    // setManualShipEnter
    } = useCustMasterContext();

    const customerMasterFun = async () => {
        try {
            const GetCompState_api = `${GetCompState_URL}CompId=${auth.CompId}`;
            const GetPaymentTerm_api = `${GetPaymentTerm_URL}CICode=${auth.CICode}`;
            const GetModeOfPayment_api = `${GetModeOfPayment_URL}CICode=${auth.CICode}`;
            const GetTaxEnvSettings_api = `${GetTaxEnvSettings_URL}CICode=${auth.CICode}`;
            const GetTaxEnvSettingsRes = await Axios.get(GetTaxEnvSettings_api);
            taxEnvSettings = GetTaxEnvSettingsRes.data.TaxSettings.toString();
            const res = await Axios.get(GetCompState_api);
            setCompState(res.data);
            const GetTaxArea_api = `${GetTaxArea_URL}CICode=${auth.CICode}&strExportable="Y"&strEnvOption=${taxEnvSettings}`;
            const GetTaxForm_api = `${GetTaxForm_URL}CICode=${auth.CICode}&strExportable="Y"&strEnvOption=${taxEnvSettings}&Country="N"`;
            const GetDistEmployee_api = `${GetDistEmployee_URL}CICode=${auth.CICode}&CompId=${auth.CompId}`;
            const GetFrequency_api = `${GetFrequency_URL}CICode=${auth.CICode}`;
            const GetDayOfWeek_api = `${GetDayOfWeek_URL}CICode=${auth.CICode}`;
            const GetVanRoute_api = `${GetVanRoute_URL}CICode=${auth.CICode}&CompId=${auth.CompId}`;
            const GetPaymentTermRes = await Axios.get(GetPaymentTerm_api);
            setPaymentTerm(GetPaymentTermRes.data);
            const GetModeOfPaymentRes = await Axios.get(GetModeOfPayment_api);
            // const modeOfPayment = GetModeOfPaymentRes.data.filter((val) => {
            //     return val.NAME == "Cash" || val.NAME == "Cheque";
            // });
            setModeOfPayment(GetModeOfPaymentRes.data);
            const taxareaRes = await Axios.get(GetTaxArea_api);
            setTaxArea(taxareaRes.data);
            const taxFormRes = await Axios.get(GetTaxForm_api);
            setTaxForm(taxFormRes.data);
            const employeeRes = await Axios.get(GetDistEmployee_api);
            setEmployeeList(employeeRes.data);
            const FrequencyRes = await Axios.get(GetFrequency_api);
            setBeatFrequency(FrequencyRes.data);
            const GetDayOfWeekRes = await Axios.get(GetDayOfWeek_api);
            setDayOfWeek(GetDayOfWeekRes.data);
            const van_route_res = await Axios.get(GetVanRoute_api);

            setVanRoute(van_route_res.data);
        } catch (error) {
            toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                position: toast.POSITION.TOP_CENTER,

                pauseOnFocusLoss: false,

                closeButton: false,

                autoClose: 3000,
            });

            console.log(error);
        }
    };
    useEffect(() => {
        customerMasterFun();
    // navigator.geolocation.getCurrentPosition((position) => {
    //     console.log(
    //         "geolo",
    //         position.coords.latitude,
    //         " ",
    //         position.coords.longitude
    //     );
    //     latitude = Math.round(position.coords.latitude * 100) / 100;
    //     longitude = Math.round(position.coords.longitude * 100) / 100;
    // });
    }, []);

    useEffect(() => {
        if (newCustomerSave) {
            setKycfieldDisabled(false);
        }
    }, []);

    const preferedDay = [
    // { id: "0", name: "Select" },
        { id: "1", name: "Sunday" },
        { id: "2", name: "Monday" },
        { id: "3", name: "Tuesday" },
        { id: "4", name: "Wednesday" },
        { id: "5", name: "Thursday" },
        { id: "6", name: "Friday" },
        { id: "7", name: "Saturday" },
    ];

    // ARRAY OF DROPDOWN API OUTPUT
    // const dropDownAll = [taxArea, paymentTerm, modeOfPayment];

    // useEffect(()=>{
    //     if(customerDetailsById.KYC=="S"||customerDetailsById.KYC=="R"){
    //
    //         setKycstatus(true);
    //     }
    //     else{
    //
    //     }
    // },[])

    const TownTemplate = (townFilter) => {
        return (
            <>
                {townFilter.PIN_CODE}-{townFilter.NAME}
            </>
        );
    };
    const ShipTownTemplate = (townFilter) => {
        return (
            <>
                {townFilter.PIN_CODE}-{townFilter.TOWN}
            </>
        );
    };
    const PayTermTaxAreaTemplate = (stateFilter) => {
        return (
            <>
                {stateFilter.CODE} - {stateFilter.NAME}
            </>
        );
    };
    // useEffect(()=>{
    //
    // // if(customerDetailsById.KYC=="R"||customerDetailsById.KYC=="S"){
    // //
    // //     setKycstatus(true);
    // // }
    // },[]);
    //CONVERTING PRIMENG REACT------------------------------------------------------------

    const procedFurther = () => {
        customerMasterSave(customerDetailsData);
    };

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: (data) => {
            setCustomerDetailsData(data);
            const validatePhoneNumber = async () => {
                try {
                    let phnValidation = await checckPhoneValidation(data.cmobileno);
                    if (phnValidation === "N") {
                        customerMasterSave(data);
                    } else {
                        setPhoneValidationPopUp(true);
                    }
                } catch (error) {
                    console.error("Error in validatePhoneNumber:", error);
                }
            };

            if (
                !newCustomerSave &&
        customerDetailsById.BillOtherPhoneNo.trim() === data.cmobileno.trim()
            ) {
                customerMasterSave(data);
            } else {
                validatePhoneNumber();
            }
        },
        validate: (data) => {
            //email regx
            // const emailRegex = new RegExp(
            //     /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
            //     "gm"
            // );
            const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
            // const emailRegex1 = new RegExp(
            //     /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
            //     "gm"
            // );

            //phone number regx (9089786745)
            const mobilenoRegex = new RegExp(/^[6-9]\d{9}$/);
            //space but no special character and number (Abhirup Basak)
            const nameRegex = new RegExp(/^[A-Za-z\s]+$/);
            //number regx only character and number.....no spl character (ANHJI0998N)
            const noregx = /^[a-zA-Z0-9]+$/;
            const gstregex =
        /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;
            const panRegex = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
            //only number accept (12,909,6780)
            // const numberregx = /^\d+$/;
            //lan line number regx
            let phnnorgx = /^(\d{3}-?)?\d{4}-?\d{4}$/;
            // decimal regex
            const decRegex = /^\d+(\.\d{1,2})?$/;
            //prefix where - / @ allowed
            const prefixvalid = /^[a-zA-Z0-9\-_@]+$/;
            //allow only Spec char allowed [.],[,],[/],[-]
            const Addressrgx = /^[A-Za-z0-9\s.,,/\\-]*$/;
            let errors = {};

            //name
            if (!data.name && formik.initialTouched) {
                errors.name = "Please provide a valid Customer Name";
                setErrorLable(true);
                setNameStatus(true);
            } else if (data.name.trim().length > 50) {
                errors.name =
          "Please enter a Name with a length of less than 50 characters";
                setErrorLable(true);
                setNameStatus(true);
            }
            // else if (!nameRegex.test(data.name)) {
            //         errors.name =
            //   "Invalid name !! Numbers and special characters are not allowed.";
            //         setErrorLable(true);
            //         setNameStatus(true);
            //     }
            else {
                setNameStatus(false);
            }

            //unique no
            //   if (!data.unique_code) {
            //     errors.unique_code = "unique code is required";
            //     setErrorLable(true);
            //     setuniqueStatus(true);
            //   } else {
            //     setuniqueStatus(false);
            //   }

            //   //creation date
            //   if (!data.creation_date) {
            //     errors.creation_date = "creation date is required";
            //     setErrorLable(true);
            //   }

            //expiry date
            // if (!data.expiry_date) {
            //     errors.expiry_date = "expiry date is required.";
            //     setErrorLable(true);
            //     setexpiryStatus(true);
            // }
            // else {
            //     setexpiryStatus(false);
            // }

            //segment
            if (!data.segment) {
                errors.segment = "Please select Segment";
                setErrorLable(true);
                setsegStatus(true);
            } else {
                setsegStatus(false);
            }

            //TIN NO
            if (data.tin_no) {
                if (!noregx.test(data.tin_no)) {
                    errors.tin_no = "Please enter a valid TIN. ";
                    setErrorLable(true);
                    setTinStatus(true);
                } else if (data.tin_no.length > 11) {
                    errors.tin_no = "Invalid TIN Number length should be less than 11";
                    setTinStatus(true);
                    setErrorLable(true);
                } else {
                    setTinStatus(false);
                }
            } else {
                setTinStatus(false);
            }

            //PAN NO
            if (data.pan_no) {
                if (!panRegex.test(data.pan_no)) {
                    errors.pan_no = "Invalid PAN Number";
                    setErrorLable(true);
                    setPannoStatus(true);
                } else if (data.pan_no.length > 10) {
                    errors.pan_no = "Invalid PAN Number length should be less than 10";
                    setPannoStatus(true);
                } else {
                    setPannoStatus(false);
                }
            } else {
                setPannoStatus(false);
            }

            //GST No
            if (data.gst_no) {
                if (!gstregex.test(data.gst_no)) {
                    errors.gst_no = "Invalid GST Number";
                    setErrorLable(true);
                    setGstnoStatus(true);
                } else if (data.gst_no.length > 15) {
                    errors.gst_no = "Invalid GST Number length should be less than 15";
                    setGstnoStatus(true);
                } else {
                    setGstnoStatus(false);
                }
            } else {
                setGstnoStatus(false);
            }

            //prefix
            if (data.prefix) {
                if (!prefixvalid.test(data.prefix)) {
                    errors.prefix = "Invalid Prefix";
                    setErrorLable(true);
                    setPrefixStatus(true);
                } else if (data.prefix.length > 10) {
                    errors.prefix = "Invalid Prefix length should be less than 10";
                    setPrefixStatus(true);
                } else {
                    setPrefixStatus(false);
                }
            } else {
                setPrefixStatus(false);
            }

            //first name
            if (!data.cfname) {
                errors.cfname = "Please enter First Name";
                setErrorLable(true);
                setFnameStatus(true);
            } else if (data.cfname) {
                if (data.cfname.trim().length > 30) {
                    errors.cfname = "Name length should be under 30 limitation";
                    setErrorLable(true);
                    setFnameStatus(true);
                } else {
                    if (!nameRegex.test(data.cfname)) {
                        errors.cfname =
              "Invalid name. Numbers and special characters are not allowed";
                        setErrorLable(true);
                        setFnameStatus(true);
                    } else {
                        setFnameStatus(false);
                    }
                }
            }

            //middle name
            if (data.cmname) {
                if (!nameRegex.test(data.cmname)) {
                    errors.cmname = "Invalid Name. Only letters are allowed.";
                    setErrorLable(true);
                    setMidnameStatus(true);
                } else if (data.cmname.trim().length > 30) {
                    errors.cmname = "Name length should be under 30 limitation.";
                    setErrorLable(true);
                    setMidnameStatus(true);
                } else {
                    if (!nameRegex.test(data.cmname)) {
                        errors.cmname =
              "Invalid name. Numbers and special characters are not allowed.";
                        setErrorLable(true);
                        setMidnameStatus(true);
                    } else {
                        setMidnameStatus(false);
                    }
                }
            }
            //last name
            if (!data.clname) {
                errors.clname = "Please enter Last Name";
                setErrorLable(true);
                setLnameStatus(true);
            } else if (data.clname) {
                if (data.clname.trim().length > 30) {
                    errors.clname = "Last Name length should be under 30 limitation.";
                    setErrorLable(true);
                    setLnameStatus(true);
                } else {
                    if (!nameRegex.test(data.clname)) {
                        errors.clname =
              "Invalid name. Numbers and special characters are not allowed.";
                        setErrorLable(true);
                        setLnameStatus(true);
                    } else {
                        setLnameStatus(false);
                    }
                }
            }

            //phone number
            if (data.cphoneno) {
                if (!phnnorgx.test(data.cphoneno)) {
                    errors.cphoneno = "Please enter Valid Phone Number";
                    setErrorLable(true);
                    setPhnoStatus(true);
                } else {
                    setPhnoStatus(false);
                }
            } else {
                setPhnoStatus(false);
            }

            //mobile no
            if (!data.cmobileno) {
                errors.cmobileno = "Please enter Mobile Number";
                setMobileStatus(true);
            } else {
                if (!mobilenoRegex.test(data.cmobileno)) {
                    errors.cmobileno = "Please enter Valid Mobile Number";
                    setErrorLable(true);
                    setMobileStatus(true);
                } else {
                    setMobileStatus(false);
                }
            }
            //mail
            if (data.cemail) {
                if (!emailRegex.test(data.cemail)) {
                    errors.cemail = "Invalid email format";
                    setErrorLable(true);
                    setCemailStatus(true);
                } else if (data.cemail.length > 60) {
                    errors.cemail = "Email length should be under 60 limitation.";
                    setErrorLable(true);
                    setCemailStatus(true);
                } else {
                    setCemailStatus(false);
                }
            } else {
                setCemailStatus(false);
            }

            //address 1
            if (!data.bill_add1) {
                errors.bill_add1 = "Please enter Address 1";
                setErrorLable(true);
                setAddress1Status(true);
            } else if (data.bill_add1) {
                if (!Addressrgx.test(data.bill_add1)) {
                    errors.bill_add1 = "No special character is allowed";
                    setErrorLable(true);
                    setAddress1Status(true);
                } else if (data.bill_add1.length > 70) {
                    errors.bill_add1 = "Address1 length should be under 70 limitation.";
                    setErrorLable(true);
                    setAddress1Status(true);
                } else {
                    setAddress1Status(false);
                }
            }
            //address 2
            if (data.bill_add2) {
                if (!Addressrgx.test(data.bill_add2)) {
                    errors.bill_add2 = "No special character is allowed";
                    setErrorLable(true);
                    setAddress2Status(true);
                } else if (data.bill_add2.length > 70) {
                    errors.bill_add2 = "Address 2 length should be under 70 limitation.";
                    setErrorLable(true);
                    setAddress2Status(true);
                } else {
                    setAddress2Status(false);
                }
            }

            //town
            if (!data.bill_town) {
                errors.bill_town = "Please select Town";
                setErrorLable(true);
                setTownStatus(true);
            } else {
                setTownStatus(false);
            }
            //   //pincode
            //   if (!data.bill_zipcode) {
            //     errors.bill_zipcode = "zipcode is required.";
            //     setErrorLable(true);
            //   }

            //credit hold
            if (!data.credit_hold) {
                errors.credit_hold = "Please choose Credit hold";
                setErrorLable(true);
                setCreditHoldStatus(true);
            } else {
                setCreditHoldStatus(false);
            }

            //payment term
            if (!data.payment_term) {
                errors.payment_term = "Please select Payment Term.";
                setErrorLable(true);
                setPaymentTermStatus(true);
            } else {
                setPaymentTermStatus(false);
            }

            //tax area
            if (!data.tax_area) {
                errors.tax_area = "Please select Tax Area.";
                setErrorLable(true);
                setTaxAreaStatus(true);
            } else {
                setTaxAreaStatus(false);
            }

            //payment mode
            if (!data.payment_mode) {
                errors.payment_mode = "Please select Payment Mode.";
                setErrorLable(true);
                setPaymentModeStatus(true);
            } else {
                setPaymentModeStatus(false);
            }

            //invoice copy
            // if (!data.invoice_copy) {
            //     errors.invoice_copy = "invoice copy is required.";
            //     setErrorLable(true);
            //     setInvoiceCopyStatus(true);
            // } else if(data.invoice_copy){
            //     if(data.invoice_copy.length > 15) {
            //         errors.invoice_copy =
            //         "invoice copy length should be under 15 limitation.";
            //         setErrorLable(true);
            //         setInvoiceCopyStatus(true);
            //     } else if (!numberregx.test(data.invoice_copy)) {
            //         errors.invoice_copy = "Invalid invoice_copy";
            //         setErrorLable(true);
            //         setInvoiceCopyStatus(true);
            //     } else {
            //         setInvoiceCopyStatus(false);
            //     }
            // }
            //credit limit
            if (data.credit_limit) {
                if (!decRegex.test(data.credit_limit)) {
                    errors.credit_limit =
            "Invalid Credit limit. Only decimal upto two digits are allowed.";
                    setErrorLable(true);
                    setCreditlimStatus(true);
                } else if (data.credit_limit > 10000000.99) {
                    errors.credit_limit = "Invalid Credit limit.";
                    setErrorLable(true);
                    setCreditlimStatus(true);
                } else {
                    setCreditlimStatus(false);
                }
            } else {
                setCreditlimStatus(false);
            }

            //trade discount
            if (!data.trade_discount) {
                errors.trade_discount = "Please enter Trade Discount";
                setErrorLable(true);
                setTradeDiscStatus(true);
            } else if (data.trade_discount > 99) {
                errors.trade_discount = "Trade Discount length should be maximum 99%.";
                setErrorLable(true);
                setTradeDiscStatus(true);
            } else {
                if (!decRegex.test(data.trade_discount)) {
                    errors.trade_discount = "Invalid Trade Discount.";
                    setErrorLable(true);
                    setTradeDiscStatus(true);
                } else {
                    setTradeDiscStatus(false);
                }
            }

            // min order
            // if (data.min_order) {
            //     if (!decRegex.test(data.min_order)) {
            //         errors.min_order = "Invalid order Number";
            //         setErrorLable(true);
            //         setMinorderStatus(true);
            //     } else if (data.min_order > 1000) {
            //         errors.min_order = "Invalid order limit";
            //         setErrorLable(true);
            //         setMinorderStatus(true);
            //     } else {
            //         setMinorderStatus(false);
            //     }
            // } else {
            //     setMinorderStatus(false);
            // }
            // //max order
            // if (data.max_order) {
            //     if (!decRegex.test(data.max_order)) {
            //         errors.max_order = "Invalid order Number";
            //         setErrorLable(true);
            //         setMaxorderStatus(true);
            //     } else if (data.max_order > 10000) {
            //         errors.max_order = "Invalid order Limit";
            //         setErrorLable(true);
            //         setMaxorderStatus(true);
            //     } else {
            //         setMaxorderStatus(false);
            //     }
            // } else {
            //     setMaxorderStatus(false);
            // }
            //transport charge
            // if (data.transport_charges) {
            //     if (!decRegex.test(data.transport_charges)) {
            //         errors.transport_charges = "Invalid input";
            //         setErrorLable(true);
            //         setTranschrgStatus(true);
            //     } else if (data.transport_charges.length > 8) {
            //         errors.transport_charges = "Invalid transport_charges";
            //         setErrorLable(true);
            //         setTranschrgStatus(true);
            //     } else {
            //         setTranschrgStatus(false);
            //     }
            // } else {
            //     setTranschrgStatus(false);
            // }
            //priority
            // if (data.priority) {
            //     if (!numberregx.test(data.priority)) {
            //         errors.priority = "Invalid input";
            //         setErrorLable(true);
            //         setPriortyStatus(true);
            //     } else if (data.priority.length > 8) {
            //         errors.priority = "Invalid priority";
            //         setErrorLable(true);
            //         setPriortyStatus(true);
            //     } else {
            //         setPriortyStatus(false);
            //     }
            // } else {
            //     setPriortyStatus(false);
            // }

            //delivery instruction
            if (data.delivery_inst) {
                if (!nameRegex.test(data.delivery_inst)) {
                    errors.delivery_inst = "Invalid input";
                    setErrorLable(true);
                    setDelvinsStatus(true);
                } else if (data.delivery_inst.length > 60) {
                    errors.delivery_inst = "Invalid instruction, should be less than 60";
                    setErrorLable(true);
                    setDelvinsStatus(true);
                } else {
                    setDelvinsStatus(false);
                }
            } else {
                setDelvinsStatus(false);
            }
            // billing instruction
            if (data.billing_inst) {
                if (!nameRegex.test(data.billing_inst)) {
                    errors.billing_inst = "Invalid input";
                    setErrorLable(true);
                    setBillinsStatus(true);
                } else if (data.billing_inst.length > 60) {
                    errors.billing_inst = "Invalid instruction. should be less than 60";
                    setErrorLable(true);
                    setBillinsStatus(true);
                } else {
                    setBillinsStatus(false);
                }
            } else {
                setBillinsStatus(false);
            }

            //delivery
            if (!data.p_day_of_delivery) {
                errors.p_day_of_delivery = "Please select Prefered Delivery Day";
                setErrorLable(true);
                setPreferedDayStatus(true);
            } else {
                setPreferedDayStatus(false);
            }

            //employee select
            if (!data.employeeSelect) {
                errors.employeeSelect = "Please select Executive Name";
                setErrorLable(true);
            }
            //beat frequency
            if (!data.beat_frequency) {
                errors.beat_frequency = "Please select Visit Frequency.";
                setErrorLable(true);
            }
            //day beat
            if (!data.day_of_beat1) {
                errors.day_of_beat1 = "Please select Customer Visit Day.";
                setErrorLable(true);
            }

            //address 1 (shipping)

            if (!data.sadd1) {
                errors.sadd1 = "Please enter Address 1";
                setErrorLable(true);
                setAddress1Status1(true);
            } else if (data.sadd1) {
                if (!Addressrgx.test(data.sadd1)) {
                    errors.sadd1 = "No special character is allowed";
                    setErrorLable(true);
                    setAddress1Status1(true);
                } else if (data.sadd1.length > 70) {
                    errors.sadd1 = "Address 1 length should be 70.";
                    setErrorLable(true);
                    setAddress1Status1(true);
                } else {
                    setAddress1Status1(false);
                }
            }
            //address2(shipping)
            if (data.sadd2) {
                if (data.sadd2.length > 70) {
                    errors.sadd2 = "Address 2 length should be  70.";
                    setErrorLable(true);
                    setShipaddStatus(true);
                } else if (!Addressrgx.test(data.sadd2)) {
                    errors.sadd2 = "No special character is allowed";
                    setErrorLable(true);
                    setShipaddStatus(true);
                } else {
                    setShipaddStatus(false);
                }
            } else {
                setShipaddStatus(false);
            }

            //town (shipping)
            if (!data.ship_town) {
                errors.ship_town = "Please select Town";
                setErrorLable(true);
                setTownStatus1(true);
            } else {
                setTownStatus1(false);
            }

            //van route
            // if (!data.van_route) {
            //     errors.van_route = "Please select Van Route";
            //     setErrorLable(true);
            //     setVanRouteStatus(true);
            // } else {
            //     setVanRouteStatus(false);
            // }

            //GST NO SHIP
            // if (data.ship_gst_no) {
            //     if (!noregx.test(data.ship_gst_no)) {
            //         errors.ship_gst_no = "Invalid GST No.";
            //         setErrorLable(true);
            //         setShipgstStatus(true);
            //     } else if (data.ship_gst_no.length > 10) {
            //         errors.ship_gst_no = "Invalid GST No. should be less than 10";
            //         setErrorLable(true);
            //         setShipgstStatus(true);
            //     } else {
            //         setShipgstStatus(false);
            //     }
            // } else {
            //     setShipgstStatus(false);
            // }

            //title
            if (data.ship_title) {
                if (!nameRegex.test(data.ship_title)) {
                    errors.ship_title = "Invalid input";
                    setErrorLable(true);
                    setStitleStatus(true);
                } else if (data.ship_title.length > 10) {
                    errors.ship_title = "Prefix length should be under 10 limitation.";
                    setErrorLable(true);
                    setStitleStatus(true);
                } else {
                    setStitleStatus(false);
                }
            } else {
                setStitleStatus(false);
            }

            //first name(shipping)
            if (!data.fname) {
                errors.fname = "Please enter First Name";
                setErrorLable(true);
                setFnameStatus1(true);
            } else if (data.fname) {
                if (data.fname.trim().length > 30) {
                    errors.fname = "Name length should be under 15.";
                    setErrorLable(true);
                    setFnameStatus1(true);
                } else {
                    if (!nameRegex.test(data.fname)) {
                        errors.fname =
              "Invalid name. Numbers and special characters are not allowed.";
                        setErrorLable(true);
                        setFnameStatus1(true);
                    } else {
                        setFnameStatus1(false);
                    }
                }
            }
            //middle name (shipping)

            if (data.mname) {
                if (!nameRegex.test(data.mname)) {
                    errors.mname = "Invalid Name. Only letters are allowed.";
                    setErrorLable(true);
                    setSmidnameStatus(true);
                } else if (data.mname.trim().length > 30) {
                    errors.cmname = "Name length should be under 30 limitation.";
                    setErrorLable(true);
                    setSmidnameStatus(true);
                } else {
                    if (!nameRegex.test(data.mname)) {
                        errors.mname =
              "Invalid name. Numbers and special characters are not allowed.";
                        setErrorLable(true);
                        setSmidnameStatus(true);
                    } else {
                        setSmidnameStatus(false);
                    }
                }
            }

            //last name (shipping)
            if (!data.lname) {
                errors.lname = "Please enter Last Name";
                setErrorLable(true);
                setLnameStatus1(true);
            } else if (data.lname) {
                if (data.lname.trim().length > 30) {
                    errors.lname = "Last name length should be under 30.";
                    setErrorLable(true);
                    setLnameStatus1(true);
                } else {
                    if (!nameRegex.test(data.lname)) {
                        errors.lname =
              "Invalid name. Numbers and special characters are not allowed.";
                        setErrorLable(true);
                        setLnameStatus1(true);
                    } else {
                        setLnameStatus1(false);
                    }
                }
            }
            //phone number (shipping)
            if (data.phno) {
                if (!phnnorgx.test(data.phno)) {
                    errors.phno = "Invalid Phone Number.";
                    setErrorLable(true);
                    setSphnumStatus(true);
                } else {
                    setSphnumStatus(false);
                }
            } else {
                setSphnumStatus(false);
            }

            //mobile no (shipping)
            if (!data.mob) {
                errors.mob = "Please enter Mobile Number";
                setMobileStatus1(true);
            } else {
                if (!mobilenoRegex.test(data.mob)) {
                    errors.mob = "Invalid mobile no.";
                    setErrorLable(true);
                    setMobileStatus1(true);
                } else {
                    setMobileStatus1(false);
                }
            }

            // //ship mail
            // if (data.ship_co_email) {
            //     if (data.ship_co_email.length > 60) {
            //         errors.ship_co_email = "Email length should be under 60 limitation.";
            //         setErrorLable(true);
            //         setShipemailStatus(true);
            //     } else {
            //         setShipemailStatus(false);
            //     }
            // }

            //ship email
            // if (data.ship_co_email) {
            //   if (!emailRegex.test(data.ship_co_email)) {
            //     errors.ship_co_email = "Invalid input email type";
            //     setErrorLable(true);
            //   }
            // }

            if (data.ship_co_email) {
                if (!emailRegex.test(data.ship_co_email)) {
                    errors.ship_co_email = "Invalid email format";
                    setErrorLable(true);
                    setShipemailStatus(true);
                } else if (data.ship_co_email.length > 60) {
                    errors.ship_co_email = "Email length should be under 60 limitation.";
                    setErrorLable(true);
                    setShipemailStatus(true);
                } else {
                    setShipemailStatus(false);
                }
            } else {
                setShipemailStatus(false);
            }

            // if (!data.tax_form) {
            //   errors.tax_form = "Select tax form";
            // }

            return errors;
        },
    });
    // credit hold btn-----
    const radioBtns_credit_hold = [
        { id: "1", name: "Yes", value: "Y" },
        { id: "2", name: "No", value: "N" },
    ];

    useEffect(() => {
        if (!newCustomerSave) {
            if (customerDetailsById.KYC == "BS" || customerDetailsById.KYC == "R") {
                toast.error(
                    `KYC Status of the selected customer is ${
                        customerDetailsById.KYC == "BS"
                            ? "Billing Stopped"
                            : customerDetailsById.KYC == "R"
                                ? "Rejected"
                                : ""
                    }, kindly get the customer KYC Approved to continue`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 4000,
                    }
                );
            }
        }
    }, [customerDetailsById]);

    // if(!newCustomerSave){
    //     if(customerDetailsById.KYC=="BS"||customerDetailsById.KYC=="R"){
    //         toast.error(`KYC Status of the selected customer is ${customerDetailsById.KYC=="BS" ? "Billing Stopped":"Rejected"}, kindly get the customer KYC Approved to continue`, {
    //             position: toast.POSITION.TOP_CENTER,
    //             pauseOnFocusLoss: false,
    //             closeButton: false,
    //             autoClose: 4000,
    //         });
    //     }
    // }

    if (!newCustomerSave) {
        formik.initialValues.code = customerDetailsById.PartyId;
        formik.initialValues.name = customerDetailsById.Name;
        formik.initialValues.unique_code = customerDetailsById.UniqueCode;
        formik.initialValues.creation_date = new Date(creationDate);
        // formik.initialValues.expiry_date = expiryDate?"":new Date(expiryDate);
        formik.initialValues.expiry_date =
      customerDetailsById.ExpiryDate == "" ? "" : new Date(expiryDate);
        formik.initialValues.tax_form = taxForm.filter((val) => {
            return val.NAME == customerDetailsById.TaxForm;
        })[0];
        formik.initialValues.payment_term = paymentTerm.filter((val) => {
            return val.NAME == customerDetailsById.PayTerm;
        })[0];
        formik.initialValues.tax_area = taxArea.filter((val) => {
            return val.NAME == customerDetailsById.TaxArea;
        })[0];
        formik.initialValues.payment_mode = modeOfPayment.filter((val) => {
            return val.NAME == customerDetailsById.ModeOfPayment;
        })[0];
        formik.initialValues.p_day_of_delivery = preferedDay.filter((val) => {
            return val.id == customerDetailsById.PrefferedDay;
        })[0];
        if (customerDetailsById.Employee != null) {
            formik.initialValues.employeeSelect = employeeList.filter((val) => {
                return val.NAME == customerDetailsById.Employee;
            })[0];
        }

        formik.initialValues.beat_frequency = beatFrequency.filter((val) => {
            return val.NAME == customerDetailsById.Frequency;
        })[0];
        if (customerDetailsById.DayOfWeek != null) {
            formik.initialValues.day_of_beat1 = dayOfWeek.filter((val) => {
                return val.NAME == customerDetailsById.DayOfWeek;
            })[0];
        }
        formik.initialValues.bill_town = townList.filter((val) => {
            return (
                val.NAME == customerDetailsById.BillTown &&
        val.GEOG_ID == customerDetailsById.BillGeogId
            );
        })[0];
        formik.initialValues.ship_town = shipTownList.filter((val) => {
            return val.TOWN == customerDetailsById.ShipTown;
        })[0];
        if (customerDetailsById.KYC == "S") {
            formik.initialValues.kyc_status = "Under Approval";
        } else if (customerDetailsById.KYC == "A") {
            formik.initialValues.kyc_status = "Approved";
        } else if (customerDetailsById.KYC == "R") {
            formik.initialValues.kyc_status = "Rejected";
        } else if (customerDetailsById.KYC == "RSS") {
            formik.initialValues.kyc_status = "Re-Submitted (Smart)";
        } else if (customerDetailsById.KYC == "RSC") {
            formik.initialValues.kyc_status = "Re-Submitted (CSD)";
        } else if (customerDetailsById.KYC == "BS") {
            formik.initialValues.kyc_status = "Billing Stopped";
        } else if (customerDetailsById.KYC == "AP") {
            formik.initialValues.kyc_status = "Under Approval";
        } else {
            formik.initialValues.kyc_status = "Not Applicable";
        }

        formik.initialValues.kyc_check =
      customerDetailsById.Status == "Y" ? true : false;
        //     formik.initialValues.app_per =
        //   customerDetailsById.Permission;
        formik.initialValues.latitude = customerDetailsById.Lat;
        formik.initialValues.longitude = customerDetailsById.Long;
        if (customerDetailsById.Lat == "" || customerDetailsById.Long == "") {
            formik.initialValues.latitude = latitude;
            formik.initialValues.longitude = longitude;
        } else {
            formik.initialValues.latitude = customerDetailsById.Lat;
            formik.initialValues.longitude = customerDetailsById.Long;
        }
        formik.initialValues.pan_no = customerDetailsById.PAN_NO;
        formik.initialValues.caadhar = customerDetailsById.AADHARNO;
        formik.initialValues.gst_no = customerDetailsById.GSTNo;
        // if(customerDetailsById.KYC=="R"||customerDetailsById.KYC=="S"){
        //     //
        //     setKycstatus(true);
        // }

        formik.initialValues.prefix = customerDetailsById.Prefix;
        formik.initialValues.cfname = customerDetailsById.BillFirstname;
        formik.initialValues.cmname = customerDetailsById.BillMiddlename;
        formik.initialValues.clname = customerDetailsById.BillLastname;
        formik.initialValues.cphoneno = customerDetailsById.BillPhoneNo;
        formik.initialValues.cmobileno = customerDetailsById.BillOtherPhoneNo;
        formik.initialValues.cemail = customerDetailsById.BillEmail;
        formik.initialValues.bill_add1 = customerDetailsById.BillAdd1;
        formik.initialValues.bill_add2 = customerDetailsById.BillAdd2;
        formik.initialValues.bill_state = customerDetailsById.BillState;
        formik.initialValues.bill_district = customerDetailsById.BillDistrict;
        formik.initialValues.bill_zipcode = customerDetailsById.BillPinCode;
        formik.initialValues.ship_to_name = customerDetailsById.ShipToName;
        formik.initialValues.sadd1 = customerDetailsById.ShipAdd1;
        formik.initialValues.sadd2 = customerDetailsById.ShipAdd2;
        formik.initialValues.ship_state = customerDetailsById.ShipState;
        formik.initialValues.ship_district = customerDetailsById.ShipDistrict;
        formik.initialValues.ship_zipcode = customerDetailsById.ShipPinCode;
        formik.initialValues.min_order = customerDetailsById.MinOrderVal;
        formik.initialValues.max_order = customerDetailsById.MaxOrderVal;
        formik.initialValues.transport_charges =
      customerDetailsById.TransportCharges;
        formik.initialValues.priority = customerDetailsById.Priority;
        formik.initialValues.delivery_inst = customerDetailsById.CustdeliveryInst;
        formik.initialValues.billing_inst = customerDetailsById.BillingIns;
        formik.initialValues.van_route = vanRoute.filter((val) => {
            return val.NAME == customerDetailsById.VanRt;
        })[0];
        formik.initialValues.ship_title = customerDetailsById.ShipTitle;
        formik.initialValues.fname = customerDetailsById.ShipFirstname;
        formik.initialValues.mname = customerDetailsById.ShipMiddlename;
        formik.initialValues.lname = customerDetailsById.ShipLastname;
        formik.initialValues.phno = customerDetailsById.ShipPhoneNo;
        formik.initialValues.mob = customerDetailsById.ShipOtherPhoneNo;
        formik.initialValues.ship_co_email = customerDetailsById.ShipEmail;
        formik.initialValues.invoice_copy = customerDetailsById.InvCopies;
        formik.initialValues.credit_limit = customerDetailsById.CreditLimit;
        formik.initialValues.trade_discount = customerDetailsById.TradeDiscount;
        formik.initialValues.credit_hold = customerDetailsById.CreditHold;
        formik.initialValues.ship_gst_no = customerDetailsById.ShipToGSTN;
        formik.initialValues.tin_no = customerDetailsById.TINNO;
        formik.initialValues.cust_url = customerDetailsById.CustPhoto;
        formik.initialValues.cust_photo = customerDetailsById.CustStore;
    } else {
    // formik.initialValues.code = "";
        formik.initialValues.name = "";
        formik.initialValues.unique_code = "";
        formik.initialValues.creation_date = "";
        formik.initialValues.expiry_date = "";
        formik.initialValues.tax_form = "";
        formik.initialValues.payment_term = "";
        formik.initialValues.tax_area = "";
        formik.initialValues.payment_mode = "";
        formik.initialValues.p_day_of_delivery = "";
        // if (customerDetailsById.Employee != null) {
        // }
        formik.initialValues.employeeSelect = "";

        formik.initialValues.beat_frequency = "";
        // if (customerDetailsById.DayOfWeek != null) {
        //     formik.initialValues.day_of_beat1 = "";
        // }
        formik.initialValues.day_of_beat1 = "";
        formik.initialValues.bill_town = "";
        formik.initialValues.ship_town = "";

        if (customerDetailsById.KYC == "N") {
            formik.initialValues.kyc_status = "";
        } else if (customerDetailsById.KYC == "R") {
            formik.initialValues.kyc_status = "";
        } else if (customerDetailsById.KYC == "S") {
            formik.initialValues.kyc_status = "";
        } else if (customerDetailsById.KYC == "A") {
            formik.initialValues.kyc_status = "";
        } else if (customerDetailsById.KYC == "T") {
            formik.initialValues.kyc_status = "";
        } else {
            formik.initialValues.kyc_status = "";
        }

        formik.initialValues.kyc_check = "";
        formik.initialValues.latitude = "";
        formik.initialValues.longitude = "";
        formik.initialValues.pan_no = "";
        formik.initialValues.caadhar = "";
        formik.initialValues.gst_no = "";

        formik.initialValues.prefix = "";
        formik.initialValues.cfname = "";
        formik.initialValues.cmname = "";
        formik.initialValues.clname = "";
        formik.initialValues.cphoneno = "";
        formik.initialValues.cmobileno = "";
        formik.initialValues.cemail = "";
        formik.initialValues.bill_add1 = "";
        formik.initialValues.bill_add2 = "";
        formik.initialValues.bill_state = "";
        formik.initialValues.bill_district = "";
        formik.initialValues.bill_zipcode = "";
        formik.initialValues.ship_to_name = "";
        formik.initialValues.sadd1 = "";
        formik.initialValues.sadd2 = "";
        formik.initialValues.ship_state = "";
        formik.initialValues.ship_district = "";
        formik.initialValues.ship_zipcode = "";
        formik.initialValues.min_order = "";
        formik.initialValues.max_order = "";
        formik.initialValues.transport_charges = "";
        formik.initialValues.priority = "";
        formik.initialValues.delivery_inst = "";
        formik.initialValues.billing_inst = "";
        formik.initialValues.van_route = "";
        formik.initialValues.ship_title = "";
        formik.initialValues.fname = "";
        formik.initialValues.mname = "";
        formik.initialValues.lname = "";
        formik.initialValues.phno = "";
        formik.initialValues.mob = "";
        formik.initialValues.ship_co_email = "";
        formik.initialValues.invoice_copy = "";
        formik.initialValues.credit_limit = "";
        formik.initialValues.trade_discount = "0.00";
        formik.initialValues.credit_hold = "N";
        formik.initialValues.latitude = latitude;
        formik.initialValues.longitude = longitude;
        formik.initialValues.ship_gst_no = "";
        formik.initialValues.tin_no = "";
    }

    // END PRIMENG------------------------------------------------------------------------
    const isFormFieldInvalid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? (
            <small className="p-error">{formik.errors[name]}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };

    // const KYCCheck = (data) => {
    //     if (data == "S") {
    //         formik.initialValues.kyc_status = "Under Approval";
    //     } else if (data == "A") {
    //         formik.initialValues.kyc_status = "Approved";
    //     } else if (data == "R") {
    //         formik.initialValues.kyc_status = "Rejected";
    //     } else if (data == "RSS") {
    //         formik.initialValues.kyc_status = "Re-Submitted (Smart)";
    //     } else if (data == "RSC") {
    //         formik.initialValues.kyc_status = "Re-Submitted (CSD)";
    //     } else if (data == "BS") {
    //         formik.initialValues.kyc_status = "Billing Stopped";
    //     } else if (data == "AP") {
    //         formik.initialValues.kyc_status = "Under Approval";
    //     } else {
    //         formik.initialValues.kyc_status = "Not Applicable";
    //     }
    // };

    useEffect(() => {
        if (Object.keys(formik.errors).length !== 0) {
            toast.error(
                "Please fill all the mandatory field marked as (*) with valid Data",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                }
            );
        }
    }, [formik.submitCount]);

    const handleCopyShipAdd = (e) => {
        setCopyToShip(true);
        if (e.value) {
            formik.setFieldValue("sadd1", formik.values.bill_add1);
            formik.setFieldValue("sadd2", formik.values.bill_add2);
        } else {
            formik.setFieldValue("sadd1", "");
            formik.setFieldValue("sadd2", "");
        }

        const isBillTownInShipTownList = !!shipTownList.find(
            (town) => town.TOWN === formik.values.bill_town.NAME
        );

        if (!isBillTownInShipTownList) {
            toast.error("This data do not exist", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        } else {
            if (e.value) {
                formik.setFieldValue("ship_town", formik.values.bill_town);
                formik.setFieldValue("ship_state", formik.values.bill_state);
                formik.setFieldValue("ship_district", formik.values.bill_district);
                formik.setFieldValue("ship_zipcode", formik.values.bill_zipcode);
                shipTownHandleChange(formik.values.bill_town, 2);
            }
        }
        formik.setFieldValue("copy_to_ship_address", e.value);
    };

    const handleCopyShipCont = (e) => {
        formik.setFieldValue("copy_to_ship_contact", e.value);
        if (e.value) {
            formik.setFieldValue("ship_title", formik.values.prefix);
            formik.setFieldValue("fname", formik.values.cfname);
            formik.setFieldValue("mname", formik.values.cmname);
            formik.setFieldValue("lname", formik.values.clname);
            formik.setFieldValue("phno", formik.values.cphoneno);
            formik.setFieldValue("mob", formik.values.cmobileno);
            formik.setFieldValue("ship_co_email", formik.values.cemail);
        } else {
            formik.setFieldValue("ship_title", "");
            formik.setFieldValue("fname", "");
            formik.setFieldValue("mname", "");
            formik.setFieldValue("lname", "");
            formik.setFieldValue("phno", "");
            formik.setFieldValue("mob", "");
            formik.setFieldValue("ship_co_email", "");
        }
    };

    if (editCustMastLoader || customerSaveUpdateLoader) {
        return <Loader />;
    } else {
        return (
            <div>
                <main>
                    {/* <div className="content-wrapper pt-3"> */}
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/masters/customermaster">Customer Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                      Details
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/masters/customermaster">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                      Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="row CustomerTabs">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <form onSubmit={formik.handleSubmit}>
                                <Tabs
                                    defaultActiveKey={0}
                                    id="fill-tab-example"
                                    className="mt-2"
                                >
                                    <Tab eventKey={0} title="Basic Information">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header x>
                                                    {formik.submitCount > 0 &&
                            (nameStatus ||
                              segStatus ||
                              tinStatus ||
                              pannoStatus ||
                              gstnoStatus) ? (
                                                            <div style={{ color: "red" }}>
                                General Information <Fi.FiAlertTriangle />
                                                            </div>
                                                        ) : (
                                                            <>General Information</>
                                                        )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">
                                  Code <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="code"
                                                                name="code"
                                                                class="form-control"
                                                                value={
                                                                    newCustomerSave
                                                                        ? (formik.values.code =
                                          partyCodeOnSave.AccountId)
                                                                        : formik.values.code
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "code",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("code"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* {getFormErrorMessage("code")} */}
                                                            {/* <Field
                                    name="code"
                                    class="form-control"
                                    type="text"
                                    placeholder="C5957"
                                    disabled
                                />
                                <div className="err">
                                    <ErrorMessage name="code" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Customer Name{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="name"
                                                                name="name"
                                                                class="form-control"
                                                                value={formik.values.name}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "name",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("name"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled={kycfieldDisabled}
                                                            />
                                                            {getFormErrorMessage("name")}
                                                            {/* <Field
                                    name="name"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                />
                                <div className="err">
                                    <ErrorMessage name="name" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Unique Code{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="unique_code"
                                                                name="unique_code"
                                                                value={
                                                                    !newCustomerSave
                                                                        ? formik.values.unique_code
                                                                        : !manualTownEnter
                                                                            ? formik.values.unique_code
                                                                            : (formik.values.unique_code =
                                          newCutomerUniCode)
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "unique_code",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("unique_code"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {!uniqueCodeBtn &&
                                  getFormErrorMessage("unique_code")}
                                                            {/* <Field
                                    name="unique_code"
                                    class="form-control"
                                    type="Number"
                                    placeholder="0123456789"
                                    disabled
                                />
                                <div className="err">
                                    <ErrorMessage name="unique_code" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">
                                  Creation Date{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>

                                                            <div className="card flex justify-content-center">
                                                                {" "}
                                                                <Calendar
                                                                    inputId="creation_date"
                                                                    name="creation_date"
                                                                    value={
                                                                        newCustomerSave?formik.initialValues.creation_date =
                                                                                new Date():formik.initialValues.creation_date
                                                                    }
                                                                    className={classNames({
                                                                        "p-invalid":
                                        isFormFieldInvalid("creation_date"),
                                                                    })}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "creation_date",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    disabled
                                                                    showIcon
                                                                    dateFormat="dd/mm/yy"
                                                                />
                                                            </div>
                                                            {/* {getFormErrorMessage("creation_date")} */}
                                                            {/* <Field
                                    name="creation_date"
                                    class="form-control"
                                    type="date"
                                    placeholder="Enter Here..."
                                />
                                <div className="err">
                                    <ErrorMessage name="creation_date" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Expiry Date
                                                            </label>
                                                            {/* <span className="mandatory">*</span> */}
                                                            <div className="card flex justify-content-center">
                                                                {" "}
                                                                <Calendar
                                                                    inputId="expiry_date"
                                                                    name="expiry_date"
                                                                    value={formik.values.expiry_date}
                                                                    className={classNames({
                                                                        "p-invalid":
                                        isFormFieldInvalid("expiry_date"),
                                                                    })}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "expiry_date",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    minDate={new Date()}
                                                                    showIcon
                                                                    dateFormat="dd/mm/yy"
                                                                />
                                                            </div>
                                                            {getFormErrorMessage("expiry_date")}
                                                            {/* <Field
                                    name="expiry_date"
                                    class="form-control"
                                    type="date"
                                    placeholder="Enter Here..."
                                />
                                <div className="err">
                                    <ErrorMessage name="expiry_date" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Segment <span className="mandatory">*</span>
                                                            </label>
                                                            <div className="input-group">
                                                                <InputText
                                                                    id="segment"
                                                                    name="segment"
                                                                    placeholder="Select"
                                                                    value={
                                                                        !newCustomerSave
                                                                            ? !manualSegmentEnterBtn
                                                                                ? (formik.values.segment =
                                              customerDetailsById.Segment)
                                                                                : (formik.values.segment =
                                              segmentValue.SEG_DESC)
                                                                            : !manualSegmentEnterBtn
                                                                                ? (formik.values.segment = "")
                                                                                : (formik.values.segment =
                                            segmentValue.SEG_DESC)
                                                                    }
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "segment",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        {
                                                                            "p-invalid":
                                          isFormFieldInvalid("segment"),
                                                                        },
                                                                        "form-control"
                                                                    )}
                                                                    disabled
                                                                />
                                                                {/* {!manualSegmentEnterBtn
                                                                            ? getFormErrorMessage("segment")
                                                                            : null} */}
                                                                {/* <Field
                                    name="segment"
                                    class="form-control"
                                    type="text"
                                    placeholder="Search Here..."
                                    disabled
                                    />{" "} */}
                                                                {/* {!editView && (
                                                                        <div className="input-group-prepend">
                                                                            {" "}
                                                                            <span
                                                                                className="input-group-text ms-2"
                                                                                onClick={() => {
                                                                                    setShow(true);

                                                                                    getSegmentForBill();
                                                                                    setSegmentConfirmBtn(false);
                                                                                }}
                                                                            >
                                                                                <Fa.FaSearch />
                                                                            </span>{" "}
                                                                        </div>
                                                                    )}{" "} */}
                                                                {newCustomerSave && (
                                                                    <div className="input-group-prepend">
                                                                        {" "}
                                                                        <span
                                                                            className="input-group-text ms-2"
                                                                            onClick={() => {
                                                                                setShow(true);

                                                                                getSegmentForBill();
                                                                                setSegmentConfirmBtn(false);
                                                                            }}
                                                                        >
                                                                            <Fa.FaSearch />
                                                                        </span>{" "}
                                                                    </div>
                                                                )}{" "}
                                                            </div>

                                                            {!manualSegmentEnterBtn
                                                                ? getFormErrorMessage("segment")
                                                                : null}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">KYC Status</label>
                                                            <InputText
                                                                id="kyc_status"
                                                                name="kyc_status"
                                                                class="form-control"
                                                                value={
                                                                    newCustomerSave
                                                                        ? segmentValue.KYC_YN == "Y"
                                                                            ? "Under Approval"
                                                                            : "Not Applicable"
                                                                        : formik.values.kyc_status
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "kyc_status",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("kyc_status"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                        </div>
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <div className="mt-4">
                                                                    <label className="form-label">
                                                                        Status <span className="mandatory">*</span>
                                                                    </label>
                                                                    <InputSwitch
                                                                        className="switch-pos ms-2"
                                                                        id="kyc_check"
                                                                        name="kyc_check"
                                                                        checked={formik.values.kyc_check}
                                                                        // checked={true}
                                                                        onChange={(e) => {
                                                                            formik.setFieldValue(
                                                                                "kyc_check",
                                                                                e.value
                                                                            );
                                                                        }}
                                                                    />

                                                                </div>
                                                            </div> */}

                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">
                                  Geographical Coordinates
                                                            </label>

                                                            <InputText
                                                                id="latitude"
                                                                name="latitude"
                                                                style={{ width: "49%" }}
                                                                value={formik.values.latitude}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "latitude",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("latitude"),
                                                                    },
                                                                    "form-control d-inline me-1"
                                                                )}
                                                                disabled
                                                            />

                                                            {/* <Field
                                    name="latitude"
                                    class="form-control d-inline me-1"
                                    style={{ width: "49%" }}
                                    type="text"
                                    placeholder="Enter Latitude"
                                    disabled
                                /> */}
                                                            <InputText
                                                                id="longitude"
                                                                style={{ width: "49%" }}
                                                                name="longitude"
                                                                value={formik.values.longitude}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "longitude",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("longitude"),
                                                                    },
                                                                    "form-control d-inline"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* <Field
                                    name="longitude"
                                    class="form-control  d-inline"
                                    style={{ width: "49%" }}
                                    type="text"
                                    placeholder="Enter Longitude"
                                    disabled
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">TIN Number</label>
                                                            <InputText
                                                                id="tin_no"
                                                                name="tin_no"
                                                                value={formik.values.tin_no}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "tin_no",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("tin_no"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("tin_no")}

                                                            {/* <Field
                                    name="tin_no"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">PAN Number</label>
                                                            <InputText
                                                                id="pan_no"
                                                                name="pan_no"
                                                                value={formik.values.pan_no}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "pan_no",
                                                                        e.target.value.toUpperCase()
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("pan_no"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("pan_no")}
                                                            {/* <Field
                                    name="pan_no"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">GST Number</label>
                                                            <InputText
                                                                id="gst_no"
                                                                name="gst_no"
                                                                value={formik.values.gst_no}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "gst_no",
                                                                        e.target.value.toUpperCase()
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("gst_no"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("gst_no")}
                                                        </div>
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <div className="">

                                                                    <label className="form-label">
                                                                        App Persmission
                                                                    </label>
                                                                    <InputSwitch
                                                                        className="switch-pos ms-2"
                                                                        id="app_per"
                                                                        name="app_per"
                                                                        checked={formik.values.app_per}
                                                                        onChange={(e) => {
                                                                            formik.setFieldValue("app_per", e.value);
                                                                        }}

                                                                    />

                                                                </div>
                                                            </div> */}
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>
                                                    {formik.submitCount > 0 &&
                            (fnameStatus ||
                              lnameStatus ||
                              mobileStatus ||
                              prefixStatus ||
                              midnameStatus ||
                              phnoStatus ||
                              cemailStatus) ? (
                                                            <div style={{ color: "red" }}>
                                Contact Person <Fi.FiAlertTriangle />
                                                            </div>
                                                        ) : (
                                                            <>Contact Person</>
                                                        )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">Prefix</label>
                                                            <InputText
                                                                id="prefix"
                                                                name="prefix"
                                                                value={formik.values.prefix}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "prefix",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("prefix"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("prefix")}
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  First Name{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="cfname"
                                                                name="cfname"
                                                                value={formik.values.cfname}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "cfname",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("cfname"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled={kycfieldDisabled}
                                                            />
                                                            {getFormErrorMessage("cfname")}
                                                            {/* <Field
                                    name="cfname"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                            {/* <div className="err">
                                    <ErrorMessage name="cfname" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Middle Name
                                                            </label>
                                                            <InputText
                                                                id="cmname"
                                                                name="cmname"
                                                                value={formik.values.cmname}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "cmname",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("cmname"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("cmname")}

                                                            {/* <Field
                                    name="cmname"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Last Name <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="clname"
                                                                name="clname"
                                                                value={formik.values.clname}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "clname",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("clname"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled={kycfieldDisabled}
                                                            />
                                                            {getFormErrorMessage("clname")}
                                                            {/* <Field
                                    name="clname"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                            {/* <div className="err">
                                    <ErrorMessage name="clname" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Phone Number
                                                            </label>
                                                            <InputText
                                                                id="cphoneno"
                                                                name="cphoneno"
                                                                value={formik.values.cphoneno}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "cphoneno",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("cphoneno"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("cphoneno")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Mobile Number
                                                            </label>
                                                            <span className="mandatory">*</span>
                                                            <InputText
                                                                id="cmobileno"
                                                                name="cmobileno"
                                                                value={formik.values.cmobileno}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "cmobileno",
                                                                        e.target.value.replace(/ d/g, "")
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("cmobileno"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled={kycfieldDisabled}
                                                            />
                                                            {getFormErrorMessage("cmobileno")}
                                                        </div>

                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Company Email
                                                            </label>
                                                            <InputText
                                                                id="cemail"
                                                                name="cemail"
                                                                value={formik.values.cemail}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "cemail",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("cemail"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("cemail")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="mt-4">
                                                                <label className="form-label">
                                    Copy to Ship
                                                                </label>

                                                                <InputSwitch
                                                                    className="switch-pos ms-2"
                                                                    id="copy_to_ship_contact"
                                                                    name="copy_to_ship_contact"
                                                                    checked={formik.values.copy_to_ship_contact}
                                                                    onChange={handleCopyShipCont}
                                                                    // onChange={(e) => {
                                                                    //     formik.setFieldValue(
                                                                    //         "copy_to_ship_contact",
                                                                    //         e.value
                                                                    //     );
                                                                    // }}

                                                                    // className={classNames({ 'p-invalid': isFormFieldInvalid('value') })}
                                                                    // disabled
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>
                                                    {formik.submitCount > 0 &&
                            (address1Status || address2Status || townStatus) ? (
                                                            <div style={{ color: "red" }}>
                                Address <Fi.FiAlertTriangle />{" "}
                                                            </div>
                                                        ) : (
                                                            <>Address</>
                                                        )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Address Line 1{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="bill_add1"
                                                                name="bill_add1"
                                                                value={formik.values.bill_add1}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "bill_add1",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("bill_add1"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled={kycfieldDisabled}
                                                            />
                                                            {getFormErrorMessage("bill_add1")}
                                                            {/* <Field
                                    name="bill_add1"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Address Line 2
                                                            </label>
                                                            <InputText
                                                                id="bill_add2"
                                                                name="bill_add2"
                                                                value={formik.values.bill_add2}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "bill_add2",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("bill_add2"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled={kycfieldDisabled}
                                                            />
                                                            {getFormErrorMessage("bill_add2")}
                                                            {/* <Field
                                    name="bill_add2"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Town <span className="mandatory">*</span>
                                                            </label>
                                                            <div className="d-block">
                                                                <AutoComplete
                                                                    field="NAME"
                                                                    virtualScrollerOptions={{
                                                                        itemSize: 38,
                                                                    }}
                                                                    // value={
                                                                    //   !manualTownEnter
                                                                    //     ? customerDetailsById.BillTown
                                                                    //     : selectedTown
                                                                    // }
                                                                    value={formik.values.bill_town}
                                                                    suggestions={townSuggestion}
                                                                    completeMethod={townFilter}
                                                                    onChange={(e) => {
                                                                        setSelectedTown(e.value);
                                                                        formik.setFieldValue(
                                                                            "bill_town",
                                                                            e.value
                                                                        );
                                                                        townHandleChange(e);
                                                                    }}
                                                                    dropdown={true}
                                                                    itemTemplate={TownTemplate}
                                                                    // forceSelection
                                                                    showEmptyMessage={true}
                                                                    disabled={kycfieldDisabled}
                                                                />
                                                                {getFormErrorMessage("bill_town")}
                                                            </div>
                                                            {/* <div className="err">
                                    <ErrorMessage name="bill_town" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">State</label>
                                                            <InputText
                                                                id="bill_state"
                                                                name="bill_state"
                                                                value={
                                                                    !manualTownEnter
                                                                        ? formik.values.bill_state
                                                                        : (formik.values.bill_state = billState)
                                                                }
                                                                onChange={(e) => {
                                                                    setManualTownEnter(false);
                                                                    formik.setFieldValue(
                                                                        "bill_state",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("bill_state"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* <Field
                                    name="bill_state"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">District</label>
                                                            <InputText
                                                                id="bill_district"
                                                                name="bill_district"
                                                                value={
                                                                    !manualTownEnter
                                                                        ? formik.values.bill_district
                                                                        : (formik.values.bill_district =
                                          billDistrict)
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "bill_district",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("bill_district"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* <Field
                                    name="bill_district"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Pin Code <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="bill_zipcode"
                                                                name="bill_zipcode"
                                                                value={
                                                                    !manualTownEnter
                                                                        ? formik.values.bill_zipcode
                                                                        : (formik.values.bill_zipcode = billZip)
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "bill_zipcode",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("bill_zipcode"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {getFormErrorMessage("bill_zipcode")}
                                                            {/* <Field
                                    class="form-control"
                                    name="bill_zipcode"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                />
                                <div className="err">
                                    <ErrorMessage name="bill_zipcode" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="">
                                                                <label className="form-label">
                                    Copy to Ship
                                                                </label>
                                                                {/* <Checkbox
                                    id="copy_to_ship_address"
                                    name="copy_to_ship_address"
                                    checked={formik.values.copy_to_ship_address}
                                    className={classNames({
                                    "p-invalid": isFormFieldInvalid(
                                        "copy_to_ship_address"
                                    ),
                                    })}
                                    onChange={(e) => {
                                    formik.setFieldValue(
                                        "copy_to_ship_address",
                                        e.checked
                                    );
                                    }}
                                    disabled
                                ></Checkbox> */}
                                                                <InputSwitch
                                                                    className="switch-pos ms-2"
                                                                    id="copy_to_ship_address"
                                                                    name="copy_to_ship_address"
                                                                    checked={formik.values.copy_to_ship_address}
                                                                    onChange={handleCopyShipAdd}
                                                                    // onChange={(e) => {
                                                                    //     formik.setFieldValue(
                                                                    //         "copy_to_ship_address",
                                                                    //         e.value
                                                                    //     );

                                                                    // }}
                                                                    // className={classNames({ 'p-invalid': isFormFieldInvalid('value') })}
                                                                    // disabled
                                                                />
                                                                {/* <Form.Check
                                className="d-inline"
                                type="switch"
                                id="custom-switch"
                            /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>
                                                    {formik.submitCount > 0 &&
                            (creditHoldStatus ||
                              paymentTermStatus ||
                              paymentModeStatus ||
                              taxAreaStatus ||
                              tradeDiscStatus ||
                              creditlimStatus) ? (
                                                            <div style={{ color: "red" }}>
                                Financial Information <Fi.FiAlertTriangle />
                                                            </div>
                                                        ) : (
                                                            <>Financial Information</>
                                                        )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="mt-0">
                                                                <label className="form-label d-block">
                                    Credit Hold{" "}
                                                                    <span className="mandatory">*</span>
                                                                </label>
                                                                {radioBtns_credit_hold.map((btn, i) => {
                                                                    return (
                                                                        <div
                                                                            key={i}
                                                                            className="d-inline-flex pe-3"
                                                                        >
                                                                            <RadioButton
                                                                                {...btn}
                                                                                checked={
                                                                                    formik.values.credit_hold ===
                                            btn.value
                                                                                }
                                                                                onChange={(e) => {
                                                                                    formik.setFieldValue(
                                                                                        "credit_hold",
                                                                                        e.value
                                                                                    );
                                                                                }}
                                                                            />
                                                                            <label
                                                                                htmlFor={btn.inputId}
                                                                                className="ms-1"
                                                                            >
                                                                                {btn.name}
                                                                            </label>
                                                                        </div>
                                                                    );
                                                                })}
                                                                {/* <InputSwitch
                                                                    className="switch-pos ms-2"
                                                                    id="credit_hold"
                                                                    name="credit_hold"
                                                                    checked={formik.values.credit_hold}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue("credit_hold", e.value);
                                                                    }}
                                                                    // className={classNames({ 'p-invalid': isFormFieldInvalid('value') })}
                                                                /> */}

                                                                {getFormErrorMessage("credit_hold")}

                                                                {/* <Field
                                    type="radio"
                                    name="credit_hold"
                                    value="Y"
                                    /> */}
                                                                {/* Yes */}

                                                                <label>
                                                                    {/* <Field
                                    type="radio"
                                    name="credit_hold"
                                    value="N"
                                    /> */}
                                                                    {/* No */}
                                                                </label>
                                                                {/* <div className="err">
                                    <ErrorMessage name="credit_hold" />
                                </div> */}
                                                                {/* <Form.Check
                                className="d-inline"
                                type="switch"
                                id="custom-switch"
                            /> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">
                                  Tax Form{" "}
                                                                {/* <span className="mandatory">*</span> */}
                                                            </label>{" "}
                                                            <InputText
                                                                inputId="tax_form"
                                                                name="tax_form"
                                                                value={formik.values.tax_form?.NAME}
                                                                // options={taxForm}
                                                                // optionLabel="NAME"
                                                                // placeholder="Select"
                                                                // className={classNames({
                                                                //     "p-invalid": isFormFieldInvalid("tax_form"),
                                                                // })}
                                                                // onChange={(e) => {
                                                                //     formik.setFieldValue("tax_form", e.value);
                                                                // }}
                                                                disabled
                                                            />
                                                            {/* {getFormErrorMessage("tax_form")} */}
                                                            {/* <Field
                                    as="select"
                                    name="tax_form"
                                    className="form-select"
                                    aria-label="Default select example"
                                    disabled
                                >
                                    <option value="">Select Tax Form</option>
                                    {taxForm.map((val) => {
                                    return (
                                        <option key={val.CODE} value={val.NAME}>
                                        {val.NAME}
                                        </option>
                                    );
                                    })}
                                </Field>
                                <div className="err">
                                    <ErrorMessage name="tax_form" />
                                </div> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Payment Term{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>{" "}
                                                            <Dropdown
                                                                inputId="payment_term"
                                                                name="payment_term"
                                                                value={formik.values.payment_term}
                                                                options={paymentTerm}
                                                                itemTemplate={PayTermTaxAreaTemplate}
                                                                optionLabel="NAME"
                                                                placeholder="Select"
                                                                className={classNames({
                                                                    "p-invalid":
                                      isFormFieldInvalid("payment_term"),
                                                                })}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "payment_term",
                                                                        e.value
                                                                    );
                                                                }}
                                                            />
                                                            {getFormErrorMessage("payment_term")}
                                                            {/* <Field
                                    as="select"
                                    className="form-select"
                                    aria-label="Default select example"
                                    name="payment_term"
                                >
                                    <option value="">Select Payment Term </option>
                                    {paymentTerm.map((val) => {
                                    return (
                                        <option key={val.CODE} value={val.NAME}>
                                        {val.NAME}
                                        </option>
                                    );
                                    })}
                                </Field> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Tax Area <span className="mandatory">*</span>
                                                            </label>{" "}
                                                            <Dropdown
                                                                inputId="tax_area"
                                                                name="tax_area"
                                                                value={formik.values.tax_area}
                                                                options={taxArea}
                                                                itemTemplate={PayTermTaxAreaTemplate}
                                                                optionLabel="NAME"
                                                                placeholder="Select"
                                                                className={classNames({
                                                                    "p-invalid": isFormFieldInvalid("tax_area"),
                                                                })}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("tax_area", e.value);
                                                                }}
                                                            />
                                                            {getFormErrorMessage("tax_area")}
                                                            {/* <Field
                                    as="select"
                                    name="tax_area"
                                    className="form-select"
                                    aria-label="Default select example"
                                >
                                    <option value="1">Select Tax Area</option>
                                    {taxArea.map((val) => {
                                    return (
                                        <option key={val.CODE} value={val.NAME}>
                                        {val.NAME}
                                        </option>
                                    );
                                    })}
                                </Field> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Payment Mode{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>{" "}
                                                            <Dropdown
                                                                inputId="payment_mode"
                                                                name="payment_mode"
                                                                value={formik.values.payment_mode}
                                                                options={modeOfPayment}
                                                                optionLabel="NAME"
                                                                placeholder="Select"
                                                                className={classNames({
                                                                    "p-invalid":
                                      isFormFieldInvalid("payment_mode"),
                                                                })}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "payment_mode",
                                                                        e.value
                                                                    );
                                                                }}
                                                            />
                                                            {getFormErrorMessage("payment_mode")}
                                                            {/* <Field
                                    as="select"
                                    name="payment_mode"
                                    className="form-select"
                                    aria-label="Default select example"
                                >
                                    <option value="">select Payment Mode</option>
                                    {modeOfPayment.map((val) => {
                                    return (
                                        <option key={val.CODE} value={val.NAME}>
                                        {val.NAME}
                                        </option>
                                    );
                                    })}
                                </Field> */}
                                                        </div>
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <label className="form-label">
                                                                    Invoice Copies{" "}
                                                                    <span className="mandatory">*</span>
                                                                </label>
                                                                <InputText
                                                                    id="invoice_copy"
                                                                    name="invoice_copy"
                                                                    value={formik.values.invoice_copy}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "invoice_copy",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldInvalid("invoice_copy"),
                                                                        },
                                                                        "form-control"
                                                                    )}
                                                                />
                                                                {getFormErrorMessage("invoice_copy")}


                                                            </div> */}
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Credit Limit (INR){" "}
                                                            </label>
                                                            <InputText
                                                                id="credit_limit"
                                                                name="credit_limit"
                                                                value={formik.values.credit_limit}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "credit_limit",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("credit_limit"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("credit_limit")}
                                                            {/* <Field
                                    name="credit_limit"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Trade Discount (%){" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="trade_discount"
                                                                name="trade_discount"
                                                                value={formik.values.trade_discount}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "trade_discount",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("trade_discount"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("trade_discount")}

                                                            {/* <Field
                                    name="trade_discount"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>
                                                    {(formik.submitCount > 0 && preferedDayStatus) ||
                            delvinsStatus ||
                            billinsStatus ? (
                                                            <div style={{ color: "red" }}>
                                Delivery Instructions <Fi.FiAlertTriangle />
                                                            </div>
                                                        ) : (
                                                            <>Delivery Instructions</>
                                                        )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12"> */}
                                                        {/* <label className="form-label">
                                                                    Min Order Value
                                                                </label> */}
                                                        {/* <InputText
                                                                    id="min_order"
                                                                    name="min_order"
                                                                    value={formik.values.min_order}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "min_order",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldInvalid("min_order"),
                                                                        },
                                                                        "form-control"
                                                                    )}
                                                                />
                                                                {getFormErrorMessage("min_order")}


                                                            </div> */}
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <label className="form-label">
                                                                    Max Order Value
                                                                </label>
                                                                <InputText
                                                                    id="max_order"
                                                                    name="max_order"
                                                                    value={formik.values.max_order}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "max_order",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldInvalid("max_order"),
                                                                        },
                                                                        "form-control"
                                                                    )}
                                                                />
                                                                {getFormErrorMessage("max_order")}

                                                            </div> */}
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <label className="form-label">
                                                                    Transport Charges
                                                                </label>
                                                                <InputText
                                                                    id="transport_charges"
                                                                    name="transport_charges"
                                                                    value={formik.values.transport_charges}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "transport_charges",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldInvalid(
                                                                                    "transport_charges"
                                                                                ),
                                                                        },
                                                                        "form-control"
                                                                    )}
                                                                />
                                                                {getFormErrorMessage("transport_charges")}
                                                            </div> */}
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <label className="form-label">Priority</label>
                                                                <InputText
                                                                    id="priority"
                                                                    name="priority"
                                                                    value={formik.values.priority}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "priority",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldInvalid("priority"),
                                                                        },
                                                                        "form-control"
                                                                    )}
                                                                />
                                                                {getFormErrorMessage("priority")}

                                                            </div> */}
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Delivery Instruction
                                                            </label>
                                                            <InputText
                                                                id="delivery_inst"
                                                                name="delivery_inst"
                                                                value={formik.values.delivery_inst}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "delivery_inst",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("delivery_inst"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("delivery_inst")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Billing Instruction
                                                            </label>
                                                            <InputText
                                                                id="billing_inst"
                                                                name="billing_inst"
                                                                value={formik.values.billing_inst}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "billing_inst",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("billing_inst"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                            />
                                                            {getFormErrorMessage("billing_inst")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Preferred Delivery Day{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <div className="flex justify-content-center">
                                                                {" "}
                                                                <Dropdown
                                                                    inputId="p_day_of_delivery"
                                                                    name=" p_day_of_delivery"
                                                                    value={formik.values.p_day_of_delivery}
                                                                    options={preferedDay}
                                                                    optionLabel="name"
                                                                    placeholder="Select"
                                                                    className={classNames({
                                                                        "p-invalid":
                                        isFormFieldInvalid(
                                            " p_day_of_delivery"
                                        ),
                                                                    })}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "p_day_of_delivery",
                                                                            e.value
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                            {getFormErrorMessage("p_day_of_delivery")}
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        <div className="Tab-box py-3">
                                            <div className="card p-3 shadow-sm">
                                                <h4>Sales Executive Details</h4>
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <label className="form-label">
                                Executive Name{" "}
                                                            <span className="mandatory">*</span>
                                                        </label>{" "}
                                                        <Dropdown
                                                            inputId="employeeSelect"
                                                            name="employeeSelect"
                                                            value={formik.values.employeeSelect}
                                                            options={employeeList}
                                                            optionLabel="NAME"
                                                            placeholder="Select"
                                                            className={classNames({
                                                                "p-invalid":
                                    isFormFieldInvalid("employeeSelect"),
                                                            })}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(
                                                                    "employeeSelect",
                                                                    e.value
                                                                );
                                                            }}
                                                        />
                                                        {getFormErrorMessage("employeeSelect")}
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <label className="form-label">
                                Visit Frequency{" "}
                                                            <span className="mandatory">*</span>
                                                        </label>{" "}
                                                        <Dropdown
                                                            inputId="beat_frequency"
                                                            name="beat_frequency"
                                                            value={formik.values.beat_frequency}
                                                            options={beatFrequency}
                                                            optionLabel="NAME"
                                                            placeholder="Select"
                                                            className={classNames({
                                                                "p-invalid":
                                    isFormFieldInvalid("beat_frequency"),
                                                            })}
                                                            onChange={(e) => {
                                                                formik.setFieldValue(
                                                                    "beat_frequency",
                                                                    e.value
                                                                );
                                                            }}
                                                        />
                                                        {getFormErrorMessage("beat_frequency")}
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 col-sm-12">
                                                        <label className="form-label">
                                Customer Visit Day{" "}
                                                            <span className="mandatory">*</span>
                                                        </label>{" "}
                                                        <Dropdown
                                                            inputId="day_of_beat1"
                                                            name="day_of_beat1"
                                                            value={formik.values.day_of_beat1}
                                                            options={dayOfWeek}
                                                            optionLabel="NAME"
                                                            placeholder="Select"
                                                            className={classNames({
                                                                "p-invalid":
                                    isFormFieldInvalid("day_of_beat1"),
                                                            })}
                                                            onChange={(e) => {
                                                                formik.setFieldValue("day_of_beat1", e.value);
                                                            }}
                                                        />
                                                        {getFormErrorMessage("day_of_beat1")}
                                                        {/* <Field
                                    as="select"
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={customerDetailsById.Beat2}
                                    name="day_of_beat1"
                                >
                                    <option>Select day of Beat1</option>
                                    {dayOfWeek.map((val) => {
                                    return (
                                        <option key={val.CODE} value={val.NAME}>
                                        {val.NAME}
                                        </option>
                                    );
                                    })}
                                </Field>
                                <div className="err">
                                    <ErrorMessage name="day_of_beat1" />
                                </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                                <div className="col-md-12 col-lg-12 col-sm-12">
                                                    <div className="float-end">
                                                        <button
                                type="submit"
                                title="Save"
                                class="btn btn-primary me-3 mt-2"
                              >
                                Next
                              </button>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </Tab>
                                    <Tab eventKey={1} title="Shipping Address">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>
                                                    {formik.submitCount > 0 &&
                            (address1Status1 ||
                              shipaddStatus ||
                              townStatus1) ? (
                                                            <div style={{ color: "red" }}>
                                Address <Fi.FiAlertTriangle />
                                                            </div>
                                                        ) : (
                                                            <>Address</>
                                                        )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">
                                  Ship To Name
                                                            </label>
                                                            <InputText
                                                                id="ship_to_name"
                                                                name="ship_to_name"
                                                                value={formik.values.ship_to_name}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "ship_to_name",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("ship_to_name"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* <Field
                                    name="ship_to_name"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Address Line 1{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="sadd1"
                                                                name="sadd1"
                                                                value={formik.values.sadd1}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "sadd1",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("sadd1"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_address
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("sadd1")}
                                                            {/* <Field
                                    name="sadd1"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Address Line 2
                                                            </label>
                                                            <InputText
                                                                id="sadd2"
                                                                name="sadd2"
                                                                value={formik.values.sadd2}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "sadd2",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("sadd2"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_address
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("sadd2")}
                                                            {/* <Field
                                    name="sadd2"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Town <span className="mandatory">*</span>
                                                            </label>
                                                            <div className="d-block">
                                                                <AutoComplete
                                                                    field={
                                                                        formik.values.copy_to_ship_address
                                                                            ? "NAME"
                                                                            : "TOWN"
                                                                    }
                                                                    // name="ship_town"
                                                                    virtualScrollerOptions={{
                                                                        itemSize: 38,
                                                                    }}
                                                                    // value={
                                                                    //   !manualShipTownEnter
                                                                    //     ? customerDetailsById.ShipTown
                                                                    //     : selectedShipTown
                                                                    // }
                                                                    value={formik.values.ship_town}
                                                                    suggestions={shipTownSuggestion}
                                                                    completeMethod={shiptownFilter}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "copy_to_ship_address",
                                                                            false
                                                                        );
                                                                        setSelectedShipTown(e.value);
                                                                        formik.setFieldValue(
                                                                            "ship_town",
                                                                            e.value
                                                                        );
                                                                        shipTownHandleChange(e, 1);
                                                                    }}
                                                                    dropdown={true}
                                                                    itemTemplate={ShipTownTemplate}
                                                                    // forceSelection
                                                                    showEmptyMessage={true}
                                                                />
                                                                {getFormErrorMessage("ship_town")}
                                                                {/* <div className="err">
                                    <ErrorMessage name="ship_town" />
                                    </div> */}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">State</label>
                                                            <InputText
                                                                id="ship_state"
                                                                name="ship_state"
                                                                value={
                                                                    !manualShipTownEnter
                                                                        ? formik.values.ship_state
                                                                        : (formik.values.ship_state = shipState)
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "ship_state",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("ship_state"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* <Field
                                    name="ship_state"
                                    class="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                            <label className="form-label">District</label>
                                                            <InputText
                                                                id="ship_district"
                                                                name="ship_district"
                                                                value={
                                                                    !manualShipTownEnter
                                                                        ? formik.values.ship_district
                                                                        : (formik.values.ship_district =
                                          shipDistrict)
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "ship_district",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("ship_district"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* <Field
                                    name="ship_district"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                /> */}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">Pin Code</label>
                                                            <InputText
                                                                id="ship_zipcode}"
                                                                name="ship_zipcode}"
                                                                value={
                                                                    !manualShipTownEnter
                                                                        ? formik.values.ship_zipcode
                                                                        : (formik.values.ship_zipcode = shipZip)
                                                                }
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "ship_zipcode}",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("ship_zipcode}"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                disabled
                                                            />
                                                            {/* <Field
                                    name="ship_zipcode"
                                    className="form-control"
                                    type="text"
                                    placeholder="Enter Here..."
                                    disabled
                                /> */}
                                                        </div>
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <label className="form-label">Van Route</label>

                                                                <Dropdown
                                                                    inputId="van_route"
                                                                    name="van_route"
                                                                    value={formik.values.van_route}
                                                                    options={vanRoute}
                                                                    optionLabel="NAME"
                                                                    placeholder="Select"
                                                                    className={classNames({
                                                                        "p-invalid":
                                      isFormFieldInvalid("van_route"),
                                                                    })}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue("van_route", e.value);
                                                                    }}
                                                                />
                                                                {getFormErrorMessage("van_route")}
                                                            </div> */}
                                                        {/* <div className="col-lg-3 col-md-6 col-sm-12">
                                                                <label className="form-label">
                                                                    Ship GSTN No.
                                                                </label>
                                                                <InputText
                                                                    id="ship_gst_no"
                                                                    name="ship_gst_no"
                                                                    value={formik.values.ship_gst_no}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "ship_gst_no",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    className={classNames(
                                                                        {
                                                                            "p-invalid":
                                                                                isFormFieldInvalid("ship_gst_no"),
                                                                        },
                                                                        "form-control"
                                                                    )}
                                                                />
                                                                {getFormErrorMessage("ship_gst_no")}

                                                            </div> */}
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <div className="mt-3">
                                                                <label className="form-label">Active</label>
                                                                {/* <Checkbox
                                    id="active_check"
                                    name="active_check"
                                    checked={formik.values.active_check}
                                    className={classNames({
                                    "p-invalid":
                                        isFormFieldInvalid("active_check"),
                                    })}
                                    onChange={(e) => {
                                    formik.setFieldValue(
                                        "active_check",
                                        e.checked
                                    );
                                    }}
                                    disabled
                                ></Checkbox> */}
                                                                <InputSwitch
                                                                    className="switch-pos ms-2"
                                                                    id="active_check"
                                                                    name="active_check"
                                                                    checked={formik.values.active_check}
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "active_check",
                                                                            e.value
                                                                        );
                                                                    }}
                                                                    // className={classNames({ 'p-invalid': isFormFieldInvalid('value') })}
                                                                    // disabled
                                                                />
                                                                {/* <Form.Check
                                    className="d-inline"
                                    type="switch"
                                    id="custom-switch"
                                    /> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item>
                                                <Accordion.Header>
                                                    {formik.submitCount > 0 &&
                            (stitleStatus ||
                              fnameStatus1 ||
                              smidnameStatus ||
                              lnameStatus1 ||
                              sphnumStatus ||
                              mobileStatus1 ||
                              shipemailStatus) ? (
                                                            <div style={{ color: "red" }}>
                                Contact Person <Fi.FiAlertTriangle />
                                                            </div>
                                                        ) : (
                                                            <>Contact Person</>
                                                        )}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="row">
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">Prefix</label>
                                                            <InputText
                                                                id="ship_title"
                                                                name="ship_title"
                                                                value={formik.values.ship_title}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "ship_title",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("ship_title"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_contact
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("ship_title")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  First Name{" "}
                                                                <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="fname"
                                                                name="fname"
                                                                value={formik.values.fname}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "fname",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("fname"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_contact
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("fname")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Middle Name
                                                            </label>
                                                            <InputText
                                                                id="mname"
                                                                name="mname"
                                                                value={formik.values.mname}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "mname",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("mname"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_contact
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("mname")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Last Name <span className="mandatory">*</span>
                                                            </label>
                                                            <InputText
                                                                id="lname"
                                                                name="lname"
                                                                value={formik.values.lname}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "lname",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("lname"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_contact
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("lname")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Phone Number
                                                            </label>
                                                            <InputText
                                                                id="phno"
                                                                name="phno"
                                                                value={formik.values.phno}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "phno",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("phno"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_contact
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("phno")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Mobile Number
                                                            </label>
                                                            <span className="mandatory">*</span>
                                                            <InputText
                                                                id="mob"
                                                                name="mob"
                                                                value={formik.values.mob}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue("mob", e.target.value);
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid": isFormFieldInvalid("mob"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_contact
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("mob")}
                                                        </div>
                                                        <div className="col-lg-3 col-md-6 col-sm-12">
                                                            <label className="form-label">
                                  Company Email
                                                            </label>
                                                            <InputText
                                                                id="ship_co_email"
                                                                name="ship_co_email"
                                                                value={formik.values.ship_co_email}
                                                                onChange={(e) => {
                                                                    formik.setFieldValue(
                                                                        "ship_co_email",
                                                                        e.target.value
                                                                    );
                                                                }}
                                                                className={classNames(
                                                                    {
                                                                        "p-invalid":
                                        isFormFieldInvalid("ship_co_email"),
                                                                    },
                                                                    "form-control"
                                                                )}
                                                                // disabled={
                                                                //     formik.values.copy_to_ship_contact
                                                                //         ? true
                                                                //         : false
                                                                // }
                                                            />
                                                            {getFormErrorMessage("ship_co_email")}
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        {/* <div className="row">
                                                <div className="col-md-12 col-lg-12 col-sm-12">
                                                    <div className="float-end">
                                                        <button
                                                            type="submit"
                                                            title="Save"
                                                            className="btn btn-primary me-3 mt-2"
                                                        >
                                    Next
                                                        </button>
                                                    </div>
                                                    <div className="float-end">
                                                        <button
                                                            type="button"
                                                            title="Save"
                                                            className="btn btn-primary me-3 mt-2"
                                                            onClick={() => setCurrentStep(currentStep - 1)}
                                                        >
                                    Prev
                                                        </button>
                                                    </div>
                                                </div>
                                            </div> */}
                                    </Tab>
                                    <Tab eventKey={2} title="Photo Identity">
                                        <div className="card p-3 m-3">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <h6>Customer Identity Photo</h6>
                                                    <div className="red-color font-sm">
                              *Size limit 500KB
                                                    </div>
                                                    <div className="red-color font-sm">
                              Customer Photo Identity should be from any of the
                              following(Pan Card, Voter Card)
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="mt-4">
                                                            <button
                                                                type="submit"
                                                                title="Upload"
                                                                className="btn btn-primary"
                                                                disabled
                                                            >
                                Upload
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="float-end">
                                                        <img
                                                            className="placeholder-img"
                                                            alt="InnoRise"
                                                            // src={formik.values.cust_url}
                                                            src={
                                                                formik.values.cust_url === "" ||
                                  formik.values.cust_photo == null
                                                                    ? require("../../Assets/Images/placeholder.jpg")
                                                                    : formik.values.cust_url
                                                            }
                                                        />
                                                        <a
                                                            className="enlarge d-block pt-1"
                                                            onClick={() => setFullIdPopup(true)}
                                                        >
                                View Full Image
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card p-3 m-3">
                                            <div className="row">
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <h6>Customer Store Photo</h6>
                                                    <div className="red-color font-sm">
                              *Size limit 500KB
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-3 col-md-3 col-sm-12">
                                                        <div className="mt-3">
                                                            <button
                                                                type="submit"
                                                                title="Upload"
                                                                className="btn btn-primary"
                                                                disabled
                                                            >
                                Upload
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="float-end">
                                                        <img
                                                            className="placeholder-img"
                                                            alt="InnoRise"
                                                            src={
                                                                formik.values.cust_photo === "" ||
                                  formik.values.cust_photo == null
                                                                    ? require("../../Assets/Images/placeholder.jpg")
                                                                    : formik.values.cust_photo
                                                            }
                                                            // src={formik.values.cust_photo}
                                                        />
                                                        <a
                                                            className="enlarge d-block pt-1"
                                                            onClick={() => setFullcustimgpPopup(true)}
                                                        >
                                View Full Image
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tab>
                                </Tabs>
                                {/* {formik.submitCount>0 &&errorLabel  ? (
                                            <h4 style={{ color: "red" }}>
                        Fields marked with * are mandatory in all tabs.
                                            </h4>
                                        ) : null} */}
                                {newCustomerSave ? (
                                    <div className="float-end">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-2"
                                            disabled={customerSaveDisable}
                                        >
                        Save
                                        </button>
                                    </div>
                                ) : (
                                    <div className="float-end">
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-2"
                                            disabled={kycStatus}
                                        >
                        Save
                                        </button>
                                    </div>
                                )}
                            </form>
                            <ToastContainer />
                        </div>
                    </div>
                    {/* </div> */}
                </main>
                <SegmentModalNewCust />
                <Dialog
                    header="Customer Store Photo"
                    visible={fullcustimgpPopup}
                    style={{ width: "50vw" }}
                    onHide={() => setFullcustimgpPopup(false)}
                    draggable={false}
                    resizable={false}
                >
                    <div>
                        <img
                            className="fullImage"
                            alt="InnoRise"
                            src={
                                formik.values.cust_photo === "" ||
                formik.values.cust_photo == null
                                    ? require("../../Assets/Images/placeholder.jpg")
                                    : formik.values.cust_photo
                            }
                            width="100%"
                        />
                    </div>
                </Dialog>

                <Dialog
                    header="Customer Identity Photo"
                    visible={fulliIdPopup}
                    style={{ width: "50vw" }}
                    onHide={() => setFullIdPopup(false)}
                    draggable={false}
                    resizable={false}
                >
                    <div>
                        <img
                            className="fullImage"
                            alt="InnoRise"
                            src={
                                formik.values.cust_url === "" ||
                formik.values.cust_photo == null
                                    ? require("../../Assets/Images/placeholder.jpg")
                                    : formik.values.cust_url
                            }
                            width="100%"
                        />
                    </div>
                </Dialog>

                <Dialog
                    header="Phone Number Validation"
                    visible={phoneValidationPopUp}
                    style={{ width: "50vw" }}
                    draggable={false}
                    resizable={false}
                    closable={false}
                >
                    <div>
            This Phone Number already exists for a customer, do you still want to
            proceed?
                    </div>
                    <div className="float-end mt-4">
                        <button
                            type="button"
                            className="btn btn-primary me-3"
                            onClick={procedFurther}
                        >
              OK
                        </button>
                        <button
                            type="button"
                            className="btn btn-cancel-red"
                            onClick={() => setPhoneValidationPopUp(false)}
                        >
              Cancel
                        </button>
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default NewCustomer;
