import Axios from "../../api/Axios";
const BASE_URL = "WareHouseMaster/";
export const Endpoint = {
    GetAllWarehouseDetails: "GetAllWarehouseDetails",
    PopulateStateData: "PopulateStateData",
    PopulateDistrictData: "PopulateDistrictData",
    PopulateTownData: "PopulateTownData",
    SaveWareHouseMaster: "SaveWareHouseMaster",
};
    
export const createAPIEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};