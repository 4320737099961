import Axios from "../../../api/Axios";

const Credit_Note_Creation = "CreditNoteCreation/";

export const EndpointCN = {
    DeleteCNData: "DeleteCNData",
    GetCNData: "GetCNGridData",
    SaveCNData: "SaveCNData",
    GetExcelData: "GetCNExcelData"
};

export const createAPIEndpointCNCreation = () => {
    return {
        get: (Endpoint) => Axios.get(Credit_Note_Creation + Endpoint),
        getExcel: (Endpoint) => Axios.get(Credit_Note_Creation + Endpoint),
        save: (Endpoint, data) => Axios.post(Credit_Note_Creation + Endpoint, data),
        delete: (Endpoint, data) => Axios.post(Credit_Note_Creation + Endpoint, data),
    };
};
