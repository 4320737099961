import React, { useContext, useEffect } from "react";
import { useState } from "react";
import storage from "../Services/Storage";
import { createContext } from "react";
import { Endpoint, createAPIEndpoint } from "../Services/EmployeeMaster/EmployeeMasterService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EmployeeMasterContext = createContext();
const EmployeeMasterProvider = ({ children }) => {
    const [popup, setPopup] = useState(false);
    const [searchpopup, setSearchPopup] = useState(false);
    const auth = storage.getLocal("user");
    const [employeeList, setEmployeeList] = useState();
    const [townPopup, setTownPopup] = useState(false);
    const [stateSuggestion, setStateSuggestion] = useState([]);
    const [districtSuggestion, setDistrictSuggestion] = useState([]);
    const [townSuggestion, setTownSuggestion] = useState([]);
    const [name, setName] = useState([]);
    const [stateGEOG_ID, setstateGEOG_ID] = useState("");
    const [districtGEOG_ID, setDistrictGEOG_ID] = useState("");
    const [townValue, setTownValue] = useState("");
    const [stateValue, setstateValue] = useState([]);
    const [districtValue, setDistrictValue] = useState([]);
    const [confirmTown, setConfirmTown] = useState(false);
    const [isEdit, setIsEdit] = useState();
    const [selectedEmployeeData, setSelectedEmployeeData] = useState([]);
    const [selectedReplacementEmployeeData, setSelectedReplacementEmployeeData] = useState({});
    const [addChildFlag, setAddChildFlag] = useState(true);
    const [parentCodeFlag, setParentCodeFlag] = useState(true);
    const [DFMR, setDFMR] = useState(false);
    const [newSaveData, setNewSaveData] = useState("");
    const [zipCode, setZipCode] = useState(false);
    const [employeeListDFMR, setEmployeeListDFMR] = useState();
    const [employeeMasterList, setEmployeeMasterList] = useState([]);
    const [isReplacement, setIsReplacement] = useState(false);
    const [townGeogId, setTownGeogId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const [DFMRFlag, setDFMRFlag] = useState(false);
    const [seChange, setSEChange] = useState(false);
    const [allsupplierdetails, setAllsupplierdetails] = useState([]);


    const nav =useNavigate();



    const newEmployeeSave = (obj1) => {
        setDeleteLoader(true);
         
        const newsaveobj = {
            ciCode: auth.CICode,
            userId: auth.UserId,
            pvcACCT_ID: obj1.code==null?"":obj1.code,
            pvcACCT_CODE: obj1.code==null?"":obj1.code,
            pvcACCT_NAME: obj1.first_name==null?"":obj1.first_name,
            pvcACCT_PLBS_TAG: "BS",
            pvcDEFINED_BY: "E",
            pvcEXPORT_TAG: "Y",
            pvcFIRST_ENTERED_BY: auth.CICode,
            // pvcACCT_LEDGER_ID: isEdit?selectedEmployeeData.LEDGER_ID:obj1.ledger.MASK_ID,
            pvcACCT_LEDGER_ID:"BANK",
            pvcSTATUS: obj1.status == true ? "Y" : "N",
            iukey: isEdit ? "2" : "1",
            pvcEMP_DESIG_ID: isEdit ? (seChange ? obj1.designation.MASK_DESIG_ID : (selectedEmployeeData.EMP_DESIG_ID == null ? "" : selectedEmployeeData.EMP_DESIG_ID)) : obj1.designation.MASK_DESIG_ID,
            pvcCOMP_ID: auth.CompId,
            pvcPHONE_NO: obj1.mob_num==null?"":obj1.mob_num,
            pvcEMP_ID: isEdit ? selectedEmployeeData.EMP_ID==null?"":selectedEmployeeData.EMP_ID : "",
            pvcEMP_PARENT_ID: isEdit? selectedEmployeeData.EMP_PARENT_ID == null ? "" : selectedEmployeeData.EMP_PARENT_ID: parentCodeFlag? selectedEmployeeData.EMP_ID == null ? "" : selectedEmployeeData.EMP_ID: "",
            pvcEMP_LVL_NO: obj1.parent_code === null || obj1.parent_code === ""? "1" : "2",
            pvcEMP_CODE: obj1.code==null?"":obj1.code,
            pvcEMP_TITLE: obj1.title==null?"":obj1.title,
            pvcEMP_FNAME: obj1.first_name==null?"":obj1.first_name,
            pvcEMP_MNAME: obj1.middle_name==null?"":obj1.middle_name,
            pvcEMP_LNAME: obj1.last_name==null?"":obj1.last_name,
            pvcEMP_ADD1: obj1.addr_1==null?"":obj1.addr_1,
            pvcEMP_ADD2: obj1.addr_2==null?"":obj1.addr_2,
            pvcEMP_GEOG_ID:townGeogId,
            pvcEMP_PHONE_NUM: obj1.phone_num==null?"":obj1.phone_num,
            pvcEMP_OTH_COMM_NUM: obj1.other_phone_num==null?"":obj1.other_phone_num,
            pvcMOBILE_ID: obj1.mob_num==null?"":obj1.mob_num,
            pvcEMP_EMAILID: obj1.email==null?"":obj1.email,
            pvcEMP_REMARKS: obj1.remarks==null?"":obj1.remarks,
            pvcENTERED_BY: auth.CICode,
            pvcEMP_PIN_CODE: obj1.zip_code==null?"":obj1.zip_code,
            pvcPHONE_AREA_CODE: obj1.std_code==null?"":obj1.std_code,
            pvcSHORT_NAME: obj1.short_name==null?"":obj1.short_name,
            pvcEMP_ACCT_ID: obj1.code==null?"":obj1.code,
            pvcBRANCH_ID: "000",
            pvcMAIL_STATUS: "N",
            pnmMARK_UP: "1.00",
            pvcREPLACE_TAG: isReplacement ? "Y" : "N",
            pvcREPLACE_ID: isReplacement ? selectedReplacementEmployeeData.MASK_EMP_ID==null?"":selectedReplacementEmployeeData.MASK_EMP_ID : "",
            pvcPERMISSION_TAG: ""
        };
         
        // return
        createAPIEndpoint()
            .post(`${Endpoint.EmployeeMasterSave}`,
                JSON.stringify(newsaveobj),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            .then((res) => {
                setDeleteLoader(false);
                if (res.data.Status == "Success") {
                    toast.success(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }

                    );
                    nav("/masters/employeemaster");
                }
                else {
                    if (res.data.Status == "Warning") {
                        toast.warning(
                            `${res.data.Message}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 2000,
                            }

                        );
                    }
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {

                    position: toast.POSITION.TOP_CENTER,
    
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
    
                console.log(error);
            });
    };

    const deleteEmployee = (objDel) => {
        setDeleteLoader(true);
        const deleteObj = {
            ciCode: auth.CICode.toString(),
            pvcEMP_ID: objDel.EMP_ID==null?"":objDel.EMP_ID.toString(),
            pvcCOMP_ID: auth.CompId.toString(),
            masK_MARK_UP: objDel.MASK_MARK_UP==null?"":objDel.MASK_MARK_UP.toString(),
            updatE_COUNT: objDel.UPDATE_COUNT==null?"":objDel.UPDATE_COUNT.toString(),
            iukey: "2"
        };
         
        // return;
        createAPIEndpoint()
            .post(`${Endpoint.DeleteEmployeeMaster}`,
                JSON.stringify(deleteObj),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            .then((res) => {

                if (res.data.Status == "Success") {
                    toast.success(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }

                    );
                    nav("/masters/employeemaster");
                    setDeleteLoader(false);
                }
                else {
                    if (res.data.Status == "Warning") {
                        toast.warning(
                            `${res.data.Message}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 2000,
                            }

                        );
                    }
                    setDeleteLoader(false);
                }
                
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {

                    position: toast.POSITION.TOP_CENTER,
    
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
    
                console.log(error);
            });
    };

    const EmployeeMasterAPI = () => {
        setIsLoading(true);
        createAPIEndpoint()
            .get(`${Endpoint.GetEmployeeMasterList}?CICode=${auth.CICode}&CompId=${auth.CompId}`)
            .then((res) => {
                setEmployeeMasterList(res.data.Data);
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {

                    position: toast.POSITION.TOP_CENTER,
    
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
    
                console.log(error);
            });

    };
    const handleState = async () => {

        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.GetGeography}?CICode=${auth.CICode}&intGeogLevel=1&strGeogParentId=""`)
            .then((res) => {
                response = res.data.Data;
                let values = response.map((val) => {
                    return val;
                });
                setStateSuggestion(values);


            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {

                    position: toast.POSITION.TOP_CENTER,
    
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
    
                console.log(error);
            });

    };


    const handleDistrict = async () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.GetGeography}?CICode=${auth.CICode}&intGeogLevel=2&strGeogParentId=${stateGEOG_ID}`)
            .then((res) => {
                response = res.data.Data;
                let values = response.map((val) => {
                    return val;
                });
                setDistrictSuggestion(values);


            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {

                    position: toast.POSITION.TOP_CENTER,
    
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
    
                console.log(error);
            });

    };



    const handleTown = async () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.GetGeography}?CICode=${auth.CICode}&intGeogLevel=3&strGeogParentId=${districtGEOG_ID}`)
            .then((res) => {
                response = res.data.Data;
                let values = response.map((val) => {
                    return val;
                });
                setTownSuggestion(values);


            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {

                    position: toast.POSITION.TOP_CENTER,
    
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
    
                console.log(error);
            });

    };

    useEffect(() => {
        handleDistrict();
    }, [stateGEOG_ID]);

    useEffect(() => {
        handleTown();
    }, [districtGEOG_ID]);


    return (
        <EmployeeMasterContext.Provider
            value={{
                popup,
                setPopup,
                searchpopup,
                setSearchPopup,
                employeeList,
                setEmployeeList,
                townPopup,
                setTownPopup,
                handleState,
                stateSuggestion,
                setStateSuggestion,
                name,
                setName,
                stateGEOG_ID,
                setstateGEOG_ID,
                handleDistrict,
                districtSuggestion,
                districtGEOG_ID,
                setDistrictGEOG_ID,
                townSuggestion,
                handleTown,
                townValue,
                setTownValue,
                stateValue,
                setstateValue,
                districtValue,
                setDistrictValue,
                confirmTown,
                setConfirmTown,
                isEdit,
                setIsEdit,
                selectedEmployeeData,
                setSelectedEmployeeData,
                addChildFlag,
                setAddChildFlag,
                parentCodeFlag,
                setParentCodeFlag,
                DFMR,
                setDFMR,
                selectedReplacementEmployeeData,
                setSelectedReplacementEmployeeData,
                newSaveData,
                setNewSaveData,
                newEmployeeSave,
                deleteEmployee,
                zipCode,
                setZipCode,
                employeeListDFMR,
                setEmployeeListDFMR,
                EmployeeMasterAPI,
                employeeMasterList,
                setEmployeeMasterList,
                setIsReplacement,
                townGeogId,
                setTownGeogId,
                isLoading,
                setIsLoading,
                deleteLoader,
                DFMRFlag, 
                setDFMRFlag,
                seChange, 
                setSEChange,
                allsupplierdetails, 
                setAllsupplierdetails
            }}
        >
            {children}
        </EmployeeMasterContext.Provider>
    );
};
export const useEmployeeMasterContext = () => {
    return useContext(EmployeeMasterContext);
};

export { EmployeeMasterProvider, EmployeeMasterContext };