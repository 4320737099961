import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import Draggable from "react-draggable";
import React, { useState } from "react";
import * as Fa from "react-icons/fa";
import { useGlobalContext1 } from "../../context/SalesDetailsContext";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { cancelOrderReason1} from "../../constants";
import { useReallocateContext } from "../../context/ReallocateContext";
import Loader from "../../Components/Loader";
// import ModalDialog from "react-bootstrap/ModalDialog";
// import { useRef } from "react";

//12-12-2022
// export interface DraggableModalDialogProps extends ModalDialogProps {
//     nodeRef?: RefObject<HTMLElement>;
//   }
// function DraggableModalDialog({ nodeRef, ...props }) {
//     return (
//         <Draggable nodeRef={nodeRef}>
//             <ModalDialog {...props} />
//         </Draggable>
//     );
// }
function ReallocateModal() {
    // const [refReason, setRefReason] = useState(cancelOrderReason1[0].CODE);
    // const[userReasonSelected,setUserReasonSelected]=useState();
    const {
        invoiceQty,
        setEditModal,
        editModal,
        handleCancelOrder,
        blockInvalidChar,
        reasonDetails,
        userReasonSelected,
        setUserReasonSelected,
        setUpdateStatusCheck,
        // payload
        disableRelease,
        hedaerReasonDetails,
        defaultReasonData,
        setDefaultReasonData,
        cancelFullorderModal, 
        setCancelFullorderModal,
        setFullCancelToggle,
        setFullCancelColor,
        setReason
    } = useGlobalContext1();
    const {
        modalShow,
        setModalShow,
        reallocateDetails,
        handleReallocateReleaseQty,
        selectRow,
        ReallocateLoader,
        promoCode,
    } = useReallocateContext();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const validateRelQty = (e, row) => {
        if (Number(row.BALANCED_QTY) < Number(e.target.value)) {
            row.PACK_REL_QTY = e.target.value ? e.target.value : "";
        } else {
            row.PACK_REL_QTY = e.target.value ? e.target.value : "";
        }
    };
    const column1 = [
        { name: "Product Code", selector: (row) => row.PRODUCT_CODE },
        {
            name: "Product Name",
            selector: (row) => (
                <div
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={row.MASK_PROD_PACK_DESC}
                >
                    {" "}
                    {row.MASK_PROD_PACK_DESC}
                </div>
            ),
        },
        { name: "Lot No", selector: (row) => row.PROD_BATCH_NO },
        { name: "MRP", selector: (row) => (row.PROD_MRP) },
        {
            name: "Product Price",
            selector: (row) => Number(row.PROD_UNIT_PRICE).toFixed(2),
        },
        { name: "Available QTY", selector: (row) => row.BALANCED_QTY },
        {
            name: "Release QTY",
            selector: (row) => (
                <div>
                    <input
                        className="form-control"
                        type="number"
                        defaultValue={disableRelease?.some((a)=>{return a.PRODUCT_CODE + a.PROD_LOT_NO === row.PRODUCT_CODE + row.PROD_BATCH_NO;})?row.PACK_REL_QTY="0":row.PACK_REL_QTY}
                        onKeyDown={blockInvalidChar}
                        onChange={(e) => validateRelQty(e, row)}
                        min="0"
                        disabled={disableRelease?.some((a)=>{return a.PRODUCT_CODE + a.PROD_LOT_NO === row.PRODUCT_CODE + row.PROD_BATCH_NO;})}
                    />
                    {/* disableRelease && disableRelease.map((a)=>a.PRODUCT_CODE + a.PROD_LOT_NO == row.PRODUCT_CODE + row.PROD_BATCH_NO) */}
                </div>
            ),
        },
    ];

    if (ReallocateLoader) {
        return (
            <div>
                <Loader />
            </div>
        );
    } else {
        return (
            <>
                <div className="col-12">
                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                    >
                        {/* <Modal.Header closeButton1></Modal.Header> */}
                        <Modal.Body>
                            <div className="successfull-popup">
                                <div>
                                    <Fa.FaCheckCircle className="check-icon" />
                                </div>
                                <div className="mt-2">
                                    <p>Sales order updated successfully</p>
                                </div>
                                <div className="text-center mt-4">
                                    <Button onClick={handleClose}>OK</Button>
                                </div>
                            </div>
                        </Modal.Body>
                        {/* <Modal.Footer></Modal.Footer> */}
                    </Modal>
                </div>
                <>
                    <div className="col-12">
                        <Modal
                            // nodeRef={nodeRef}
                            // dialogAs={DraggableModalDialog}
                            className="reallocate-modal"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                      Ordering Details
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="">
                                    <div className="row mb-2">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="">
                                                <p>
                              Total Order Quantity:{" "}
                                                    <span>{selectRow.PACK_ORD_QTY}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="">
                                                <p>
                              Invoiced Quantity:{" "}
                                                    <span>{selectRow.TOT_INV_QTY}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <p>
                            Base Product Code:{" "}
                                                <span>{selectRow.PRODUCT_CODE}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="grid grid-shadow">
                                                <DataTable
                                                    columns={column1}
                                                    data={reallocateDetails}
                                                    pagination
                                                    noDataComponent = {`There is No Base or Promo Product Code available for Product Code - ${selectRow.PRODUCT_CODE}`}
                                                />
                                                {}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <p>
                          Promo Product Codes:{" "}
                                            <span
                                                style={{
                                                    color:
                                promoCode ===
                                "No other promo code is mapped with this base code"
                                    ? "red"
                                    : null,
                                                }}
                                            >
                                                {promoCode}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8 col-md-8 col-sm-12">
                                            <div className="">
                                                {invoiceQty ? (
                                                    <div className="error font-lg red-color">
                                                        <Fa.FaInfoCircle className="error-icon" />{" "}
                                Please change Release Quantity, already Invoiced
                                Quantity is{" "}
                                                        <span className="red-color">
                                                            <strong>{invoiceQty}</strong>
                                                        </span>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-12">

                                            <div className="float-end">
                                                <button type="button" className="btn btn-primary me-3"
                                                    onClick={() => {
                                                        handleReallocateReleaseQty();
                                                        setUpdateStatusCheck(false);
                                                        setModalShow(false);
                                                    }}
                                                >
                                            Add to Cart
                                                </button>
                                                <button
                                                    type="button"
                                                    onClick={() => {
                                                        setModalShow(false);
                                                    }}
                                                    className="btn btn-cancel-red"
                                                >
                            Cancel
                                                </button>
                                            </div>
                                            
                                            
                                            <ToastContainer />
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            {/* <Modal.Footer>
                            <div className="float-end">
                                <button type="button" className="btn btn-primary me-3" onClick={handleConfirm}>OK</button>
                                <button type="button" className="btn btn-cancel-red" onClick={handleClose}>Close</button>
                            </div>
                            </Modal.Footer> */}
                        </Modal>
                    </div>
                </>
                <>
                    <div className="col-12">
                        <Modal
                            className="reallocate-modal"
                            show={editModal}
                            onHide={() => setEditModal(false)}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                      Cancellation Reason
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label className="form-label">Reason</label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={userReasonSelected}
                                            onChange={(e) => setUserReasonSelected(e.target.value)}
                                        >
                                            <option>Select Reason</option>
                                            {reasonDetails.filter((val)=>val.NAME!="Hold Order").map((reason) => (

                                                <option key={reason.CODE} value={reason.CODE}>
                                                    {reason.NAME}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary float-end "
                                            onClick={(e) => {
                                                handleCancelOrder(e, userReasonSelected);
                                                setEditModal(false);
                                            }}
                                            disabled={userReasonSelected === "Select Reason" || typeof userReasonSelected === "undefined"}
                                        >
                          Confirm
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                            {/* <Modal.Footer></Modal.Footer> */}
                        </Modal>
                    </div>
                </>
                <>
                    <div className="col-12">
                        <Modal
                            className="reallocate-modal"
                            show={cancelFullorderModal}
                            onHide={() => {setCancelFullorderModal(false);
                                setFullCancelToggle(false);
                                setFullCancelColor(true);
                                setReason(false);
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                      Cancellation Reason
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <label className="form-label">Reason</label>
                                        <select
                                            className="form-select"
                                            aria-label="Default select example"
                                            value={defaultReasonData}
                                            onChange={(e) => setDefaultReasonData(e.target.value)}
                                        >
                                            <option>Select Reason</option>
                                            {hedaerReasonDetails.map((reason) => (

                                                <option key={reason.CODE} value={reason.CODE}>
                                                    {reason.NAME}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mt-3">
                                        <button
                                            type="button"
                                            className="btn btn-primary float-end "
                                            onClick={(e) => {
                                                handleCancelOrder(e, defaultReasonData);
                                                setCancelFullorderModal(false);
                                            }}
                                            disabled={defaultReasonData=="Select Reason"?true:false}
                                        >
                          Confirm   
                                        </button>
                                    </div>
                                </div>
                            </Modal.Body>
                            {/* <Modal.Footer></Modal.Footer> */}
                        </Modal>
                    </div>
                </>
            </>
        );
    }
}

export default ReallocateModal;
