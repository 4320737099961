import React from "react";


const Loader = () => {

    return (
        <>
            <div id="overlay">
                <div className="spinner d-flex justify-content-center">
                    <img className="loader-img" alt="InnoRise" src={require("../Assets/Images/innoriseLoader.gif")}/>
                </div>
            </div>
        </>
    );
};

export default Loader;




