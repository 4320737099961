import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    createAPISupplierMastEndpoint,
    EndpointSupplier,
} from "../../Services/SupplierMast/SuppliermastService";
import storage from "../../Services/Storage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import { useEmployeeMasterContext } from "../../context/EmployeeMasterContext";

function SupplierMasterView() {
    // const [allsupplierdetails, setAllsupplierdetails] = useState([]);
    const [suppliermastloader, setSuppliermastloader] = useState(false);

    const {allsupplierdetails, setAllsupplierdetails}=useEmployeeMasterContext();
    const auth = storage.getLocal("user");
    const nav = useNavigate();
    const NewSupplier = () => {
        nav("/masters/suppliermaster/details");
    };

    useEffect(() => {
        getAllSupplierMastData();
    }, []);

    const getAllSupplierMastData = () => {
        setSuppliermastloader(true);
        createAPISupplierMastEndpoint()
            .get(`${EndpointSupplier.GetAllSupplierDetails}?CompId=${auth.CompId}`)
            .then((res) => {
                setAllsupplierdetails(res.data.Data);
                 
                setSuppliermastloader(false);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setSuppliermastloader(false);
            });
    };

    const tosuppliermast = (rowdata) => {
        return (
            <div className="action-icon">
                <button
                    className="grid-icon-img"
                    title="View/Edit User Details"
                    onClick={() => {
                         
                        nav(`/salesmanagement/salesreturn/details?suppliermastcode=${encodeURIComponent(rowdata.MASK_SUPP_ID)}`);
                    }}
                >
                    <img
                        alt="InnoRise"
                        src={require("../../Assets/Images/ViewEdit.png")}
                    />
                </button>
            </div>
        );
    };

    if (suppliermastloader) {
        return(
            <Loader />
        );
    } else {
        return (
            <>
                <div className="col-12 pt-3 pb-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3 className="py-2">Supplier Master</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end mb-2">
                                <button type="submit" className="btn btn-primary" onClick={NewSupplier} >
                                   Add New Supplier
                                </button>
                            </div>
                        </div>
                    </div>
                    <section className="">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="DataTable">
                                <DataTable
                                    value={allsupplierdetails}
                                    tableStyle={{ minWidth: "50rem" }}
                                    paginator
                                    rows={10}
                                    showGridlines
                                    scrollable
                                    stripedRows
                                    scrollHeight="80vh"
                                >
                                    <Column field="CODE" header="Code"></Column>
                                    <Column field="NAME" header="Name"></Column>
                                    <Column body={tosuppliermast} header="Action"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
}

export default SupplierMasterView;
