import { PDFExport } from "@progress/kendo-react-pdf";
import Modal from "react-bootstrap/Modal";
import React, { useRef } from "react";
import { useStockAdjustmentContext } from "../../context/StockAdjustmentContext";
import "./PrintView.scss";
import { useGlobalContext } from "../../context/SalesOrderContext";
import PrintInvoicePopUp from "../../Components/PrintInvoicePopUp";

const StockPrint = () => {
    const printRef = useRef();
    const pdfExportComponent = useRef(null);
    function getUniqueNo() {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dateunique = date.valueOf();
        let uniqueNo = `STKADJ-${day}-${month}-${year}-${dateunique}`;
        return uniqueNo;
    }


    const { printPopup,
        setPrintPopup, printData } = useStockAdjustmentContext();
    const {orientation} = useGlobalContext();

    if(orientation!=="portrait-primary"){
        return (
            <div className="col-12 pt-3 pb-3">
                <Modal className="print-popup" size="lg" show={printPopup} >
                    <Modal.Body>
                        <div id="printablediv">
                            <PDFExport
                                fileName={getUniqueNo()}
                                paperSize="A4"
                                forcePageBreak=".page-break"
                                ref={pdfExportComponent}
                            >
                                <div className="py-5 mt-4">
                                    <p className="text-center" style={{ fontSize: "10px" }}>
                                        <b>Stock Adjustment</b>
                                    </p>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="parent  parent-invoice-terms-6">
                                                <div className="childOne  invoice-terms">
                                                    <p>{printData.Dist_Name}{" "}{printData.Add1}<br />
                                                        {printData.Add2}{" "}{printData.Add3}{" "}{printData.Ex3}
                                                    </p>
                                                    <p style={{ paddingTop: "10px" }}>
                                                        <span className="firstPara">
                                                        Phone Number
                                                        </span>
                                                        {printData.Ph_No}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-8 col-md-6 col-sm-12">

                                        </div>
                                    </div>
                                    <hr style={{ margin: "0.5rem" }}></hr>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="parent  parent-invoice-terms-6">
                                                <div className="childOne  invoice-terms">
                                                    <p>
                                                        <span className="firstPara" style={{ width: "45%" }}>
                                                        Stock Adjustment Number
                                                        </span>
                                                    : {printData.STK_ADJ_NO}
                                                    </p>
                                                    <p>
                                                        <span className="firstPara" style={{ width: "45%" }}>
                                                        Stock Adjustment Date
                                                        </span>
                                                    : {printData.STK_ADJ_DATE}
                                                    </p>
                                                    <p>
                                                        <span className="firstPara" style={{ width: "45%" }}>
                                                        Location
                                                        </span>
                                                    : {printData.LOCATION}
                                                    </p>
                                                    <p>
                                                        <span className="firstPara" style={{ width: "45%" }}>
                                                        Division
                                                        </span>
                                                    : {printData.DIVISION}
                                                    </p>
                                                    <p>
                                                        <span className="firstPara" style={{ width: "45%" }}>
                                                        Warehouse
                                                        </span>
                                                    : {printData.WAREHOUSE}
                                                    </p>
                                                    <p>
                                                        <span className="firstPara" style={{ width: "45%" }}>
                                                        Employee
                                                        </span>
                                                    : {printData.EMPLOYEE}
                                                    </p>
                                                    <p>
                                                        <span className="firstPara" style={{ width: "45%" }}>
                                                        Reason
                                                        </span>
                                                    : {printData.REASON}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="childOne  invoice-terms">
                                                <p>
                                                    <span className="firstPara" style={{ width: "45%" }}>
                                                    Created By
                                                    </span>
                                                : {printData.FIRST_ENTERED_BY}
                                                </p>
                                                <p>
                                                    <span className="firstPara" style={{ width: "45%" }}>
                                                    Created On
                                                    </span>
                                                : {printData.FIRST_ENTERED_ON}
                                                </p>
                                                <p>
                                                    <span className="firstPara" style={{ width: "45%" }}>
                                                    CDR Approved By
                                                    </span>
                                                : {printData.CDR_APPROVED_BY}
                                                </p>
                                                <p>
                                                    <span className="firstPara" style={{ width: "45%" }}>
                                                    CDR Approved On
                                                    </span>
                                                : {printData.CDR_APPROVED_ON}
                                                </p>
                                                <p>
                                                    <span className="firstPara" style={{ width: "45%" }}>
                                                    Distributor Approved By
                                                    </span>
                                                : {printData.DIST_APPROVED_BY}
                                                </p>
                                                <p>
                                                    <span className="firstPara" style={{ width: "45%" }}>
                                                    Distributor Approved On
                                                    </span>
                                                : {printData.DIST_APPROVED_ON}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="parent parent-invoice-table">
                                        <table className="invoice-table-details" style={{ height: "auto" , borderBottom: "none" }}>
                                            <thead>
                                                <tr className="table-row-border">
                                                    <th>Sl Number</th>
                                                    <th>Stock Type</th>
                                                    <th>Product Code and Description</th>
                                                    <th>Batch Number</th>
                                                    <th className="RightAlignTD">Quantity(Case)</th>
                                                    <th className="RightAlignTD">Quantity(Packs)</th>
                                                    <th className="RightAlignTD">Quantity(EA/Ltrs/Kg)</th>
                                                </tr>
                                            </thead>
                                            <tbody key={1} style={{ height: "400px" }}>
                                                {Object.keys(printData).length > 1 ? printData.ListStockAdjustmentPrintProd.map((data) => (
                                                    <tr key={data.SL_NO}>
                                                        <td>{data.SL_NO}</td>
                                                        <td>{data.Stock_Type}</td>
                                                        <td>{data.ProductCode_Desc}
                                                        </td>
                                                        <td>{data.Batch_No}</td>
                                                        <td className="RightAlignTD">{data.Quantity_Case}</td>
                                                        <td className="RightAlignTD">{data.Quantity_Pack}</td>
                                                        <td className="RightAlignTD">{data.Quantity_EA_Ltr_Kg}</td>
                                                    </tr>
                                                )) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div>
                                        <div className="row m-auto">
                                            <div className="column">
                                                <div className="rupeeBox">
                                                    <p>
                                                        <span>Total</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="column">
                                                <div className="childOne  invoice-terms-one">
                                                    <p>
                                                        <span>{printData.SumQtyPack}</span>
                                                        <span>{printData.SumQtyLtrs}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </PDFExport>
                        </div>
                    </Modal.Body >

                    <Modal.Footer>
                        <button className="btn btn-cancel-red me-3" onClick={() => setPrintPopup(false)}>Close</button>
                        <button className="btn btn-primary"
                            ref={printRef}
                            onClick={() => {
                                if (pdfExportComponent.current) {
                                    pdfExportComponent.current.save();
                                }
                            }}
                        >Download</button>
                    </Modal.Footer>
                </Modal>
            </div>
        );}
    else{
        return(<PrintInvoicePopUp show={printPopup} hideShow = {setPrintPopup} name = "Stock Print View"/>);
    }
};

export default StockPrint;