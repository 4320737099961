import React, { useContext, useState } from "react";
// import { useEffect } from "react";
// import { toast } from "react-toastify";
import Axios from "../api/Axios";
import storage from "../Services/Storage";
// import { useReallocateContext } from "./ReallocateContext";
import { useGlobalContext1 } from "./SalesDetailsContext";
// import { useNavigate } from "react-router-dom";

const DefaultVal_URL = "Default/DefaultVal?";
// const getPrintMessage_URL = "SalesOrder/GetPrintMessage?";

const ManualSOUpdateContext = React.createContext();
const ManualSOUpdateProvider = ({ children }) => {
    const auth = storage.getLocal("user");
    const {
        // custId,
        // setFilteredSalesDetails,
        // soHdrId,
        // setSoHdrId,
        // switchUpdate,
        // setSwitchUpdate,
        // setSalesOrderDtlpath,
        // cartVal,
        // setCartVal,
        // SONo,
        // setSONo,
        // custInfo,
        // setCustInfo,
        // exchRate,
        // setExchRate,
        // priceCalculation,
        // setPriceCalculation,
        // payTermDueDate,
        // setPayTermDueDate,
        // defWh,
        // setDefWh,
        // printMessage,
        setPrintMessage,
        // OrdMode,
        setOrdMode,
        // deliveryInstruction,
        setDeliveryInstruction,
        // billingInstruction,
        setBillingInstruction,
        // printHeading,
        setPrintHeading,
        // ESPECIE,
        setESPECIE,
        setLoading
    } = useGlobalContext1();
    // const [MopId, setMopId] = useState([0]);
    const [getWareHouse, setGetWareHouse] = useState([]);
    // const [remarks, setRemarks] = useState("");

    const date = new Date();
    let day = date.getDate();
    let month = String(date.getMonth() + 1).padStart(2, "0");
    let year = date.getFullYear();
    const currentDate = `${day}/${month}/${year}`.toString();

    const getDefaultVal = async () => {
        setLoading(true);
        const defaultVal_api1 = `${DefaultVal_URL}CompId=${auth.CompId}&defId=${7}`;
        const defaultVal_api2 = `${DefaultVal_URL}CompId=${
            auth.CompId
        }&defId=${21}`;
        const defaultVal_api3 = `${DefaultVal_URL}CompId=${
            auth.CompId
        }&defId=${20}`;
        const defaultVal_api4 = `${DefaultVal_URL}CompId=${
            auth.CompId
        }&defId=${16}`;
        const defaultVal_api5 = `${DefaultVal_URL}CompId=${
            auth.CompId
        }&defId=${22}`;
        // const printMessage_api = `${getPrintMessage_URL}CICode=${auth.CICode};`;

        const defaultVal_Res1 = await Axios.get(defaultVal_api1);
        const defaultVal_Res2 = await Axios.get(defaultVal_api2);
        const defaultVal_Res3 = await Axios.get(defaultVal_api3);
        const defaultVal_Res4 = await Axios.get(defaultVal_api4);
        const defaultVal_Res5 = await Axios.get(defaultVal_api5);

        setOrdMode(defaultVal_Res1.data.Id);
        setDeliveryInstruction(defaultVal_Res2.data.Value);
        setBillingInstruction(defaultVal_Res3.data.Value);
        setPrintHeading(defaultVal_Res4.data.Value);
        setESPECIE(defaultVal_Res5.data.Value);
        setLoading(false);
    };


    return (
        <ManualSOUpdateContext.Provider
            value={{
                currentDate,
                setGetWareHouse,
                getWareHouse,
                // setMopId,
                // setRemarks,
                getDefaultVal,
                setPrintMessage
            }}
        >
            {children}
        </ManualSOUpdateContext.Provider>
    );
};
export const useManualSOUpdateContext = () => {
    return useContext(ManualSOUpdateContext);
};

export { ManualSOUpdateProvider, ManualSOUpdateContext };
