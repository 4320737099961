import Axios from "../api/Axios";
const BASE_URL = "DebitCreditNote/";
const Default_BASE_URL = "Default/";
export const Endpoint = {
    GetAllDebitCreditNotes: "GetAllDebitCreditNotes",
    GetAllTaxCode: "GetAllTaxCode",
    GetAllAccountName: "GetAllAccountName",
    GetAllMappedSchemeData: "GetAllMappedSchemeData",
    GetAllEmployeesList: "GetAllEmployeesList",
    GetAllPartyList: "GetAllPartyList",
    GetAllReasonsList: "GetAllReasonsList",
    GetDefaultValue: "DefaultVal",
    ViewDrCr: "DebitCreditView",
    SAVEDECR: "SaveDebCreNote",
};

export const createAPIEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
        getDefaultValue:(Endpoint)=>Axios.get(Default_BASE_URL+Endpoint)
    };
};