import React, { useContext,createContext } from "react";
import { useState } from "react";

const ForgotPasswordContext = createContext();
const ForgotPasswordProvider = ({ children }) => {

    const [confirmPassPopup,setConfirmPassPopup] =useState(false);
    const [constUserId, setconstUserId] = useState("");
    const [userDetails, setUserDetails] = useState([]);

    return (
        <ForgotPasswordContext.Provider
            value={{
                confirmPassPopup,
                setConfirmPassPopup,
                constUserId, 
                setconstUserId,
                userDetails, 
                setUserDetails,
            }}
        >
            {children}
        </ForgotPasswordContext.Provider>
    );
};
export const useForgotPasswordContext = () => {
    return useContext(ForgotPasswordContext);
};

export { ForgotPasswordProvider, ForgotPasswordContext };