import React, { useState } from "react";
import { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import Loader from "../../Components/Loader";
import storage from "../../Services/Storage";
import * as Fa from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { createAPIEndpointproduct,Endpoint } from "../../Services/productmaster/ProductMastService";
import { Column } from "primereact/column";


const ShowAllProdTable = () => {
    const nav = useNavigate();
    const auth = storage.getLocal("user");
    const [allProductData, setAllProductData] = useState([]);
    const [productMasterLoader, setProductMasterLoader] = useState(false);

 

    //API will be called here
    const getAllProduct = async () => {
        setProductMasterLoader(false);
        createAPIEndpointproduct()
            .get(`${Endpoint.GetAllProductMastData}?CICode=${auth.CICode}`)
            .then((response) => {
                setAllProductData(response.data.Data);
                setProductMasterLoader(true);
            })
            .catch(() => {
                setProductMasterLoader(true);
            });
    };

    // const column = [
    //     {
    //         name: (
    //             <div>
    //                 <div
    //                     onMouseOver={() => setSearchBox1(true)}
    //                     onMouseOut={() => setSearchBox1(false)}
    //                 >
    //         Code
    //                     <Fa.FaSort />
    //                 </div>
    //                 <div>
    //                     <input
    //                         type="text"
    //                         className="filter-textbox"
    //                         id="PRODUCT_CODE"
    //                         onChange={(e) => {
    //                             handleChange(e);
    //                         }}
    //                     />
    //                 </div>
    //             </div>
    //         ),

    //         selector: (row) => row.PRODUCT_CODE,
    //         sortable: searchBox1,
    //     },

    //     {
    //         name: (
    //             <div>
    //                 <div
    //                     onMouseOver={() => setSearchBox1(true)}
    //                     onMouseOut={() => setSearchBox1(false)}
    //                 >
    //         Name <Fa.FaSort />
    //                 </div>
    //                 <div>
    //                     <input
    //                         type="text"
    //                         className="filter-textbox"
    //                         id="MASK_PRODUCT_NAME"
    //                         onChange={(e) => {
    //                             handleChange(e);
    //                         }}
    //                     />
    //                 </div>
    //             </div>
    //         ),

    //         selector: (row) => row.MASK_PRODUCT_NAME,
    //         sortable: searchBox1,
    //     },

    //     {
    //         name: (
    //             <div>
    //                 <div
    //                     // onMouseOver={() => setSearchBox1(true)}
    //                     // onMouseOut={() => setSearchBox1(false)}
    //                 >
    //         Status
    //                 </div>
    //                 <div>
    //                     {/* <input
    //           type="text"
    //           className="filter-textbox"
    //           id="STATUS"
    //           onChange={(e) => {
    //             handleChange(e);
    //           }}
    //         /> */}
    //                 </div>
    //             </div>
    //         ),
    //         selector: (row) => row.STATUS,
    //     },

    //     {
    //         name: "Action",
    //         cell: (row) => (
    //             <div className="action-icon">
    //                 <div>
    //                     <button
    //                         className="grid-icon"
    //                         title="View Product Details"
    //                         onClick={() => {
    //                             nav(
    //                                 `/productpages?mask_pro_id=${encodeURIComponent(
    //                                     row.PRODUCT_CODE
    //                                 )}`
    //                             );
    //                         }}
    //                     >
    //                         <Fa.FaRegFileAlt />
    //                     </button>
    //                 </div>
    //             </div>
    //         ),
    //     },
    // ];
   

    const renderAction = (rowdata) => {
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View Product Details">
                        <button className="grid-icon" onClick={()=>{nav(`/masters/productmaster/details?mask_pro_id=${encodeURIComponent(rowdata.PRODUCT_CODE)}`);}}>                                    
                            <Fa.FaEye />
                        </button>
                    </div>
                    {/* <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="Cancel">
                        <button className="grid-icon">
                            <Fa.FaTimes className="cancel" />
                        </button>
                    </div> */}
                </div>
            </>
        );

    };
    useEffect(() => {
        getAllProduct();
    }, []);

    // useEffect(() => {
    //     let allProducts = [...allProductFilter];

    //     if (productFilters.MASK_PRODUCT_NAME) {
    //         allProducts = allProducts.filter((val) => {
    //             return val.MASK_PRODUCT_NAME.toLocaleLowerCase().includes(
    //                 productFilters.MASK_PRODUCT_NAME.toLocaleLowerCase()
    //             );
    //         });
    //     }
    //     if (productFilters.PRODUCT_CODE) {
    //         allProducts = allProducts.filter((val) => {
    //             return val.PRODUCT_CODE.toLocaleLowerCase().includes(
    //                 productFilters.PRODUCT_CODE.toLocaleLowerCase()
    //             );
    //         });
    //     }
    //     if (productFilters.STATUS) {
    //         allProducts = allProducts.filter((val) => {
    //             return val.STATUS.toLocaleLowerCase().includes(
    //                 productFilters.STATUS.toLocaleLowerCase()
    //             );
    //         });
    //     }
    //     setAllProductData(allProducts);
    // }, [productFilters]);

    // const handleChange = (e) => {
    //     const id = e.target.id;
    //     const value = e.target.value;
    //     setProductFilters({ ...productFilters, [id]: value });
    // };

    if (!productMasterLoader) {
        return <Loader />;
    } else {
        return (
            <>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="DataTable">
                        <DataTable
                            value={allProductData}
                            dataKey='PRODUCT_CODE'
                            paginator
                            rows={10}
                            filterDisplay="row"
                            showGridlines
                            stripedRows
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            tableStyle={{ minWidth: "80rem" }}
                            globalFilterFields={["PROD_CODE", "DESCRIPTION"]} emptyMessage="No Products found.">
                            <Column field="PRODUCT_CODE" header="Product Code" filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                            <Column field="MASK_PRODUCT_NAME" header="Product Name" filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                            <Column field="PROD_VARIANT_DESC" header="Product Variant" style={{ minWidth: "10rem" }}/>
                            <Column field="MASK_NET_VOL" header="Net Volume" style={{ minWidth: "7rem" }} />
                            <Column field="MASK_BASE_UOM_DESC" header="Unit of Mesaurement(UOM)" style={{ minWidth: "7rem" }} />
                            <Column field="STATUS" header="Status" style={{ minWidth: "7rem" }} />
                            <Column field={renderAction} header="Action" />
                        </DataTable>
                    </div>
                </div>
            </>
        );
    }
};

export default ShowAllProdTable;
