import React, { useState } from "react";
import * as Fa from "react-icons/fa";
import { saveAs } from "file-saver";
import storage from "../../Services/Storage";
// import Loader from "../../Components/Loader";
import Axios from "../../api/Axios";
import { Endpoint } from "../../Services/ReportsAll/PriceListService";
const PriceList = () => {
    const BASE_URL = "PriceList/";
    const auth = storage.getLocal("user");
    const [loading, setLoading] = useState(false);
    const handleDownload = async () => {
        setLoading(true);
        try {
            const response = await Axios.get(
                `${BASE_URL}${Endpoint.GetPriceList}?UserId=${auth.UserId}`,
                {
                    responseType: "blob"
                }
            );

            const blob = new Blob([response.data], { type: "application/zip" });
            saveAs(blob, "PriceListReport.zip");
            setLoading(false);
        }
        catch (error) {
            console.log("Error", error);
        }
    };
    return (
        <main>
            <div className="col-12 pt-3 pb-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Price List Report</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12"></div>
                </div>
                <section className="sales-box">
                    <div className="">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <img src={require("../../Assets/Images/priceList.png")} style={{ width: "90%" }} />
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <p style={{ fontSize: "16px" }}>Click on the button to download the <b>Price List Report</b></p>
                                <button
                                    disabled={loading}
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        handleDownload();
                                    }}
                                >
                                    {loading ? "Loading..." : "Download Zip File"}
                                    {loading ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <Fa.FaDownload className="ms-2" />
                                    )}
                                </button>
                                {/* <button type="button" className="btn btn-primary" onClick={() => handleDownload()}>Download <Fa.FaDownload className="ms-2" /></button> */}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
    

};

export default PriceList;