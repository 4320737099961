import React, { useContext, createContext } from "react";
import { useState } from "react";
import { EndpointPurchaseReturn, createAPIEndpointPurchaseReturn } from "../Services/PurchaseReturn/PurchaseReturnService";
import storage from "../Services/Storage";
import { format } from "date-fns";
import { toast } from "react-toastify";



const PurchaseReturnContext = createContext();
const PurchaseReturnProvider = ({ children }) => {

    const [matchingDocPopUp, setMatchingDocPopUp] = useState(false);
    const [supplierDisable, setSupplierDisable] = useState(false);
    const [GRNAllData, setGRNAllData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [product, setProduct] = useState([]);
    const [populateDataTable, setPopulateDataTable] = useState([]);
    const todaydate = new Date();
    const currentDate = format(todaydate, "yyyy-MM-dd");
    const sevenDays = todaydate.setDate(todaydate.getDate() - 7);
    const formattedSevenDaysAgo = format(sevenDays, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(formattedSevenDaysAgo);
    const [toDate, setToDate] = useState(currentDate);
    const [purchaseReturnTable, setPurchaseReturnTable] = useState([]);
    const [supplierId, setSupplierId] = useState("");
    const [matchingDocDetails, setMatchingDocDetails] = useState("");
    const [employee, setEmployee] = useState("");
    const [printData, setPrintData] = useState([]);
    const [printPopup, setPrintPopup] = useState(false);
    const [viewPurchaseReturn,setViewPurchaseReturn] = useState([]);
    const [isLoading, setIsLoading] = useState(false);


    const auth = storage.getLocal("user");

    const findDatePurchaseReturn = (e, fromDate, toDate) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        getPurchaseReturn(fromDateRange, toDateRange);

        e.preventDefault();
    };
    //Function API calling for getCreditNotesData for selected dates
    const getPurchaseReturn = async (fromDateRange, toDateRange) => {
        try {
            setIsLoading(true);
            createAPIEndpointPurchaseReturn()
                .get(`${EndpointPurchaseReturn.GetAllPurchaseReturnRecord}?UserId=${auth.UserId}&CompId=${auth.CompId}&fromDate=${fromDateRange}&toDate=${toDateRange}`)
                .then((res) => {
                    console.log("res.d", res.data);
                    setPurchaseReturnTable(res?.data?.Data);
                    setIsLoading(false);
                });

        } catch (error) {
            setIsLoading(false);
            toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            console.log(error);
        }
    };

    const printPurchaseReturn = (drcrId)=>{
        setIsLoading(true);
        createAPIEndpointPurchaseReturn()
            .get(`${EndpointPurchaseReturn.PrintPurRet}?strPurchaseReturnId=${drcrId}`)
            .then((response)=>{
                setPrintData(response?.data);
                setPrintPopup(true);
                setIsLoading(false);
            })
            .catch((error)=>{
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                console.log(error);
                setIsLoading(false);
            });
            
    };
    
    return (
        <PurchaseReturnContext.Provider
            value={{
                matchingDocPopUp,
                setMatchingDocPopUp,
                supplierDisable,
                setSupplierDisable,
                GRNAllData,
                setGRNAllData,
                selectedData,
                setSelectedData,
                product,
                setProduct,
                setPopulateDataTable,
                populateDataTable,
                findDatePurchaseReturn,
                getPurchaseReturn,
                setToDate,
                toDate,
                setFromDate,
                fromDate,
                formattedSevenDaysAgo,
                currentDate,
                purchaseReturnTable,
                supplierId, 
                setSupplierId,
                matchingDocDetails, 
                setMatchingDocDetails,
                employee, 
                setEmployee,
                printPurchaseReturn,
                printData,
                viewPurchaseReturn,
                setViewPurchaseReturn,
                printPopup,
                setPrintPopup,
                isLoading, 
                setIsLoading

            }}
        >
            {children}
        </PurchaseReturnContext.Provider>
    );
};
export const usePurchaseReturnContext = () => {
    return useContext(PurchaseReturnContext);
};

export { PurchaseReturnProvider, PurchaseReturnContext };