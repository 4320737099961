import React, { useEffect, useState } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { useEmployeeMasterContext } from "../../context/EmployeeMasterContext";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import * as Fa from "react-icons/fa";


const EmployeeTable = () => {
    const nav = useNavigate();

    const {
        selectedEmployeeData,
        setSelectedEmployeeData,
        setIsEdit,
        addChildFlag,
        setAddChildFlag,
        setParentCodeFlag,
        setDFMR,
        employeeMasterList,
        EmployeeMasterAPI,
        isLoading,
    } = useEmployeeMasterContext();

    const [selectedNodeKeys, setSelectedNodeKeys] = useState("");
    const [disableEditFlag, setDisableEditFlag] = useState(false);


    const newAddNavigation = () => {
        nav("/masters/employeemaster/details");
        setIsEdit(false);
        setParentCodeFlag(false);
        setDFMR(false);
    };

    const newAddChildNavigation = () => {
        setParentCodeFlag(true);
        nav("/masters/employeemaster/details");
        setIsEdit(false);

    };
    const editPageNavigation = () => {
        if (selectedEmployeeData.EMP_ID == undefined) {
            toast.error(
                "Please select employee.",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                }

            );
            return;
        }
        nav("/masters/employeemaster/details");
        setIsEdit(true);
        setParentCodeFlag(false);
    };

    useEffect(() => {
        EmployeeMasterAPI();
        setSelectedEmployeeData([]);

    }, []);
    let children = [];
    let objData = [];
    let parentData = employeeMasterList.filter((val) => val.EMP_LVL_NO === 1);
    let i = 0;
    let j = 0;
    let childKey = 0;
    parentData.forEach((valp) => {
        employeeMasterList.forEach((val) => {
            if (valp.EMP_CODE === val.PARENT_CODE) {
                childKey = `${i}-${j}`;
                let x = { key: childKey, data: val };
                children.push(x);

                j++;
            }
        });
        objData = [
            ...objData,
            { key: i.toString(), Parent: valp, children: children },
        ];
        children = [];
        j = 0;
        i++;
    });

    let datatableData = objData.map((val) => ({
        key: val.key,
        data: val.Parent,

        children: val.children,
    }));

    const onEmployeeSelect = (event) => {
        setDisableEditFlag(true);
        setAddChildFlag(true);
        setDFMR(false);
        setSelectedEmployeeData(event.node.data);

        if (event.node.data.EMP_STATUS == "Inactive") {
            setAddChildFlag(false);
        }

        if (event.node.data.EMP_ID.includes("DFMR")) {
            setAddChildFlag(false);
            setDFMR(true);
        }
        if (event.node.data.EMP_LVL_NO === 2) {
            setAddChildFlag(false);
        }
    };

     
    useEffect(() => {
        setAddChildFlag(false);

    }, []);
    const rowClassName = (node) => {
         
        return { "p-highlight": (!node.children), "p-highlight1": (node.children != 0) };
    };

    const renderAction = () => {
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View/Edit Employee Details">
                        <button disabled={disableEditFlag?false:true} onClick={editPageNavigation} className="btn btn-secondary"><Fa.FaEdit /></button>
                    </div>
                </div>
            </>
        );
    };

     

    if (isLoading) {
        return <Loader />;
    }
    else {
        return (
            <main>
                <div className="col-12 pt-3 pb-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3 className="py-2">Employee Master</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end mb-2">
                                {addChildFlag && <button type="submit" className="btn btn-outline-primary me-3" onClick={newAddChildNavigation}>Add Child</button>}
                                <button type="submit" className="btn btn-primary" onClick={newAddNavigation}>Add New Employee</button>
                            </div>
                        </div>
                    </div>
                    <section className="">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="DataTable">
                                <TreeTable value={datatableData} selectionMode="single" rowClassName={rowClassName} selectionKeys={selectedNodeKeys} onSelect={onEmployeeSelect} paginator rows={10} onSelectionChange={(e) => setSelectedNodeKeys(e.value)} showGridlines stripedRows tableStyle={{ minWidth: "80rem" }} >
                                    <Column field="EMP_CODE" header="Employee Code" expander></Column>
                                    <Column field="EMP_FNAME" header="Employee Name" body={(rowData) => <span>{rowData.data.EMP_FNAME} {rowData.data.EMP_MNAME} {rowData.data.EMP_LNAME}</span>}></Column>
                                    <Column field="EMP_STATUS" header="Status"></Column>
                                    <Column field="DESIG_NAME" header="Designation"></Column>
                                    <Column field="TOWN" header="Town"></Column>
                                    <Column field="MOBILE_ID" header="Mobile Number"></Column>
                                    <Column field={renderAction} header="Action" style={{ width: "6rem" }}></Column>
                                </TreeTable>
                            </div>
                        </div>
                        {/* <div className="col-lg-5 col-md-12 col-sm-12">
                                <div className="StickyShortViewBox sticky-top">
                                    <div className="float-end">
                                        <h3 onClick={editPageNavigation} className="text-success" ><Fa.FaEdit /></h3>
                                    </div>
                                    <p className="mb-1"><span className="one">Parent Code</span><span className="two">: {sticky ? selectedEmployeeData.PARENT_CODE : ""}</span></p>
                                    <p className="mb-1"><span className="one">First Name</span><span className="two">: {sticky ? selectedEmployeeData.EMP_FNAME : ""}</span></p>
                                    <p className="mb-1"><span className="one">Middle Name</span><span className="two">: {sticky ? selectedEmployeeData.EMP_MNAME : ""} </span></p>
                                    <p className="mb-1"><span className="one">Last Name</span><span className="two">: {sticky ? selectedEmployeeData.EMP_LNAME : ""}</span></p>
                                    <p className="mb-1"><span className="one">Code</span><span className="two">: {sticky ? selectedEmployeeData.EMP_CODE : ""}</span></p>
                                    <p className="mb-1"><span className="one">Designation</span><span className="two">: {sticky ? selectedEmployeeData.DESIG_NAME : ""} </span></p>
                                    <p className="mb-1"><span className="one">Title</span><span className="two">: {sticky ? selectedEmployeeData.EMP_TITLE : ""}</span></p>
                                    <p className="mb-1"><span className="one">Short Name</span><span className="two">: {sticky ? selectedEmployeeData.EMP_SHORT_NAME : ""} </span></p>
                                </div>
                                <small className="p-error p-2"><i>**Note: Please select single row to edit Employee**</i></small>
                            </div> */}
                    </section>
                </div>
            </main>
        );
    }
};
export default EmployeeTable;
