import Axios from "../../api/Axios";
const BASE_URL = "PDAReceipt/";

export const Endpoint = {
    GetALLPDAReceipt: "GetALLPDAReceipt",
    GetPDAReceiptByRef: "GetPDAReceiptByRef",
    GetSavedPDAReceipt: "GetSavedPDAReceipt",
    SavePDAReceipt: "SavePDAReceipt",
};


export const createAPIPDAReceipt = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};