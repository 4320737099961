import React, { useContext, createContext } from "react";
import { useState } from "react";
import {
    EndpointInvoice,
    createAPIEndpointInvoice,
} from "../Services/Invoice/InvoiceService";
import storage from "../Services/Storage";
import { format, subDays } from "date-fns";
import { toast } from "react-toastify";
import Axios from "../api/Axios";
import {
    createAPIEndpoint,
    Endpoint,
} from "../Services/DeliveryChallan/DeliveryChlnService";

const DeliveryChallanContext = createContext();
const DeliveryChallanProvider = ({ children }) => {
    const [DCpopup, setDCPopup] = useState(false);
    const [printData, setPrintData] = useState([]);
    const [pageLoading, setPageLoading] = useState(false);
    const [checkInvoice, setCheckInvoice] = useState(false);
    const [cancelInvPopUp, setCancelInvPopUp] = useState(false);
    const [rowInvoiceData, setRowInvoiceData] = useState(false);
    const [branchIdInvoice, setBranchIdInvoice] = useState("");
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [ILSOLID, setILSOLID] = useState([]);
    const [cancelpopUpMsg, setCancelpopUpMsg] = useState("");
    const [openPopUp, setOpenPopUp] = useState(false);
    const [getrowdata, setGetrowdata] = useState();

    const currentDate = format(new Date(), "dd/MM/yyyy");
    const auth = storage.getLocal("user");

    const beforeCancelInvoice = (rowData) => {
        console.log(rowData);
        setPageLoading(true);
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=36`
            )
            .then((res) => {
                // console.log("Ab00", res);
                setPageLoading(false);
                setCancelpopUpMsg(res.data.Id);
                setOpenPopUp(true);
                setGetrowdata(rowData);
        
                //show pop up
                // cancelInvoice(rowData);
            })
            .catch(() => {
                toast.error("Something went wrong.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setPageLoading(false);
            });
    };

    const onCofirmOk = () => {
        // console.log("rowData", getrowdata);
        setOpenPopUp(false);
        cancelInvoice(getrowdata);
    };

    const cancelInvoice = (row) => {
        setPageLoading(true);
        setRowInvoiceData(row);
        createAPIEndpointInvoice()
            .getBranch(
                `${EndpointInvoice.GetBranchByWH}?CICode=${auth.CICode}&strWareHouseId=${row.WH_ID}`
            )
            .then((res) => {
                setBranchIdInvoice(res.data);
            })
            .catch(() => {
                toast.error("Something went wrong.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setPageLoading(false);
            });

        let checkInvoicancelObj = {
            comP_ID: auth.CompId,
            disT_TAG: auth.DistTag,
            masK_INVH_ID: row.MASK_INVH_ID,
            invH_NO: row.INVH_NO,
            invH_DATE: row.INVH_DATE,
        };

        createAPIEndpointInvoice()
            .post(
                `${EndpointInvoice.CancelInvoiceCheck}`,
                JSON.stringify(checkInvoicancelObj),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            .then((res) => {
                setPageLoading(false);

                setILSOLID(res?.data?.ILSOLID);
                setCheckInvoice(res?.data);
                setCancelInvPopUp(true);
                // if(checkInvoice.TASK_DESC=="DocAdjust"){
                //     setCheckInvoice(res.data);
                //     setCancelInvPopUp(true);
                //     }
            })
            .catch((error) => {
                console.log(error);
                setPageLoading(false);
                toast.error(`${error.response.data.Message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            });
        setPageLoading(false);
    };

    const confirmCancelInv = () => {
        setPageLoading(true);
        setCancelInvPopUp(false);
        let cancelObj = {
            masK_INVH_ID: rowInvoiceData.MASK_INVH_ID,
            invH_NO: rowInvoiceData.INVH_NO,
            invH_DATE: rowInvoiceData.INVH_DATE,
            tasK_DESC: checkInvoice.TASK_DESC,
            ilsolid: ILSOLID,
            comP_ID: auth.CompId,
            crediT_NOTE_ID: "",
            crediT_NOTE_NO: "",
            crediT_NOTE_DATE: currentDate,
            wH_ID: rowInvoiceData.WH_ID,
            useR_ID: auth.UserId,
            defineD_BY: "E",
            brancH_ID: branchIdInvoice,
            cusT_ID: rowInvoiceData.CUST_ID,
            currencY_ID: rowInvoiceData.CURRENCY_ID,
            invH_TOT_AMT: rowInvoiceData?.INVH_TOT_AMT.toString(),
            irN_NO: rowInvoiceData.IRN_NO,
            sohD_ID: rowInvoiceData.SOHD_ID,
            invH_EXCH_RATE: rowInvoiceData?.INVH_EXCH_RATE.toString(),
        };
        if (checkInvoice.TASK_DESC !== "ChkCancel") {
            createAPIEndpointInvoice()
                .post(
                    `${EndpointInvoice.ConfirmCancelInvoice}`,
                    JSON.stringify(cancelObj),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                .then((res) => {
                    if (checkInvoice.TASK_DESC == "DocAdjust") {
                        setCheckInvoice(res.data);
                        setCancelInvPopUp(true);
                    } else if (checkInvoice.TASK_DESC == "ChkReceiptCancel") {
                        setCheckInvoice(res.data);
                        setCancelInvPopUp(true);
                    } else if (checkInvoice.TASK_DESC == "ChkCustomerAdvance") {
                        setCheckInvoice(res.data);
                        setCancelInvPopUp(true);
                    } else if (checkInvoice.TASK_DESC == "ChkCancel") {
                        setCheckInvoice(res.data);
                        setCancelInvPopUp(true);
                    }
                    setPageLoading(false);
                })
                .catch(() => {
                    toast.error("Something went wrong.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setPageLoading(false);
                    setCancelInvPopUp(false);
                });
        } else {
            createAPIEndpointInvoice()
                .post(
                    `${EndpointInvoice.ConfirmCancelInvoice}`,
                    JSON.stringify(cancelObj),
                    {
                        headers: { "Content-Type": "application/json" },
                    }
                )
                .then(async (res) => {
                    setPageLoading(false);
                    toast.success(`${res.data.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setCancelInvPopUp(false);
                    try {
                        const url = "Invoice/GetAllInvoice?";
                        const currentDate = format(new Date(), "dd-MM-yyyy");
                        const date = subDays(new Date(), 7);
                        const sevenDays = format(date, "dd-MM-yyyy");
                        const Invoice_Url = `${url}CICode=${auth.CICode}&strFromDateString=${sevenDays}&strToDateString=${currentDate}&CompId=${auth.CompId}`;

                        const response = await Axios.get(Invoice_Url);

                        {
                            response.data.length < 1
                                ? setFilteredInvoices([])
                                : setFilteredInvoices(response.data);
                        }
                    } catch (error) {
                        toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3000,
                        });
                    }
                })
                .catch((error) => {
                    setPageLoading(false);
                    toast.error(`${error?.response.data.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setCancelInvPopUp(false);
                });
        }
    };

    return (
        <DeliveryChallanContext.Provider
            value={{
                DCpopup,
                setDCPopup,
                printData,
                setPrintData,
                pageLoading,
                setPageLoading,
                checkInvoice,
                setCheckInvoice,
                cancelInvPopUp,
                setCancelInvPopUp,
                rowInvoiceData,
                setRowInvoiceData,
                branchIdInvoice,
                setBranchIdInvoice,
                confirmCancelInv,
                // cancelInvoice,
                filteredInvoices,
                setFilteredInvoices,
                beforeCancelInvoice,
                openPopUp,
                setOpenPopUp,
                cancelpopUpMsg,
                onCofirmOk,
            }}
        >
            {children}
        </DeliveryChallanContext.Provider>
    );
};
export const useDeliveryChallanContext = () => {
    return useContext(DeliveryChallanContext);
};

export { DeliveryChallanProvider, DeliveryChallanContext };
