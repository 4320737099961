import Axios from "../../api/Axios";
const BASE_URL = "SalesReturn/";
const Default_BASE_URL = "Default/";

export const EndpointSalesReturn = {
    GetCustomerData: "GetCustomerData",
    GetInvoiceData: "GetInvoiceData",
    GetSalesReturnRecord : "GetSalesReturnRecord",
    GetWareHouse:"GetWareHouse",
    GetReasonData:"GetReasonData",
    GetProductData:"GetProductData",
    GetSelectedProductOrderQty :"GetSelectedProductOrderQty",
    AddtoCart : "AddtoCart",
    GetInvoiceSearchData : "GetInvoiceSearchData",
    GetSalRetDetails : "GetSalRetDetails",
    SaveSalesReturn : "SaveSalesReturn",
    GetDefaultValue: "DefaultVal",
    ConfirmAdjust:"ConfirmAdjust",
    PrintSalesReturn:"PrintSalesReturn",
    WhDefaultVal : "WhDefaultVal"
};
export const createAPIEndpointSalesReturn = () => {
    return {
        getDefaultValue:(Endpoint)=>Axios.get(Default_BASE_URL+Endpoint),
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};