import React from "react";
import { useNavigate } from "react-router-dom";
import { useStockAdjustmentContext } from "../../context/StockAdjustmentContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useEffect } from "react";
import { format, subDays } from "date-fns";
import * as Fa from "react-icons/fa";
import Loader from "../../Components/Loader";
import StockPrint from "./StockPrint";
import { useState } from "react";

const StockTable = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const nav = useNavigate();
    const addnewpage = () => {
        nav("/inventorymanagement/stockadjustment/details");
    };

    const {
        findDateStock,
        getStockData,
        allStkAdjData,
        deleteStockAdj,
        loading,
        getPrintData,
        setPrintPopup,
        setRowData,
        setselectedWHData
    } = useStockAdjustmentContext();

    useEffect(() => {
        const onPageLoad = ()=>{
            const fDate = new Date(fromDate);
            const fromDateRange = format(fDate, "dd/MM/yyyy");
            const tDate = new Date(toDate);
            const toDateRange = format(tDate, "dd/MM/yyyy");

            getStockData(fromDateRange, toDateRange);
            setselectedWHData("");
        };
        onPageLoad();
    }, [1]);

    

    const handleDeleteCart = (rowData) => {
        deleteStockAdj(rowData);     
    };

    const StkAdjNo = (rowData) => {
        return (
            <>
                <a
                    onClick={() => {
                        setRowData(rowData);
                        nav(
                            `/inventorymanagement/stockadjustment/details?stk_adj_hdr_id=${encodeURIComponent(
                                rowData.MASK_STK_ADJ_HDR_ID
                            )}`
                        );
                    }}
                >
                    {rowData.STK_ADJ_NO}
                </a>
            </>
        );
    };

    const renderAction = (rowdata) => {
         
        return (
            <>
                <div className="action-icon">
                    {/* <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="Print">
                        <button className="grid-icon" onClick={() => { nav(`/newstock?stk_adj_hdr_id=${encodeURIComponent(rowdata.MASK_STK_ADJ_HDR_ID)}`); }}>
                            <Fa.FaPrint />
                        </button>
                    </div> */}

                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        onClick={() => {
                            setPrintPopup(true);
                            getPrintData(rowdata);
                        }}
                        title="Print"
                    >
                        <button className="grid-icon">
                            <Fa.FaPrint />
                        </button>
                    </div>
                    {rowdata.MASK_STATUS == "Submitted" && (
                        <div
                            className="d-inline"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            onClick={() => handleDeleteCart(rowdata)}
                            title="Cancel"
                        >
                            <button className="grid-icon">
                                <Fa.FaTimes className="cancel" />
                            </button>
                        </div>
                    )}
                </div>
            </>
        );
    };
    const customFilter = (value, filter) => {
    // debugger
        return value.toLowerCase().includes(filter.toLowerCase());
    };
    // console.log("bhbe",toDate)
     

    return (
        <div className="col-12 pt-4 pb-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Stock Adjustment</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <button type="submit" className="btn btn-primary" onClick={addnewpage}>Add New</button>
                    </div>
                </div>
            </div>

            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                            <label className="form-label">From Date</label>
                            <input
                                type="date"
                                className="form-control"
                                defaultValue={sevenDays}
                                max={toDate}
                                min="2010-01-01"
                                name="date-field"
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                            <label className="form-label">To Date</label>
                            <input
                                type="date"
                                defaultValue={currentDate}
                                max={currentDate}
                                min={fromDate}
                                className="form-control"
                                name="date-field"
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="float-end mt-4">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                        findDateStock(e, fromDate, toDate);
                                    }}
                                >
                  Get Data
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section className="">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h3>All Records</h3>
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="DataTable pb-4 pt-1">
                            {loading ? (
                                <Loader />
                            ) : (
                                <DataTable
                                    value={allStkAdjData}
                                    dataKey="MASK_STK_ADJ_HDR_ID"
                                    paginator
                                    showGridlines
                                    stripedRows
                                    scrollable
                                    rows={10}
                                    filterDisplay="row"
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    globalFilterFields={["PROD_CODE", "DESCRIPTION"]}
                                    emptyMessage="No stock adjustment found."
                                    tableStyle={{ minWidth: "60rem" }}
                                >
                                    <Column
                                        field="WAREHOUSE_DESCRIPTION"
                                        header="Warehouse"
                                        style={{ minWidth: "12rem" }}
                                    />
                                    {/* <Column field={} header="Stock Adjustment Number" style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." /> */}
                                    <Column
                                        field="STK_ADJ_NO"
                                        header="Stock Adjustment Number"
                                        style={{ minWidth: "8rem" }}
                                        body={StkAdjNo}
                                        filter
                                        filterMatchMode="custom"
                                        filterFunction={customFilter}
                                        filterPlaceholder="Search Here..."
                                    />
                                    <Column
                                        field="STOCK_ADJUSTMENT_DATE"
                                        header="Stock Adjustment Date"
                                        style={{ minWidth: "12rem" }}
                                    />
                                    <Column
                                        field="MASK_STATUS"
                                        header="Status"
                                        style={{ minWidth: "12rem" }}
                                    />
                                    <Column field={renderAction} header="Action" />
                                </DataTable>
                            )}
                        </div>
                    </div>
                </div>
                <StockPrint />
            </section>
        </div>
    );
};

export default StockTable;
