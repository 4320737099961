import Axios from "../../api/Axios";
const BASE_URL = "DeliveryChallan/";
const Default_BASE_URL = "Default/";
const SaleOrder_BASE_URL = "SalesOrder/";
const FreeInvoice_BASE_URL = "FreeInvoice/";



export const Endpoint = {
    GetWarehouse: "GetWarehouse",
    GetDeliveryChallanCustomer: "GetDeliveryChallanCustomer",
    GetCustomerData: "GetCustomerData",
    GetPayTerm: "GetPayTerm",
    GetSalesExecutive: "GetSalesExecutive",
    GetMOP: "GetMOP",
    GetAllProduct: "GetAllProduct",
    GetSchemeGridData: "GetSchemeGridData",
    GetDivisionInfo:"GetDivisionInfo",
    GetBranchByWH:"GetBranchByWH",
    GetRegistDesc:"GetRegistDesc",
    GetSerieByBranch:"GetSerieByBranch",
    GetSaleDiscPercByMOP:"GetSaleDiscPercByMOP",
    GetMaxInvdateForBackdating:"GetMaxInvdateForBackdating",
    GetDefaultValue: "DefaultVal",
    GetCustomerInformation:"GetCustomerInformation",
    GetExchRate:"GetExchRate",
    GetCustFinancialInfo:"GetCustFinancialInfo",
    SaveDeliveryChallan:"SaveDeliveryChallan",
    PrintDeliveryChallan:"PrintDeliveryChallan"
};

export const createAPIEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
        getDefaultValue:(Endpoint)=>Axios.get(Default_BASE_URL+Endpoint),
        getSegTaxID:(Endpoint)=>Axios.get(SaleOrder_BASE_URL+Endpoint),
        getRegist:(Endpoint)=>Axios.get(FreeInvoice_BASE_URL+Endpoint)
    };
};