import Axios from "../../api/Axios";
const BASE_URL = "EmployeeMaster/";


export const Endpoint = {
    GetDesignation: "GetDesignation",
    GetLedger: "GetLedger",
    GetEmployeeList: "GetEmployeeList",
    GetEmployeeListDFRM: "GetEmployeeListDFRM",
    GetGeography: "GetGeography",
    GetEmployeeMasterList: "GetEmployeeMasterList",
    EmployeeMasterSave: "EmployeeMasterSave",
    DeleteEmployeeMaster: "DeleteEmployeeMaster"
};

export const createAPIEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
        getDefaultValue:(Endpoint)=>Axios.get(BASE_URL+Endpoint)
    };
};