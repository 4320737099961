export const initialValues = {
    delivery_Challan_no:"",
    delivery_Challan_date:"",
    warehouse:"",
    cust_name:"",
    payment_term:"",
    sales_executive:"",
    stock_type:"Inventory",
    payment_mode:"",
    remarks:"",
    product_code:"",
    product_description:"",
    lot_no:"",
    available_quantity:"",
    order_quantity:"",

};