import Axios from "../../api/Axios";
const BASE_URL = "ParamSetting/Transaction/";
export const Endpoint = {
    GetTransParameterData: "GetTransParameterData",
    GetFinYearData: "GetFinYearData",
    SaveTransactionData: "SaveTransactionData",
};

export const createAPIEndpointTrans = () => {
    return { get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint,data)=> Axios.post(BASE_URL+Endpoint,data)
    };
};