import Axios from "../../api/Axios";
const BASE_URL = "ReceiptsPayments/";
export const Endpoint = {
    PopulateAllReceiptGrid: "PopulateAllReceiptGrid",
    PopulatePartyData: "PopulatePartyData",
    PopulateMOPData: "PopulateMOPData",
    PopulateAccountsData: "PopulateAccountsData",
    GetCustFinancialInfo: "GetCustFinancialInfo",
    GetReceiptDetail: "GetReceiptDetail",
    AdjustDocument: "AdjustDocument",
    CheckAccoutForPosting: "CheckAccoutForPosting",
    GetReceiptDocDetail: "GetReceiptDocDetail",
    GetReceiptChqDetail: "GetReceiptChqDetail",
    CancelCheck: "CancelCheck",
    ConfirmCancel: "ConfirmCancel",
    PrintReceipt: "PrintReceipt",
    SaveReceipt: "SaveReceipt",
};
// /api/ReceiptsPayments/GetReceiptDocDetail
// /api/ReceiptsPayments/ConfirmCancel
// /api/ReceiptsPayments/SaveReceipt
// /api/ReceiptsPayments/PopulateAllReceiptGrid
// /api/ReceiptsPayments/PopulatePartyData
// /api/ReceiptsPayments/PopulateAccountsData
// /api/ReceiptsPayments/GetCustFinancialInfo
// /api/ReceiptsPayments/GetReceiptDetail
// /api/ReceiptsPayments/GetReceiptChqDetail
// /api/ReceiptsPayments/CancelCheck

export const createAPIReceiptsEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};