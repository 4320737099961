import Modal from "react-bootstrap/Modal";
import React from "react";
import { useEmployeeMasterContext } from "../../context/EmployeeMasterContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";


const SearchCustomerModal = () => {

    const nav=useNavigate();
    const { searchpopup, 
        setSearchPopup,
        employeeList,
        newEmployeeSave,
        newSaveData,
        setIsReplacement,
        selectedReplacementEmployeeData,
        setSelectedReplacementEmployeeData,
        employeeListDFMR,
        DFMRFlag
    } = useEmployeeMasterContext();

    const confirmEmployee = () => {
        newEmployeeSave(newSaveData);
        nav("/masters/employeemaster");
        setSearchPopup(false);
    };

    const handleClose = () => {
        setSearchPopup(false);
    };


    return (
        <div>
            <>
                <div className="col-12 pt-3 pb-3">
                    <Modal show={searchpopup} size="lg" onHide={() => { setSearchPopup(false); }} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Search Employee</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="DataTable pt-1 pb-4">
                                    <DataTable value={DFMRFlag?employeeListDFMR:employeeList} showGridlines stripedRows selection={selectedReplacementEmployeeData} onSelectionChange={(e) => { setSelectedReplacementEmployeeData(e.value); setIsReplacement(true); }} paginator scrollHeight="80vh" rows={10} rowsPerPageOptions={[5, 10, 25]} dataKey="EMP_CODE" tableStyle={{ minWidth: "50rem" }}>
                                        <Column selectionMode="single" headerStyle={{ width: "3rem" }}></Column>
                                        {/* <Column field="MASK_EMP_ID" header="Emplpoyee"></Column> */}
                                        <Column field="EMP_CODE" header="Employee Code"></Column>
                                        <Column field="NAME" header="Employee Name"></Column>
                                    </DataTable>
                                </div>
                               
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="float-end">
                                <button type="button" className="btn btn-primary me-3" onClick={confirmEmployee}>OK</button>
                                <button type="button" className="btn btn-cancel-red" onClick={handleClose}>Close</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        </div>
    );
};

export default SearchCustomerModal;