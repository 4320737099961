import * as Ci from "react-icons/ci";
import * as Fa from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import React, { useRef } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useState, useEffect } from "react";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import Axios from "../../api/Axios";
// import SalesDetailsTable from "../SalesOrderDetails/SalesDetailsTable";
import { useGlobalContext1 } from "../../context/SalesDetailsContext";
import { useGlobalContext } from "../../context/SalesOrderContext";
import storage from "../../Services/Storage";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { useReallocateContext } from "../../context/ReallocateContext";
import ReallocateModal from "../ReallocateModal";
import { useManualSOUpdateContext } from "../../context/ManualSOUpdateContext";
import Print from "../InvoicePrint/NewPrintInvoice";
import { Link, useNavigate } from "react-router-dom";

let CartVolume = [];
const MOP_URl = "SalesOrder/GetMOP?";
const WareHouse_URL = "SalesOrder/GetWareHouseData?";
const PayTerm_URL = "SalesOrder/GetPayTerm?";
const GetCust_URL = "SalesOrder/GetCustomer?";
const SalesExicutive_URL = "SalesOrder/GetSalesExecutive?";
const ExchRate_URl = "SalesOrder/GetExchRate?";
const CustInfo_URL = "SalesOrder/GetCustomerInformation?";
// const CreditPosition_URL = "SalesOrder/GetCustFinancialInfo?";
// const CreditPosition_URL2 = "SalesOrder/GetCustCreditDtlData?";
const GetSegmentCODE_URL = "SalesOrder/GetSegmentCODE?";
const GetSegment_URL = "SalesOrder/GetSegment?";
const GetOEM_IE_URL = "SalesOrder/GetOEM_IE?";
const GetSEGwiseProdDataSOCreation_URL =
    "SalesOrder/GetSEGwiseProdDataSOCreation?";
const GetAllProdScheme_URL = "SalesOrder/GetAllProdScheme?";
const getDefaultWh_URL = "Default/WhDefaultVal?";
const DefaultVal_URL = "Default/DefaultVal?";
const CalculatePrice_URL = "SalesOrder/CalculatePrice?";
const ManualAddToCart_URl = "SalesOrder/ManualAddToCart";
const GetPayTermDueDate_URL = "SalesOrder/GetPayTermDueDate?";
const GetBranchByWH_URL = "SalesOrder/GetBranchByWH?";
const GetSerieByBranch_URL = "SalesOrder/GetSerieByBranch?";
const GetPrintMessage_URL = "SalesOrder/GetPrintMessage?";

const reallocation = "SalesOrder/ReAllocation";

// let prodRow;
let customerId;

function CreateSOOrder() {
    const nav = useNavigate();
    const auth = storage.getLocal("user");
    const {orientation}=useGlobalContext();
    const {
        // invoiceNo,
        salesOrderHdr,
        reasonDetails,
        filteredSalesDetails,
        setCustId,
        setPayload,
        // setFilteredSalesDetails,
        // invoiceCall,
        invoicePopup,
        setSwitchUpdate,
        getSalesOrderDetails,
        cartVal,
        setCartVal,
        // SONo,
        // setSONo,
        custInfo,
        setCustInfo,
        // exchRate,
        setExchRate,
        // priceCalculation,
        setPriceCalculation,
        setSalesOrderDtlpath,
        // payTermDueDate,
        setPayTermDueDate,
        defWh,
        setDefWh,
        // printMessage,
        setPrintMessage,
        // remarks,
        setRemarks,
        // serie,
        setSerie,
        orderSummary,
        setOrderSummary,
        loading,
        setLoading,
        handleDeletecart,
        customer,
        setCustomer,
        product,
        setProduct,
        addToCart,
        setAddToCart,
        blockInvalidChar,
        manualSOLoader,
        setManualSOLoader,
        currentDate,
        filterRow,
        filterRow1,
        tradeDisc,
        setTradeDisc,
        setCustPONo,
        volume,
        weight,
        setPayTerm,
        // payTerm,
        // MopId, 
        setMopId,
    } = useGlobalContext1();
    const {
        setGetWareHouse,
        getWareHouse,
        // autoAllocate,
        getDefaultVal,
    } = useManualSOUpdateContext();
    const { handleEdit, setPromoCode, setModalShow, setReallocateLoader, setReallocateDetails, orderQty,
        setOrderQty,
        releaseQty,
        setReleaseQty,
        setSoDetailsTable,
        defVal,
        setDefVal,
        setManualReallocateBtn,
        prodRow,
        setProdRow,
        suggestedProductList,
        setSuggestedProductList,
        setSelectedSalesDetails,
        setReallocateToggel, setSelectRow,netvol } = useReallocateContext();
    // const [creditDetails, setCreditDetails] = useState([]);
    // const [creditDetails2, setCreditDetails2] = useState([]);
    const [custDetails, setCustDetails] = useState([]);
    const [capitals, setCapitals] = useState([]);
    const [capital, setCapital] = useState(0);
    const [getMOP, setGetMOP] = useState([]);
    const [getSalesExecutive, setGetSalesExecutive] = useState([]);
    // const [allCustomers, setAllCustermes] = useState([]);
    const [productList, setProductList] = useState([]);
    const [filterProductList, setFilterProductList] = useState([]);
    // const [reallocloader, setReallocloader] = useState([]);
    const [prodDtl, setProdDtl] = useState([]);
    const [calculatePrice, setCalculatePrice] = useState(0);


    // product dropdown -----------------------------------

    // const [getCust, setGetCust] = useState([]);
    const [getPayTerm, setGetPayTerm] = useState([]);
    const [capitalSuggestions, setCapitalSuggestions] = useState(null);
    const [kycdisabled, setKycdisabled] = useState(false);
    const addtocart = () => {
        if(Number(calculatePrice) === 0){
            toast.error("Product Price is Not Defined", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
        else{
            ManualAddToCart();
            setSwitchUpdate(false);
            setManualReallocateBtn(false);
            setSuggestedProductList("");
            setProdRow("");
        }
    };
    const handleReset = () => {
        setCartVal("");
        // setCreditDetails("");
        // setCreditDetails2("");
        // setCustDetails("");
        // setCustInfo("");
        // setProductList("");
        setSuggestedProductList("");
        setOrderSummary("");
        setProdRow("");
        setOrderQty("");
        setReleaseQty("");
        setProduct(false);
        setAddToCart(false);
        setCalculatePrice(0);
        // setCapital();
        // setCartVal("");
    };
    const ManualSOData = async () => {
        setPayload(true);
        setLoading(true);
        try {
            setSalesOrderDtlpath(false);
            const mopurl = `${MOP_URl}CICode=${auth.CICode}`;
            const warehouseurl = `${WareHouse_URL}CICode=${auth.CICode}&UserId=${auth.UserId}&CompId=${auth.CompId}`;
            const paytermurl = `${PayTerm_URL}CICode=${auth.CICode}`;
            const custurl = `${GetCust_URL}CompId=${auth.CompId}`;
            const salesexeurl = `${SalesExicutive_URL}CompId=${auth.CompId}`;
            const exchrateurl = `${ExchRate_URl}CICode${auth.CICode}&CurrencyId=${salesOrderHdr.MASK_CURRENCY_ID}&DefinedBy=${auth.DefinedBy}&CurrencyCode="2"`;
            const defaultwhurl = `${getDefaultWh_URL}CompId=${auth.CompId}&defId=3&UserId=${auth.UserId}`;
            const printmessageurl = `${GetPrintMessage_URL}CICode=${auth.CICode}`;
            const printmessageres = await Axios.get(printmessageurl);
            setPrintMessage(printmessageres.data.Table[0].PRINT_DESCRIPTION);
            const mopresponse = await Axios.get(mopurl);
            const warehouseres = await Axios.get(warehouseurl);
            const paytermres = await Axios.get(paytermurl);
            const custdetailsres = await Axios.get(custurl);
            // setAllCustermes(custdetailsres.data);
            const salesexeres = await Axios.get(salesexeurl);
            const exchrateres = await Axios.get(exchrateurl);
            const defaultwhres = await Axios.get(defaultwhurl);
            console.log("defaultwhres",defaultwhres);
            const defaultVal_api = `${DefaultVal_URL}CompId=${
                auth.CompId
            }&defId=${1}`;
            const default_Res = await Axios.get(defaultVal_api);
            const branchbywh_url = `${GetBranchByWH_URL}CICode=${auth.CICode}&strWareHouseId=${defaultwhres.data.Id}`;
            const branchbywh_res = await Axios.get(branchbywh_url);
            console.log("branchbywh_res", branchbywh_res.data);
            const serieByBranch_url = `${GetSerieByBranch_URL}CICode=${auth.CICode}&strBranchId=${branchbywh_res.data}`;
            const serieByBranch_res = await Axios.get(serieByBranch_url);
            console.log("serieByBranch_res", serieByBranch_res.data);
            setSerie(serieByBranch_res.data);
            setDefVal(default_Res.data);
            setGetMOP(mopresponse.data);
            setGetWareHouse(warehouseres.data);
            setGetPayTerm(paytermres.data);
            setGetSalesExecutive(salesexeres.data);
            setCustDetails(custdetailsres.data);
            setDefWh(defaultwhres.data);
            setExchRate(exchrateres.data);
            //   const custinfourl = `${CustInfo_URL}CICode=${auth.CICode}&strCustId=${}`
            setCapitals(
                custdetailsres.data.map((val) => {
                    return val.NAME;
                })
            );
            setLoading(false);
        } catch (error) {
            console.log("ManualSOApi", error);
            setLoading(false);
        }
    };
    console.log("orderQty", typeof orderQty.toString());
    // !  const creditDtl = async () => {

    //  //   const credURL = `${CreditPosition_URL}CICode=${auth.CICode}&strCustId=${credit[0].MASK_CUST_ID}&strTransType=S`;
    //   //  const credURL2 = `${CreditPosition_URL2}CICode=${auth.CICode}&strCustId=${credit[0].MASK_CUST_ID}`;
    //  //   const credres = await Axios.get(credURL);
    //  //   const credres2 = await Axios.get(credURL2);
    //   //  setCreditDetails(credres.data);
    //   //  setCreditDetails2(credres2.data);
    //  // };
        

    const handleChange = async (e) => {
        setCapital(e.target.value);
        handleReset();
        console.log(
            "custinfores4",
            custDetails.filter((val) => val.NAME == e.target.value)
        );

        customerId = custDetails.filter((val) => val.NAME == e.target.value);
        console.log("customerId",customerId);
        
        if(customerId.length==0){
            setCustId(null);
            // setCreditDetails(null);
            // setCreditDetails2(null);
            setCustInfo(null);
            setPayTermDueDate(null);
            setProdDtl(null);
            setProductList(null);
            setCustomer(false);
        }
        else {
            setCustId(customerId);
            setLoading(true);
            try {
                const custinfourl = `${CustInfo_URL}CICode=${auth.CICode}&strCustId=${customerId[0].MASK_CUST_ID}`;
                // const credurl = `${CreditPosition_URL}CICode=${auth.CICode}&strCustId=${customerId[0].MASK_CUST_ID}&strTransType=S`;
                // const credurl2 = `${CreditPosition_URL2}CICode=${auth.CICode}&strCustId=${customerId[0].MASK_CUST_ID}`;
                const custinfores = await Axios.get(custinfourl);
                // const credres = await Axios.get(credurl);
                // const credres2 = await Axios.get(credurl2);
                // setCreditDetails(credres.data);
                // setCreditDetails2(credres2.data);
                setCustInfo(custinfores.data.Table);
                const paytermduedateurl = `${GetPayTermDueDate_URL}CICode=${auth.CICode}&strPayTermId=${custinfores.data.Table[0].MASK_PAY_TERM_ID}&strDteDate=${currentDate}`;
                const paytermduedateres = await Axios.get(paytermduedateurl);
                console.log(
                    "paytermduedateres",
                    paytermduedateres.data.Table[0].DUE_DATE
                );
                setPayTermDueDate(paytermduedateres.data.Table[0].DUE_DATE);
                console.log("custinfores", custinfores.data.Table);
                setLoading(false);
                // ___________________________________________________________________
                const GetSegmentCODE_api = `${GetSegmentCODE_URL}CICode=${auth.CICode}&strCustId=${customerId[0].MASK_CUST_ID}`;
                const segCodeRes = await Axios.get(GetSegmentCODE_api);
                console.log("seg000", segCodeRes.data);
                const GetSegment_api = `${GetSegment_URL}CICode=${auth.CICode}&strSegID=${segCodeRes.data}`;
                const getSegementRes = await Axios.get(GetSegment_api);
                console.log("segcount", getSegementRes.data);
                console.log("a00000",customerId);
                if(customerId[0].KYC_STATUS=="R"||customerId[0].KYC_STATUS=="BS"){
                    toast.error(`KYC Status of the selected customer is ${customerId[0].KYC_STATUS=="R"?"Rejected":customerId[0].KYC_STATUS=="BS"?"Billing Stopped":""}, kindly get the customer KYC Approved to continue.`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 4000,
                    });
                    setKycdisabled(true);
                    handleReset();
                }
                else{
                    if (Number(getSegementRes.data) > 0) {
                        const GetOEM_IE_api = `${GetOEM_IE_URL}CICode=${auth.CICode}&strSegID=${segCodeRes.data}`;
                        const oemRes = await Axios.get(GetOEM_IE_api);
                        console.log("oem", oemRes.data);
                        const GetSEGwiseProdDataSOCreation_api = `${GetSEGwiseProdDataSOCreation_URL}CompId=${auth.CompId}&stkwhid=${defWh.Id}`;
                        const getsegwiseRes = await Axios.get(GetSEGwiseProdDataSOCreation_api);
                        setProdDtl(getsegwiseRes.data);
                        console.log("productList111", getsegwiseRes.data);
                        console.log(
                            "productList1111",
                            getsegwiseRes.data.map((val) => {
                                return val.DESCRIPTION + "," + val.PROD_BATCH_NO;
                            })
                        );
                        setProductList(
                            getsegwiseRes.data.map((val) => {
                                return val.DESCRIPTION + " , " + val.PROD_BATCH_NO +"( ₹ "  + val.PRODUCT_MRP +")" + " , " + val.STOCK_AVAILABLE  ;
                            })
                        );
                    } else {
                        const defaultVal_api = `${DefaultVal_URL}CompId=${
                            auth.CompId
                        }&defId=${1}`;
    
                        let default_Res = await Axios.get(defaultVal_api);
                        console.log("def000", default_Res.data.Id);
                        console.log("whid", getWareHouse);
                        console.log("defwh", defWh.Id);
                        let whfilter = getWareHouse.filter((val) => {
                            return val.MASK_WH_ID == defWh.Id;
                        });
                        console.log("whfilter", whfilter);
                        console.log("compid ", auth.CompId);
                        const GetAllProdScheme_api = `${GetAllProdScheme_URL}CICode=${
                            auth.CICode
                        }&DivnId=${default_Res.data.Id}&WhId=${
                            whfilter[0].MASK_WH_ID
                        }&strStockTypeId=${1}&stkwhid=${defWh.Id}&CompId=${auth.CompId}`;
                        const AllProdSchemeRes = await Axios.get(GetAllProdScheme_api);
                        console.log("elseProduct ", AllProdSchemeRes.data);
                        setProdDtl(AllProdSchemeRes.data);
                        setProductList(
                            AllProdSchemeRes.data.map((val) => {
                                return val.DESCRIPTION + " , " + val.PROD_BATCH_NO +"( ₹ "  + val.PRODUCT_MRP +")" + " , " + val.STOCK_AVAILABLE ;
                            })
                        );
                    }
                    setCustomer(true);
                    setKycdisabled(false);
                }

                
            } catch (error) {
                console.log("custinfores1", error);
                setLoading(false);
            }
        }
    };
    const handleProd = async (e) => {
        console.log("pdt000",e.target.value);
        console.log("pdt000",prodDtl[0].DESCRIPTION);
        console.log("pdt000",prodDtl);
        setSuggestedProductList(e.target.value);
        console.log(
            "pdt000",
            prodDtl.filter((val) => {
                return val.DESCRIPTION + " , " + val.PROD_BATCH_NO +"( ₹ "  + val.PRODUCT_MRP +")" + " , " + val.STOCK_AVAILABLE  == e.target.value;
            })
        );
        let pdt = prodDtl.filter((val) => {
            return val.DESCRIPTION + " , " + val.PROD_BATCH_NO +"( ₹ "  + val.PRODUCT_MRP +")" + " , " + val.STOCK_AVAILABLE  == e.target.value;
        });
        if (pdt.length !== 0) {
            if (prodDtl) {
                const prodRow1 = prodDtl.filter(
                    (val) => val.DESCRIPTION + " , " + val.PROD_BATCH_NO +"( ₹ "  + val.PRODUCT_MRP +")" + " , " + val.STOCK_AVAILABLE == e.target.value
                );
                setProdRow(prodRow1);
                console.log("prodrow", prodRow1);

                const calculatepriceurl = `${CalculatePrice_URL}CICode=${auth.CICode}&strTrans=SO&strCurrDate=${currentDate}&strSuppCustId=${customerId[0].MASK_CUST_ID}&strCurrencyId=${custInfo[0].MASK_CURRENCY_ID}&strDivnId=${defVal.Id}&strProdId=${prodRow1[0].MASK_PROD_ID}&strDistId=${auth.CompId}&strPriceListId=${prodRow1[0].PROD_BATCH_NO}&strUOMType=2&strModTransRate=0&strSalesAreaId=${custInfo[0].MASK_SALES_AREA_ID}&strCustShipGeogId=${custInfo[0].CSHIP_ADD_GEOG_ID}`;
                try {
                    const calculatepriceres = await Axios.get(calculatepriceurl);
                    console.log("calculatepriceres", calculatepriceres.data);
                    setCalculatePrice(calculatepriceres.data.UnitPrice);
                    setProduct(true);
                } catch (error) {
                    console.log(error);
                    setProduct(true);
                    setCalculatePrice(0);
                }
            }
        }
        else {
            setProdRow("");
        }
    };
    const filterCapitals = function (e) {
        let results = capitals.filter((capital) => {
            return capital.toLowerCase().includes(e.query.toLowerCase());
        });
        console.log("custinfores3", results);
        setCapitalSuggestions(results);
    };
    const filterProduct = function (e) {
        let results = productList.filter((capital) => {
            return capital.toLowerCase().includes(e.query.toLowerCase());
        });
        console.log("custinfores3", results);
        setFilterProductList(results);
    };
    const ManualAddToCart = async () => {
        // setLoading(true);
        if (prodRow.length === 0) {
            toast.error("No Product Selected", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
        else if (Number(prodRow[0].STOCK_AVAILABLE) < Number(orderQty)) {
            toast.error("Stock is not available", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            setOrderQty("");
            setReleaseQty("");
        }
        else {
            setManualSOLoader(true);
            const obj = {
                CICode: auth.CICode,
                CustId: customerId[0].MASK_CUST_ID,
                UserId: auth.UserId,
                ComboProduct: prodRow[0].MASK_PROD_ID,
                AvailableQty: prodRow[0].STOCK_AVAILABLE.toString(),
                OrderQty: orderQty.toString(),
                ReleaseQty: releaseQty.toString(),
                ProdName: prodRow[0].DESCRIPTION,
                LotNo: prodRow[0].PROD_BATCH_NO,
                CompId: auth.CompId,
                TradeDiscPer: tradeDisc ? tradeDisc.toString() : "0",
                PrintFormatCode: auth.PrintFormatCode,
                UOM: "2",
                strDivnId: defVal.Id,
                CartValue: "Add To Cart",
                StockType: "1",
                OEMORDER: null,
            };
            if (orderQty.length == 0 || orderQty == 0) {
                toast.error("Enter Correct Ordered Quantity", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setManualSOLoader(false);
            }
            else if (Number(releaseQty) > Number(orderQty)) {
                toast.error("Release Quantity Should Be Less Than Order Quantity", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setManualSOLoader(false);
            }
            else if (cartVal?.length == 24) {
                toast.warn("Items should not exceeds 24 lines", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setManualSOLoader(false);
            }
            else {
                if (cartVal.length !== 0) {
                    if ((cartVal.filter((a) => a.PRODUCT_CODE + a.PROD_LOT_NO === prodRow[0].MASK_PROD_ID + prodRow[0].PROD_BATCH_NO)).length === 1) {
                        toast.error("Product Line already exists", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                        setManualSOLoader(false);
                        setOrderQty("");
                        setReleaseQty("");
                    } else {
                        try {
                            const res = await Axios.post(ManualAddToCart_URl, JSON.stringify(obj), {
                                headers: { "Content-Type": "application/json" },
                            });
                            console.log("ManualAddToCart", res.data[0]);
                            toast.success("Product successfully added to cart", {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 2000,
                            });
                            console.log("add to cart", res.data);
                            setCartVal((current) => [...current, res.data[0]]);
                            CartVolume.push(res.data[0]);
                            console.log("add to cartval", cartVal);
                            setPriceCalculation((current) => [...current, res.data[0]]);
                            netvol(CartVolume);
                            setAddToCart(true);
                            setManualSOLoader(false);
                            setOrderQty("");
                            setReleaseQty("");
                            setOrderSummary(true);
                        } catch (error) {
                            console.log("ManualAddToCart1", error.response.data);
                            toast.error(error.response.data.Status, {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 2000,
                            });
                            setManualSOLoader(false);
                        }
                    }
                }
                else {
                    try {
                        const res = await Axios.post(ManualAddToCart_URl, JSON.stringify(obj), {
                            headers: { "Content-Type": "application/json" },
                        });
                        console.log("ManualAddToCart", res.data[0]);
                        toast.success("Product successfully added to cart", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                        console.log("add to cart", res.data);
                        setCartVal((current) => [...current, res.data[0]]);
                        CartVolume.push(res.data[0]);
                        console.log("add to cartval", cartVal);
                        setPriceCalculation((current) => [...current, res.data[0]]);
                        netvol(CartVolume);
                        setAddToCart(true);
                        setManualSOLoader(false);
                        setOrderQty("");
                        setReleaseQty("");
                        setOrderSummary(true);
                    } catch (error) {
                        console.log("ManualAddToCart1", error.response.data);
                        toast.error(error.response.data.Status, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                        setManualSOLoader(false);
                    }
                }
            }
        }
    };
    const handleDeletecart1 = (row) => {
        handleDeletecart(row);
        const cart = cartVal.filter((productDtl) => {
            return (
                productDtl.PRODUCT_CODE + productDtl.PROD_LOT_NO !==
      row.PRODUCT_CODE + row.PROD_LOT_NO
            );
        });
        CartVolume = cart;
        console.log("CartVolumeafterdelete",CartVolume);
    };
    
    console.log("weight",weight);
    console.log("weight",volume);
   
    useEffect(() => {
        ManualSOData();
        getDefaultVal();
        setTradeDisc("");
        CartVolume=[];
        setSwitchUpdate(false);
    }, []);

    // console.log("aaaaaaaaa",calculatePrice.length);
    // console.log("SuggestedProductList",suggestedProductList.length);
    // console.log("ppppp",prodDtl);
    const handlemanualreallocate = () => {
        if(Number(calculatePrice) === 0){
            toast.error("Product Price is Not Defined", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
        else if (custInfo.length == 0) {
            toast.warn("Please Select Customer", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
        else if (suggestedProductList.length == 0) {
            toast.warn("Please select a Product", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
        else {
            manualReallocate();
            filterRow1();
        }
    };

    const manualReallocate = async () => {
        setReallocateToggel(true);
        setManualReallocateBtn(true);
        setReallocateLoader(true);
        // setReallocloader(true);
        let _soDetailsData = [{
            MASK_SODT_ID: null,
            DIVISION: null,
            MASK_PROD_ID: null,
            STOCK_TYPE: null,
            PRODUCT_CODE: null,
            PROD_SMALL_DESC: null,
            PROD_LOT_NO: null,
            PRICE_LIST_NO: null,
            CASE_TYPE: null,
            CDR_DISC: null,
            EUS_DISC_PERC: null,
            EUS_DISC: null,
            CASE_ORD_QTY: null,
            PACK_ORD_QTY: null,
            TOT_ORD_QTY: null,
            PROD_PRICE: null,
            BASE_PRICE: null,
            CASE_REL_QTY: null,
            PACK_REL_QTY: null,
            TOT_REL_QTY: null,
            TOT_INV_QTY: null,
            MASK_PROD_VAL: null,
            MASK_SODT_PROD_STK_TYPE: null,
            MASK_PACK_UOM_CODE: null,
            MASK_TOT_BACK_QTY: null,
            MASK_TOT_DROP_QTY: null,
            MASK_TRADE_DISC: null,
            MASK_VAL_BASE_CRNC: null,
            MASK_SPLCON_TAX: null,
            MASK_TOT_TAX: null,
            MASK_SODT_TOT_VAT: null,
            MASK_CASE_FACTOR: null,
            MASK_STATUS: null,
            UPDATE_COUNT: null,
            MASK_EXPORTED: null,
            MASK_FIRST_ENTERED_BY: null,
            MASK_FIRST_ENTERED_ON: null,
            MASK_LAST_MODIFIED_BY: null,
            MASK_LAST_MODIFIED_ON: null,
            MASK_IUKEY: null,
            MASK_SEQUENCE: null,
            EXTENDED_PRICE: null,
            MASK_SODT_EUS_DISC_PERC_TAG: null,
            MASK_SODT_TRANS_VAL: null,
            MASK_SODT_CDR_DISC_PERC: null,
            MASK_SODT_CDR_DISC_PERC_TAG: null,
            MASK_SODT_PRICE_OR_TAG: null,
            MASK_SODT_UPPER_BAND: null,
            MASK_SODT_LOWER_BAND: null,
            MASK_SODT_PRL_PRICE: null,
            MASK_SOHD_ID: null,
            MASK_DIVN_ID: null,
            MASK_EVAL_BY_LOT_TAG: null,
            MASK_EUS_DISC_APP_TO: null,
            MASK_PRODUCT_MRP: null,
            MASK_TOT_REL_QTY: null,
            MASK_OLD_STK_ONLY: null,
            RBO_REAS_CODE: null,
            STOCK_TYP: null,
            TOT_AVL_QTY: null,
            MASK_UPDATE_COUNT: null
        }];
        let obj = {
            AddToCart: "Manual",
            CICode: auth.CICode,
            CompId: auth.CompId,
            CustId: customerId[0].MASK_CUST_ID,
            LotNo: prodRow[0].PROD_BATCH_NO,
            OddLotNo: "",
            ComboProduct: prodRow[0].MASK_PROD_ID,
            ProdName: prodRow[0].DESCRIPTION,
            ReleaseQty: releaseQty.toString(),
            ComboOddLotProduct: "",
            OrderQty: orderQty.toString(),
            OddOrderQty: "",
            ReallocateProdId: prodRow[0].MASK_PROD_ID,
            ComboFreeProduct: "",
            OEMORDER: "TATA",
            StockType: "1",
            Tag: "N",
            UserId: auth.UserId,
            PrintFormatCode: auth.PrintFormatCode,
            UOM: "2",
            UOMCode: "EA",
            CurrencyId: custInfo[0].MASK_CURRENCY_ID,
            CurrencyCode: "2",
            strDivnId: defVal.Id,
            SODetailsData: _soDetailsData
        };
        try {
            const res = await Axios.post(reallocation, JSON.stringify(obj), {
                headers: { "Content-Type": "application/json" },
            });

            //
            if (
                res.data.SODetailsData.PromoCode ===
                "No other promo code is mapped with this base code" ||
                res.data.SODetailsData.PromoCode === null ||
                res.data.SODetailsData.PromoCode === "0"
            ) {
                setPromoCode(res.data.SODetailsData.PromoCode);
            } else {
                setPromoCode(res.data.SODetailsData.PromoCode.split(",").join(", "));
            }
            // setReallocloader(false);
            const obj1 = {
                PACK_ORD_QTY: orderQty.toString(),
                TOT_INV_QTY: "0",
                PRODUCT_CODE: prodRow[0].MASK_PROD_ID,
            };
            setSelectRow(obj1);
            setModalShow(true);
            setReallocateLoader(false);
            const reallocationFilter = res.data.SalesOrder.filter(
                (val) => Number(val.BALANCED_QTY) > 0
            );
            setSelectedSalesDetails(obj);
            console.log("SODetailsDataTable",res.data.SODetailsDataTable);
            setSoDetailsTable(res.data.SODetailsDataTable);
            for (let i = 0; i < res.data.SODetailsDataTable.length; i++) {
                setPriceCalculation((current) => [...current, res.data.SODetailsDataTable[0]]);
            }
            reallocationFilter.sort((a, b) => {
                return b.PACK_REL_QTY - a.PACK_REL_QTY;
            });
            setReallocateDetails(reallocationFilter);
            // setSuggestedProductList("");
            // prodRow = "";
            // setOrderQty("0");
            // setReleaseQty("0");
            // pdtCode = row.PRODUCT_CODE;
            // pdtLot = row.PROD_LOT_NO;
            //   setCartVal((current) =>
            //     current.filter((productDtl) => {
            //       return (
            //         productDtl.PRODUCT_CODE !== row.PRODUCT_CODE &&
            //         productDtl.PROD_LOT_NO !== row.PROD_LOT_NO
            //       );
            //     })
            //   );
        } catch (error) {
            setReallocateLoader(false);
            // setReallocloader(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            console.log("reallocation_res", error);
        }
    };

    const ref = useRef(null);
    const column = [
        { name: "Product Code", selector: (row) => row.PRODUCT_CODE },
        {
            name: "Product Name",
            selector: (row) => (
                <div
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title={row.PROD_SMALL_DESC}
                >
                    {" "}
                    {row.PROD_SMALL_DESC}
                </div>
            ),
        },
        { name: "Lot No", selector: (row) => row.PROD_LOT_NO },
        {
            name: "MRP(₹)",
            selector: (row) =>
                (Math.round(row.MASK_PRODUCT_MRP * 100) / 100).toLocaleString("en-US"),
        },
        {
            name: "Product Price(₹)",
            selector: (row) =>
                (Math.round(row.PROD_PRICE * 100) / 100).toLocaleString("en-US"),
        },
        {
            name: "Extented Price(₹)",
            selector: (row) =>
                (Math.round(row.EXTENDED_PRICE * 100) / 100).toLocaleString("en-US"),
        },
        { name: "Order QTY", selector: (row) => row.PACK_ORD_QTY },
        { name: "Invoiced QTY", selector: (row) => row.TOT_INV_QTY },
        { name: "Release QTY", selector: (row) => row.TOT_REL_QTY },
        {
            name: "Back QTY",
            selector: (row) => Math.round((row.MASK_TOT_BACK_QTY * 100) / 100),
        },
        {
            name: "Reason",
            cell: (row) => (
                <div>
                    <select
                        className="form-select"
                        value={row.MASK_TOT_BACK_QTY > 0
                            ? (row.RBO_REAS_CODE = "REAS/54")
                            : null}
                        // onChange={(e) => {
                        //   handleChange(e, row);
                        // }}
                        disabled={row.PACK_ORD_QTY === row.TOT_INV_QTY}
                    >
                        <option value="">Select Reason </option>
                        {reasonDetails.map((res) =>
                            res.CODE === "REAS/50" ||
                                res.CODE === "REAS/52" ||
                                res.CODE === "REAS/53" ||
                                res.CODE === "REAS/57" ? (
                                    <option
                                        value={res.CODE}
                                        key={res.CODE}
                                        disabled
                                        style={{ background: "#dddddd" }}
                                    >
                                        {res.NAME}{" "}
                                    </option>
                                ) : (
                                    <option value={res.CODE} key={res.CODE}>
                                        {res.NAME}{" "}
                                    </option>
                                )
                        )}
                    </select>
                </div>
            ),
        },
        {
            name: "Action",

            cell: (row) => (
                <div className="action-icon">
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Edit"
                    >
                        <button
                            className={
                                row.PACK_ORD_QTY === row.TOT_INV_QTY
                                    ? "grid-icon-secondary grid-icon"
                                    : "grid-icon"
                            }
                            onClick={() => {
                                handleEdit(ref, row);
                                filterRow(row);
                            }}
                            disabled={row.PACK_ORD_QTY === row.TOT_INV_QTY}
                        >
                            <Fa.FaPencilAlt />{" "}
                        </button>
                    </div>
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="delete"
                    >
                        <button
                            className="grid-icon"
                            onClick={() => handleDeletecart1(row)}
                        >
                            {" "}
                            <Ci.CiTrash />
                        </button>
                    </div>
                </div>
            ),
        },
    ];
    console.log("CartVal", cartVal);
    if (loading) {
        return <Loader />;
    } else {
        return (
            <main>
                {/* <div className="content-wrapper pt-1"> */}
                <div className="sales-header">
                    <div className="row">
                        <div className="col-lg-1 col-md-1 col-sm-12">
                            {orientation==="portrait-primary"&&<a href="#" className="sidebar-toggle" data-toggle="push-menu" role="button"><Fa.FaBars /></a>}
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="invoice-num">
                                {/* {invoiceNo !== 0 && (
                    <div className="invoice-num">
                      Invoice <span>{invoiceNo}</span> created successfully
                    </div>
                  )} */}
                            </div>
                        </div>
                        <div className="col-lg-7 col-md-7 col-sm-12">
                            <div className="float-end">
                                {/* {disableAutoAllocate &&
                    cartVal
                      .map((a) => Number(a.TOT_INV_QTY))
                      .reduce((a, b) => a + b) === 0 &&
                    cartVal
                      .map((a) => Number(a.TOT_REL_QTY))
                      .reduce((a, b) => a + b) === 0 && ( */}
                                {/* )} */}
                                <button type="submit" className="btn btn-outline-primary me-3"
                                    onClick={() => {
                                        nav("/salesmanagement/salesorder");
                                    }}
                                >
                                    <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                                </button>
                                <button type="submit" className="btn btn-primary"
                                    onClick={ () => {
                                        if(cartVal.some((val) => Number(val.PROD_PRICE) < 0)){
                                            toast.error("Price of product is zero or negative.", {
                                                position: toast.POSITION.TOP_CENTER,
                                                pauseOnFocusLoss: false,
                                                closeButton: false,
                                                autoClose: 2000,
                                            });
                                        }
                                        else if((custInfo.some((val)=>val?.MASK_TAX_AREA_ID=="NA"))){
                                            toast.error("Tax for the customer is not defined hence billing cannot be done.", {
                                                position: toast.POSITION.TOP_CENTER,
                                                pauseOnFocusLoss: false,
                                                closeButton: false,
                                                autoClose: 2000,
                                            });
                                        }
                                        else{
                                                
                                            setSwitchUpdate(false);
                                            getSalesOrderDetails();
                                        }                                            
                                    }}
                                    disabled={kycdisabled}
                                >
                                  Save Allocation
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="fix-panel">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/salesmanagement/salesorder">Sales Orders</Link>
                            </li>
                            <li className="breadcrumb-item active">Create Sales Order</li>
                        </ol>
                    </nav>
                    <div className="stepper mt-3">
                        <ul className="nav nav-tabs">
                            <li className={customer ? "completed manualSO" : "manualSO"}>
                                <a className="" href="#stepper-step-1">
                                    <span className="round-tab">1</span>
                                    <div className="s-text">
                                        <Ci.CiViewList className="s-icon" /> Customer Details
                                    </div>
                                </a>
                            </li>
                            <li className={product ? "completed manualSO" : "disable"}>
                                <a className="" href="#stepper-step-2">
                                    <span className="round-tab">2</span>

                                    <div className="s-text1">
                                        <Ci.CiSettings className="s-icon" /> Product Details
                                    </div>
                                </a>
                            </li>
                            <li className={addToCart ? "completed manualSO" : "disable"}>
                                <a className="" href="#stepper-step-2">
                                    <span className="round-tab">3</span>

                                    <div className="s-text3">
                                        <Ci.CiShoppingCart className="s-icon" /> Add to Cart/Reallocate
                                    </div>
                                </a>
                            </li>
                            <li className="manualSO">
                                <a className="" href="#stepper-step-3">
                                    <span className="round-tab">4</span>

                                    <div className="s-text2">
                                        <Ci.CiFloppyDisk className="s-icon" /> Save Allocation
                                    </div>
                                </a>
                            </li>
                            {/* <li className="manualSO">
                                    <a className="" href="#stepper-step-3">
                                        <span className="round-tab">5</span>

                                        <div className="s-text3">
                                            <Ci.CiMemoPad className="s-icon" /> Invoice
                                        </div>
                                    </a>
                                </li> */}
                        </ul>
                    </div>
                </div>
                <div className="manual-so-content">
                    <Accordion defaultActiveKey="0" className="mb-4">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header x> Customer Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Customer Name <span className="mandatory">*</span></label>
                                            <div className="d-block">
                                                <AutoComplete
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    value={capital}
                                                    suggestions={capitalSuggestions}
                                                    completeMethod={filterCapitals}
                                                    onChange={(e) => handleChange(e)}
                                                    dropdown={true}
                                                    showEmptyMessage={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Sales Order Date</label>
                                            <field className="form-control">{currentDate}</field>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Sales Order Number</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                // placeholder="Sales Order No"
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Warehouse</label>
                                            <select
                                                className="form-control"
                                                aria-label="Default select example"
                                                disabled
                                            >
                                                <option selected>
                                                    {defWh.value}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Payment Term</label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                onChange={(e) => setPayTerm(e.target.value)}
                                            >
                                                {custInfo?.length === 1 ? (
                                                    <>
                                                        <option
                                                            value={custInfo[0].MASK_PAY_TERM_ID}
                                                            selected
                                                        >
                                                            {custInfo[0].MASK_PAY_TERM_DESC}
                                                        </option>
                                                        {getPayTerm
                                                            .filter(
                                                                (res) =>
                                                                    res.MASK_PAY_TERM_ID !==
                                                                        custInfo[0].MASK_PAY_TERM_ID
                                                            )
                                                            .map((res) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            res.MASK_PAY_TERM_ID
                                                                        }
                                                                        key={
                                                                            res.MASK_PAY_TERM_ID
                                                                        }
                                                                    >
                                                                        {
                                                                            res.DESCRIPTION
                                                                        }
                                                                    </option>
                                                                );
                                                            })}
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value="1">Select One</option>
                                                        {getPayTerm.map((res) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        res.MASK_PAY_TERM_ID
                                                                    }
                                                                    key={
                                                                        res.MASK_PAY_TERM_ID
                                                                    }
                                                                >
                                                                    {
                                                                        res.DESCRIPTION
                                                                    }
                                                                </option>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Mode Of Payment</label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                                onChange={(e) => setMopId(e.target.value)}
                                            >
                                                {custInfo?.length === 1 ? (
                                                    <>
                                                        <option value={custInfo[0].MASK_MOP_ID} selected>
                                                            {custInfo[0].MASK_MOP_DESC}
                                                        </option>
                                                        {getMOP
                                                            .filter(
                                                                (res) =>
                                                                    res.MASK_MOP_ID !== custInfo[0].MASK_MOP_ID
                                                            )
                                                            .map((res) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            res.MASK_MOP_ID
                                                                        }
                                                                        key={
                                                                            res.MASK_MOP_ID
                                                                        }
                                                                    >
                                                                        {
                                                                            res.DESCRIPTION
                                                                        }
                                                                    </option>
                                                                );
                                                            })}
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value="1">Select One</option>
                                                        {getMOP.map((res) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        res.MASK_MOP_ID
                                                                    }
                                                                    key={
                                                                        res.MASK_MOP_ID
                                                                    }
                                                                >
                                                                    {
                                                                        res.DESCRIPTION
                                                                    }
                                                                </option>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Sales Executive</label>
                                            <select
                                                className="form-select"
                                                aria-label="Default select example"
                                            >
                                                {custInfo?.length === 1 ? (
                                                    <>
                                                        <option value={custInfo[0].EMP_ID} selected>
                                                            {custInfo[0].MASK_EMP_NAME}
                                                        </option>
                                                        {getSalesExecutive
                                                            .filter(
                                                                (res) =>
                                                                    res.NAME !== custInfo[0].MASK_EMP_NAME
                                                            )
                                                            .map((res) => {
                                                                return (
                                                                    <option
                                                                        value={
                                                                            res.MASK_EMP_ID
                                                                        }
                                                                        key={
                                                                            res.MASK_EMP_ID
                                                                        }
                                                                    >
                                                                        {
                                                                            res.NAME
                                                                        }
                                                                    </option>
                                                                );
                                                            })}
                                                    </>
                                                ) : (
                                                    <>
                                                        <option value="1">Select One</option>
                                                        {getSalesExecutive.map((res) => {
                                                            return (
                                                                <option
                                                                    value={
                                                                        res.MASK_EMP_ID
                                                                    }
                                                                    key={
                                                                        res.MASK_EMP_ID
                                                                    }
                                                                >
                                                                    {res.NAME}
                                                                </option>
                                                            );
                                                        })}
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Customer PO Number</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Customer PO Number"
                                                onChange={(e) => setCustPONo(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Trade Discount</label>
                                            <input
                                                className="form-control"
                                                value={tradeDisc}
                                                type="number"
                                                placeholder="Trade Discount"
                                                onChange={(e) => {
                                                    const regex = /^\d{0,2}(\.\d{0,2})?$/;
                                                    if (regex.test(e.target.value) || e.target.value === "") {
                                                        setTradeDisc(e.target.value);
                                                    }
                                                }}

                                                // onChange={(e)=>setTradeDisc(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Remarks</label>
                                            <textarea
                                                className="form-control"
                                                rows="3"
                                                placeholder="This is where you will leave remarks about the customers order on InnoRise..."
                                                onChange={(e) => setRemarks(e.target.value)}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="card px-3 py-3 mb-3">
                                    <h4>Shipping Address</h4>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label">Address Line 1</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        custInfo?.length === 1
                                                            ? custInfo[0].CSHIP_ADD1
                                                            : "Address Line 1"
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label">Address Line 2</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        custInfo?.length === 1
                                                            ? custInfo[0].CSHIP_ADD2
                                                            : "Address Line 2"
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-4 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label">Town</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        custInfo?.length === 1
                                                            ? custInfo[0].BILL_TOWN
                                                            : "Town"
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="card px-3 py-3 mb-3">
                                        <h4>Credit Position</h4>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Credit Limit Set</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={
                                                            creditDetails2?.length === 1
                                                                ? creditDetails2[0].CUST_CREDIT_LIMIT
                                                                : "Credit Limit Set"
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Closing balance</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={
                                                            creditDetails?.length === 1
                                                                ? creditDetails[0].ClosingBalance
                                                                : "Closing balance"
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Cheques in Hand</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={
                                                            creditDetails?.length === 1
                                                                ? creditDetails[0].ChkInHand
                                                                : "Cheques in Hand"
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Net Balance</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        value={
                                                            creditDetails?.length === 1
                                                                ? creditDetails[0].NetBalance
                                                                : "Net Balance"
                                                        }
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                {/* <div className="row">
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label">Max Order Value</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        creditDetails2?.length === 1
                                                            ? creditDetails2[0].CUST_MAX_ORDER_VAL
                                                            : "Max Order Value"
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label">Min Order Value</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        creditDetails2?.length === 1
                                                            ? creditDetails2[0].CUST_MIN_ORDER_VAL
                                                            : "Min Order Value"
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label">Credit Limit Used</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    placeholder="Credit Limit Used"
                                                    value={
                                                        creditDetails2?.length === 1 &&
                            creditDetails?.length === 1
                                                            ? Math.round(
                                                                (creditDetails2[0].CUST_CREDIT_LIMIT -
                                    creditDetails[0].AvailCrLimit) *
                                    100
                                                            ) / 100
                                                            : "Credit Limit Used"
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-sm-12">
                                            <div className="mb-3">
                                                <label className="form-label">
                          Available Credit Limit
                                                </label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={
                                                        creditDetails?.length === 1
                                                            ? creditDetails[0].AvailCrLimit
                                                            : "Available Credit Limit"
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div> */}
                            </Accordion.Body>
                        </Accordion.Item>
                        {<Accordion.Item eventKey="1" className="mt-2">
                            <Accordion.Header x> Product Details</Accordion.Header>
                            <Accordion.Body>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Product <span className="mandatory">*</span></label>
                                            <div className="d-block">
                                                <AutoComplete
                                                    virtualScrollerOptions={{ itemSize: 38 }}
                                                    // multiple={true}
                                                    value={suggestedProductList}
                                                    suggestions={filterProductList}
                                                    completeMethod={filterProduct}
                                                    onChange={(e) => handleProd(e)}
                                                    dropdown={true}
                                                    forceSelection
                                                    showEmptyMessage={true}
                                                    disabled={kycdisabled}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Ordered Quantity</label>
                                            <input
                                                className="form-control"
                                                value={orderQty}
                                                type="number"
                                                onKeyDown={blockInvalidChar}
                                                placeholder="Ordered Quantity"
                                                onChange={(e) => setOrderQty(e.target.value)}
                                                disabled={kycdisabled}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 col-sm-12">
                                        <div className="mb-3">
                                            <label className="form-label">Release Quantity</label>
                                            <input
                                                className="form-control"
                                                value={releaseQty}
                                                type="number"
                                                onKeyDown={blockInvalidChar}
                                                placeholder="Release Quantity"
                                                onChange={(e) => setReleaseQty(e.target.value)}
                                                disabled={kycdisabled}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-12">
                                        {prodRow && (
                                            <div className="lot-panel">
                                                <div className="d-inline info-text col">
                                                    <p>
                                                        <Fa.FaRegFileAlt /> Lot Number{" "}
                                                        <span>{prodRow[0]?.PROD_BATCH_NO}</span>{" "}
                                                    </p>
                                                </div>
                                                <div className="d-inline info-text col">
                                                    <p>
                                                        <Fa.FaRegBookmark /> Unit Price :{" "}
                                                        <span>
                                                            {Number(calculatePrice).toLocaleString("en-IN", { style: "currency", currency: "INR" })}
                                                        </span>{" "}
                                                    </p>
                                                </div>
                                                <div className="d-inline info-text col">
                                                    <p>
                                                        <Fa.FaRegCheckSquare /> Available Quantity :{" "}
                                                        <span>{prodRow[0]?.STOCK_AVAILABLE}</span>{" "}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-lg-4 col-md-4 col-sm-12">
                                        <div className="float-end">
                                            <button
                                                type="submit"
                                                className="btn btn-outline-primary me-3"
                                                onClick={handlemanualreallocate}
                                                disabled={kycdisabled}
                                            >
                                                    Reallocate
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-primary me-3"
                                                onClick={addtocart}
                                                disabled={kycdisabled}
                                            >
                                                    Add to Cart
                                            </button>
                                            <button
                                                type="submit"
                                                className="btn btn-cancel-red"
                                                onClick={handleReset}
                                                disabled={kycdisabled}
                                            >
                                                    Reset
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        {orderSummary && (
                                            <div className="row mt-3 order-summery">
                                                <div className="col-lg-4 col-md-12 col-sm-12">
                                                    <h3> Sales Order Summary</h3>
                                                    {/* {prodRow && (
                                                            <p className="float-end">
                                                                {prodRow[0].DESCRIPTION}
                                                            </p>
                                                        )} */}
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className=" order-detail-container">
                                                        <div className="order-box1">
                                                                Net Volume (Ltr):{" "}
                                                        </div>
                                                        <div className="order-box text-end">
                                                            <span>{volume}</span>
                                                        </div>
                                                    </div>
                                                    <div className=" order-detail-container">
                                                        <div className="order-box1">Net Weight (kg):</div>
                                                        <div className="order-box text-end">
                                                            <span>{weight}</span>
                                                        </div>
                                                    </div>
                                                    <hr className="borderMobileView"></hr>
                                                    <div className=" order-detail-container">
                                                        <div className="order-box1">Gross Value:</div>
                                                        <div className="order-box text-end">
                                                            <span>
                                                                    ₹{" "}
                                                                {cartVal.length > 0 ? ((
                                                                    (
                                                                        cartVal
                                                                            .map((a) => Number(a.BASE_PRICE))
                                                                            .reduce((a, b) => a + b)
                                                                    ).toFixed(2)
                                                                )) : "0.00"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                                <div className="col-lg-4 col-md-6 col-sm-12">
                                                    <div className="order-detail-container ps-4">
                                                        <div className="order-box">
                                                                Amount Before Tax:{" "}
                                                        </div>
                                                        <div className="order-box text-end">
                                                            <span>
                                                                    ₹{" "}
                                                                {cartVal.length > 0 ? ((
                                                                    (
                                                                        (cartVal
                                                                            .map((a) => Number(a.BASE_PRICE))
                                                                            .reduce((a, b) => a + b) -
                                                                                cartVal
                                                                                    .map((a) => Number(a.CDR_DISC))
                                                                                    .reduce((a, b) => a + b) -
                                                                                cartVal
                                                                                    .map((a) => Number(a.EUS_DISC))
                                                                                    .reduce((a, b) => a + b) -
                                                                                cartVal
                                                                                    .map((a) => Number(a.MASK_TRADE_DISC))
                                                                                    .reduce((a, b) => a + b)) 
                                                                    ).toFixed(2) 
                                                                )) : "0.00"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="order-detail-container ps-4 border-bottom">
                                                        <div className="order-box">GST Amount:</div>
                                                        <div className="order-box text-end">
                                                            <span>
                                                                    ₹{" "}
                                                                {cartVal.length > 0 ? (((
                                                                    cartVal
                                                                        .map((a) => Number(a.MASK_SODT_TOT_VAT))
                                                                        .reduce((a, b) => a + b) 
                                                                ).toFixed(2)
                                                                )) : "0.00"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="total order-detail-container ps-4">
                                                        <div className="order-box ">Total Amount: </div>
                                                        <div className="order-box text-end">
                                                            <span className="">
                                                                    ₹{" "}
                                                                {cartVal.length > 0 ? ((
                                                                    (
                                                                        (cartVal
                                                                            .map((a) => Number(a.BASE_PRICE))
                                                                            .reduce((a, b) => a + b) -
                                                                                cartVal
                                                                                    .map((a) => Number(a.CDR_DISC))
                                                                                    .reduce((a, b) => a + b) -
                                                                                cartVal
                                                                                    .map((a) => Number(a.EUS_DISC))
                                                                                    .reduce((a, b) => a + b) -
                                                                                cartVal
                                                                                    .map((a) => Number(a.MASK_TRADE_DISC))
                                                                                    .reduce((a, b) => a + b) +
                                                                                (cartVal
                                                                                    .map((a) =>
                                                                                        Number(a.MASK_SODT_TRANS_VAL)
                                                                                    )
                                                                                    .reduce((a, b) => a + b) +
                                                                                    cartVal
                                                                                        .map((a) => Number(a.MASK_TOT_TAX))
                                                                                        .reduce((a, b) => a + b) +
                                                                                    cartVal
                                                                                        .map((a) =>
                                                                                            Number(a.MASK_SODT_TOT_VAT)
                                                                                        )
                                                                                        .reduce((a, b) => a + b))).toFixed(2)
                                                                    )
                                                                )) : "0.00"}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>}
                    </Accordion>
                    <div className="grid grid-shadow sales-order-details">
                        {manualSOLoader ? <Loader /> :
                            <DataTable
                                columns={column}
                                data={cartVal}
                                pagination
                                fixedHeader
                                fixedHeaderScrollHeight="400px"
                                selectableRowsHighlight
                                highlightOnHover
                                // conditionalRowStyles={conditionalRowStyles}
                                subHeaderAlign="right"
                            />}
                    </div>
                    {/* {reallocloader?<Loader/>:<ReallocateModal data={filteredSalesDetails} column={column} />} */}
                    <ReallocateModal data={filteredSalesDetails} column={column} />
                    {invoicePopup ? <Print /> : null}
                </div>
                {/* </div> */}
            </main>
        );
    }
}

export default CreateSOOrder;
