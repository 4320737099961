import React from "react";
import { useNavigate } from "react-router-dom";
import { usePurchaseReturnContext } from "../../context/PurchaseReturnContext";
import { useEffect } from "react";
import { format } from "date-fns";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import PurchaseReturnPrint from "./PurchaseReturnPrint";
import Loader from "../../Components/Loader";


const AllPurchaseReturnRecords = () => {

    const {
        getPurchaseReturn,
        setToDate,
        toDate,
        setFromDate,
        fromDate,
        formattedSevenDaysAgo,
        currentDate,
        findDatePurchaseReturn,
        purchaseReturnTable,
        printPurchaseReturn,
        setSelectedData,
        setViewPurchaseReturn,
        isLoading, 
    }=usePurchaseReturnContext();

    useEffect(() => {
        // calling function for Api
        // setModalPopup(false);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        setSelectedData([]);
        getPurchaseReturn(fromDateRange, toDateRange);

        setViewPurchaseReturn(false);

    }, []);

    const renderAction = (rowdata) => {
         
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View Product Details">
                        <button className="grid-icon" onClick={() => { nav(`/purchasemanagement/purchasereturn/details?id=${encodeURIComponent(rowdata?.MASK_GRN_ID)}&date=${encodeURIComponent(rowdata?.GRN_DATE)}`); }}>
                            <Fa.FaEye />
                        </button>
                    </div>
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="Print">
                        <button className="grid-icon" onClick={() => {
                            printPurchaseReturn(rowdata?.MASK_GRN_ID);
                        }}>
                            <Fa.FaPrint />
                        </button>
                    </div>
                </div>
            </>
        );

    };

    const nav = useNavigate();
    return (
        <div className="col-12 pt-3 pb-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Purchase Return</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <button type="button" className="btn btn-primary" onClick={() => { nav("/purchasemanagement/purchasereturn/details"); }}>
                            Add New
                        </button>
                    </div>
                </div>
            </div>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">From Date</label>
                                <input 
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => setFromDate(e.target.value)}
                                    defaultValue={formattedSevenDaysAgo}
                                    max={toDate}
                                    min="2010-01-01"
                                    name="date-field"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">To Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => setToDate(e.target.value)}
                                    defaultValue={currentDate}
                                    // max={currentDate}
                                    min={fromDate}
                                    name="date-field"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="float-end mt-4">
                                <button type="button" className="btn btn-primary" onClick={(e) => findDatePurchaseReturn(e, fromDate, toDate)} >Get Debit Notes</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <section className="">
                <h3 className="mb-3">All Records</h3>
                <div className="DataTable pb-4">
                    {isLoading? <Loader/>: <DataTable
                        value={purchaseReturnTable}
                        // dataKey='MASK_PROD_ID'
                        paginator
                        showGridlines
                        stripedRows
                        rows={10}
                        filterDisplay="row"
                        rowsPerPageOptions={[10, 25, 50]}
                        scrollHeight="80vh"
                        tableStyle={{ minWidth: "60rem" }}
                        globalFilterFields={["PROD_CODE", "DESCRIPTION"]} emptyMessage="No records found."
                                
                    >
                        <Column field={"GRN_NO"} header={"Debit Note Number"} filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "12rem" }} />
                        <Column field={"GRN_DATE"} header={"Debit Note Date"} style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                        <Column field={"SUPP_NAME"} header={"Supplier Name"} style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                        <Column field={"DIVN_NAME"} header={"Division"} style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                        <Column field={renderAction} header="Action" />
                    </DataTable>}
                </div>
            </section>
            <PurchaseReturnPrint />
        </div>
    );
};

export default AllPurchaseReturnRecords;