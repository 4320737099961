import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import React, { useEffect, useState } from "react";
import {
    createAPIEndpoint,
    Endpoint,
} from "../../Services/PaymentAccDefination/PaymentAcDefservice";
import storage from "../../Services/Storage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";

function PaymentAccountDefinition() {
    const [bankdata, setBankdata] = useState([]);
    const [cashdata, setCashdata] = useState([]);
    const [editkeybank, setEditkeybank] = useState(false);
    const [editkeycash, setEditkeycash] = useState(false);
    const [disabledbtn, setDisabledbtn] = useState(false);
    const [tableloaderbank, setTableloaderbank] = useState(false);
    const [tableloadercash, setTableloadercash] = useState(false);
    const [selectdatabank, setSelectdatabank] = useState("");
    const [selectdatacash, setSelectdatacash] = useState("");
    const auth = storage.getLocal("user");

    //#region API
    const fetchBank = async () => {
        setTableloaderbank(true);
        createAPIEndpoint()
            .get(`${Endpoint.PopulateBankData}?CompId=${auth.CompId}`)
            .then((res) => {
                setBankdata(res.data.Data);
                setTableloaderbank(false);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                console.log(error);
                setTableloaderbank(false);
            });
    };

    const fetchCash = async () => {
        setTableloadercash(true);
        createAPIEndpoint()
            .get(`${Endpoint.PopulateCashData}?CompId=${auth.CompId}`)
            .then((res) => {
                setCashdata(res.data);
                setTableloadercash(false);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                console.log(error);
                setTableloadercash(false);
            });
    };
    //#endregion

    useEffect(() => {
        fetchBank();
        fetchCash();
    }, []);

    //#region Bank
    const initialValuesBank = {
        code: "",
        bank_name: "",
        branch: "",
        account: "",
    };

    const formik_bank = useFormik({
        initialValues: initialValuesBank,
        onSubmit: (data) => {
            formik_bank.resetForm();
            submitBankData(data);
        },

        validate: (data) => {
            let errors = {};
            let regex = /^[0-9]+$/;
            if (data.code.trim().length < 1) {
                errors.code = "Code is required.";
            } else if (data.code.length > 20) {
                errors.code = "Code length less than 20.";
            }

            if (data.bank_name.trim().length < 1) {
                errors.bank_name = "Bank Name is required.";
            } else if (data.bank_name.length > 20) {
                errors.bank_name = "Bank Name length less than 20.";
            }
            if (data.branch.trim().length < 1) {
                errors.branch = "Branch is required.";
            } else if (data.branch.length > 20) {
                errors.branch = "Branch Name length less than 20.";
            }

            if (data.account.trim().length < 1) {
                errors.account = "Account is required.";
            } else if (data.account.length > 20) {
                errors.account = "Account length less than 20.";
            } else if (!regex.test(data.account)) {
                errors.account = "Invalid Account Number";
            }

            return errors;
        },
    });

    const isFormFieldInvalid_bank = (name) =>
        !!(formik_bank.touched[name] && formik_bank.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid_bank(name) ? (
            <small className="p-error">{formik_bank.errors[name]}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };
    //#endregion

    //#region Cash
    const initialValuesCash = {
        code_cash: "",
        account_name: "",
    };

    const formik_cash = useFormik({
        initialValues: initialValuesCash,
        onSubmit: (data) => {
            formik_cash.resetForm();
            submitCashData(data);
        },
        validate: (data) => {
            let errors = {};
            if (!data.account_name) {
                errors.account_name = "Account Name is required.";
            } else if (data.account_name.length >= 20) {
                errors.account_name = "Account Name length should be less than 20.";
            }
            return errors;
        },
    });

    const isFormFieldInvalid_cash = (name) =>
        !!(formik_cash.touched[name] && formik_cash.errors[name]);

    const getFormErrorMessage_cash = (name) => {
        return isFormFieldInvalid_cash(name) ? (
            <small className="p-error">{formik_cash.errors[name]}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };
    //#endregion

    //#region submit bankdata

    const submitBankData = (data) => {
        let payload = {
            CICode: auth.CICode.toString(),
            CompId: auth.CompId.toString(),
            UserId: auth.UserId.toString(),
            PvcACCOUNT_TYPE: "BANK",
            BankId: selectdatabank.MASK_ACCT_ID ?? "",
            Code: data.code.toString(),
            Bank_Name: data.bank_name.toString(),
            Branch: data.branch.toString(),
            Account: data.account.toString(),
            IUKEYB: editkeybank ? "2" : "1",
            CashId: "",
            AccCode: "",
            AccName: "",
            IUKEYC: "",
        };

        createAPIEndpoint()
            .post(`${Endpoint.UploadData}`, JSON.stringify(payload), {
                headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
                toast.success(response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setDisabledbtn(false);
                setSelectdatabank("");
                setEditkeybank(false);

                fetchBank();
            })
            .catch((error) => {
                if (error.response.data.Message == "VAL10124") {
                    toast.warning("This record has already exist", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                }
                fetchBank();
            });
    };
    //#endregion

    // if(editkeybank){
    //     formik_bank.values.code = rowdata.BANK_CODE;
    // }else{
    //     formik_bank.values.code = "";
    // }

    const bankEditData = (rowdata) => {
        setSelectdatabank(rowdata);
        setDisabledbtn(true);
        setEditkeybank(true);

        // debugger;
        // formik_bank.values.code = rowdata.BANK_CODE;
        // formik_bank.values.bank_name = rowdata.BANK_NAME;
        // formik_bank.values.account = rowdata.BANK_ACCT_NO;
        // formik_bank.values.branch = rowdata.MASK_BANK_BRANCH_NAME;

        // formik_bank.values = {
        //     code : formik_bank.values.code = setFieldValue("code", rowdata.BANK_CODE),
        //     bank_name : setFieldValue("account", rowdata.BANK_ACCT_NO),
        //     account :setFieldValue("account", rowdata.BANK_ACCT_NO),
        //     branch : setFieldValue("branch", rowdata.MASK_BANK_BRANCH_NAME)
        // }

        formik_bank.setFieldValue("code", rowdata.BANK_CODE);
        formik_bank.setFieldValue("account", rowdata.BANK_ACCT_NO);
        formik_bank.setFieldValue("branch", rowdata.MASK_BANK_BRANCH_NAME);
        formik_bank.setFieldValue("bank_name", rowdata.BANK_NAME);
    };

    const allowEdit_Bank = (rowdata) => {
        return (
            <div className="action-icon">
                <a href="#BankMaster">
                    <button
                        className="grid-icon-img"
                        title="View/Edit Bank Master Details"
                        onClick={() => bankEditData(rowdata)}
                    >
                        <img
                            alt="InnoRise"
                            src={require("../../Assets/Images/ViewEdit.png")}
                        />
                    </button>
                </a>
            </div>
        );
    };

    //#region Cash

    let submitCashData = (data) => {
        let payload = {
            CICode: auth.CICode.toString(),
            CompId: auth.CompId.toString(),
            UserId: auth.UserId.toString(),
            PvcACCOUNT_TYPE: "CASH",
            BankId: "",
            Code: "",
            Bank_Name: "",
            Branch: "",
            Account: "",
            IUKEYB: "",
            CashId: selectdatacash.MASK_ACCT_ID ?? "",
            AccCode: data.code_cash,
            AccName: data.account_name,
            IUKEYC: editkeycash ? "2" : "1",
        };
        createAPIEndpoint()
            .post(`${Endpoint.UploadData}`, JSON.stringify(payload), {
                headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
                toast.success(response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setSelectdatacash("");
                setEditkeycash(false);

                fetchCash();
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                console.log(error);
                fetchCash();
            });
    };

    //#endregion

    let cashEditData = (rowdata) => {
        setSelectdatacash(rowdata);
        setEditkeycash(true);
        formik_cash.setFieldValue("code_cash", rowdata.BANK_CODE);
        formik_cash.setFieldValue("account_name", rowdata.BANK_NAME);
    };

    const allowEdit_Cash = (rowdata) => {
        return (
            <div className="action-icon">
                <a href="#CashMaster">
                    <button
                        className="grid-icon-img"
                        title="View/Edit Cash Master Details"
                        onClick={() => cashEditData(rowdata)}
                    >
                        <img
                            alt="InnoRise"
                            src={require("../../Assets/Images/ViewEdit.png")}
                        />
                    </button>
                </a>
            </div>
        );
    };

    return (
        <>
            <main>
                <div className="col-12 pt-3">
                    {/* <div className="float-end mt-2">
                        <button type="submit" title="Add" className="btn btn-primary">
              Save
            </button>
                    </div> */}
                    <h3 className="py-2">Payment A/C Definition</h3>
                    <div className="row me-1 mb-4 CustomerTabs">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <Tabs
                                defaultActiveKey="bankmaster"
                                id="fill-tab-example"
                                className="mt-2"
                            >
                                <Tab eventKey="bankmaster" title="Bank Master">
                                    <div className="Tab-box" id="BankMaster">
                                        <div className="card p-3 shadow-sm mt-2">
                                            <form onSubmit={formik_bank.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <label className="form-label"> Code </label>
                                                        <InputText
                                                            name="code"
                                                            id="code"
                                                            type="text"
                                                            placeholder="Enter Here..."
                                                            value={formik_bank.values.code}
                                                            disabled={disabledbtn}
                                                            onChange={(e) => {
                                                                formik_bank.setFieldValue(
                                                                    "code",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            className={classNames(
                                                                {
                                                                    "p-invalid": isFormFieldInvalid_bank("code"),
                                                                },
                                                                "form-control"
                                                            )}
                                                        />
                                                        {getFormErrorMessage("code")}
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <label className="form-label"> Bank Name </label>
                                                        <InputText
                                                            name="bank_name"
                                                            id="bank_name"
                                                            type="text"
                                                            placeholder="Enter Here..."
                                                            value={formik_bank.values.bank_name}
                                                            onChange={(e) => {
                                                                formik_bank.setFieldValue(
                                                                    "bank_name",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            className={classNames(
                                                                {
                                                                    "p-invalid":
                                    isFormFieldInvalid_bank("bank_name"),
                                                                },
                                                                "form-control"
                                                            )}
                                                        />
                                                        {getFormErrorMessage("bank_name")}
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <label className="form-label"> Branch </label>
                                                        <InputText
                                                            name="branch"
                                                            id="branch"
                                                            type="text"
                                                            placeholder="Enter Here..."
                                                            value={formik_bank.values.branch}
                                                            disabled={disabledbtn}
                                                            onChange={(e) => {
                                                                formik_bank.setFieldValue(
                                                                    "branch",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            className={classNames(
                                                                {
                                                                    "p-invalid":
                                    isFormFieldInvalid_bank("branch"),
                                                                },
                                                                "form-control"
                                                            )}
                                                        />
                                                        {getFormErrorMessage("branch")}
                                                    </div>
                                                    <div className="col-lg-3 col-md-6 col-sm-12">
                                                        <label className="form-label"> Account Number </label>
                                                        <InputText
                                                            name="account"
                                                            id="account"
                                                            type="text"
                                                            placeholder="Enter Here..."
                                                            value={formik_bank.values.account}
                                                            disabled={disabledbtn}
                                                            onChange={(e) => {
                                                                formik_bank.setFieldValue(
                                                                    "account",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            className={classNames(
                                                                {
                                                                    "p-invalid":
                                    isFormFieldInvalid_bank("account"),
                                                                },
                                                                "form-control"
                                                            )}
                                                        />
                                                        {getFormErrorMessage("account")}
                                                    </div>
                                                </div>
                                                <div className="float-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                            Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-md-12 col-lg-12 col-sm-12">
                                            <div className="DataTable pt-4">
                                                {tableloaderbank ? (
                                                    <Loader />
                                                ) : (
                                                    <DataTable
                                                        value={bankdata}
                                                        tableStyle={{ minWidth: "40rem" }}
                                                        showGridlines
                                                        stripedRows
                                                    >
                                                        <Column
                                                            field="BANK_CODE"
                                                            header="Bank Code"
                                                            sortable
                                                        ></Column>
                                                        <Column
                                                            field="BANK_NAME"
                                                            header="Bank Name"
                                                            sortable
                                                        ></Column>
                                                        <Column
                                                            field="BANK_ACCT_NO"
                                                            header="Bank Account Number"
                                                        ></Column>
                                                        <Column
                                                            body={allowEdit_Bank}
                                                            header="Action"
                                                        ></Column>
                                                    </DataTable>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Tab>

                                <Tab eventKey="cashmaster" title="Cash Master">
                                    <div className="Tab-box" id="CashMaster">
                                        <div className="card p-3 shadow-sm mt-2">
                                            <form onSubmit={formik_cash.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                        <label className="form-label">Code</label>
                                                        <InputText
                                                            type="text"
                                                            name="code_cash"
                                                            id="code_cash"
                                                            placeholder="Enter Here..."
                                                            disabled
                                                            value={formik_cash.values.code_cash}
                                                            // onChange={(e) => {
                                                            //   formik_cash.setFieldValue(
                                                            //     "code_cash",
                                                            //     e.target.value
                                                            //   );
                                                            // }}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                                        <label className="form-label"> Account Name </label>
                                                        <InputText
                                                            type="text"
                                                            name="account_name"
                                                            id="account_name"
                                                            placeholder="Enter Here..."
                                                            value={formik_cash.values.account_name}
                                                            onChange={(e) => {
                                                                formik_cash.setFieldValue(
                                                                    "account_name",
                                                                    e.target.value
                                                                );
                                                            }}
                                                            className={classNames(
                                                                {
                                                                    "p-invalid":
                                    isFormFieldInvalid_cash("account_name"),
                                                                },
                                                                "form-control"
                                                            )}
                                                        />
                                                        {getFormErrorMessage_cash("account_name")}
                                                    </div>
                                                </div>
                                                <div className="float-end">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary"
                                                    >
                            Save
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                        <div className="col-md-12 col-lg-12 col-sm-12">
                                            <div className="DataTable pt-4">
                                                {tableloadercash ? (
                                                    <Loader />
                                                ) : (
                                                    <DataTable
                                                        value={cashdata}
                                                        tableStyle={{ minWidth: "10rem" }}
                                                        showGridlines
                                                        stripedRows
                                                    >
                                                        <Column
                                                            field="BANK_CODE"
                                                            header="Cash Code"
                                                        ></Column>
                                                        <Column
                                                            field="BANK_NAME"
                                                            header="Account Name"
                                                        ></Column>
                                                        <Column
                                                            body={allowEdit_Cash}
                                                            header="Action"
                                                        ></Column>
                                                    </DataTable>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default PaymentAccountDefinition;
