import Axios from "../../api/Axios";
const BASE_URL = "User/";
export const Endpoint = {
    // GetCompState: "GetCompState",
    GetEmployeeList: "GetEmployeeList",
    GetAllDistributor: "GetAllDistributor",
    AllUserManagementData: "AllUserManagementData",
    GetAllUserGrpData: "GetAllUserGrpData",
    UserDataSave: "UserDataSave",
    GetViewOnlyUserMappedData: "GetViewOnlyUserMappedData",
    GetAllUserGrpDataById: "GetAllUserGrpDataById",
    ViewUserManagementData: "ViewUserManagementData",
};

export const createAPIEndpointUser = () => {
    return { get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint,data)=> Axios.post(BASE_URL+Endpoint,data)
    };
};

