import React, { useContext,createContext } from "react";
import { useState } from "react";



const FreeInvoiceContext = createContext();
const FreeInvoiceProvider = ({ children }) => {

    const [FOCpopup,setFOCPopup] =useState(false);
    const [FOCprintData,setFOCPrintData] =useState([]);
    const [FOCpageLoading, setFOCPageLoading] = useState(false);

    return (
        <FreeInvoiceContext.Provider
            value={{
                FOCpopup,
                setFOCPopup,
                FOCprintData,
                setFOCPrintData,
                FOCpageLoading,
                setFOCPageLoading,
            }}
        >
            {children}
        </FreeInvoiceContext.Provider>
    );
};
export const useFreeInvoiceContext = () => {
    return useContext(FreeInvoiceContext);
};

export { FreeInvoiceProvider, FreeInvoiceContext };