import Axios from "../../api/Axios";
const BASE_URL = "Default/";
export const EndpointDefault = {
    GetLiteThemeStatus: "GetLiteThemeStatus",
    SaveLiteTheme: "SaveLiteTheme",
};


export const createAPIDefaultEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};