import axios from "axios";
// import storage from "../Services/Storage";
// import { useNavigate } from "react-router-dom";
// const BASE_URL = "https://innorisepreprdapi.azurewebsites.net/api";
// const BASE_URL = "https://innorisedevapi.azurewebsites.net/api";
const BASE_URL = "https://csdapiuat.castroldms.com/api";
// const BASE_URL = "https://csdapi.castroldms.com/api";
// const BASE_URL = "https://localhost:44308/api/";

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
});

// const nav = useNavigate();
const getTokenFromLocalStorage = () => {
    return localStorage.getItem("Token");
};
const addTokenToLocalStorage = (token) => {
    localStorage.setItem("Token", token);
};
// const Logout_URL = "https://csdapiuat.castroldms.com/api/login/logout";

// const logout_payload = {UserId : localStorage.getItem("UserId")};



// const logout = async () => {
//     console.log("call logout");
//     try {
//         await axios.post(
//             Logout_URL,
//             logout_payload
//         );
//         localStorage.clear();
//         navigation.navigate("/");
//     } catch (error) {
//         console.log(error);
//     }
// };

// setTimeout(logout, 10000);

// console.log("A000",localStorage.getItem("UserId"))
// console.log("A000",logout_payload);


const payload = () => {
    const UserId = localStorage.getItem("UserId");
    const token = localStorage.getItem("Token");
    const RefreshToken = localStorage.getItem("RefreshToken");
    let obj = {
        userId: UserId,
        token: token,
        refreshToken: RefreshToken,
    };
    return obj;
};
// const auth = storage.getLocal("user");
const Axios = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
});
// debugger;
//Add a request interceptor
Axios.interceptors.request.use(
    (config) => {
        const Token = getTokenFromLocalStorage();
        //localStorage.getItem('Token');
        if (Token) {
            config.headers.Authorization = `Bearer ${Token}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);
// Add a response interceptor
Axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        // console.log("A000",error);
        // const navigation =useNavigate();

        const originalRequest = error.config;
        if (error.response===undefined && error.code==="ERR_NETWORK") {
        //eslint-disable-next-line
        navigation.navigate("/");
            localStorage.clear();
        } else {
            // If the error status is 401 and there is no originalRequest._retry flag,
            // it means the token has expired and we need to refresh it
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                try {
                    // const UserId = localStorage.getItem("UserId");
                    // const token = localStorage.getItem("Token");
                    // const RefreshToken = localStorage.getItem("RefreshToken");
                    // let obj = {
                    //     userId : UserId,
                    //     token : token,
                    //     refreshToken : RefreshToken
                    // };
                    let obj = payload();
                    const response = await Axios.post(
                        "login/refreshToken",
                        JSON.stringify(obj)
                    );
                    //eslint-disable-next-line
          const { Token } = response?.data?.Data;

                    // Retry the original request with the new token
                    originalRequest.headers.Authorization = `Bearer ${Token}`;
                    addTokenToLocalStorage(Token);
                    return Axios(originalRequest);
                } catch (error) {
                    // Handle refresh token error or redirect to login
                    //
                    //eslint-disable-next-line
          navigation.navigate("/");
                }
            }

            return Promise.reject(error);
        }
    }
);
export default Axios;
