import Axios from "../../api/Axios";
const BASE_URL = "SupplierMaster/";
export const EndpointSupplier = {
    SupplierMasterView: "SupplierMasterView",
    SupplierMasterSave: "SupplierMasterSave",
    GetAllSupplierDetails: "GetAllSupplierDetails",
    // PopulateTownData: "PopulateTownData",
    // SaveWareHouseMaster: "SaveWareHouseMaster",
};
export const createAPISupplierMastEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};