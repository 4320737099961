import Axios from "../../api/Axios";
const BASE_URL = "CustomerMast/";
export const Endpoint = {
    GetAllCustomer: "GetAllCustomer",

    // GetPaymentTerm: "GetPaymentTerm",
    GetTaxEnvSettings: "GetTaxEnvSettings",
    GetTaxForm: "GetTaxForm",
    GetFrequency: "GetFrequency",
    GetDayOfWeek: "GetDayOfWeek",
    GetVanRoute: "GetVanRoute",
    GetCompState: "GetCompState",
    
    GetPaymentTerm: "GetPaymentTerm",
    GetTaxArea: "GetTaxArea",
    GetModeOfPayment: "GetModeOfPayment",
    GetDistEmployee: "GetDistEmployee",
    // GetTaxEnvSettings: "GetTaxEnvSettings",
};
export const createAPICustomerMastEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};


// const GetPaymentTerm_URL = "CustomerMast/GetPaymentTerm?";
// const GetModeOfPayment_URL = "CustomerMast/GetModeOfPayment?";
// const GetTaxEnvSettings_URL = "CustomerMast/GetTaxEnvSettings?";
// // const GetTaxArea_URL = "CustomerMast/GetTaxArea?";
// // const GetTaxForm_URL = "CustomerMast/GetTaxForm?";
// const GetDistEmployee_URL = "CustomerMast/GetDistEmployee?";
// const GetFrequency_URL = "CustomerMast/GetFrequency?";
// const GetDayOfWeek_URL = "CustomerMast/GetDayOfWeek?";
// const GetVanRoute_URL = "CustomerMast/GetVanRoute?";
// const GetCompState_URL = "CustomerMast/GetCompState?";