import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import storage from "../../Services/Storage";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import * as XLSX from "xlsx";
import { createAPIPDAReceipt, Endpoint} from "../../Services/PDAReceipt/PDAReceiptService";

const PDAReceiptCreation = () => {

    const [viewPDAReceipt, setPDAReceipt] = useState([]);
    const [isHidden, setIsHidden] = useState(false);
    const [isDisableBtn, setDisableBtn] = useState(false);
    const [errorExcelData, setErrorExcelData] = useState([]);
    const [viewPDAByRef,setPDAByRef] = useState([]);
    const [viewPopUp,setViewPopUp] = useState(false);
    const [selectedPDAReceipt, setSelectedPDAReceipt] = useState([]);
    const auth = storage.getLocal("user");
  
  
   
    const getAllPDAreceipt = async () => {
        createAPIPDAReceipt()
            .get(`${Endpoint.GetALLPDAReceipt}?CompId=${auth.CompId}`)
            .then((response) => {
                setPDAReceipt(response.data.Data);
            })
            .catch(() => {
            });
    };

  
    let pdaObj = {
        userId: auth.UserId,
        compId: auth.CompId,
        array_REF: selectedPDAReceipt.map((obj) => {
            return obj.REF_NO;
        }),

    };


    const savePDAReceipt = async () => {
        createAPIPDAReceipt().post(
            `${Endpoint.SavePDAReceipt}`,
            JSON.stringify(pdaObj),
            {
                headers: { "Content-Type": "application/json" },
            }
        ).then( async (res) => {
            // console.log(res.data,'check')
            setErrorExcelData(res.data.Data);
            getAllPDAreceipt();
            if(res.data.Status == "Success"){
                toast.success(
                    `${res.data.Message}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }

                );

            } else if (res.data.Status == "Error" && res.data.Data.length > 0)
            {
                setIsHidden(true);
                toast.warning(
                    `${res.data.Message}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }

                );
            }
            else {
                toast.warning(
                    `${res.data.Message}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }

                );
            }

        });
    };


    const getPDAReceiptByRef = async(id) => {
        createAPIPDAReceipt().get(
            `${Endpoint.GetPDAReceiptByRef}?RefId=${id}`
        ).then((response)=> {
            setPDAByRef(response.data.Data);
            setViewPopUp(true);
            // console.log(response.data.Data,'see this')
        }).catch(() => {
        //code
        });
    };

    const errorDownloadExcel = ()=>{
        let uniqueNo = "export_PDA_errorfile";
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(errorExcelData);
        XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
        XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
    };

    useEffect(() => {
        getAllPDAreceipt();
    }, []);


    const renderAction = (item) => {
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View Details">
                        <button className="grid-icon"  >
                            <Fa.FaEye onClick= {() => getPDAReceiptByRef(item.REF_NO)}/>
                        </button>
                    </div>
                </div>
            </>
        );

    };


    return (
        <div className="col-12 pt-3 pb-4">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">PDA Receipt Creation</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        { isHidden ? <button type="" className="btn btn-cancel-red me-3"  onClick={errorDownloadExcel}>
                               Error Download
                        </button> : null }
                        <Link to="/creditmanagement/pdareceipts">
                            <button type="" className="btn btn-outline-primary me-3">
                                <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                            </button>
                        </Link>
                        
                        {  <button type=""  disabled = {!isDisableBtn} className="btn btn-primary" onClick={savePDAReceipt}>
                            Save
                        </button  > }
                    </div>
                </div>
            </div>
            <section className="">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="DataTable pb-4 pt-1">
                        <DataTable
                            value={viewPDAReceipt}
                            paginator
                            rows={10}
                            filterDisplay="row"
                            scrollable
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            showGridlines
                            selectionMode="checkbox"
                            selection={selectedPDAReceipt}
                            onSelectionChange={(e) => {
                                setSelectedPDAReceipt(e.value);
                                setDisableBtn(e.value.length > 0 ? true : false);                
                            }}
                            stripedRows
                            emptyMessage="No Data Found..."
                            tableStyle={{ minWidth: "120rem" }}>
                            <Column selectionMode="multiple" header="Select" />
                            <Column field="REF_NO" header="Reference Number" />
                            <Column field="REF_DATE" header="Reference Date" />
                            <Column field="PARTY_CODE" header="Customer Code" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                            <Column field="PARTY_NAME" header="Customer Name" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                            <Column field="EMP_CODE" header="DSR Code" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                            <Column field="EMP_NAME" header="DSR Name" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                            <Column field="RCPT_AMT" header="Amount" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                            <Column field="COLLECTION_TYPE" header="Mode" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                            <Column field="INV_COUNT" header="Invoice Count" />
                            <Column field={renderAction} header="Action" />
                        </DataTable>
                    </div>
                </div>
            </section>
            <Dialog header="Invoice Details" style={{ width: "80vw" }} closable={false} resizable={false} draggable={false} visible = {viewPopUp}>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="DataTable mb-3">
                        <DataTable
                            value = {viewPDAByRef}
                            rows={10}
                            showGridlines
                            stripedRows
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            emptyMessage="No Data Found..."
                            tableStyle={{ minWidth: "60rem" }}
                        >
                            <Column field="PARTY_CODE" header="Customer Code"></Column>
                            <Column field="PARTY_NAME" header="Customer Name"></Column>
                            <Column field="INV_NO" header="Invoice Number"></Column>
                            <Column field="COLLECTION_TYPE" header="Mode"></Column>
                            <Column field="CHEQUE_NO" header="Cheque Number"></Column>
                            <Column field="CHEQUE_DATE" header="Cheque Date"></Column>
                            <Column field="BANK_NAME" header="Bank Name"></Column>
                            <Column field="RCPT_AMT" header="Amount"></Column>
                        </DataTable>
                    </div>
                </div>
                <div className="float-end">
                    <button type="button" className="btn btn-primary me-3"  onClick={ () => setViewPopUp(false)}>OK</button>
                    <button type="button" className="btn btn-cancel-red" onClick={ () => setViewPopUp(false)}>Cancel</button>
                </div>
            </Dialog>
        </div>
    );
};

export default PDAReceiptCreation;