import React, { useEffect } from "react";
// import storage from "../../Services/Storage";
import { useNavigate } from "react-router-dom";
import { useSalesReturnContext } from "../../context/SalesReturnContext";
// import storage from "../../Services/Storage";
import * as Fa from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format } from "date-fns";
import SalesReturnPrint from "./SalesReturnPrint";
import Loader from "../../Components/Loader";

const Index = () => {
    // const auth = storage.getLocal("user");
    // const [selectedSalesRet, setSelectedSalesRet] = useState([]);
    const {
        // creditNotesColumn,
        getCreditNotes,
        // getDefaultCreData,
        creditNotesTable,
        // setCreditNotesTable,
        // allCreditNoteData,
        // setAllCreditNoteData,
        fromDate,
        toDate,
        formattedSevenDaysAgo,
        currentDate,
        setFromDate,
        setToDate,
        findDateSalesReturn,
        setViewSalesReturn,
        printInvoiceCall,
        invoiceDataLoad,
        setModalPopup
    } = useSalesReturnContext();

     
     

    const nav = useNavigate();

    const AddnewCreditNote = () => {
        nav(`/salesmanagement/salesreturn/details?mask_pro_id=${"null"}`);
    };

    // const downloadInvoice = () => {
    //     selectedSalesRet.forEach((item) => printInvoiceCall(item.MASK_INVH_ID));
    //     // const multiDownloadSalesReturn = selectedSalesRet.map(item => {
    //     //     printInvoiceCall(item.MASK_INVH_ID);
    //     // });
    //     //  
         
    // };

    useEffect(() => {
        // calling function for Api
        setModalPopup(false);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        getCreditNotes(fromDateRange, toDateRange);

        setViewSalesReturn(false);

    }, []);


    const renderAction = (rowdata) => {
         
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View Product Details">
                        <button className="grid-icon" onClick={() => { nav(`/salesmanagement/salesreturn/details?mask_salret_id=${encodeURIComponent(rowdata.MASK_INVH_ID)}&INVH=${encodeURIComponent(rowdata.INVH_DATE)}`); }}>
                            <Fa.FaEye />
                        </button>
                    </div>
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="Print">
                        <button className="grid-icon" onClick={() => {
                            printInvoiceCall(rowdata.MASK_INVH_ID);
                        }}>
                            <Fa.FaPrint />
                        </button>
                    </div>
                </div>
            </>
        );

    };


    return (
        <div className="col-12 pt-3 pb-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Sales Return</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <button type="submit" className="btn btn-primary" onClick={AddnewCreditNote}>
                           Add New
                        </button>
                    </div>
                </div>
            </div>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => setFromDate(e.target.value)}
                                    defaultValue={formattedSevenDaysAgo}
                                    max={toDate}
                                    min="2010-01-01"
                                    name="date-field"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="mb-3">
                                <label className="form-label">To Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    onChange={(e) => setToDate(e.target.value)}
                                    defaultValue={currentDate}
                                    // max={currentDate}
                                    min={fromDate}
                                    name="date-field"
                                />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="float-end mt-4">
                                {/* <button
                                    type="button"
                                    title="Download"
                                    className="btn btn-outline-primary me-3"
                                    onClick={downloadInvoice}
                                    disabled={selectedSalesRet.length == 0 ? true : false}
                                >
                                    Download
                                </button> */}
                                <button
                                    type="button"
                                    className="btn btn-primary "
                                    onClick={(e) => findDateSalesReturn(e, fromDate, toDate)}
                                >
                                    Get Credit Notes
                                </button>

                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <section className="mt-3">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3 className="mb-3">All Records</h3>
                        <div className="DataTable pb-4">
                            {invoiceDataLoad ? <Loader /> : <DataTable
                                value={creditNotesTable}
                                // dataKey='MASK_PROD_ID'
                                // selectionMode='single'
                                // selection={selectedProductCode}
                                // onSelectionChange={(e) => { productLotselect ? setSelectedLot(e.value) : setSelectedProductCode(e.value); }}
                                paginator
                                showGridlines
                                stripedRows
                                rows={10}
                                filterDisplay="row"
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                tableStyle={{ minWidth: "60rem" }}
                                scrollable
                                globalFilterFields={["PROD_CODE", "DESCRIPTION"]} emptyMessage="No records found."
                                // tableStyle={{ minWidth: "20rem" }}
                                // selectionMode='checkbox'
                                // selection={selectedSalesRet}
                                // onSelectionChange={(e) => { setSelectedSalesRet(e.value); }}
                            >
                                {/* <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column> */}
                                <Column field={"INVH_NO"} header={"Credit Note Number"} filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "12rem" }} />
                                <Column field={"INVH_DATE"} header={"Credit Note Date"} style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                                <Column field={"CUST_NAME"} header={"Customer Name"} style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                                <Column field={renderAction} header="Action" />
                            </DataTable>}
                        </div>
                    </div>
                </div>
            </section>
            <SalesReturnPrint />
        </div>
    );

};

export default Index;
