import React from "react";
import ReactDOM from "react-dom/client";
import "./Assets/Styles/global.scss";
import "./Assets/Styles/responsive.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./context/AuthProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import { AppProvider } from "./context/SalesOrderContext";
//import { SalesDetailsProvider } from "./context/SalesDetailsContext";
//import CountryTables from "./Components/DemoTable";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <AuthProvider>
            {/* <AppProvider> */}
            {/* <SalesDetailsProvider> */}
            <Routes>
                <Route path="/*" element={<App />} />
            </Routes>
            {/* <CountryTables/> */}
            {/* </SalesDetailsProvider> */}
            {/* </AppProvider> */}
        </AuthProvider>
    </BrowserRouter>
    // </React.StrictMode>
);
if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
        navigator.serviceWorker.register("/service-worker.js").then(
            (registration) => {
                console.log("Service Worker registered with scope:", registration.scope);
            },
            (error) => {
                console.log("Service Worker registration failed:", error);
            }
        );
    });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
