import React, { useState } from "react";
import ShowAllProdTable from "./ShowAllProdTable";
import * as Fa from "react-icons/fa";
import XLSX from "xlsx";
import storage from "../../Services/Storage";
import { toast } from "react-toastify";
import {
    createAPIEndpointproduct,
    Endpoint,
} from "../../Services/productmaster/ProductMastService";
// import Loader from "../../Components/Loader";

const Index = () => {
    // const [excelData, setExcelData] = useState([]);
    // const [productMasterLoader, setProductMasterLoader] = useState(false);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const auth = storage.getLocal("user");

    const handleExportExcel = () => {
        setLoadingExcel(true);
        let date = new Date();
        let day = date.getDate();
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let exactmonths = months[date.getMonth()];
        let year = date.getFullYear();
        let uniqueNo = `${day}${exactmonths}${year}`;

        createAPIEndpointproduct()
            .get(`${Endpoint.GetExcelAllProductData}?CICode=${auth.CICode}`)
            .then((res) => {
                var wb = XLSX.utils.book_new();
                var ws = XLSX.utils.json_to_sheet(res.data.Data);
                XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                XLSX.writeFile(wb, `Product_Data_${uniqueNo}.xlsx`);
                setLoadingExcel(false);
            })
            .catch(() => {
                setLoadingExcel(false);
                toast.error("No Data is Available to Download", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };

    // const fetchAllExcelDeatils = async () => {
    //   createAPIEndpointproduct()
    //     .get(`${Endpoint.GetExcelAllProductData}?CICode=${auth.CICode}`)
    //     .then((res) => {
    //       setExcelData(res.data.Data);
    //        
    //       // setProductMasterLoader(true);
    //     })
    //     .catch((err) => {
    //        
    //     });
    // };

    // useEffect(() => {
    //   fetchAllExcelDeatils();
    // }, []);

    // if (!productMasterLoader) {
    //     return <Loader />;
    //   } else {
    return (
        <main>
            <div className="col-12 pt-3 pb-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Product Master</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <button type="submit" className="btn btn-primary" onClick={handleExportExcel} disabled={loadingExcel} >
                                {loadingExcel ? "Loading..." : " Save As Excel"} <Fa.FaDownload className="ms-2"/>
                            </button>
                        </div>
                    </div>
                </div>
                <section className="">
                    <ShowAllProdTable />
                </section>
            </div>
        </main>
    );
};

export default Index;
