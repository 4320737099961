import Modal from "react-bootstrap/Modal";
import React from "react";
import { useSalesReturnContext } from "../../context/SalesReturnContext";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import storage from "../../Services/Storage";
import { EndpointSalesReturn, createAPIEndpointSalesReturn } from "../../Services/SalesReturnAdd/SalesReturnServices";

const GetInvoiceModal = () => {
    const auth= storage.getLocal("user");
    const {
        // invoiceDataColumn,
        // invoicesugg,
        invoicePopup1,
        setInvoicePopup1,
        // fetchProductCode,
        handleProductCode,
        // handleReasonData,
        // procodeheader,
        setProcodeheader,
        invoiceData,
        selectedData, 
        setSelectedData,
        setCustomernamedisable,
        setInvSearchData
    } = useSalesReturnContext();

    const handleClose = () => {
        setInvoicePopup1(false);
    };


    const handleConfirm = () => {
        setCustomernamedisable(true);
        setInvoicePopup1(false);
         
        setProcodeheader(selectedData.MASK_INVH_ID);
        // handleReasonData();
        handleProductCode(selectedData.MASK_INVH_ID);
        
    };


    // const selectedproduct = ()=>{
    // //  
    // };

    const handleRowSelect = (e) => {
         
        setSelectedData(e.value);
        let invSearchData={
            ciCode: auth.CICode,
            masK_INVH_ID: e.value.MASK_INVH_ID,
            invH_NO: e.value.INVH_NO,
            invH_DATE: e.value.INVH_DATE,
            masK_EVAL_BY_LOT_TAG: e.value.MASK_EVAL_BY_LOT_TAG,
            masK_INVH_TYPE: e.value.MASK_INVH_TYPE
        };
        createAPIEndpointSalesReturn()
            .post(`${EndpointSalesReturn.GetInvoiceSearchData}`,
                JSON.stringify(invSearchData),
                {
                    headers: { "Content-Type": "application/json" },
                })
            .then((res)=>{
                setInvSearchData(res.data.InvDtl[0]);
            });
    };

    

    return (
        <div>
            <>
                <div className="col-12 pt-3 pb-3">
                    <Modal size="lg" className="SearchMatchingDocumentsModal" show={invoicePopup1} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Search Matching Documents</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="DataTable py-4">
                                    <DataTable
                                        value={invoiceData}
                                        selection={selectedData}
                                        onSelectionChange={(e)=>{handleRowSelect(e);}}
                                        dataKey="MASK_INVH_ID"
                                        tableStyle={{ minWidth: "50rem" }}
                                        filterDisplay="row"
                                        paginator
                                        rows={10}
                                        selectionMode="radiobutton"
                                        rowsPerPageOptions={[10, 25, 50]}
                                        scrollHeight="80vh"
                                        stripedRows
                                        showGridlines
                                    >
                                        <Column
                                            selectionMode="single"
                                            headerStyle={{ width: "3rem" }}
                                        />
                                        <Column
                                            field="INVH_DATE"
                                            header="Document Date"
                                            filterMatchMode="contains" 
                                            filter 
                                            filterPlaceholder="Search Here..."
                                        />
                                        <Column
                                            field="INVH_NO"
                                            header="Document Number"
                                            filterMatchMode="contains" 
                                            filter 
                                            filterPlaceholder="Search Here..."
                                            
                                        />
                                    </DataTable>
                                </div>
                                
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="float-end">
                                <button type="button" className="btn btn-primary me-3" onClick={handleConfirm}>OK</button>
                                <button type="button" className="btn btn-cancel-red" onClick={handleClose}>Close</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        </div>
    );
};

export default GetInvoiceModal;
