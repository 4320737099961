import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React from "react";
import { Link } from "react-router-dom";
import * as Fa from "react-icons/fa";

const UserGroupsDetails = () => {
    return (
        <div className="col-12 pt-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mx-1">
                            <li className="breadcrumb-item">
                                <Link to="/usergroups">User Groups</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Details</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end">
                        <Link to="/usergroups">
                            <button type="submit" className="btn btn-primary">
                                <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="">
                <form>
                    <section className="sales-box">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Code <span className="mandatory">*</span></label>
                                <InputText class="form-control" type="text" disabled placeholder="WESTERN LUBE COMPANY THANE" />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Name <span className="mandatory">*</span></label>
                                <InputText class="form-control" type="text" placeholder="" />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Remarks <span className="mandatory">*</span></label>
                                <InputText class="form-control" type="text" disabled placeholder="This is a autocomplete field with drodpwn" />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="mt-0">
                                    <label className="form-label d-block">Status <span className="mandatory">*</span></label>
                                    <div className="d-inline-flex pe-3">
                                        <RadioButton checked />
                                        <label className="ms-1">
                                            Active
                                        </label>
                                    </div>
                                    <div className="d-inline-flex">
                                        <RadioButton />
                                        <label className="ms-1">
                                            Inactive
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-12">
                                <div className="float-end mt-3">
                                    <button type="submit" className="btn btn-cancel-red me-3">Cancel</button>
                                    <button type="submit" className="btn btn-primary">Save</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </form>
            </div>
        </div>
    );
};

export default UserGroupsDetails;