import React, { useEffect, useState } from "react";
import { format, subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useReceiptsContext } from "../../context/ReceiptsContext";
import Loader from "../../Components/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import PrintReceipt from "./PrintReceipt";
import PrintReceiptCash from "./PrintReceiptCash";
import { Dropdown } from "primereact/dropdown";

const AllReceiptsRecords = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const nav = useNavigate();
    const handleMovetoNew = () => {
        nav("/creditmanagement/receipts/details");
    };
    const { findDateReceipts, tableloader, allGridReceiptData, GetAllTabledata,printReceipt,printPopup,printLoader,printPopupCash, } =
    useReceiptsContext();

    useEffect(() => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        GetAllTabledata(fromDateRange, toDateRange);
    }, []);

    // const modeStatus = (rowdata) => {
    //     if(rowdata.MODE==="CASH"){
    //         return ("Cash");
    //     }
    //     else if(rowdata.MODE==="CHQ"){
    //         rowdata.MODE="Cheque";
    //         return("Cheque");
    //     }
    //     else{
    //         return("None");
    //     }
    // };
    const [statuses] = useState(["Cash","Cheque"]);
    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select One" className="p-column-filter" showClear style={{ maxWidth: "12rem" }} />
        );
    };

    //     const renderAction = (rowdata) => {
    //          
    //         return (
    //             <>
    //                 <div className="action-icon">
    //                     <div
    //                         className="d-inline"
    //                         data-toggle="tooltip"
    //                         data-placement="bottom"
    //                         onClick={() => {
    //                             // setPrintPopup(true);
    //                             // getPrintData(rowdata);
    //                         }}
    //                         title="Print"
    //                     >
    //                         <button className="grid-icon">
    //                             <Fa.FaPrint />
    //                         </button>
    //                     </div>
    //                     <div
    //                         className="d-inline"
    //                         data-toggle="tooltip"
    //                         data-placement="bottom"
    //                         title="View Product Details"
    //                     >
    //                         <button
    //                             className="grid-icon"
    //                             onClick={() => {
    //                                 nav(
    //                                     `/newreceipt?RCVHID=${encodeURIComponent(
    //                                         rowdata.MASK_RCVH_ID
    //                                     )}`
    //                                 );
    //                             }}
    //                         >
    //                             <Fa.FaEye />
    //                         </button>
    //                     </div>
    //                 </div>
    //             </>
    //         );
    //     };

    //   const printReceipt=(rowData)=>{
    //  
    //   }

    const renderAction = (rowdata) => {
         
        return (
            <>
                <div className="action-icon">
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        onClick={() => {
                            printReceipt(rowdata);
                        }}
                        title="Print"
                    >
                        <button className="grid-icon">
                            <Fa.FaPrint />
                        </button>
                    </div>
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="View Receipt Details"
                    >
                        <button
                            className="grid-icon"
                            onClick={() => {
                                nav(
                                    `/creditmanagement/receipts/details?RCVHID=${encodeURIComponent(
                                        rowdata.MASK_RCVH_ID
                                    )}`
                                );
                            }}
                        >
                            <Fa.FaEye />
                        </button>
                    </div>
                </div>
            </>
        );
    };
    if(printLoader){
        return(<Loader/>);
    }
    else{
        return (
            <>

                <div className="col-12 pt-3 pb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3 className="py-2">Receipts</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end mb-2">
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    onClick={handleMovetoNew}
                                >
                Add New Receipt
                                </button>
                            </div>
                        </div>
                    </div>
                    <section className="sales-box">
                        <form>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        defaultValue={sevenDays}
                                        max={currentDate}
                                        min="2010-01-01"
                                        name="date-field"
                                        onChange={(e) => {
                                            setFromDate(e.target.value);
                                        }}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <label className="form-label">To Date</label>
                                    <input
                                        type="date"
                                        defaultValue={currentDate}
                                        max={currentDate}
                                        className="form-control"
                                        name="date-field"
                                        min="2010-01-01"
                                        onChange={(e) => setToDate(e.target.value)}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12">
                                    <div className="float-end mt-4">
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => {
                                                findDateReceipts(e, fromDate, toDate);
                                            }}
                                        >
                    Get Receipts
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </section>
                    <section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>

                            <div className="DataTable pb-4 pt-1">
                                {tableloader ? (
                                    <Loader />
                                ) : (
                                    <DataTable
                                        value={allGridReceiptData}
                                        paginator
                                        rows={10}
                                        showGridlines
                                        stripedRows
                                        filterDisplay="row"
                                        emptyMessage="No Receipts found."
                                        rowsPerPageOptions={[10, 25, 50]}
                                        tableStyle={{ minWidth: "50rem" }}
                                    >
                                        <Column
                                            field="DOCUMENT_NO"
                                            header="Receipt No"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="DOCUMENT_DATE"
                                            header="Receipt Date"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="CUSTOMER"
                                            header=" Customer Name"
                                            filterMatchMode="contains"
                                            filter
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="MODE"
                                            header="Mode of Payment"
                                            // body={modeStatus}
                                            showFilterMenu={false} filter filterElement={statusRowFilterTemplate}  style={{ minWidth: "12rem" }}
                                        ></Column>
                                        <Column field={renderAction} header="Action" />
                                    </DataTable>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
                {printPopup && <PrintReceipt/>}
                {printPopupCash && <PrintReceiptCash/>}
            </>
        );
    }
  
};

export default AllReceiptsRecords;
