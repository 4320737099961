import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useState } from "react";
import storage from "../../Services/Storage";
import { createAPIPDAReceipt, Endpoint } from "../../Services/PDAReceipt/PDAReceiptService";
import { format, subDays } from "date-fns";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";




const PDAReceipts = () => {
   
    const [setsavePDAReceipt,getsavePDAReceipt] = useState([]);
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");

    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const auth = storage.getLocal("user");


    const getSavedPDAReceipt = async () => {
        createAPIPDAReceipt()
            .get(`${Endpoint.GetSavedPDAReceipt}?FromDate=${format(new Date(fromDate), "dd/MM/yyyy")}&ToDate=${format(new Date(toDate), "dd/MM/yyyy")}&CompId=${auth.CompId}`)
            .then((response) => {
                getsavePDAReceipt(response.data.Data);
                if(response.data.Status == "Warning"){
                    toast.warning(
                        `${response.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
    
                    );
    
                }
            })
            .catch(() => {
            });
    };

    return (
        <div className="col-12 pt-3 pb-4">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">PDA Receipts</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <Link to="/creditmanagement/pdareceiptcreation">
                            <button type="submit" className="btn btn-primary">
                                Add New PDA Receipt
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">From Date</label>
                            <input type="date" className="form-control" defaultValue={sevenDays}
                                max={currentDate} onChange={(e) => {
                                    setFromDate(e.target.value);
                                }} />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">To Date</label>
                            <input type="date" className="form-control"  defaultValue={currentDate}
                                max={currentDate} onChange={(e) => {
                                    setToDate(e.target.value);
                                }} />
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="float-end mt-4">
                                <button type="button" className="btn btn-primary" onClick={getSavedPDAReceipt}>Get Receipts</button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section className="">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <h3>All Records</h3>
                    <div className="DataTable pb-4 pt-1">
                        <DataTable
                            value={setsavePDAReceipt}
                            paginator
                            rows={10}
                            showSelectAll={false}
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            showGridlines
                            scrollable
                            stripedRows
                            tableStyle={{ minWidth: "60rem" }}
                        >
                            <Column field="REF_NO" header="Reference Number"></Column>
                            <Column field="PARTY_NAME" header="Party Code"></Column>
                            <Column field="RCVH_NO" header="Receipt Number"></Column>
                            <Column field="INV_NO" header="Invoice Number"></Column>
                            <Column field="INV_AMT" header="Invoice Amount"></Column>
                        </DataTable>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PDAReceipts;