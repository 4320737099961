import Axios from "../api/Axios";
const BASE_URL = "Message/";
export const EndpointMessage = {
    GetMessageByMsgIdSQL: "GetMessageByMsgIdSQL"
};
    
export const createAPIEndpointMessage = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};