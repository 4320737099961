import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";
import {
    createAPIEndpointCNCreation,
    EndpointCN,
} from "../../Services/CreditManagement/CreditoteCreation/CreditNoteCreation";
import storage from "../../Services/Storage";
import { Checkbox } from "primereact/checkbox";
import { useCNCreationContext } from "../../context/CreditNoteCreation";
import { ToastContainer, toast } from "react-toastify";
import XLSX from "xlsx";
import Loader from "../../Components/Loader";

const AllCreationRecords = () => {
    const { CNGridData, setCNGridData } = useCNCreationContext();
    const auth = storage.getLocal("user");
    const [selectedCN, setSelectedCN] = useState([]);
    const [loadingExcel, setLoadingExcel] = useState(false);

    console.log("auth", auth);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [globalFilter, setGlobalFilter] = useState("");
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const currentPageData = CNGridData.slice(first, first + rows);

    const onSelectionChange = (e) => {
        setSelectedCN(e.value);
    };

    const onHeaderCheckboxChange = (e) => {
        if (e.checked) {
            setSelectedCN(currentPageData);
        } else {
            setSelectedCN([]);
        }
    };

    const headerCheckbox = (
        <Checkbox
            onChange={onHeaderCheckboxChange}
            checked={
                currentPageData.length > 0 &&
        selectedCN.length === currentPageData.length
            }
        />
    );
    const getCNData = () => {
        setLoaderStatus(true);
        createAPIEndpointCNCreation()
            .get(`${EndpointCN.GetCNData}?CompId=${auth.CICode}`)
            .then((response) => {
                console.log("response", response);
                setCNGridData(response.data);
                setLoaderStatus(false);
            })
            .catch((error) => {console.log(error);                setLoaderStatus(false);
            });
    };

    const guidArr = () => {
    // const mappedGUID = [...selectedCN]
        const mappedGUID = selectedCN?.map((item) => item?.GUID);
        return mappedGUID;
    };
    const saveCNData = () => {
        if (selectedCN?.length > 0) {
            createAPIEndpointCNCreation()
                .save(`${EndpointCN.SaveCNData}`, {
                    compId: auth.CompId,
                    guid: guidArr(),
                })
                .then((response) => {
                    toast.success(
                        `${response?.Message ?? "Credits Note(s) saved successfully"}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                    getCNData();
                    // setCNGridData(response.data);
                })
                .catch(() => {});
            setSelectedCN([]);
        }
        return;
    };

    const deleteCNData = () => {
        if (selectedCN?.length > 0) {
            createAPIEndpointCNCreation()
                .delete(`${EndpointCN.DeleteCNData}`, {
                    compId: auth.CompId,
                    guid: guidArr(),
                })
                .then((response) => {
                    toast.warn(
                        `${response?.Message ?? "Credits Note(s) deleted successfully"}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                    getCNData();
                    // setCNGridData(response.data);
                })
                .catch((error) => {console.log(error);});
            setSelectedCN([]);
        }
        return;
    };

    const ExportToExcel = () => {
        setLoadingExcel(true);
        createAPIEndpointCNCreation()
            .getExcel(`${EndpointCN.GetExcelData}?CompId=${auth.CICode}`)
            .then((response) => {
                console.log("response", response);
                if (response?.data?.Status == "Warning") {
                    toast.warning(`${response?.data?.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    setLoadingExcel(false);
                } else {
                    if (response.data.length > 0) {
                        setLoadingExcel(false);
                        let date = new Date();
                        let day = date.getDate();
                        let month = date.getMonth() + 1; // getMonth() returns 0-11, so add 1
                        let exactDay = day.toString().padStart(2, "0");
                        let exactMonth = month.toString().padStart(2, "0");
                        let year = date.getFullYear();
                        console.log("Date:", response.data);
                        let uniqueNo = `CreditNoteCreation${exactDay}${exactMonth}${year}`;
                        var wb = XLSX.utils.book_new();
                        var ws = XLSX.utils.json_to_sheet(response.data);
                        XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                        XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                    } else {
                        setLoadingExcel(false);

                        toast.warning("No Data Found", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                setLoadingExcel(false);

            });
    };
    useEffect(() => {
        getCNData();
    }, []);
    return (
        <main>
            <ToastContainer />
            <div className='col-12 pt-3 pb-4'>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h3 className='py-2'>Credit Note Creation</h3>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <div className='float-end mb-2'>
                            <button
                                type='submit'
                                className='btn btn-outline-primary me-3'
                                onClick={ExportToExcel}
                            >
                                {loadingExcel ? "Loading..." : "Export To Excel"}{" "}
                                {loadingExcel ? (
                                    <span
                                        className='spinner-border spinner-border-sm'
                                        role='status'
                                        aria-hidden='true'
                                    ></span>
                                ) : (
                                    <Fa.FaDownload className='ms-2' />
                                )}
                            </button>
                            <button
                                type='submit'
                                className='btn btn-cancel-red me-3'
                                disabled={!selectedCN.length}
                                onClick={deleteCNData}
                            >
                Delete
                            </button>
                            <button
                                type='submit'
                                className='btn btn-primary'
                                disabled={!selectedCN.length}
                                onClick={saveCNData}
                            >
                Save
                            </button>
                        </div>
                    </div>
                </div>
                <section className=''>
                    <div className='col-md-12 col-lg-12 col-sm-12'>
                        <div className='DataTable'>
                            {loaderStatus ? (
                                <Loader />
                            ) : (
                                <DataTable
                                    value={CNGridData}
                                    dataKey='GUID'
                                    paginator
                                    rows={rows}
                                    first={first}
                                    onPage={(e) => {
                                        setFirst(e.first);
                                        setRows(e.rows);
                                    }}
                                    // rowClassName={rowClassName}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight='80vh'
                                    scrollable
                                    filterDisplay='row'
                                    showGridlines
                                    stripedRows
                                    showSelectAll={false}
                                    selectionMode='checkbox'
                                    selection={selectedCN}
                                    onSelectionChange={onSelectionChange}
                                    globalFilter={globalFilter}
                                    onGlobalFilterChange={(e) => setGlobalFilter(e.target.value)}
                                    emptyMessage='No Invoices found.'
                                    tableStyle={{ minWidth: "60rem" }}
                                >
                                    <Column
                                        selectionMode='multiple'
                                        headerStyle={{ width: "3em" }}
                                        header={headerCheckbox}
                                    />
                                    {/* <Column
                  field='MASK_SOHD_SO_NO'
                  header='Sales Order No'
                  filter
                  filterPlaceholder='Search Here...'
                  style={{ minWidth: '8rem' }}
                /> */}
                                    <Column field='CUST_CODE' header='Customer Code'></Column>
                                    <Column field='CUST_NAME' header='Custome Name'></Column>
                                    <Column field='SCHM_CODE' header='Scheme Code'></Column>
                                    <Column
                                        field='SCHM_DESC'
                                        header='Scheme Description'
                                    ></Column>
                                    <Column
                                        field='FIRST_ENTERED_ON'
                                        header='CN Upload Date'
                                    ></Column>
                                    <Column
                                        field='AMOUNT'
                                        header='Amount'
                                        style={{ textAlign: "right" }}
                                    ></Column>
                                </DataTable>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default AllCreationRecords;
