import { useNavigate } from "react-router-dom";
import axios from "axios";
import storage from "../Services/Storage";
import { toast } from "react-toastify";
// const Logout_URL = "https://localhost:44308/api/login/logout";
const Logout_URL = "https://csdapiuat.castroldms.com/api/login/logout";
// const Logout_URL = "https://csdapi.castroldms.com/api/login/logout";
// const Logout_URL = "https://innorisepreprdapi.azurewebsites.net/api/login/logout";
// const Logout_URL = "https://innorisedevapi.azurewebsites.net/api/login/logout";
const useLogout = () => {
    const auth = {UserId : storage.getLocal("user").UserId};
    const navigate = useNavigate();
    const logout = async () => {
        try {
            await axios.post(
                Logout_URL,
                (auth)
            );
            localStorage.clear();
            navigate("/");
        } catch (error) {
            toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            console.log(error);
        }
    };
    return logout;
};
export default useLogout;
