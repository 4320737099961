import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ViewCustomerTable from "./ViewCustomerTable";
import { useCustMasterContext } from "../../context/CustomerMasterContext";
import InformationModal from "./InformationModal";
import * as Fa from "react-icons/fa";

function ViewCustomer() {
    const {
        // setNewCustomerSave,
        getSaveBillShipTown,
        exportdata,
        setCustomerDetailsById,
    } = useCustMasterContext();
    const nav = useNavigate();
    const newCust = () => {
        nav("/masters/customermaster/details");

        getSaveBillShipTown();
    };
    useEffect(()=>{
        setCustomerDetailsById([]);
    },[]);
    return (
        <>
            <main>
                {/* <div className="content-wrapper pt-3 pb-4"> */}
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Customer Master</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <button type="submit" className="btn btn-outline-primary me-3" onClick={newCust} >
                                    Add New Customer
                            </button>
                            <button type="submit" className="btn btn-primary" onClick={exportdata} >
                                    Export All <Fa.FaFileExport className="ms-2"/>
                            </button>
                        </div>
                    </div>
                </div>
                <section className="">
                    <ViewCustomerTable />
                </section>
                {/* </div> */}
            </main>
            <InformationModal />
        </>
    );
}

export default ViewCustomer;
