export const PurchaseReturnInitialVal = {
    debit_note_no:"",
    debit_note_date:"",
    supplier_code:"",
    supplier_name: "",
    matching_doc_no:"",
    matching_doc_date: "",
    warehouse:"",
    reason_desc:"",
    total_amount:"",
    print_message:"",
    full_grn:"",
    product_code:"",
    product_description:"",
    recieved_quantity:"",
    returned_quantity:"",
};