import Axios from "../../api/Axios";
const BASE_URL = "StockReport/";
export const Endpoint = {
    GetStockData: "GetStockData",
    GetStockDataNonInv: "GetStockDataNonInv",
    GetStockDataExcel: "GetStockDataExcel",
    GetStockDataExcelNI: "GetStockDataExcelNI",
};

export const createAPIEndpoint = () => {
    return {get:(Endpoint)=>Axios.get(BASE_URL+Endpoint)};
};