import React from "react";
import * as Fa from "react-icons/fa";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const SchemeShortFall = () => {

    return (
        <>
            <div className="col-12 pt-3 pb-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Scheme Short Fall Report</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <button type="submit" className="btn btn-primary">
                                Export All <Fa.FaFileExport className="ms-2" />
                            </button>
                        </div>
                    </div>
                </div>
                <section className="sales-box">
                    <form>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Quarter</label>
                                <Dropdown placeholder="2024(Q3)" />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label d-block mb-3">Scheme Type</label>
                                <div className="flex flex-wrap gap-3">
                                    <div className="d-inline-flex pe-4">
                                        <RadioButton inputId="" name="Value" value="Value" />
                                        <label className='ps-2'>Value</label>
                                    </div>
                                    <div className="d-inline-flex pe-3">
                                        <RadioButton inputId="" name="FOC" value="FOC" />
                                        <label className='ps-2'>FOC</label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="float-end mt-4">
                                    <button type="button" className="btn btn-primary">
                                        Get Data
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                <section className="mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3>All Records</h3>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="DataTable">
                                <DataTable
                                    // value={custData}
                                    // tableStyle={{ minWidth: "60rem" }}
                                    rows={10}
                                    filterDisplay="row"
                                    showGridlines
                                    stripedRows
                                    selectionMode="checkbox"
                                    scrollHeight="80vh"
                                    scrollable
                                // selection={selectedCustomer}
                                // onSelectionChange={(e) => {
                                //     setSelectedCustomer(e.value);
                                // }}
                                >
                                    <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                                    <Column field="" header="Customers Name" filter filterMatchMode="contains" filterPlaceholder="Search Here..."></Column>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
};

export default SchemeShortFall;