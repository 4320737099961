import Axios from "../../api/Axios";
const BASE_URL = "ParamSetting/Master/";
export const Endpoint = {
    GetParameterData: "GetParameterData",
    SaveParamData: "SaveParamData",
};

export const createAPIEndpointMaster = () => {
    return { get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint,data)=> Axios.post(BASE_URL+Endpoint,data)
    };
};