import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useWareHouseMasterContext } from "../../context/WareHouseContext";



const GeoTownModal = () => {

    

    const {
        townpopup,
        setTownPopup,
        townValue,
        setTownValue,
        stateValue,
        setstateValue,
        districtValue,
        setDistrictValue,
        stateSuggestion,
        districtSuggestion,
        townSuggestion,
        setstateGEOG_ID,
        setDistrictGEOG_ID,
        setTownGeogId,
        setZipCode,
        setConfirmTown,
    } =
    useWareHouseMasterContext();

    let id = 0;
    const [states, setStates] = useState("");
    const [districts, setDistricts] = useState("");
    const [towns, setTowns] = useState("");

    const itemTemplate = (stateFilter) => {
        return (
            <>
                {stateFilter.GEOG_ID}-{stateFilter.NAME}
            </>
        );
    };

    const searchDistrict = (e) => {

        setTimeout(() => {
            let _filteredDistrict;
            if (!e.query.trim().length) {
                _filteredDistrict = [...districtSuggestion];
            } else {
                _filteredDistrict = districtSuggestion.filter((district) => {
                    return district.GEOG_ID.toLowerCase().startsWith(e.query.toLowerCase()) || district.NAME.toLowerCase().startsWith(e.query.toLowerCase());
                });
            }
            setDistricts(_filteredDistrict);
        }, 250);
    };

    const searchTown = (e) => {
        setTimeout(() => {
            let _filteredTown;
            if (!e.query.trim().length) {
                _filteredTown = [...townSuggestion];
            } else {
                _filteredTown = townSuggestion.filter((town) => {
                    return town.GEOG_ID.toLowerCase().startsWith(e.query.toLowerCase()) || town.NAME.toLowerCase().startsWith(e.query.toLowerCase());
                });
            }
            setTowns(_filteredTown);
        }, 250);
    };

    const searchState = (e) => {
        setTimeout(() => {
            let _filteredState;
            if (!e.query.trim().length) {
                _filteredState = [...stateSuggestion];
            } else {
                _filteredState = stateSuggestion.filter((state) => {
                    return state.GEOG_ID.toLowerCase().startsWith(e.query.toLowerCase()) || state.NAME.toLowerCase().startsWith(e.query.toLowerCase());
                });
            }
            setStates(_filteredState);
        }, 250);
    };


    const handleChange = (e, id) => {

        if (id == 1) {
            stateSuggestion.map((val) => {
                val.NAME == e.target.value.NAME && setstateValue(e.target.value.NAME);
            });
            if (e.target.value.NAME == undefined || e.target.value.NAME == null) {
                setstateValue(e.target.value);
            }
            setstateGEOG_ID(e.target.value.GEOG_ID);
        }

        if (id == 2) {
            districtSuggestion.map((val) => {
                val.NAME == e.target.value.NAME && setDistrictValue(e.target.value.NAME);
            });
            if (e.target.value.NAME == undefined || e.target.value.NAME == null) {
                setDistrictValue(e.target.value);
            }
            setDistrictGEOG_ID(e.target.value.GEOG_ID);
        }
        if (id == 3) {
            townSuggestion.map((val) => {
                val.NAME == e.target.value.NAME && setTownValue(e.target.value.NAME);
            });
            if (e.target.value.NAME == undefined || e.target.value.NAME == null) {
                setTownValue(e.target.value);
            }
            setZipCode(e.target.value.CODE);
            setTownGeogId(e.target.value.GEOG_ID);
        }

    };



    return (
        <div>
            <>
                <div className="col-12 pt-3 pb-3">
                    <Modal show={townpopup} 
                        onHide={() => { setTownPopup(false); }} 
                        aria-labelledby="contained-modal-title-vcenter" centered>
                        {/* <form onSubmit={formik.handleSubmit}> */}
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Search Geography</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="mb-3">
                               

                                
                                    <label className="form-label">State</label>
                                    <AutoComplete
                                        field="State"
                                        value={stateValue}
                                        suggestions={states}
                                        completeMethod={searchState}
                                        onChange={(e) => {
                                            id = 1;
                                            handleChange(e, id);

                                        }}
                                        itemTemplate={itemTemplate}
                                        dropdown
                                        emptyMessage="No results found."
                                        showEmptyMessage
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">District</label>
                                    <AutoComplete
                                        field="District"
                                        value={districtValue}
                                        suggestions={districts}
                                        completeMethod={searchDistrict}
                                        onChange={(e) => {
                                            id = 2;
                                            handleChange(e, id);

                                        }}
                                        itemTemplate={itemTemplate}
                                        dropdown
                                        emptyMessage="No results found."
                                        showEmptyMessage
                                    />
                                </div>
                                <div className="mb-3">
                                    <label className="form-label">Town</label>
                                    <AutoComplete
                                        field="Town"
                                        value={townValue}
                                        suggestions={towns}
                                        completeMethod={searchTown}
                                        onChange={(e) => {
                                            id = 3;
                                            handleChange(e, id);
                                        }}
                                        itemTemplate={itemTemplate}
                                        dropdown
                                        emptyMessage="No results found."
                                        showEmptyMessage
                                    />
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="float-end">
                                <button type="button" className="btn btn-primary me-3" onClick={() => {setConfirmTown(true); setTownPopup(false); }} disabled={townValue==""?true:false} >OK</button>
                                <button type="button" className="btn btn-cancel-red" onClick={() => { setTownPopup(false); }}>Close</button>
                            </div>
                        </Modal.Footer>

                    </Modal>
                </div>
            </>
        </div>
    );
};

export default GeoTownModal;