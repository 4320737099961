import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Fa from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format } from "date-fns";
import { useState } from "react";
import XLSX from "xlsx";
import {
    EndpointWO,
    createAPIEndpointWO,
} from "../../Services/WriteOff/WriteOffService";
import storage from "../../Services/Storage";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useWriteOffContext } from "../../context/WriteOffContext";
import Loader from "../../Components/Loader";

const OutstandingAdjustment = () => {
    const nav = useNavigate();
    const auth = storage.getLocal("user");
    const todaydate = new Date();
    const currentDate = format(todaydate, "yyyy-MM-dd");
    const sevenDays = todaydate.setDate(todaydate.getDate() - 7);
    const formattedSevenDaysAgo = format(sevenDays, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(formattedSevenDaysAgo);
    const [toDate, setToDate] = useState(currentDate);
    const [custData, setCustData] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [selectedDataByCust, setSelectedDataByCust] = useState([]);
    const [dataByCust, setDataByCust] = useState([]);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [savePopUp, setSavePopUp] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [narration, setNarration] = useState("");
    const [error, setError] = useState("");

    const { isLoading, setIsLoading } = useWriteOffContext();

    const findDateFunc = (e, fromDate, toDate, id) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        const from = new Date(fromDateRange.split("/").reverse().join("/"));
        const to = new Date(toDateRange.split("/").reverse().join("/"));
        let differenceInDays = 0;
        if (!isNaN(from) && !isNaN(to)) {
            const differenceInMillis = to.getTime() - from.getTime();
            differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);
        } else {
            return false;
        }

        if (differenceInDays > 30) {
            toast.warning("Date range should be within 30 days", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            setCustData([]);
        } else {
            if (id == 1) {
                populateCustomer(fromDateRange, toDateRange);
            } else {
                handleCustData(fromDateRange, toDateRange);
            }
        }

        e.preventDefault();
    };

    const handleCustData = (fromDateRange, toDateRange) => {
        let strcust = "";
        if (selectedCustomer.length == custData.length) {
            strcust = "ALL";
        } else {
            strcust = selectedCustomer
                .map((cust) => `${cust.MASK_PARTY_ID}`)
                .join(",");
        }
        setDataLoading(true);
        createAPIEndpointWO()
            .get(
                `${EndpointWO.GetDataByCust}?strStartDate=${fromDateRange}&strEndDate=${toDateRange}&strCustId=${strcust}&CompId=${auth.CompId}`
            )
            .then((res) => {
                setDataByCust(res?.data?.Data);
                setDataLoading(false);
            })
            .catch((error) => {
                setDataLoading(false);
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                console.log(error);
            });
    };

    const handleSaveOutStanding = (e) => {
        e.preventDefault();
        if (validate(narration)) {
            setIsLoading(true);
            let objOutStandingDetl = selectedDataByCust.map((a) => ({
                documenT_NO: a?.DOCUMENT_NO,
                documenT_DATE: a?.DOCUMENT_DATE,
                masK_DOC_TYPE: a?.MASK_DOC_TYPE,
                masK_APAR_TYPE: a?.MASK_APAR_TYPE,
                masK_DVCV_NO: a?.MASK_DVCV_NO,
                masK_CURRENCY_ID: a?.MASK_CURRENCY_ID,
                masK_CUST_ID: a?.MASK_CUST_ID,
                outstanding: a?.OUTSTANDING,
            }));
            let SaveObj = {
                userId: auth.UserId,
                compId: auth.CompId,
                narration: narration,
                strWriteOffFlag: "",
                chkparty: false,
                objAdvanceDetl: [],
                objOutStandingDetl: objOutStandingDetl,
            };

            createAPIEndpointWO()
                .post(`${EndpointWO.SaveWriteOff}`, JSON.stringify(SaveObj), {
                    headers: { "Content-Type": "application/json" },
                })
                .then((res) => {
                    if (res?.data?.Status == "Success") {
                        toast.success(`${res?.data?.Message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3000,
                        });
                        setIsLoading(false);
                        nav("/creditmanagement/writeoff");
                    } else {
                        toast.warning(`${res?.data?.Message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3000,
                        });
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    toast.error(`${error.response.data.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                });
        } else {
            return false;
        }
    };

    const populateCustomer = (fromDateRange, toDateRange) => {
        setDataLoading(true);
        createAPIEndpointWO()
            .get(
                `${EndpointWO.PopulateCustomer}?strStartDate=${fromDateRange}&strEndDate=${toDateRange}&CompId=${auth.CompId}`
            )
            .then((res) => {
                setCustData(res?.data?.Data);
                setDataLoading(false);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                setDataLoading(false);
                console.log(error);
            });
    };

    const handleExportExcel = (fromDate, toDate) => {
        let strcust = "";
        if (selectedCustomer.length == custData.length) {
            strcust = "ALL";
        } else {
            strcust = selectedCustomer
                .map((cust) => `${cust.MASK_PARTY_ID}`)
                .join(",");
        }
        setLoadingExcel(true);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dateunique = date.valueOf();
        let uniqueNo = `${day}-${month}-${year}-${dateunique}`;
        createAPIEndpointWO()
            .get(
                `${EndpointWO.GetDataByCust}?strStartDate=${fromDateRange}&strEndDate=${toDateRange}&strCustId=${strcust}&CompId=${auth.CompId}`
            )
            .then((val) => {
                if (val?.data?.Data.length > 0) {
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.json_to_sheet(val?.data?.Data);
                    XLSX.utils.book_append_sheet(wb, ws, `Outstanding${uniqueNo}.xlsx`);
                    XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                } else {
                    toast.warning("No Data Found between these dates", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                }
                setLoadingExcel(false);
            })
            .catch((error) => {
                setLoadingExcel(false);
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });

                console.log(error);
            });
    };

    const validate = (value) => {
        if (!value) {
            setError("This field is required.");
            return false;
        } else {
            setError("");
            return true;
        }
    };

    const handleChange = (e) => {
        setNarration(e.target.value);
        if (error) {
            validate(e.target.value);
        }
    };

    if (isLoading) {
        return (
            <>
                <Loader />;
            </>
        );
    } else {
        return (
            <div className="col-12 pt-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Outstanding Adjustment</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <Link to="/creditmanagement/writeoff">
                                <button type="submit" className="btn btn-outline-primary me-3">
                                    <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                  Records
                                </button>
                            </Link>
                            <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => {
                                    handleExportExcel(fromDate, toDate);
                                }}
                            >
                                {loadingExcel ? "Loading..." : "Export"}{" "}
                                {loadingExcel ? (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <Fa.FaFileExport className="ms-2" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <section className="sales-box">
                    <form>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label className="form-label">From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        onChange={(e) => setFromDate(e.target.value)}
                                        defaultValue={formattedSevenDaysAgo}
                                        max={toDate}
                                        min="2010-01-01"
                                        name="date-field"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <label className="form-label">To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        onChange={(e) => setToDate(e.target.value)}
                                        defaultValue={currentDate}
                                        // max={currentDate}
                                        min={fromDate}
                                        name="date-field"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="float-end mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-primary "
                                        onClick={(e) => findDateFunc(e, fromDate, toDate, 1)}
                                    >
                    Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>

                <section>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="sales-box">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="py-2">Customers List</h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="float-end mb-2">
                                            <button
                                                disabled={selectedCustomer.length > 0 ? false : true}
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={(e) => findDateFunc(e, fromDate, toDate, 2)}
                                            >
                        Get Data
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="WriteOff_CustList">
                                        {dataLoading ? (
                                            <Loader />
                                        ) : (
                                            <DataTable
                                                value={custData}
                                                // tableStyle={{ minWidth: "60rem" }}
                                                rows={10}
                                                filterDisplay="row"
                                                showGridlines
                                                stripedRows
                                                selectionMode="checkbox"
                                                scrollHeight="80vh"
                                                scrollable
                                                selection={selectedCustomer}
                                                onSelectionChange={(e) => {
                                                    setSelectedCustomer(e.value);
                                                }}
                                            >
                                                <Column
                                                    selectionMode="multiple"
                                                    headerStyle={{ width: "3rem" }}
                                                ></Column>
                                                <Column
                                                    field="CUSTOMER_NAME"
                                                    header="Customers Name"
                                                    filter
                                                    filterMatchMode="contains"
                                                    filterPlaceholder="Search Here..."
                                                ></Column>
                                                {/* <Column field="DESCRIPTION" header="Warehouse Description"></Column>
                                        <Column body={toNewWareHouse} header="Action"></Column> */}
                                            </DataTable>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-12 col-sm-12">
                            <div className="sales-box">
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <h3 className="py-2">Records</h3>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="float-end mb-2">
                                            <button
                                                disabled={selectedDataByCust.length > 0 ? false : true}
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => setSavePopUp(true)}
                                            >
                        Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="DataTable">
                                        <DataTable
                                            value={dataByCust}
                                            tableStyle={{ minWidth: "60rem" }}
                                            rows={10}
                                            showGridlines
                                            stripedRows
                                            selectionMode="checkbox"
                                            scrollHeight="80vh"
                                            scrollable
                                            selection={selectedDataByCust}
                                            onSelectionChange={(e) => {
                                                setSelectedDataByCust(e.value);
                                            }}
                                        >
                                            <Column
                                                selectionMode="multiple"
                                                headerStyle={{ width: "3rem" }}
                                            ></Column>
                                            <Column
                                                field="CUST_CODE"
                                                header="Code"
                                                style={{ minWidth: "3rem" }}
                                            ></Column>
                                            <Column
                                                field="CUSTOMER_NAME"
                                                header="Name"
                                                style={{ minWidth: "8rem" }}
                                            ></Column>
                                            <Column
                                                field="DOCUMENT_NO"
                                                header="Document Number"
                                                style={{ minWidth: "6rem" }}
                                            ></Column>
                                            <Column
                                                field="DOCUMENT_DATE"
                                                header="Document Date"
                                                style={{ minWidth: "6rem" }}
                                            ></Column>
                                            <Column
                                                field="OUTSTANDING"
                                                header="Outstanding"
                                                style={{ minWidth: "6rem" }}
                                            ></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div>
                    <Dialog
                        header="Information"
                        visible={savePopUp}
                        style={{ width: "40vw" }}
                        onHide={() => {
                            setError("");
                            setSavePopUp(false);
                        }}
                        closable={false}
                        resizable={false}
                        draggable={false}
                    >
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <form>
                                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                                    <label className="form-label">
                    Narration <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        className="form-control"
                                        value={narration}
                                        onBlur={(e) => validate(e.target.value)}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    {error && <div style={{ color: "red" }}>{error}</div>}
                                </div>
                            </form>
                        </div>
                        <div className="float-end">
                            <button
                                type="button"
                                className="btn btn-primary me-3"
                                onClick={(e) => handleSaveOutStanding(e)}
                            >
                OK
                            </button>
                            <button
                                type="button"
                                className="btn btn-cancel-red"
                                onClick={() => {
                                    setError("");
                                    setSavePopUp(false);
                                }}
                            >
                Cancel
                            </button>
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
};

export default OutstandingAdjustment;
