import Axios from "../../api/Axios";
const BASE_URL = "WriteOff/";
export const EndpointWO = {
    PopulateWriteOffData : "PopulateWriteOffData",
    PopulateWODetails : "PopulateWODetails",
    GetAdvanceOutstandingData : "GetAdvanceOutstandingData",
    PopulateCustomer : "PopulateCustomer",
    GetDataByCust : "GetDataByCust",
    SaveWriteOff : "SaveWriteOff"
};
    
export const createAPIEndpointWO = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};