import Modal from "react-bootstrap/Modal";
import React from "react";
import { useEmployeeMasterContext } from "../../context/EmployeeMasterContext";
import SearchCustomerModal from "./SearchCustomerModal";
// import { useNavigate } from "react-router-dom";



const InformationModal = () => {

    // const nav = useNavigate();
    const { popup, setPopup, setSearchPopup, newEmployeeSave, newSaveData } = useEmployeeMasterContext();

    const newClick = () => {
        setPopup(false);
        newEmployeeSave(newSaveData);

    };
    const replacementClick = () => {

        setSearchPopup(true);
        setPopup(false);
    };
    return (
        <div>
            <>
                <div className="col-12 pt-3 pb-3">
                    <Modal show={popup} onHide={() => { setPopup(false); }} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
                                <div className="text-center">
                                    <p className="mb-0">Do you want to create &ldquo;New&ldquo; or &ldquo;Replace&ldquo;?</p>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="float-end">
                                <button type="button" className="btn btn-primary me-3" onClick={newClick}>New</button>
                                {/* <button type="button" className="btn btn-cancel-red me-3" onClick={handleClose}>Close</button> */}
                                <button type="button" className="btn btn-outline-primary" onClick={replacementClick}>Replacement</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
                <SearchCustomerModal />
            </>
        </div>
    );
};

export default InformationModal;