import React, { useContext } from "react";
import { useState } from "react";
import {
    createAPIReceiptsEndpoint,
    Endpoint,
} from "../Services/Receipts/ReceiptsService";
import storage from "../Services/Storage";
// import { useNavigate } from "react-router";
// import Axios from "../api/Axios";
import format from "date-fns/format";
// import { toast } from "react-toastify";
// import storage from "../../Services/Storage";

const ReceiptsContext = React.createContext();

const ReceiptsProvider = ({ children }) => {
    const [allGridReceiptData, setAllGridReceiptData] = useState([]);
    const [tableloader, setTableloader] = useState(false);

    const [allInvoiceData, setAllInvoiceData] = useState([]);
    const [selectedInvoicesData, setselectedInvoicesData] = useState([]);
    const [invoicePopupShow, setInvoicePopupShow] = useState(false);
    const [printPopup, setPrintPopup] = useState(false);
    const [printPopupCash, setPrintPopupCash] = useState(false);
    const [printResponseData, setPrintResponseData] = useState([]);
    const [printLoader, setPrintLoader] = useState(false);
    const auth = storage.getLocal("user");

    const findDateReceipts = (e, fromDate, toDate) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        GetAllTabledata(fromDateRange, toDateRange);
        e.preventDefault();
    };

    const GetAllTabledata = (fromDateRange, toDateRange) => {
        setTableloader(true);
        createAPIReceiptsEndpoint()
            .get(
                `${Endpoint.PopulateAllReceiptGrid}?FromDate=${fromDateRange}&ToDate=${toDateRange}&CompId=${auth.CompId}`
            )
            .then((response) => {
                setAllGridReceiptData(response.data);
                 
                setTableloader(false);
            })
            .catch(() => {
                setTableloader(false);
                setAllGridReceiptData([]);
            });
    };

    const printReceipt = (rowData) => {
         
        setPrintLoader(true);
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.PrintReceipt}?RcptId=${rowData.MASK_RCVH_ID}`)
            .then((response) => {
                if (rowData.MODE == "Cash") {
                    setPrintResponseData(response.data);
                    setPrintLoader(false);
                    setPrintPopupCash(true);
                } else {
                    setPrintResponseData(response.data);
                    setPrintLoader(false);
                    setPrintPopup(true);
                }
            })
            .catch(() => {
                setPrintLoader(false);
            });
    };

    return (
        <ReceiptsContext.Provider
            value={{
                findDateReceipts,
                GetAllTabledata,
                tableloader,
                allGridReceiptData,
                allInvoiceData,
                setAllInvoiceData,
                selectedInvoicesData,
                setselectedInvoicesData,
                invoicePopupShow,
                setInvoicePopupShow,
                printReceipt,
                printPopup,
                setPrintPopup,
                printResponseData,
                printLoader,
                printPopupCash,
                setPrintPopupCash,
            }}
        >
            {children}
        </ReceiptsContext.Provider>
    );
};

export const useReceiptsContext = () => {
    return useContext(ReceiptsContext);
};

export { ReceiptsProvider, ReceiptsContext };
