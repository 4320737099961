import Modal from "react-bootstrap/Modal";
import React from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { EndpointPurchaseReturn, createAPIEndpointPurchaseReturn } from "../../Services/PurchaseReturn/PurchaseReturnService";
import { usePurchaseReturnContext } from "../../context/PurchaseReturnContext";
import storage from "../../Services/Storage";
import { toast } from "react-toastify";

const GetInvoiceModal = () => {

    const auth = storage.getLocal("user");

    const {
        matchingDocPopUp,
        setMatchingDocPopUp,
        setSupplierDisable,
        GRNAllData,
        selectedData, 
        setSelectedData,
        setProduct,
        supplierId, 
        setMatchingDocDetails
    }=usePurchaseReturnContext();

    const handleClose = () => {
        setMatchingDocPopUp(false);
    };


    const handleConfirm = () => {
        setSupplierDisable(true);
        setMatchingDocPopUp(false);  
        handleProductCode(selectedData.MASK_GRN_ID);

        createAPIEndpointPurchaseReturn()
            .get(`${EndpointPurchaseReturn.MatchDocOk}?CompId=${auth.CompId}&strGRNHdrId=${selectedData.MASK_GRN_ID}&strSupplier=${supplierId}&UserId=${auth.UserId}`)
            .then((res)=>{
                setMatchingDocDetails(res?.data);
            })
            .catch((error)=>{
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                console.log(error);
            });
        
    };


    const handleProductCode =(val)=>{
        createAPIEndpointPurchaseReturn()
            .get(`${EndpointPurchaseReturn.GetProductList}?strGRNId=${val}`)
            .then((res)=>{
                setProduct(res?.data.Data);
                console.log("product",res?.data);
            });
    };

    const handleRowSelect = (e) => { 
        setSelectedData(e.value);
    };

    

    return (
        <div>
            <>
                <div className="col-12 pt-3 pb-3">
                    <Modal size="lg" className="SearchMatchingDocumentsModal" show={matchingDocPopUp} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Search Matching Documents</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="pt-0">
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="DataTable py-4">
                                    <DataTable
                                        value={GRNAllData}
                                        selection={selectedData}
                                        onSelectionChange={(e)=>{handleRowSelect(e);}}
                                        dataKey="GRN_NO"
                                        tableStyle={{ minWidth: "50rem" }}
                                        filterDisplay="row"
                                        paginator
                                        rows={10}
                                        selectionMode="radiobutton"
                                        rowsPerPageOptions={[10, 25, 50]}
                                        scrollHeight="80vh"
                                        stripedRows
                                        showGridlines
                                    >
                                        <Column
                                            selectionMode="single"
                                            headerStyle={{ width: "3rem" }}
                                        />
                                        <Column
                                            field="GRN_NO"
                                            header="Document Number"
                                            filterMatchMode="contains" 
                                            filter 
                                            filterPlaceholder="Search Here..."
                                        />
                                        <Column
                                            field="MASK_GRN_DATE"
                                            header="Document Date"
                                            filterMatchMode="contains" 
                                            filter 
                                            filterPlaceholder="Search Here..."
                                            
                                        />
                                    </DataTable>
                                </div>
                                
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="float-end">
                                <button type="button" className="btn btn-primary me-3" onClick={handleConfirm}>OK</button>
                                <button type="button" className="btn btn-cancel-red" onClick={handleClose}>Close</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        </div>
    );
};

export default GetInvoiceModal;
