
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React from "react";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Endpoint, createAPIEndpoint } from "../../Services/StockAdjustmentService.js/StockAdjustmentService";
import storage from "../../Services/Storage";
import { useState } from "react";
import { useStockAdjustmentContext } from "../../context/StockAdjustmentContext";
import InformationModal from "./InformationModal";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Ci from "react-icons/ci";
import Loader from "../../Components/Loader";
import * as Fa from "react-icons/fa";
import { EndpointSalesReturn, createAPIEndpointSalesReturn } from "../../Services/SalesReturnAdd/SalesReturnServices";

const NewStock = () => {
    const auth = storage.getLocal("user");
    const [reasonData, setReasonData] = useState([]);
    const [viewStock, setViewStock] = useState("");

    const {
        setInfoPopup,
        StockTypeApi,
        selectedWHData,
        setselectedWHData,
        allWarehouseData,
        setAllWarehouseData,
        currentDate,
        populateProductDataTable,
        setPopulateProductDataTable,
        newStockAdjustedSave,
        ViewStockAdj,
        adjNo,
        selectedReasonData,
        setselectedReasonData,
        loading,
        rowData,
        handleSaveApproved,
        viewWhData,
    } = useStockAdjustmentContext();


    const [searchParams] = useSearchParams();
    let stkAdjHdrId = searchParams.get("stk_adj_hdr_id");



    useEffect(() => {
        if (stkAdjHdrId != null) {
            ViewStockAdj(stkAdjHdrId);
            setViewStock(true);
        }
        else {
            setPopulateProductDataTable([]);
        }
    }, [1]);

    console.log("dfdf", selectedReasonData);
    console.log("dfdf", viewStock);
    const handleAddRow = () => {
        if (selectedReasonData == "") {
            toast.error(
                "Reason cannot be empty.",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                }

            );
            return;
        }
        setInfoPopup(true);
        StockTypeApi();
    };


    useEffect(() => {
        const WareHouseAPI =  () => {
            createAPIEndpointSalesReturn()
                .getDefaultValue(`${EndpointSalesReturn.WhDefaultVal}?CompId=${auth.CompId}&UserId=${auth.UserId}`)
                .then((ress)=>{
                    createAPIEndpoint()
                        .get(`${Endpoint.GetAllWarehouseData}?UserId=${auth.UserId}&CompId=${auth.CompId}`)
                        .then((res) => {
                            const warehouseW1 = res.data.Data.find((val)=>{
                                return val.MASK_WH_ID===ress.data.Id;
                            });

                            setselectedWHData(res.data.Data.length > 0 ? warehouseW1 : "");
                            setAllWarehouseData(res.data.Data);
                        });})
                .catch((error) => {
                    console.log(error);
                });
        };


        createAPIEndpoint()
            .get(`${Endpoint.GetDefaultWHData}?DefiId=3`)
            .then((res) => {
                console.log("DefaultWH", res.data);
            })
            .catch((error) => {
                console.log(error);
            });

        createAPIEndpoint()
            .get(`${Endpoint.GetAllReasonData}`)
            .then((res) => {
                console.log("Reason", res.data);
                setReasonData(res.data.Data);
            })
            .catch((error) => {
                console.log(error);
            });
        WareHouseAPI();
        setselectedReasonData("");
        // setViewWhData;
    }, [1]);

    const WHTemplate = (filtered) => {
        return (
            <>
                {filtered.CODE}{" "}-{" "}{filtered.DESCRIPTION}
            </>
        );
    };
    const ReasonTemplate = (filtered) => {
        return (
            <>
                {filtered.REASON_CODE}{" "}-{" "}{filtered.REASON_DESC}
            </>
        );
    };

    const handleSave = () => {
        console.log("caloing from handle save");

        let submitObj = {
            Date: currentDate,
            Warehouse: selectedWHData,
            Reason: selectedReasonData,
        };
        newStockAdjustedSave(submitObj);
    };


    const handleDeletecart = (rowData) => {
        console.log("rowww", rowData);
        const deletedData = [];
        const updatedData = populateProductDataTable.filter((item) => {
            if (item.pvcSTK_PROD_ID !== rowData.pvcSTK_PROD_ID) {
                return true;
            } else if (item.pvcSTK_BATCH_NO !== rowData.pvcSTK_BATCH_NO) {
                return true;
            } else {
                deletedData.push(item);
                return false;
            }
        });
        setPopulateProductDataTable(updatedData);
    };

    const renderActions = (rowData) => {
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" onClick={() => handleDeletecart(rowData)} data-placement="bottom" title="Delete">
                        <button className="grid-icon">
                            <Ci.CiTrash className="cancel" />
                        </button>
                    </div>
                </div>
            </>
        );
    };

    if (loading) {
        return (
            <>
                <Loader />
            </>
        );
    }
    else {
        return (
            <div className="col-12 pt-4 pb-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mx-1">
                                <li className="breadcrumb-item">
                                    <Link to="/inventorymanagement/stockadjustment">Stock Adjustment</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Details</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end">
                            <Link to="/inventorymanagement/stockadjustment">
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary me-3"
                                >
                                    <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                      Records
                                </button>
                            </Link>
                            <button
                                disabled={rowData.MASK_STATUS == "Approved" ? false : true}
                                type="submit"
                                className="btn btn-primary"
                                onClick={() => handleSaveApproved()}
                            >
                    Save
                            </button>
                        </div>
                    </div>
                </div>

                <section className="sales-box">
                    <form>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Adjustment Number</label>
                                <InputText value={viewStock ? adjNo : ""} disabled />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Date</label>
                                <InputText value={currentDate} disabled />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Warehouse</label>
                                {viewStock ? (
                                    <InputText value={viewWhData} disabled />
                                ) : (
                                    <Dropdown
                                        name="DESCRIPTION"
                                        options={allWarehouseData}
                                        optionLabel="DESCRIPTION"
                                        value={selectedWHData}
                                        onChange={(e) => {
                                            setselectedWHData(e.target.value);
                                        }}
                                        dropdown={true}
                                        itemTemplate={WHTemplate}
                                        emptyMessage="No results found."
                                        showEmptyMessage
                                    />
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Reason</label>
                                {viewStock ? (
                                    <InputText value={selectedReasonData} disabled />
                                ) : (
                                    <Dropdown
                                        name="reason"
                                        value={selectedReasonData}
                                        options={reasonData}
                                        optionLabel="REASON_DESC"
                                        onChange={(e) => {
                                            setselectedReasonData(e.target.value);
                                        }}
                                        // disabled={viewStock?true:false}
                                        dropdown={true}
                                        itemTemplate={ReasonTemplate}
                                        emptyMessage="No results found."
                                        showEmptyMessage
                                    />
                                )}
                            </div>
                            <div>
                                {viewStock ? null : (
                                    <div className="float-end my-2">
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary me-3"
                                            onClick={handleAddRow}
                                        >
                          Add Row
                                        </button>
                                        <button
                                            type="button"
                                            disabled={
                                                populateProductDataTable.length == 0 ? true : false
                                            }
                                            className="btn btn-primary"
                                            onClick={handleSave}
                                        >
                          Submit
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                </section>
                <section className="">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3>Records</h3>
                        <div className="col-md-12 col-lg-12 col-sm-12">
                            <div className="DataTable pb-4 pt-1">
                                <DataTable
                                    value={populateProductDataTable}
                                    dataKey="product_code"
                                    showGridlines
                                    stripedRows
                                    tableStyle={{ minWidth: "60rem" }}
                                >
                                    <Column field="Stock_Type" header="Stock Type"></Column>
                                    <Column
                                        field={
                                            viewStock ? "ProductCode_Desc" : "pvcSTK_PROD_ID"
                                        }
                                        header="Product Code"
                                    ></Column>
                                    <Column
                                        field={viewStock ? "Product" : "pvcPROD_SMALL_DESC"}
                                        header="Product Description"
                                    ></Column>
                                    <Column
                                        field={viewStock ? "Batch_No" : "pvcSTK_BATCH_NO"}
                                        header="Product Lot Number"
                                    ></Column>
                                    <Column
                                        field={
                                            viewStock ? "Quantity_Pack" : "pnmSTK_PHYSICAL_QTY"
                                        }
                                        header="Adjusted Quantity"
                                    ></Column>
                                    <Column
                                        field={
                                            viewStock ? "Quantity_Pack" : "pnmSTK_PHYSICAL_QTY"
                                        }
                                        header="Physical Quantity"
                                    ></Column>
                                    {viewStock ? null : (
                                        <Column body={renderActions} header="Action "></Column>
                                    )}
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </section>

                <InformationModal />
            </div>
        );
    }
};

export default NewStock;