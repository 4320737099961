import Axios from "../../api/Axios";
const BASE_URL = "PaymentAccDefination/";
export const Endpoint = {
    PopulateBankData: "PopulateBankData",
    PopulateCashData: "PopulateCashData",
    UploadData: "UploadData",
};

export const createAPIEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};
