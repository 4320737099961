import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Fa from "react-icons/fa";
import { AutoComplete } from "primereact/autocomplete";
import { InputSwitch } from "primereact/inputswitch";
import { EndpointPurchaseReturn, createAPIEndpointPurchaseReturn } from "../../Services/PurchaseReturn/PurchaseReturnService";
import storage from "../../Services/Storage";
import { useEffect } from "react";
import { useFormik } from "formik";
import { PurchaseReturnInitialVal } from "../../Validations/PurchaseReturnValidation";
import GetInvoiceModal from "./GetInvoiceModal";
import { usePurchaseReturnContext } from "../../context/PurchaseReturnContext";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { EndpointSalesReturn, createAPIEndpointSalesReturn } from "../../Services/SalesReturnAdd/SalesReturnServices";
import Loader from "../../Components/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const PurchaseReturnDetails = () => {
    const auth = storage.getLocal("user");
    const [supplierName, setsupplierName] = useState([]);
    const [supplierSugg, setsupplierSugg] = useState([]);
    const [reasonDesc, setReasonDesc] = useState([]);
    const [reasonDescSugg, setReasonDescSugg] = useState([]);
    const [disableProduct, setDisableProduct] = useState(false);
    const [viewObject, setViewObject] = useState([]);
    
    const {
        setMatchingDocPopUp,
        supplierDisable,
        setGRNAllData,
        selectedData,
        product,
        populateDataTable,
        setPopulateDataTable,
        setSupplierId,
        matchingDocDetails, 
        employee, 
        setEmployee,
        getPurchaseReturn,
        viewPurchaseReturn,
        setViewPurchaseReturn,
        toDate,
        fromDate,
        isLoading, 
        setIsLoading
    } = usePurchaseReturnContext();

    const [searchParam] = useSearchParams();
    const DrCrId=searchParam.get("id");
    const date=searchParam.get("date");
    const nav = useNavigate();
    const currentDate = format(new Date(), "dd/MM/yyyy");

    // filters autoComplete
    function SupplierNamefilter(e) {
        setTimeout(() => {
            let _filteredData;
            if (!e.query.trim().length) {
                if (supplierName.length > 0) {
                    _filteredData = [...supplierName];
                }
            } else {
                _filteredData = supplierName?.filter((val) => {
                    return (
                        val?.CODE.toLowerCase().includes(e.query.toLowerCase()) ||
                        val?.NAME.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }
            setsupplierSugg(_filteredData);
        }, 250);
    }

    function ReasonDescFilter(e) {
        setTimeout(() => {
            let _filteredData;
            if (!e.query.trim().length) {
                if (reasonDesc?.length > 0) {
                    _filteredData = [...reasonDesc];
                }
            } else {
                _filteredData = reasonDesc?.filter((val) => {
                    return (
                        val?.CODE.toLowerCase().includes(e.query.toLowerCase()) ||
                        val?.DESCRIPTION.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }
            setReasonDescSugg(_filteredData);
        }, 250);
    }




    //item templates
    const itemTemplateReason = (filterval) => {

        return (

            <>{filterval.CODE} : {filterval.DESCRIPTION}</>
        );
    };

    const itemTemplateSupplier = (filterval) => {

        return (

            <>{filterval.CODE} : {filterval.NAME}</>
        );
    };

    const itemTemplateprod = (filterval) => {
        return (
            <>{filterval.PRODUCT_CODE} : {filterval.PRODUCT}</>
        );
    };



    //formik 
    const formik = useFormik({
        initialValues: PurchaseReturnInitialVal,
        onSubmit: (data) => {
            handleSubmit(data);
        },
        validate: (data) => {
            const errors = {};
            //only character no number or special character
            if (!data.supplier_name) {
                errors.supplier_name = "Supplier name is required";
            }
            if (!data.matching_doc_no) {
                errors.matching_doc_no = "Matching doucument is required";
            }
            // if (!data.warehouse) {
            //     errors.warehouse = "Warehouse is required";
            // }
            if (!data.reason_desc) {
                errors.reason_desc = "Reason Description is required";
            }
            if (!data.product_code) {
                errors.product_code = "Product Code is required";
            }
            if (!data.returned_quantity) {
                errors.returned_quantity = "Total Return quantity is required";
            }
            else if (data.returned_quantity) {
                if (Number(data.returned_quantity) > Number(data.recieved_quantity)) {
                    errors.returned_quantity = "Returned quantity cannot be greater.";
                }
            }
            return errors;
        },
    });

    const numRegex=/^\d+$/;

    if (viewPurchaseReturn) {

        formik.initialValues.supplier_code = viewObject.MASK_PARTY_CODE;
        formik.initialValues.debit_note_no = viewObject.GRN_NO;
        formik.initialValues.debit_note_date = viewObject.INVH_DATE;
        formik.initialValues.supplier_name = viewObject.SUPP_NAME;
        formik.initialValues.matching_doc_no = viewObject.MASK_MATCH_DOC_NO;
        formik.initialValues.matching_doc_date = viewObject.MASK_MATCH_DOC_DATE;
        formik.initialValues.warehouse = viewObject.MASK_WH_DESC;
        formik.initialValues.reason_desc = viewObject?.MASK_REAS_DESC;
        formik.initialValues.print_message = viewObject.MASK_PRINT_MSG;
        formik.initialValues.total_amount = viewObject?.MASK_INVH_TOT_AMT;
    }
    else {
        formik.initialValues.debit_note_no = "";
        formik.initialValues.debit_note_date = "";
        formik.initialValues.supplier_name = "";
        formik.initialValues.matching_doc_no = "";
        formik.initialValues.matching_doc_date = "";
        // formik.initialValues.warehouse="";
        formik.initialValues.reason_desc = "";
        formik.initialValues.print_message = "";
        formik.initialValues.total_amount = "";

    }

    //end Formik

    // useEffects
    useEffect(() => {
        const PageLoad = async () => {
            await createAPIEndpointSalesReturn()
                .getDefaultValue(`${EndpointSalesReturn.WhDefaultVal}?CompId=${auth.CompId}&UserId=${auth.UserId}`)
                .then((response) => {
                    createAPIEndpointSalesReturn()
                        .get(`${EndpointSalesReturn.GetWareHouse}?userId=${auth.UserId}&CompId=${auth.CompId}`)
                        .then((res) => {
                            PurchaseReturnInitialVal.warehouse = res?.data?.Data.find((val) => {
                                return val?.MASK_WH_ID === response?.data?.Id;
                            });
                        })
                        .catch((error) => {
                            toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 3000,
                            });
                            console.log(error);
                        });
                })
                .catch((error) => {
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                });
            createAPIEndpointPurchaseReturn()
                .get(`${EndpointPurchaseReturn.GetSupplier}?CompId=${auth.CompId}`)
                .then((res) => {
                    setsupplierName(res?.data?.Data);
                })
                .catch((error) => {
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                });
            createAPIEndpointPurchaseReturn()
                .get(`${EndpointPurchaseReturn.GetReasonDesc}?CompId=${auth.CompId}`)
                .then((res) => {
                    setReasonDesc(res?.data?.Data);
                })
                .catch((error) => {
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                });
            createAPIEndpointPurchaseReturn()
                .get(`${EndpointPurchaseReturn.GetEmployee}?UserId=${auth.UserId}&CompId=${auth.CompId}`)
                .then((res)=>{
                    setEmployee(res?.data?.Data[0]);
                });

        };
        PageLoad();
    }, [1]);

    useEffect(() => {
        if (formik.values.full_grn == true) {
            formik.touched.returned_quantity = false;
            formik.touched.product_code = false;
            setDisableProduct(true);
            setIsLoading(true);
            formik.setFieldValue("product_code", "");
            formik.values.product_description = "";
            formik.setFieldValue("product_description", "");
            formik.values.recieved_quantity = "";
            formik.setFieldValue("recieved_quantity", "");
            formik.values.returned_quantity = "";
            formik.setFieldValue("returned_quantity", "");

            createAPIEndpointPurchaseReturn()
                .get(`${EndpointPurchaseReturn.GetPurchaseRetDetails}?DNId=${selectedData.MASK_GRN_ID}`)
                .then((res) => {
                    toast.success("Product added to the cart.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    setPopulateDataTable(res.data.Data);
                    setIsLoading(false);
                })
                .catch((error)=>{
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                    setIsLoading(false);
                });
        }
        else {
            setDisableProduct(false);
            setIsLoading(false);
            setPopulateDataTable([]);
        }
    }, [formik.values.full_grn]);

    useEffect(()=>{
        if(DrCrId!=null){
            setIsLoading(true);
            createAPIEndpointPurchaseReturn()
                .get(`${EndpointPurchaseReturn.GetAllPurchaseReturnRecord}?UserId=${auth.UserId}&CompId=${auth.CompId}&fromDate=${date}&toDate=${date}`)
                .then((res) => {
                    let filterdata = res.data.Data.find((item) => {
                        return item.MASK_GRN_ID === DrCrId;
                    });
                    setViewObject(filterdata);
                    setIsLoading(false);
                })
                .catch((error)=>{
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                    setIsLoading(false);
                });
            setViewPurchaseReturn(true);
            createAPIEndpointPurchaseReturn()
                .get(`${EndpointPurchaseReturn.GetPurchaseRetDetails}?DNId=${DrCrId}`)
                .then((res) => {
                    setPopulateDataTable(res.data.Data);
                })
                .catch((error)=>{
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    console.log(error);
                });
        }
        else{
            setViewPurchaseReturn(false);
        }

    },[]);

    //post functions
    const handleSubmit = (data) => {
        let prdDtl = {
            compId: auth.CompId,
            productDescription: data?.product_description,
            productCode: data.product_code.MASK_PROD_ID,
            batchNo: data.product_code.mask_PROD_BATCH_NO,
            totalReturnQty: data.returned_quantity,
            totalOrderQty: data.recieved_quantity,
            evaluatedBy: "",
            divnId: selectedData?.mask_DIVN_ID,
            refGrndId:data.product_code?.MASK_REF_GRND_ID,
            warehouseId: data.warehouse.MASK_WH_ID,
            stkType: data.product_code.MASK_GRND_STK_TYPE_ID,
            maxQty4Return: data.product_code.MASK_GRND_PACK_RECV_QTY,
            purchaseRetDetailsCount: (populateDataTable.length + 1).toString(),
            objDetailData: {
                price: data.product_code.mask_GRND_PROD_PRICE,
                caseFactor: data.product_code.mask_CASE_FACTOR,
                caseRecvQty: data.product_code.MASK_GRND_CASE_RECV_QTY,
                packRecvQty: data.product_code.MASK_GRND_PACK_RECV_QTY,
                cdrDisc: data?.product_code?.mask_grnd_cdr_disc.toString(),
                totGrnQty: data?.product_code?.mask_tot_grn_qty.toString(),
                totRecvQty: data?.returned_quantity,
                tradeDisc: data?.product_code?.MASK_GRND_TRADE_DISC.toString(),
                basePrice: data.product_code.GRND_PROD_BASE_PRICE,
                totalTax: data?.product_code?.MASK_GRND_TOT_TAX.toString(),
                totalVatTax: data?.product_code?.MASK_GRND_TOT_VAT.toString(),
                mopDisc: data?.product_code?.mask_grnd_mop_val.toString(),
                spclConsTax: data?.product_code?.MASK_GRND_SPLCON_TAX.toString(),
                productValue: data?.product_code?.MASK_GRND_PROD_VAL,
                valueInBaseCurrency: data?.product_code?.MASK_GRND_VAL_BASE_CRNC.toString(),
                extendedPrice: data?.product_code?.mask_EXTENTED_PRICE
            }
        };
        setIsLoading(true);
        // return
        createAPIEndpointPurchaseReturn()
            .post(`${EndpointPurchaseReturn.AddToCart}`,
                JSON.stringify(prdDtl),
                {
                    headers: { "Content-Type": "application/json" },
                })
            .then((res) => {
                const maskProdId = res?.data?.objDetl?.PROD_CODE;
                let isDuplicateID = populateDataTable.some(item => {
                    if (item?.PROD_CODE === maskProdId) {
                        if (item?.PROD_BATCH_NO === res?.data?.objDetl?.PROD_BATCH_NO)
                            return true;
                        else
                            return false;
                    }
                    else
                        return false;
                });

                if (isDuplicateID) {
                    toast.error(
                        "Product already added to the cart.",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }

                    );
                    isDuplicateID = false;
                }
                else {
                    toast.success(
                        "Product added to the cart.",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }

                    );
                    setPopulateDataTable((prevState) => [...prevState, res?.data?.objDetl]);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                toast.error(error.response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                console.log(error);
                setIsLoading(false);

            });

        formik.values.product_code = "";
        formik.values.product_description = "";
        formik.values.recieved_quantity = "";
        formik.values.returned_quantity = "";
    };

    const savePurchaseReturn = (data)=>{

        const oblDtlData = populateDataTable.map((a)=>({
            masK_PROD_ID: a.MASK_PROD_ID,
            proD_CODE: a.PROD_CODE,
            proD_SMALL_DESC: a.PROD_SMALL_DESC,
            proD_BATCH_NO: a.PROD_BATCH_NO,
            pricE_LIST_NO: a.PRICE_LIST_NO,
            grnD_CASE_UOM_CODE: a.GRND_CASE_UOM_CODE,
            masK_CASE_FACTOR: a.MASK_CASE_FACTOR,
            grnD_CASE_RECV_QTY: a.GRND_CASE_RECV_QTY,
            grnD_PACK_RECV_QTY: a.GRND_PACK_RECV_QTY,
            toT_RECV_QTY: a.TOT_RECV_QTY,
            grnD_PROD_PRICE: a.GRND_PROD_PRICE,
            grnD_PROD_BASE_PRICE: a.GRND_PROD_BASE_PRICE,
            extenteD_PRICE: a.EXTENTED_PRICE,
            masK_GRND_PROD_VAL: a.MASK_GRND_PROD_VAL,
            masK_GRND_PODT_ID: a.MASK_GRND_PODT_ID,
            masK_PROD_ID1: a.MASK_PROD_ID,
            masK_PROD_DESC: a.MASK_PROD_DESC,
            masK_PACK_UOM_CODE: a.MASK_PACK_UOM_CODE,
            masK_GRND_TOT_TAX: a.MASK_GRND_TOT_TAX,
            masK_GRND_TRADE_DISC: a.MASK_GRND_TRADE_DISC,
            masK_GRND_VAL_BASE_CRNC: a.MASK_GRND_VAL_BASE_CRNC,
            masK_GRND_TOT_VAT: a.MASK_GRND_TOT_VAT,
            masK_REF_GRN_DETL_ID: a.MASK_REF_GRN_DETL_ID,
            masK_DROP_QTY: a.MASK_DROP_QTY,
            masK_TO_RECV_QTY: a.MASK_TO_RECV_QTY,
            masK_OTHER_CHARGES: a.MASK_OTHER_CHARGES,
            masK_GROSS_WEIGHT: a.MASK_GROSS_WEIGHT,
            masK_TOT_RECV_QTY: a.MASK_TOT_RECV_QTY,
            masK_FREIGHT_CHARGES: a.MASK_FREIGHT_CHARGES,
            masK_STCK_OTHER_CHARGE: a.MASK_STCK_OTHER_CHARGE,
            masK_STCK_FREIGHT_CHARGE: a.MASK_STCK_FREIGHT_CHARGE,
            masK_PRICE_CALCULATED: a.MASK_PRICE_CALCULATED,
            masK_SUBSTITUTE_PROD: a.MASK_SUBSTITUTE_PROD,
            masK_GRND_ID: a.MASK_GRND_ID,
            masK_TOT_GRN_QTY: a.MASK_MAX_GRN_QTY,
            masK_GRND_CDR_DISC: a.MASK_GRND_CDR_DISC,
            masK_GRND_MOP_VAL: a.MASK_GRND_MOP_VAL,
            masK_MAX_GRN_QTY: a.MASK_MAX_GRN_QTY,
            masK_CFOP_ID: a.CFOP_CODE,
            cfoP_CODE: a.MASK_CFOP_ID,
            cfoP_DESCRIPTION: a.CFOP_DESCRIPTION,
            masK_GRND_STK_TYPE_ID: a.MASK_GRND_STK_TYPE_ID,
            masK_GRND_SPLCON_TAX:a.MASK_GRND_SPLCON_TAX 
        }));

        let submitObj = {
            userId: auth.UserId,
            compId: auth.CompId,
            objHdrData: {
                debitNo: "",
                debitDate: data.debit_note_date,
                reasDesc: data.reason_desc?.DESCRIPTION,
                evaluatedBy: matchingDocDetails?.objDocData?.strEvaluatedBy,
                divnId: matchingDocDetails?.objDocData?.strDivnId,
                wareHouseId: data?.warehouse?.MASK_WH_ID,
                grnNo: data?.matching_doc_no,
                grnHdrId: selectedData?.MASK_GRN_ID,
                grnDate: data?.matching_doc_date,
                baseCurrTotAmt: data?.total_amount.toString(),
                totAmount: data?.total_amount.toString(),
                empId: employee?.MASK_EMP_ID,
                print: data?.print_message,
                reasonCode: data?.reason_desc?.CODE,
                roundingOffAmt:(populateDataTable.length > 0 ? ((
                    populateDataTable.map((a) => Number(a.GRND_PROD_BASE_PRICE))
                        .reduce((a, b) => a + b,0) -
                    populateDataTable.map((a) => Number(a.MASK_GRND_CDR_DISC))
                        .reduce((a, b) => a + b,0) -
                    populateDataTable.map((a) => Number(a.MASK_GRND_MOP_VAL))
                        .reduce((a, b) => a + b,0) -
                    populateDataTable.map((a) => Number(a.MASK_GRND_SPLCON_TAX))
                        .reduce((a, b) => a + b,0) +
                    populateDataTable.map((a) => Number(a.MASK_GRND_TOT_TAX))
                        .reduce((a, b) => a + b,0) +
                    populateDataTable.map((a) => Number(a.MASK_GRND_TOT_VAT))
                        .reduce((a, b) => a + b,0)
                ) - (
                    Math.round(
                        populateDataTable.map((a) => Number(a.GRND_PROD_BASE_PRICE))
                            .reduce((a, b) => a + b,0) -
                populateDataTable.map((a) => Number(a.MASK_GRND_CDR_DISC))
                    .reduce((a, b) => a + b,0) -
                populateDataTable.map((a) => Number(a.MASK_GRND_MOP_VAL))
                    .reduce((a, b) => a + b,0) -
                populateDataTable.map((a) => Number(a.MASK_GRND_SPLCON_TAX))
                    .reduce((a, b) => a + b,0) +
                populateDataTable.map((a) => Number(a.MASK_GRND_TOT_TAX))
                    .reduce((a, b) => a + b,0) +
                populateDataTable.map((a) => Number(a.MASK_GRND_TOT_VAT))
                    .reduce((a, b) => a + b,0)
                    ))):0.0).toString(),
                branchId: data?.warehouse?.MASK_BRANCH_ID,
                diffAmt: matchingDocDetails?.DataSt[0]?.MASK_RET_AMT,
                locid : matchingDocDetails?.objDocData?.strLocId
            },
            oblDtlData :oblDtlData
        };
        setIsLoading(true);
        // return
        createAPIEndpointPurchaseReturn()
            .post(`${EndpointPurchaseReturn.Save}`,
                JSON.stringify(submitObj),
                {
                    headers: { "Content-Type": "application/json" },
                })
            .then((res) => {
                // console.log("saveData",res.data);
                if(res?.data?.Status==="Success"){
                    setIsLoading(false);
                    nav("/purchasemanagement/purchasereturn");
                    const fDate = new Date(fromDate);
                    const fromDateRange = format(fDate, "dd/MM/yyyy");
                    const tDate = new Date(toDate);
                    const toDateRange = format(tDate, "dd/MM/yyyy");
                    
                    getPurchaseReturn(fromDateRange, toDateRange);
                    toast.success(`${res?.data?.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    
                }
            })
            .catch((error)=>{
                setIsLoading(false);
                toast.error(error.response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                console.log(error);
            });
                
    };

    //other functions
    const productDistribution = (e) => {
        formik.setFieldValue("product_description", e.target.value.PRODUCT);
        formik.setFieldValue("recieved_quantity", e.target.value.TOT_ORD_QTY);

    };

    const handleSupplierSelect = (data) => {
        if(typeof data !=="object"){
            return true;
        }
        else{
            if(data){
                setSupplierId(data?.MASK_SUPP_ID);
                createAPIEndpointPurchaseReturn()
                    .get(`${EndpointPurchaseReturn.GetMatchingDocuments}?strSupplier=${data?.MASK_SUPP_ID}&UserId=${auth.UserId}`)
                    .then((res) => {
                        setGRNAllData(res?.data?.Data);
                        setMatchingDocPopUp(true);
                    });
            }
            else{
                return false;
            }
        }
    };

    const handleDeletecart = (rowData) => {
        formik.setFieldValue("full_grn",false);
        const updatedData = populateDataTable.filter(item => item?.PROD_CODE !== rowData?.PROD_CODE);
        setPopulateDataTable(updatedData);
    };

    const renderActions = (rowData) => {

        return (
            <div>
                <button
                    className="grid-icon"
                    onClick={() => handleDeletecart(rowData)}
                >
                    <Fa.FaTimes className="cancel" />
                </button>
            </div>
        );
    };



    return (
        <div className="col-12 pt-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mx-1">
                            <li className="breadcrumb-item">
                                <Link to="/purchasemanagement/purchasereturn">Purchase Return</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Details</li>
                        </ol>
                    </nav>

                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <Link to="/purchasemanagement/purchasereturn"><button type="submit" className="btn btn-primary">
                            <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                        </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="">
                <form onSubmit={formik.handleSubmit}>
                    <section className="sales-box">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Debit Note No <span className="mandatory">*</span></label>
                                <InputText
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.debit_note_no}
                                    disabled />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Debit Note Date <span className="mandatory">*</span></label>
                                <InputText
                                    className="form-control"
                                    placeholder=""
                                    value={formik.values.debit_note_date = currentDate}
                                    disabled />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Supplier Code <span className="mandatory">*</span></label>
                                <InputText
                                    className="form-control"
                                    placeholder=""
                                    value={formik.values.supplier_code}
                                    disabled />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Supplier Name <span className="mandatory">*</span></label>
                                <div className="d-block">
                                    <AutoComplete
                                        field="NAME"
                                        name="supplier_name"
                                        // placeholder="Select Supplier Name"
                                        value={formik.values.supplier_name}
                                        suggestions={supplierSugg}
                                        onChange={(e) => {
                                            handleSupplierSelect(e.value);
                                            formik.handleChange;
                                            formik.setFieldValue("full_grn", false);
                                            formik.setFieldValue("supplier_name", e.value);
                                            formik.setFieldValue("supplier_code", e.value.CODE);

                                        }}
                                        onBlur={()=>{formik.handleBlur;}}
                                        completeMethod={SupplierNamefilter}
                                        itemTemplate={itemTemplateSupplier}
                                        dropdown
                                        disabled={viewPurchaseReturn}
                                        emptyMessage="Supplier name not found."
                                        showEmptyMessage
                                    />
                                    {formik.touched.supplier_name && formik.errors.supplier_name && (
                                        <small className="p-error">{formik.errors.supplier_name}</small>
                                    )}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Matching Doc Number <span className="mandatory">*</span></label>
                                <div className="input-group">
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        value={selectedData.length !== 0 ? formik.values.matching_doc_no = selectedData?.GRN_NO : formik.values.matching_doc_no}
                                        placeholder=""
                                        disabled
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                    <div className="input-group-prepend">
                                        <button
                                            className={!supplierDisable ? "GetInvoiced ms-2" : "input-group-text ms-2"}
                                            type="button"
                                            disabled={!supplierDisable || viewPurchaseReturn}
                                            onClick={() => {
                                                setMatchingDocPopUp(true);
                                                formik.setFieldValue("full_grn", false);
                                                setPopulateDataTable([]);
                                            }}
                                        >
                                            Get GRN
                                        </button>
                                    </div>
                                </div>
                                {formik.touched.matching_doc_no && formik.errors.matching_doc_no && (
                                    <small className="p-error">{formik.errors.matching_doc_no}</small>
                                )}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Matching Doc Date <span className="mandatory">*</span></label>
                                <InputText className="form-control" placeholder=""
                                    value={selectedData.length !== 0 ? formik.values.matching_doc_date = selectedData.MASK_GRN_DATE : formik.values.matching_doc_date}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Warehouse <span className="mandatory">*</span></label>
                                <div className="d-block">
                                    <AutoComplete
                                        field="DESCRIPTION"
                                        value={formik.values.warehouse}
                                        disabled
                                        dropdown
                                    />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Reason Description <span className="mandatory">*</span>
                                </label>
                                <div className="d-block">
                                    <AutoComplete
                                        field="DESCRIPTION"
                                        name="reason_description"
                                        // placeholder="Select Reason Description"
                                        value={formik.values.reason_desc}
                                        suggestions={reasonDescSugg}
                                        onChange={(e) => {
                                            formik.handleChange;
                                            // handleReasonData(e);
                                            formik.setFieldValue(
                                                "reason_desc",
                                                e.target.value
                                            );
                                        }}
                                        onBlur={formik.handleBlur}
                                        completeMethod={ReasonDescFilter}
                                        itemTemplate={itemTemplateReason}
                                        dropdown
                                        disabled={viewPurchaseReturn}
                                    />
                                    {formik.touched.reason_desc && formik.errors.reason_desc && (
                                        <small className="p-error">{formik.errors.reason_desc}</small>
                                    )}
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Total Amount</label>
                                <InputText
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={
                                        formik.values.total_amount =
                                        populateDataTable.length > 0 ? ((
                                            Math.round(
                                                populateDataTable.map((a) => Number(a.GRND_PROD_BASE_PRICE))
                                                    .reduce((a, b) => a + b,0) -
                                            populateDataTable.map((a) => Number(a.MASK_GRND_CDR_DISC))
                                                .reduce((a, b) => a + b,0) -
                                            populateDataTable.map((a) => Number(a.MASK_GRND_MOP_VAL))
                                                .reduce((a, b) => a + b,0) -
                                            populateDataTable.map((a) => Number(a.MASK_GRND_SPLCON_TAX))
                                                .reduce((a, b) => a + b,0) +
                                            populateDataTable.map((a) => Number(a.MASK_GRND_TOT_TAX))
                                                .reduce((a, b) => a + b,0) +
                                            populateDataTable.map((a) => Number(a.MASK_GRND_TOT_VAT))
                                                .reduce((a, b) => a + b,0)
                                            ))).toLocaleString("en-IN", { minimumFractionDigits: 2 }):"0.00"
                                    }
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Print Message</label>
                                <InputText
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.print_message}
                                    onChange={(e) => {
                                        formik.setFieldValue("print_message", e.target.value);
                                    }}
                                    disabled={viewPurchaseReturn}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mt-3">
                                <label className="form-label">
                                    Full GRN <span className="mandatory">*</span>
                                </label>
                                <label className="d-inline ms-2">
                                    <InputSwitch
                                        className="switch-pos"
                                        id="full_grn"
                                        name="full_grn"
                                        checked={formik.values.full_grn}
                                        onChange={(e) => {
                                            if (formik.values.reason_desc == "") {
                                                toast.error(
                                                    "Please select a Reason.",
                                                    {
                                                        position: toast.POSITION.TOP_CENTER,
                                                        pauseOnFocusLoss: false,
                                                        closeButton: false,
                                                        autoClose: 2000,
                                                    }

                                                );
                                            }
                                            else {
                                                formik.setFieldValue("full_grn", e.value);
                                            }
                                        }}
                                        disabled={(formik.values.supplier_name == "" || viewPurchaseReturn ) ? true : false}
                                    />
                                </label>
                            </div>
                        </div>
                    </section>

                    <section className="sales-box">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Product Code</label>
                                <Dropdown
                                    name="DESCRIPTION"
                                    options={product}
                                    optionLabel="MASK_PROD_ID"
                                    value={formik.values.product_code}
                                    onChange={(e) => {
                                        formik.handleChange;
                                        productDistribution(e);
                                        formik.setFieldValue("product_code", e.target.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={itemTemplateprod}
                                    emptyMessage='No products found.'
                                    showEmptyMessage
                                    disabled={disableProduct || viewPurchaseReturn}
                                />
                                {formik.touched.product_code && formik.errors.product_code && (
                                    <small className="p-error">{formik.errors.product_code}</small>
                                )}
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Recieved Quantity</label>
                                <InputText
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.recieved_quantity ? Number(formik.values.recieved_quantity).toFixed(2) : ""}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Product Description</label>
                                <InputText
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.product_description}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Returned Quantity</label>
                                <InputText
                                    className="form-control"
                                    type="text"
                                    placeholder=""
                                    value={formik.values.returned_quantity}
                                    disabled={disableProduct || viewPurchaseReturn}
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                        formik.handleChange;
                                        if(!numRegex.test(e.target.value) || e.target.value=="0"){
                                            if(e.target.value==""){
                                                formik.setFieldValue(
                                                    "returned_quantity",
                                                    e.target.value
                                                );
                                            }
                                            else{
                                                return false;
                                            }
                                        }
                                        else{
                                            formik.setFieldValue(
                                                "returned_quantity",
                                                e.target.value
                                            );
                                        }
                                        
                                    }}
                                    
                                />
                                {formik.touched.returned_quantity && formik.errors.returned_quantity && (
                                    <small className="p-error">{formik.errors.returned_quantity}</small>
                                )}
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                {!viewPurchaseReturn && <div className="float-end mt-2">
                                    <button disabled={disableProduct || viewPurchaseReturn} type="submit" className="btn btn-outline-primary me-3">
                                        Add To Cart
                                    </button>
                                    <button disabled={viewPurchaseReturn || populateDataTable.length==0} type="button" className="btn btn-primary" onClick={()=>savePurchaseReturn(formik.values)}>
                                        Save
                                    </button>
                                </div>}
                            </div>
                        </div>
                    </section>
                </form>
            </div>
            <div>
                {isLoading ? <Loader /> : <section className="mt-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <h3>All Records</h3>
                        <div className="DataTable py-2">
                            <DataTable
                                value={populateDataTable}
                                dataKey='productCode'
                                tableStyle={{ minWidth: "60rem" }}
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                showGridlines
                                stripedRows>
                                <Column field="MASK_PROD_ID" header="Product Code"></Column>
                                <Column field="PROD_SMALL_DESC" header="Product Description"></Column>
                                <Column field="PROD_BATCH_NO" header="Product Lot Number"></Column>
                                <Column field="TOT_RECV_QTY" header="Total Quantity"></Column>
                                <Column field="GRND_PROD_PRICE" header="Product Price"></Column>
                                <Column field="EXTENTED_PRICE" header="Extended Price"></Column>
                                {!viewPurchaseReturn &&<Column body={renderActions} header="Action "></Column>}
                            </DataTable>
                        </div>
                    </div>
                </section>}
            </div>
            <GetInvoiceModal />
        </div>
    );
};

export default PurchaseReturnDetails;