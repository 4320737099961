import Axios from "../../api/Axios";
const BASE_URL = "DSRDealerMapping/";
export const Endpoint = {
    UploadData: "UploadData",
    ExportData: "ExportData",
};

export const createAPIEndpointDSR = () => {
    return { get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint,data)=> Axios.post(BASE_URL+Endpoint,data)
    };
};
