// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React from "react";
import { AutoComplete } from "primereact/autocomplete";
import { useCustMasterContext } from "../../context/CustomerMasterContext";
import { toast } from "react-toastify";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";

const SegmentModalNewCust = () => {
    const {
        show,
        setShow,
        handleSegmentStream,
        segmentStreamInput,
        segmentStreamFilter,
        segmentStreamSuggestion,
        handleSegmentChannel,
        segmentChannelInput,
        segmentChannelFilter,
        segmentChannelSuggestion,
        segmentSubChannelInput,
        segmentSubChannelFilter,
        segmentSubChannelSuggestion,
        handleSegmentSubChannel,
        handleSubSegmentDetail,
        subSegmentDetailInput,
        subSegmentDetailSuggestion,
        segmentSubDetailFilter,
        setSegmentStreamInput,
        setSegmentChannelInput,
        setSegmentSubChannelInput,
        setSubSegmentDetailInput,
        setManualSegmentEnterBtn,
        segmentConfirmBtn,
    } = useCustMasterContext();

    const handleClose = () => setShow(false);
    const StreamTemplate = (stream) => {
        return (
            <>
                {stream.STREAM_CHANNEL_DESC}&nbsp; 
            </>
        );
    };
    const ChannelTemplate = (channel) => {
        return (
            <>
                {channel.STREAM_CHANNEL_DESC}&nbsp;
            </>
        );
    };
    const SubChannelTemplate = (subchannel) => {
        return (
            <>
                {subchannel.STREAM_CHANNEL_DESC}&nbsp; 
            </>
        );
    };
    const SubSegmentTemplete = (subdetail) => {
        return (
            <>
                {subdetail.SEG_DESC}&nbsp; 
            </>
        );
    };

    return (
        <div>
            <>
                <div className="col-12 pt-3 pb-3">
                    <Modal
                        show={show}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">Segment</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                {/* <DataTable
                                            value={townList}
                                            paginator
                                            rows={10}
                                            rowsPerPageOptions={[10, 25, 50]}
                                            tableStyle={{ minWidth: "10rem" }}
                                            selectionMode="single"
                                            selection={selectedProduct}
                                            onSelectionChange={(e) => setSelectedProduct(e.value)}
                                            dataKey="GEOG_ID"
                                            //   selectionMode="single"
                                            //   selection={selectedProduct}
                                            //   onSelectionChange={(e) => setSelectedProduct(e.value)}
                                            //   dataKey="id"
                                            //   onRowSelect={onRowSelect}
                                            //   onRowUnselect={onRowUnselect}
                                            //   metaKeySelection={false}
                                            //   tableStyle={{ minWidth: "50rem" }}
                                        >
                                            <Column field="PIN_CODE" header="Name"></Column>
                                            <Column field="NAME" header="Code"></Column>
                                        </DataTable> */}
                                <label className="form-label"> Stream</label>
                                <AutoComplete
                                    field="STREAM_CHANNEL_DESC"
                                    value={segmentStreamInput}
                                    suggestions={segmentStreamSuggestion}
                                    completeMethod={segmentStreamFilter}
                                    onChange={(e) => {
                                        handleSegmentStream(e);
                                        setSegmentStreamInput(e.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={StreamTemplate}
                                    showEmptyMessage={true}
                                />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label className="form-label">  Channel</label>
                                <AutoComplete
                                    field="STREAM_CHANNEL_DESC"
                                    value={segmentChannelInput}
                                    suggestions={segmentChannelSuggestion}
                                    completeMethod={segmentChannelFilter}
                                    onChange={(e) => {
                                        handleSegmentChannel(e);
                                        setSegmentChannelInput(e.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={ChannelTemplate}
                                    showEmptyMessage={true}
                                />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label className="form-label">  Sub-Channel</label>
                                <AutoComplete
                                    field="STREAM_CHANNEL_DESC"
                                    value={segmentSubChannelInput}
                                    suggestions={segmentSubChannelSuggestion}
                                    completeMethod={segmentSubChannelFilter}
                                    onChange={(e) => {
                                        handleSegmentSubChannel(e);
                                        setSegmentSubChannelInput(e.value);
                                    }}
                                    // disabled={true}
                                    dropdown={true}
                                    itemTemplate={SubChannelTemplate}
                                    showEmptyMessage={true}
                                />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                                <label className="form-label">
                                    {" "}
                       Sub-Segment detail
                                </label>
                                <AutoComplete
                                    field="SEG_DESC"
                                    value={subSegmentDetailInput}
                                    suggestions={subSegmentDetailSuggestion}
                                    completeMethod={segmentSubDetailFilter}
                                    onChange={(e) => {
                                        handleSubSegmentDetail(e);
                                        setSubSegmentDetailInput(e.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={SubSegmentTemplete}
                                    showEmptyMessage={true}
                                />
                            </div>

                            <div className="float-end mt-4">
                                <button
                                    type="button"
                                    className="btn btn-cancel me-3"
                                    onClick={() => {
                                        setSegmentStreamInput([]);
                                        setSegmentChannelInput([]);
                                        setSegmentSubChannelInput([]);
                                        setSubSegmentDetailInput([]);
                                    }}
                                        
                                >
                    Clear
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        toast.warning(
                                            `Selected segment is  "${subSegmentDetailInput.SEG_DESC}" for the customer, after saving the segment cannot be edited.`,
                                            {
                                                position: toast.POSITION.TOP_CENTER,
                                                pauseOnFocusLoss: false,
                                                closeButton: false,
                                                autoClose: 8000,
                                            }
                                        );
                                        setManualSegmentEnterBtn(true);
                                        setShow(false);
                                    }}
                                    disabled={!segmentConfirmBtn}
                                >
                    Confirm
                                </button>
                                    
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </>
        </div>
    );
};

export default SegmentModalNewCust;
