import Axios from "../../api/Axios";
const BASE_URL = "PurchaseReturn/";
const Default_BASE_URL = "Default/";

export const EndpointPurchaseReturn = {
    
    GetAllPurchaseReturnRecord : "GetAllPurchaseReturnRecord",
    GetPurchaseRetDetails : "GetPurchaseRetDetails",
    CheckforAdjustmentRow : "CheckforAdjustmentRow",
    GetProductList : "GetProductList",
    GetGRNDetailData : "GetGRNDetailData",
    AddToCart : "AddToCart",
    GetWareHouse:"GetWareHouse",
    GetDefaultValue: "DefaultVal",
    WhDefaultVal : "WhDefaultVal",
    GetSupplier : "GetSupplier",
    GetReasonDesc : "GetReasonDesc",
    GetMatchingDocuments : "GetMatchingDocuments",
    Save: "Save",
    PrintPurRet : "PrintPurRet",
    MatchDocOk  : "MatchDocOk",
    GetEmployee : "GetEmployee"
};
export const createAPIEndpointPurchaseReturn = () => {
    return {
        getDefaultValue:(Endpoint)=>Axios.get(Default_BASE_URL+Endpoint),
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};