import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Formik, useFormik } from "formik";
import Axios from "../../api/Axios";
import storage from "../../Services/Storage";
import Loader from "../../Components/Loader";
import { format, subDays } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { useDeliveryChallanContext } from "../../context/DeliveryChallanContext";
import { Checkbox } from "primereact/checkbox";
import { useGlobalContext1 } from "../../context/SalesDetailsContext";
import AnnexurePrint from "./AnnexurePrint";

const AnnexureAllRecords = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const auth = storage.getLocal("user");
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState([]);
    console.log("auth", auth);
    const [globalFilter, setGlobalFilter] = useState("");
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const { filteredInvoices, setFilteredInvoices } = useDeliveryChallanContext();
    const {
        invoicePopup,
        setInvoicePopup,
        setPrintInvoiceModalData,
        printInvoiceModalData,
    } = useGlobalContext1();
    console.log("printInvoiceModalData", printInvoiceModalData, invoicePopup);
    // const isSelectable = (data) => data?.STATUS === "CN";
    // const rowClassName = (data) => (isSelectable(data) ? "p-disabled" : "");

    const getInvoiceData = async (fromDateRange, toDateRange) => {
        try {
            const url = "InvoiceAnnexurePrint/GetCNInvoice?";
            //   const url = 'Invoice/GetAllInvoice?';
            const getAllInvoices = `${url}FromDate=${fromDateRange}&ToDate=${toDateRange}&CompId=${auth.CompId}`;
            const response = await Axios.get(getAllInvoices);
            setFilteredInvoices(response.data.length ? response.data : []);
            setLoaderStatus(false);
        } catch (error) {
            setLoaderStatus(false);
        }
    };

    const getInvocies = async (values) => {
        if (!values.startDate || !values.endDate) return;
        if (values.startDate > values.endDate) {
            toast.warn("From Date should be equal to or less than To Date", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            return;
        }
        setLoaderStatus(true);
        const fromDateRange = format(new Date(values.startDate), "dd/MM/yyyy");
        const toDateRange = format(new Date(values.endDate), "dd/MM/yyyy");
        getInvoiceData(fromDateRange, toDateRange);
    };

    const formik = useFormik({
        initialValues: { startDate: sevenDays, endDate: currentDate },
        enableReinitialize: true,
        onSubmit: (data) => {
            getInvocies(data);
        },
    });

    const download = () => {
        if (selectedInvoice.some((val) => val.INVH_NO.includes("DCH"))) {
            toast.warn("Selection Includes Delivery Challan", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        } else {
            printInvoiceCall(selectedInvoice);
        }
        setSelectedInvoice([]);
    };

    const printInvoiceCall = async (selectedData) => {
        let myArr = [];
        for (let i = 0; i < selectedData.length; i++) {
            myArr.push(selectedData[i]?.MASK_INVH_ID);
        }
        const objInvoice = {
            compId: auth?.CompId,
            inV_HDR_ID: myArr,
            printFormatCode: auth?.PrintFormatCode,
        };
        setPrintInvoiceModalData(objInvoice);
        setInvoicePopup(true);
        console.log("selectedData", selectedData, myArr);
    };
    useEffect(() => {
        setSelectedInvoice([]);
        const currentDate = format(new Date(), "dd/MM/yyyy");
        const date = subDays(new Date(), 7);
        const sevenDays = format(date, "dd/MM/yyyy");
        getInvoiceData(sevenDays, currentDate);
    }, []);

    const currentPageData = filteredInvoices.slice(first, first + rows);

    const onSelectionChange = (e) => {
        setSelectedInvoice(e.value);
    };

    const onHeaderCheckboxChange = (e) => {
        if (e.checked) {
            setSelectedInvoice(currentPageData);
        } else {
            setSelectedInvoice([]);
        }
    };

    const headerCheckbox = (
        <Checkbox
            onChange={onHeaderCheckboxChange}
            checked={
                currentPageData.length > 0 &&
        selectedInvoice.length === currentPageData.length
            }
        />
    );

    const renderAction = (rowData) => {
        return (
            <>
                <div className='action-icon'>
                    <div
                        className='d-inline'
                        data-toggle='tooltip'
                        data-placement='bottom'
                        title='Print'
                    >
                        <button
                            className='grid-icon'
                            onClick={() => printInvoiceCall([rowData])}
                        >
                            <Fa.FaPrint />
                        </button>
                    </div>
                </div>
            </>
        );
    };
    return (
        <div className='col-12 pt-3'>
            <h3 className='py-2'>Invoice Annexure Print</h3>
            <section className='sales-box'>
                <Formik initialValues={{ startDate: sevenDays, endDate: currentDate }}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12 mb-2'>
                                <label className='form-label'>From Date</label>
                                <input
                                    name='startDate'
                                    type='date'
                                    className='form-control'
                                    value={formik.values.startDate}
                                    onChange={formik.handleChange}
                                    max={formik.values.endDate}
                                />
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 mb-2'>
                                <label className='form-label'>To Date</label>
                                <input
                                    name='endDate'
                                    type='date'
                                    className='form-control'
                                    value={formik.values.endDate}
                                    onChange={formik.handleChange}
                                    min={formik.values.startDate}
                                    max={currentDate}
                                />
                            </div>
                            <div className='col-lg-4 col-md-12 col-sm-12'>
                                <div className='float-end mt-4'>
                                    <button
                                        type='submit'
                                        title='Get Invoice'
                                        className='btn btn-outline-primary me-3'
                                    >
                    Get Invoice
                                    </button>
                                    <button
                                        type='button'
                                        title='Download'
                                        className='btn btn-primary'
                                        disabled={selectedInvoice.length == 0}
                                        onClick={download}
                                    >
                    Download <Fa.FaDownload className='ms-2' />
                                    </button>
                                    <ToastContainer />
                                </div>
                            </div>
                        </div>
                    </form>
                </Formik>
            </section>

            <section className='mt-3'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <h3>All Records</h3>
                    <div className='DataTable pb-4 pt-1'>
                        {loaderStatus ? (
                            <Loader />
                        ) : (
                            <DataTable
                                value={filteredInvoices}
                                dataKey='INVH_NO'
                                paginator
                                rows={rows}
                                first={first}
                                onPage={(e) => {
                                    setFirst(e.first);
                                    setRows(e.rows);
                                }}
                                // rowClassName={rowClassName}
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight='80vh'
                                scrollable
                                filterDisplay='row'
                                showGridlines
                                stripedRows
                                showSelectAll={false}
                                selectionMode='checkbox'
                                selection={selectedInvoice}
                                onSelectionChange={onSelectionChange}
                                globalFilter={globalFilter}
                                onGlobalFilterChange={(e) => setGlobalFilter(e.target.value)}
                                emptyMessage='No Invoices found.'
                                tableStyle={{ minWidth: "60rem" }}
                            >
                                <Column
                                    selectionMode='multiple'
                                    headerStyle={{ width: "3em" }}
                                    header={headerCheckbox}
                                />
                                {/* <Column
                  field='MASK_SOHD_SO_NO'
                  header='Sales Order No'
                  filter
                  filterPlaceholder='Search Here...'
                  style={{ minWidth: '8rem' }}
                /> */}
                                <Column
                                    field='INVH_NO'
                                    header='Invoice No'
                                    filter
                                    filterPlaceholder='Search Here...'
                                    style={{ minWidth: "8rem" }}
                                />
                                <Column
                                    field='INVH_DATE'
                                    header='Invoice Date'
                                    filter
                                    filterPlaceholder='Search Here...'
                                    style={{ minWidth: "8rem" }}
                                />
                                <Column
                                    field='CUST_NAME'
                                    header='Customer Name'
                                    filter
                                    filterPlaceholder='Search Here...'
                                />
                                {/* <Column
                  field='SEG_DESC'
                  header='Segment Name'
                  filter
                  filterPlaceholder='Search Here...'
                  style={{ minWidth: '8rem' }}
                />
                <Column field='OTP_STATUS' header='OTP Status' /> */}
                                <Column header='Action' body={renderAction} />
                            </DataTable>
                        )}
                    </div>
                </div>
            </section>
            {invoicePopup ? <AnnexurePrint /> : null}
        </div>
    );
};

export default AnnexureAllRecords;
