import { useFormik } from "formik";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
    createAPIEndpointUser,
    Endpoint,
} from "../../Services/User/UserService";
import storage from "../../Services/Storage";
import { toast } from "react-toastify";
import { classNames } from "primereact/utils";
import * as Fa from "react-icons/fa";

const UserDetails = () => {
    const [emplysuggestion, setemplysuggestion] = useState([]);
    const [employee, setemployee] = useState([]);
    const [usergrpsuggestion, setUsergrpsuggestion] = useState([]);
    const [usergrp, setUsergrp] = useState([]);
    // const [distributor, setDistributor] = useState();
    const [olduser, setOlduser] = useState(false);
    const [viewonlyUsergrp, setViewonlyUsergrp] = useState([]);
    const [viewonlyUsergroupId, setViewonlyUsergroupId] = useState("");
    const [editAllData, setEditAllData] = useState([]);
    const [getDistributor, setGetDistributor] = useState();
    const [onlyUserId, setOnlyUserId] = useState("");
    const [employeeName, setEmployeeName] = useState("");

    const auth = storage.getLocal("user");
    const [searchParams] = useSearchParams();
    let emp_id = searchParams.get("userid");
    //  
    const nav = useNavigate();
    const initialValues = {
        userid: "",
        employee: "",
        status: "",
        lastlogin: "",
        lastlogout: "",
        nologin: "",
        usergroup: "",
        distributor: "",
        prefixuserid: "",
    };

    useEffect(() => {
        if (emp_id != null) {
             
            setOlduser(true);
            editDataApi(emp_id);
            renderAllEmployeeData();
            renderDistributor();
        }
        else{
            renderAllEmployeeData();
            renderAllUserGrpData();
            renderDistributor();
        }
    }, []);

    const editDataApi = (emp_id)=>{

        createAPIEndpointUser()
            .get(`${Endpoint.GetAllUserGrpDataById}?UserId=${emp_id}`)
            .then((res) => {
                setUsergrpsuggestion(res.data.Data);
                 
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });

        createAPIEndpointUser()
            .get(`${Endpoint.ViewUserManagementData}?UserId=${emp_id}`)
            .then((res) => {
                 
                setEditAllData(res.data.Data[0]);
                setOnlyUserId(res.data.Data[0].USER_ID);
                setEmployeeName(res.data.Data[0].EMPLOYEE);
            // handleSetAllData(res.data.Data[0]);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });

        createAPIEndpointUser()
            .get(`${Endpoint.GetViewOnlyUserMappedData}?UserId=${emp_id}`)
            .then((res) => {
                 
                setViewonlyUsergrp(res.data.Data[0].USER_GROUP);
                setViewonlyUsergroupId(res.data.Data[0].MASK_GR_ID);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };

  

    const renderAllEmployeeData = () => {
        createAPIEndpointUser()
            .get(`${Endpoint.GetEmployeeList}?CompId=${auth.CompId}`)
            .then((res) => {
                setemplysuggestion(res.data.Data);
                 
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };

    const renderAllUserGrpData = () => {
        createAPIEndpointUser()
            .get(`${Endpoint.GetAllUserGrpData}`)
            .then((res) => {
                setUsergrpsuggestion(res.data.Data);
                 
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };
    

    const renderDistributor = () => {
        createAPIEndpointUser()
            .get(`${Endpoint.GetAllDistributor}?CompId=${auth.CompId}`)
            .then((res) => {
                 
                setGetDistributor(res.data.Data[0].COMP_NAME);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };

    

    const employeeTemplate = (employee) => {
        return (
            <>
                {employee.EMP_CODE} - {employee.NAME}
            </>
        );
    };
    const userTemplate = (usergrp) => {
        return <>{usergrp.GR_NAME}</>;
    };

    const searchEmployee = (e) => {
        setTimeout(() => {
            let _filteredemployee;
            if (!e.query.trim().length) {
                _filteredemployee = [...emplysuggestion];
            } else {
                _filteredemployee = emplysuggestion.filter((employee) => {
                    return (
                        employee.EMP_CODE.toLowerCase().includes(e.query.toLowerCase()) ||
            employee.NAME.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }

            setemployee(_filteredemployee);
        }, 250);
    };

    const searchusergrp = (e) => {
        setTimeout(() => {
            let _filteredemployee;
            if (!e.query.trim().length) {
                _filteredemployee = [...usergrpsuggestion];
            } else {
                _filteredemployee = usergrpsuggestion.filter((usergrp) => {
                    return usergrp.GR_NAME.toLowerCase().includes(e.query.toLowerCase())|| usergrp.USER_GROUP.toLowerCase().includes(e.query.toLowerCase());
                });
            }

            setUsergrp(_filteredemployee);
        }, 250);
    };

    const status = [
        {
            id: "Active",
            value: "Active",
        },
        {
            id: "Inactive",
            value: "Inactive",
        },
    ];

    const formik = useFormik({
        initialValues: initialValues,
        validate: (data) => {
            let errors = {};

            if (!data.employee) {
                errors.employee = "Employee is required";
            }
            if (!data.userid) {
                errors.userid = "User Id is required";
            }
            if (!data.usergroup) {
                errors.usergroup = "User Group is required";
            }

            return errors;
        },
        onSubmit: (data) => {
             
            submittingUserDetils(data);
            formik.resetForm();
        },
    });

    const submittingUserDetils = (data) => {
         
         
         

        const payload = {
            pvcUSER_ID: auth.UserId,
            pvcCI_CODE: auth.CICode,
            pvcCOMP_ID:auth.CompId,
            pvcNEW_USER_ID: auth.CICode + "_" + data.userid,
            pvcUSER_PASSWORD: "",
            pvcDEFINED_BY: "E",
            pvcSTATUS: data.status === "Active" ? "Y" : "N",
            pvcISActive: "",
            pvcEMP_ID: data.employee.MASK_EMP_ID,
            iukey: "1",
            pvcGR_ID: data.usergroup.MASK_GR_ID ?? "",
        };

        let editstatus="";
        if(olduser){
             
            if(editAllData.MASK_STATUS=="Y" && data.status === "INACTIVE"){
                editstatus="INACTIVE";
            }
            else if(editAllData.MASK_STATUS=="N" && data.status === "ACTIVE"){
                editstatus="ACTIVE";
            }
            else if(editAllData.MASK_STATUS=="Y" && data.status === "ACTIVE"){
                editstatus="";
            }
            else if(editAllData.MASK_STATUS=="N" && data.status === "INACTIVE"){
                editstatus= "";
            }

        }

        const editpayload = {
            pvcUSER_ID: auth.UserId,
            pvcCI_CODE: auth.CICode,
            pvcCOMP_ID:auth.CompId,
            pvcNEW_USER_ID: editAllData.USER_ID,
            pvcUSER_PASSWORD: editAllData.MASK_USER_PASSWORD,
            pvcDEFINED_BY: "E",
            pvcSTATUS:data.status === "Active" ? "Y" : "N",
            pvcISActive: editstatus,
            pvcEMP_ID: editAllData.EMPLOYEE==data.employee?editAllData.MASK_EMP_ID:data.employee.MASK_EMP_ID,
            iukey: "2",
            pvcGR_ID: viewonlyUsergrp==data.usergroup ? viewonlyUsergroupId:data.usergroup.GR_CODE,
        };

         
         
        // return;
        createAPIEndpointUser()
            .post(`${Endpoint.UserDataSave}`, JSON.stringify(olduser?editpayload:payload), {
                headers: { "Content-Type": "application/json" },
            })
            .then((response) => {
                toast.success(response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 4000,
                });
                nav("/user");
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };

    const isFormFieldInvalid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? (
            <small className="p-error">{formik.errors[name]}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };

    //  
    //     let str = editAllData.USER_ID;
    //     var parts = str.split("_");
    // var lastPart = parts[parts.length - 1]; 
    //      

    if(olduser){
        formik.initialValues.userid= onlyUserId;
        formik.initialValues.employee= employeeName;
        formik.initialValues.status= editAllData.MASK_STATUS=="Y"?"Active":"Inactive";
        formik.initialValues.lastlogin= editAllData.MASK_LAST_LOGIN;
        formik.initialValues.lastlogout= editAllData.MASK_LAST_LOGOUT;
        formik.initialValues.nologin= editAllData.MASK_LOGIN_COUNT;
        formik.initialValues.usergroup= viewonlyUsergrp;
        formik.initialValues.distributor= getDistributor;
        formik.initialValues.prefixuserid= "";
    }else{
        formik.initialValues.userid= "";
        formik.initialValues.employee= "";
        formik.initialValues.status= "Active";
        formik.initialValues.lastlogin= "";
        formik.initialValues.lastlogout= "";
        formik.initialValues.nologin= "";
        formik.initialValues.usergroup= "";
        formik.initialValues.distributor= getDistributor;
        formik.initialValues.prefixuserid= "";
    }


    // if(olduser){
    //     // formik.initialValues.userid= editAllData.USER_ID??.split("_").pop();
    //     formik.initialValues.employee= editAllData.EMPLOYEE;
    //     formik.initialValues.status= editAllData.MASK_STATUS=="Y"?"Active":"Inactive";
    //     formik.initialValues.lastlogin= editAllData.MASK_LAST_LOGIN;
    //     formik.initialValues.lastlogout= editAllData.MASK_LAST_LOGOUT;
    //     formik.initialValues.nologin= editAllData.MASK_LOGIN_COUNT;
    //     formik.initialValues.usergroup= "";
    //     formik.initialValues.distributor= getDistributor;
    //     formik.initialValues.prefixuserid= "";
    // }else{
    //     formik.initialValues.userid= "";
    //     formik.initialValues.employee= "";
    //     formik.initialValues.status= "Active";
    //     formik.initialValues.lastlogin= "";
    //     formik.initialValues.lastlogout= "";
    //     formik.initialValues.nologin= "";
    //     formik.initialValues.usergroup= "";
    //     formik.initialValues.distributor= getDistributor;
    //     formik.initialValues.prefixuserid= "";
    // }
    //  

    return (
        <div className="col-12 pt-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mx-1">
                            <li className="breadcrumb-item">
                                <Link to="/user">User Management</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Details</li>
                        </ol>
                    </nav>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end">
                        <Link to="/user">
                            <button type="submit" className="btn btn-primary">
                                <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="">
                <form onSubmit={formik.handleSubmit}>
                    <section className="sales-box">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Distributor <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    className="form-control"
                                    type="text"
                                    disabled
                                    //   placeholder="WESTERN LUBE COMPANY THANE"
                                    values={formik.values.distributor}
                                    // onChange={formik.handleChange}
                                    // onBlur={formik.handleBlur}
                                    defaultValue={formik.initialValues.distributor}
                                />
                            </div>

                            {olduser? <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  User ID <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="uid"
                                    name="uid"
                                    value={onlyUserId}
                                    disabled
                                />
                            </div>:<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label d-block">
                  UserId <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    style={{ width: "49%" }}
                                    className="form-control d-inline me-1"
                                    value={auth.CICode + "_"}
                                    disabled
                                />
                                <InputText
                                    id="userid"
                                    name="userid"
                                    style={{ width: "49%" }}
                                    value={formik.values.userid}
                                    onChange={(e) => {
                                        if(e.target.value.trim().length<3){
                                            formik.setFieldValue("userid", e.target.value);
                                        }
                                    }}
                                    className={classNames(
                                        { "p-invalid": isFormFieldInvalid("userid") },
                                        "form-control d-inline"
                                    )}
                                    disabled ={olduser}
                                />
                                {getFormErrorMessage("userid")}
                                
                            </div>}

                            {/* {olduser &&<div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Current Employee
                                </label>
                                <InputText
                                    id="employee"
                                    name="employee"
                                    value={employeeName}
                                    disabled
                                />
                            </div>} */}

                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                Employee <span className="mandatory">*</span>
                                </label>
                                <AutoComplete
                                    field="NAME"
                                    name="employee"
                                    value={formik.values.employee}
                                    suggestions={employee}
                                    completeMethod={searchEmployee}
                                    onChange={(e) => {
                                        formik.setFieldValue("employee", e.value);
                                    }}
                                    dropdown={true}
                                    itemTemplate={employeeTemplate}
                                    emptyMessage="No Employee found."
                                    showEmptyMessage
                                    className={classNames({
                                        "p-invalid": isFormFieldInvalid("employee"),
                                    })}
                                />
                                {getFormErrorMessage("employee")}
                            </div>
                            
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Last Login <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="lastlogin"
                                    name="lastlogin"
                                    className="form-control"
                                    value={formik.values.lastlogin}
                                    onChange={(e) => {
                                        formik.setFieldValue("title", e.target.value);
                                    }}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Last Logout <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="lastlogout"
                                    name="lastlogout"
                                    className="form-control"
                                    value={formik.values.lastlogout}
                                    onChange={(e) => {
                                        formik.setFieldValue("lastlogout", e.target.value);
                                    }}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Number of Login <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="nologin"
                                    name="nologin"
                                    className="form-control"
                                    value={formik.values.nologin}
                                    onChange={(e) => {
                                        formik.setFieldValue("nologin", e.target.value);
                                    }}
                                    disabled
                                />
                            </div>
                            {/* {olduser && <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Current User Group <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="nologin"
                                    name="nologin"
                                    className="form-control"
                                    value={viewonlyUsergrp}
                                    // onChange={(e) => {
                                    //     formik.setFieldValue("nologin", e.target.value);
                                    // }}
                                    disabled
                                />
                            </div>} */}

                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="mt-0">
                                    <label className="form-label d-block">
                    Available User Groups<span className="mandatory">*</span>
                                    </label>
                                    <AutoComplete
                                        field={"GR_NAME"}
                                        name="usergroup"
                                        value={formik.values.usergroup}
                                        suggestions={usergrp}
                                        completeMethod={searchusergrp}
                                        onChange={(e) => {
                                            formik.setFieldValue("usergroup", e.value);
                                        }}
                                        dropdown={true}
                                        itemTemplate={userTemplate}
                                        emptyMessage="No User Group found."
                                        showEmptyMessage
                                    />
                                    {getFormErrorMessage("usergroup")}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="mt-0">
                                    <label className="form-label d-block">
                    Status <span className="mandatory">*</span>
                                    </label>
                                    {status.map((btn, i) => {
                                        return (
                                            <div key={i} className="d-inline-flex pe-3">
                                                <RadioButton
                                                    {...btn}
                                                    checked={
                                                        formik.values.status === btn.value
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("status", e.value);
                                                    }}
                                                />
                                                <label htmlFor={btn.inputId} className="ps-2">
                                                    {btn.value}
                                                </label>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="float-end">
                            <button
                                type="submit"
                                className="btn btn-cancel-red me-3"
                            >
                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary">
                Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UserDetails;
