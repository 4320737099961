export const StockAdjustmentAddRowInitVal = {
    row:"",
    stock_type:"",
    uom_type:"",
    product_code: "",
    product_description:"",
    product_lot_no: "",
    available_stock:"",
    quantity:"",
    GRN:"",
};