import React, { useContext } from "react";
import { useState } from "react";
import storage from "../Services/Storage";
import { createContext } from "react";
import { toast } from "react-toastify";
import { format, subDays } from "date-fns";
import { Endpoint, createAPIEndpoint } from "../Services/StockAdjustmentService.js/StockAdjustmentService";
import { useNavigate } from "react-router-dom";

// let from_date;
// let to_date;

const StockAdjustmentContext = createContext();
const StockAdjustmentProvider = ({ children }) => {

    const nav = useNavigate();
    const currentDate = format(new Date(), "dd/MM/yyyy");
    const date = subDays(new Date(), 7);
    const currentDateMinusSeven = format(date, "dd/MM/yyyy");
    const auth = storage.getLocal("user");
    const [infoPopup, setInfoPopup] = useState(false);
    const [stockTypeData, setStockTypeData] = useState([]);
    const [productCodePopup, setProductCodePopup] = useState(false);
    const [populateProductData, setPopulateProductData] = useState([]);
    const [selectedProductCode, setSelectedProductCode] = useState([]);
    const [selectedWHData, setselectedWHData] = useState("");
    const [allWarehouseData, setAllWarehouseData] = useState([]);
    const [defautStockType, setDefautStockType] = useState([]);
    const [productDetails, setProductDetails] = useState([]);
    const [productLotselect, setProductLotselect] = useState(false);
    const [populateProductLotData, setPopulateProductLotData] = useState([]);
    const [selectedLot, setSelectedLot] = useState([]);
    const [populateProductDataTable, setPopulateProductDataTable] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [productDetailTable, setProductDetailTable] = useState([]);
    const [allStkAdjData, setAllStkAdjData] = useState([]);
    const [fromDate, setFromDate] = useState(currentDateMinusSeven);
    const [toDate, setToDate] = useState(currentDate);
    const [viewReason, setViewReason] = useState("");
    const [selectedReasonData, setselectedReasonData] = useState("");
    const [adjNo, setAdjNo] = useState("");
    const [loading, setLoading] = useState(false);
    const [printPopup, setPrintPopup] = useState(false);
    const [printData, setPrintData] = useState({});
    const [GRNdata, setGRNdata] = useState([]);
    const [selectedGRN, setSelectedGRN] = useState("");
    const [viewGRN, setviewGRN] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [viewWhData, setViewWhData] = useState("");



    const StockTypeApi = async () => {
        await createAPIEndpoint()
            .get(`${Endpoint.PopulateStockType}`)
            .then((res) => {

                setDefautStockType(res.data.Data[0]);
                setStockTypeData(res.data.Data);
            });
    };

    const GRNAPI = (code, lot_no) => {
        createAPIEndpoint()
            .get(`${Endpoint.GetGRN}?prodid=${code}&prodlot=${lot_no}&CompId=${auth.CompId}`)
            .then((res) => {
                setGRNdata(res.data.Data);
            });
    };

    const deleteStockAdj = async (rowData) => {

        const formatDate=(date) =>{
            const day = date.getDate().toString().padStart(2, "0");
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        setLoading(true);
        createAPIEndpoint()
            .get(`${Endpoint.DeleteStockAdjustment}?CompId=${auth.CompId}&strHeaderId=${rowData.MASK_STK_ADJ_HDR_ID}&SelectedDate=${rowData.STOCK_ADJUSTMENT_DATE}`)
            .then((res) => {
                if (res.data.Status == "Success") {
                    toast.success(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                }
                const today = new Date();
                const sevenDaysAgo = new Date(today);
                sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
                const todayFormatted = formatDate(today);
                const sevenDaysAgoFormatted = formatDate(sevenDaysAgo);
                getStockData(sevenDaysAgoFormatted, todayFormatted);
                setLoading(false);
            });
    };

    const findDateStock = (e, fromDate, toDate) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        // from_date = format(fDate, "yyyy/MM/dd");
        // to_date = format(tDate, "yyyy/MM/dd");
        // if (from_date > to_date) {
        //     toast.warn("From Date should be equal to or less than To Date", {
        //         position: toast.POSITION.TOP_CENTER,
        //         pauseOnFocusLoss: false,
        //         closeButton: false,
        //         autoClose: 2000,
        //     });

        //     // return filteredSalesOrders;
        // } else if (from_date < "2010-01-01") {
        //     toast.warn("From Date should be equal to or greater than 01-01-2010", {
        //         position: toast.POSITION.TOP_CENTER,
        //         pauseOnFocusLoss: false,
        //         closeButton: false,
        //         autoClose: 2000,
        //     });

        //     // return filteredSalesOrders;
        // }
        getStockData(fromDateRange, toDateRange);

        e.preventDefault();
    };

    const getStockData = async (fromDate, toDate) => {
        setLoading(true);
        await createAPIEndpoint()
            .get(`${Endpoint.GetAllSTKAdjstData}?strStartDate=${fromDate}&strEndDate=${toDate}&UserId=${auth.UserId}&CompId=${auth.CompId}`)
            .then((res) => {
                setAllStkAdjData(res.data.Data);
                setLoading(false);
            });

    };


    const handleSaveApproved = () => {
        setIsloading(true);
        let objSave = {
            STK_HEADER_ID: rowData.MASK_STK_ADJ_HDR_ID,
            UserId: auth.UserId,
            CompId: auth.CompId,
            AdjNo: rowData.STK_ADJ_NO,
            WareHouseId: rowData.MASK_WH_ID
        };

        // return
        createAPIEndpoint()
            .post(`${Endpoint.ApproveStockAdjustment}`,
                JSON.stringify(objSave),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            .then((res) => {
                if (res.data.Status == "Success") {
                    toast.success(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                    nav("/inventorymanagement/stockadjustment");
                    setIsloading(false);
                }
                else if (res.data.Status == "Warning") {
                    toast.warning(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                    setIsloading(false);
                }
            })
            .catch((err) => {
                toast.warning(
                    `${err.data.Message}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
                );
                setIsloading(false);
            });
    };

    const ViewStockAdj = async (stkAdjHdrId) => {
        setLoading(true);
        createAPIEndpoint()
            .get(`${Endpoint.PrintSTKAdjstData}?strStkAdjHdrId=${stkAdjHdrId}`)
            .then((res) => {

                if (res.data.Status == "Error") {

                    toast.warning(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );

                }
                else {
                    setPopulateProductDataTable(res.data.ListStockAdjustmentPrintProd);
                    setselectedReasonData(res.data.REASON);
                    setViewWhData(res.data.WAREHOUSE);
                    setselectedWHData(res.data.WAREHOUSE);
                    setAdjNo(res.data.STK_ADJ_NO);
                }
                setLoading(false);
            });
    };

    const ProductCodeApi = () => {
        setIsloading(true);
        createAPIEndpoint()
            .get(`${Endpoint.GetProductList}?strDivId=LUB|C`)
            .then((res) => {

                setPopulateProductData(res.data.Data);
                setIsloading(false);
            });
    };

    const getPrintData = async (rowData) => {
        await createAPIEndpoint()
            .get(`${Endpoint.PrintSTKAdjstData}?strStkAdjHdrId=${rowData.MASK_STK_ADJ_HDR_ID}`)
            .then((res) => {
                setPrintData(res.data);
                setPrintPopup(true);
            });
    };

    const ProductLotApi = async () => {
        setIsloading(true);
        await createAPIEndpoint()
            .get(`${Endpoint.GetProductLotList}?strProdId=${selectedProductCode.MASK_PROD_ID}&strCompId=${auth.CompId}&strStockType=1&strWHId=${selectedWHData.MASK_WH_ID}&strLocationId=N&strDivisionId=LUB|C&strProdBatchId=${selectedProductCode.MASK_PROD_ID}`)
            .then((res) => {

                setPopulateProductLotData(res.data.Data);
                setIsloading(false);
            });
    };

    const productDisc = () => {
        createAPIEndpoint()
            .get(`${Endpoint.GetProductDisc}?ProdId=${selectedProductCode.MASK_PROD_ID}&strCompId=${auth.CompId}&strStockType=1&strWHId=${selectedWHData.MASK_WH_ID}&strLocationId=N&strDivisionId=LUB|C&CurrentDate=${currentDate}`)
            .then((res) => {

                if (res.data.Status == "Warning") {
                    setProductDetails([]);
                    toast.warning(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );

                }
                else {
                    // setSelectedLot([]);
                    ProductLotApi();

                    setProductDetails(res.data.Data[0]);
                }
            });
    };

    const newStockAdjustedSave = (obj1) => {

        const updatedPopulateData = populateProductDataTable.map(item => {
            const updatedItem = { ...item };
            delete updatedItem.Stock_Type;
            return updatedItem;
        });

        setLoading(true);
        let stocckSaveObj = {
            ciCode: auth.CICode,
            compId: auth.CompId,
            userId: auth.UserId,
            pvcDIVN_ID: "LUB|C",
            pvcSTK_SRC_WH_ID: obj1.Warehouse.MASK_WH_ID,
            PvcSTK_BRANCH_ID: obj1.Warehouse.MASK_BRANCH_ID,
            pvcSTK_ADJ_NO: "",
            pdtSTK_ADJ_DATE: obj1.Date,
            pvcREASON_ID: obj1.Reason.MASK_REASON_ID,
            pvcREASON_DESC: obj1.Reason.REASON_DESC,
            pvcEMP_ID: "",
            pvcREMARKS: "N",
            pvcSTK_ADJ_TYPE_ID: "1",
            pvcLOCATION_ID: "N",
            pvcDEFINED_BY: "E",
            pvcSTATUS: "S",
            pvcEXPORT_TAG: "N",
            pvcFIRST_ENTERED_BY: auth.UserId,
            pvcSTK_EVAL_BY: "P",
            pvcCFOP_ID: "",
            pvcACCT_ID: "P/L",
            iukey: "1",
            dtProductList: updatedPopulateData,
        };

        createAPIEndpoint()
            .post(`${Endpoint.SaveStockAdjustment}`,
                JSON.stringify(stocckSaveObj),
                {
                    headers: { "Content-Type": "application/json" },
                }
            ).then((res) => {
                if (res.data.Status == "Warning") {
                    toast.warning(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                else {
                    toast.success(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                    nav("/inventorymanagement/stockadjustment");
                    setProductDetailTable([]);
                }
                setLoading(false);
            });
    };

    return (
        <StockAdjustmentContext.Provider
            value={{
                currentDate,
                infoPopup,
                setInfoPopup,
                StockTypeApi,
                stockTypeData,
                productCodePopup,
                setProductCodePopup,
                ProductCodeApi,
                populateProductData,
                setPopulateProductData,
                selectedProductCode,
                setSelectedProductCode,
                productDisc,
                selectedWHData,
                setselectedWHData,
                allWarehouseData,
                setAllWarehouseData,
                defautStockType,
                setDefautStockType,
                productDetails,
                setProductDetails,
                productLotselect,
                setProductLotselect,
                populateProductLotData,
                setPopulateProductLotData,
                selectedLot,
                setSelectedLot,
                populateProductDataTable,
                setPopulateProductDataTable,
                newStockAdjustedSave,
                isloading,
                setIsloading,
                productDetailTable,
                setProductDetailTable,
                fromDate,
                setFromDate,
                toDate,
                setToDate,
                getStockData,
                allStkAdjData,
                setAllStkAdjData,
                ViewStockAdj,
                viewReason,
                setViewReason,
                selectedReasonData,
                setselectedReasonData,
                adjNo,
                setAdjNo,
                deleteStockAdj,
                loading,
                currentDateMinusSeven,
                printPopup,
                setPrintPopup,
                printData,
                setPrintData,
                getPrintData,
                GRNAPI,
                GRNdata,
                setGRNdata,
                selectedGRN,
                setSelectedGRN,
                findDateStock,
                viewGRN,
                setviewGRN,
                rowData,
                setRowData,
                handleSaveApproved,
                viewWhData,
                setViewWhData,
            }}
        >
            {children}
        </StockAdjustmentContext.Provider>
    );
};
export const useStockAdjustmentContext = () => {
    return useContext(StockAdjustmentContext);
};

export { StockAdjustmentProvider, StockAdjustmentContext };