import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { Link } from "react-router-dom";

const UserGroups = () => {
    return (
        <main>
            <div className="col-12">
                <div className="float-end">
                    <Link to="/usergroupsdetails">
                        <button type="submit" className="btn btn-primary">Add New</button>
                    </Link>
                </div>
                <h3 className="py-2">User Groups</h3>
                <section className="">
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="DataTable pb-4 pt-1">
                            <DataTable  dataKey='product_code' showGridlines stripedRows tableStyle={{ minWidth: "50rem" }} scrollable>
                                <Column  header="Code"></Column>
                                <Column  header="Name"></Column>
                                <Column  header="User Group Privilege"></Column>
                                <Column  header="Status"></Column>
                                <Column  header="Action"></Column>
                            </DataTable>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    );
};

export default UserGroups;