import React, { useState } from "react";
import * as Fa from "react-icons/fa";
import { FileUpload } from "primereact/fileupload";
import * as XLSX from "xlsx";
import {
    createAPIEndpointPayterm,
    Endpoint,
} from "../../Services/PayTermLimit/Paytermlimitservice";
import { toast } from "react-toastify";
import { read, utils } from "xlsx";
import storage from "../../Services/Storage";
import { useRef } from "react";

const Index = () => {


    const [loadingExcel, setLoadingExcel] = useState(false);
    const [errorExcelData, seterrorExcelData] = useState([]);
    const [errorxlsbtn, setErrorxlsbtn] = useState(false);

    const auth = storage.getLocal("user");
    const fileUploadRef = useRef(null);
    let header = [
        {
            "CSD Code": null,
            "Customer Code": null,
            "Customer Unique Code": null,
            "Pay Term Code": null,
            "Credit Limit": null,
        },
    ];

    const payterm_def = [
        { "Pay Term Code": "AC24/C", "Pay Term Description": "AC24" },
        { "Pay Term Code": "NA", "Pay Term Description": "NA" },
        { "Pay Term Code": "PT1/C", "Pay Term Description": "On Delivery" },
        { "Pay Term Code": "PT10/C", "Pay Term Description": "Upcountry Cheque" },
        { "Pay Term Code": "PT11/C", "Pay Term Description": "11" },
        { "Pay Term Code": "PT12/C", "Pay Term Description": "14 Days Credit" },
        { "Pay Term Code": "PT13/C", "Pay Term Description": "13" },
        { "Pay Term Code": "PT14/C", "Pay Term Description": "14" },
        { "Pay Term Code": "PT2/C", "Pay Term Description": "7 Days Credit" },
        { "Pay Term Code": "PT3/C", "Pay Term Description": "15 Days Credit" },
        { "Pay Term Code": "PT4/C", "Pay Term Description": "21 Days Credit" },
        { "Pay Term Code": "PT5/C", "Pay Term Description": "31 Days Credit" },
        { "Pay Term Code": "PT6/C", "Pay Term Description": "45 Days Credit" },
        { "Pay Term Code": "PT7/C", "Pay Term Description": "Credit - > 45 Days" },
        { "Pay Term Code": "PT8/C", "Pay Term Description": "Demand Draft" },
        { "Pay Term Code": "PT9/C", "Pay Term Description": "21 Days Credit" }
    ];

    const handleExportTemplate = () => {
        let uniqueNo = "PayTerm_CreditLimit_Template";
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(header);
        const ws1 = XLSX.utils.json_to_sheet(payterm_def);
        XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
        XLSX.utils.book_append_sheet(wb, ws1, "Payterm_Definition");
        XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
    };

    const handleExportExcel = () => {
        setLoadingExcel(true);

        let uniqueNo = "PayTerm_CreditLimit";

        createAPIEndpointPayterm()
            .get(`${Endpoint.PaymentCreditDownload}`)
            .then((res) => {
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(res.data.Data);
                XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                setLoadingExcel(false);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setLoadingExcel(false);
            });
    };
    const handleExportErrorxls = () => {
        let uniqueNo = "export_Payterm_errorfile";
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(errorExcelData);
        XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
        XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
    };

    const handleFileUpload = (event) => {
        let parsedData = [];
        let fileTypes = [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ];

        const selectedFile = event.files[0];


        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);

            reader.onload = (e) => {
                const fileData = e.target.result;
                const workbook = read(fileData, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                parsedData = utils.sheet_to_json(sheet);
                 

                const actualheaderclmn = [
                    "A1",
                    "B1",
                    "C1",
                    "D1",
                    "E1",
                ];

                 
                const headingsClmnAll = [];
                for (const key in sheet) {
                    headingsClmnAll.push(key);
                }

                //  
                let headerclmn = [];
                for (let i = 0; i <= 5; i++) {
                    headerclmn.push(headingsClmnAll[i]);
                }
                 

                let chceckClmnInclude = actualheaderclmn.every((element) =>
                    headingsClmnAll.includes(element)
                );

                 

                // //checking headers name
                // // actual headers name that should be in the excel
                const actualheaders = [
                    "CSD Code",
                    "Customer Code",
                    "Customer Unique Code",
                    "Pay Term Code",
                    "Credit Limit",
                ];
                const allfieldname = [];
                for (const key in sheet) {
                    allfieldname.push(sheet[key].v);
                }
                //exctract headers name from the all fields name
                let headernames = [];
                for (let i = 0; i <= 5; i++) {
                    headernames.push(allfieldname[i]);
                }
                 
                //compare header are present in excel or not
                let chceckHeaderInclude = actualheaders.every((element) =>
                    headernames.includes(element)
                );

                 
                 
                 
                 

                if (chceckClmnInclude && chceckHeaderInclude) {
                    if (parsedData != null && parsedData.length >= 1) {
                        let modelexcel = parsedData.map((val) => ({
                            CSD_Code: val["CSD Code"]?.toString() ?? "",
                            Party_Code: val["Customer Code"]?.toString() ?? "",
                            Customer_Unique_Code: val["Customer Unique Code"]?.toString() ?? "",
                            Payterm: val["Pay Term Code"]?.toString() ?? "",
                            Credit_Limit: val["Credit Limit"]?.toString() ?? "",
                        }));

                        let objUpload = {
                            ciCode: auth.CICode,
                            compId: auth.CompId,
                            lstModelPaytermUploads: modelexcel,
                        };
                         
                        createAPIEndpointPayterm()
                            .post(`${Endpoint.CreditLimitUpload}`, JSON.stringify(objUpload), {
                                headers: { "Content-Type": "application/json" },
                            })
                            .then((res) => {
                                if (res.data.Status == "Success") {
                                    toast.success(res.data.Message, {
                                        position: toast.POSITION.TOP_CENTER,
                                        pauseOnFocusLoss: false,
                                        closeButton: false,
                                        autoClose: 2000,
                                    });
                                    setErrorxlsbtn(false);
                                    fileUploadRef.current.clear();
                                }
                                else if (res.data.Status == "Warning" && res.data.Data) {
                                    //  
                                    toast.warning("error file avaliable to download", {
                                        position: toast.POSITION.TOP_CENTER,
                                        pauseOnFocusLoss: false,
                                        closeButton: false,
                                        autoClose: 2000,
                                    });
                                    seterrorExcelData(res.data.Data);
                                    setErrorxlsbtn(true);
                                }
                            })
                            .catch((error) => {
                                toast.error(error.message, {
                                    position: toast.POSITION.TOP_CENTER,
                                    pauseOnFocusLoss: false,
                                    closeButton: false,
                                    autoClose: 3000,
                                });
                            });
                    } else {
                        toast.warning("Can't Insert an Empty file", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3000,
                        });
                    }
                } else {
                    toast.warning("please insert a valid file", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                }
            };
        } else {
            toast.warning(
                "Please specify a valid file. Can upload only Excel file",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 4000,
                }
            );
        }
    };


    return (
        <div className="col-12 pt-3 pb-5">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Pay Term & Credit Limit Upload</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-2">
                        <button type="submit" className="btn btn-outline-primary me-3" onClick={handleExportTemplate}>Download Template <Fa.FaDownload className='ms-2' /></button>
                        {errorxlsbtn && (
                            <button type="submit" className="btn btn-outline-secondery me-3" onClick={handleExportErrorxls}>
                                Export Error File <Fa.FaFileExport className='ms-2' />
                            </button>
                        )}
                        <button type="submit" className="btn btn-primary" onClick={handleExportExcel} disabled={loadingExcel ? true : false}>{loadingExcel ? "Loading..." : "Export"} <Fa.FaFileExport className='ms-2' /></button>
                    </div>
                </div>
            </div>
            <div className="sales-box">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="UploadSection">
                        <FileUpload
                            ref={fileUploadRef}
                            customUpload
                            uploadHandler={handleFileUpload}
                            single="true"
                            accept=".xlsx, .xls"
                            onClear={() => {
                                setErrorxlsbtn(false);
                            }}
                            onRemove={() => {
                                setErrorxlsbtn(false);
                            }}
                            emptyTemplate={
                                <p className="m-0">
                                    Drag and drop files to here to upload.
                                </p>
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
