import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useFormik } from "formik";
import * as Fi from "react-icons/fi";
import { toast } from "react-toastify";
import {
    createAPIEndpoint,
    Endpoint,
} from "../../Services/WareHouse/WarehouseService";
import storage from "../../Services/Storage";
import Loader from "../../Components/Loader";
// import Townpopup from "./GeoTownModal";
// import { classNames } from "primereact/utils";
import { useWareHouseMasterContext } from "../../context/WareHouseContext";
import GeoTownModal from "./GeoTownModal";
import * as Fa from "react-icons/fa";
import { EndpointMessage, createAPIEndpointMessage } from "../../Services/MessageService";

function WarehouseMasterNew() {
    const {
        setwareHouseDataAll,
        wareHouseDataAll,
        setTownPopup,
        handleState,
        zipCode,
        townValue,
        stateValue,
        districtValue,
        confirmTown,
        setConfirmTown,
        setDistrictValue,
        setTownValue,
        setstateValue,
        townGeogId,
        setTownGeogId
    } = useWareHouseMasterContext();

    const [viewWarehouse, setViewWarehouse] = useState(false);
    const [saveloader, setSaveloader] = useState(false);
    // const [viewWarehouseAllData, setViewWarehouseAllData] = useState([]);
    // const [viewfielddisable, setviewfielddisable] = useState(false);

    //status state
    const [codeStatus, setCodeStatus] = useState(false);
    const [descriptionStatus, setDescriptionStatus] = useState(false);
    const [address1Status, setAddress1Status] = useState(false);
    const [address2Status, setAddress2Status] = useState(false);
    const [remarksStatus, setRemarksStatus] = useState(false);
    const [townStatus, setTownStatus] = useState(false);
    const [contact_personStatus, setContact_personStatus] = useState(false);
    const [activeStatus, setActiveStatus] = useState(false);
    const [ErrorLable, setErrorLable] = useState(false);
    const [phoneNumberStatus, setPhoneNumberStatus] = useState(false);

    const [searchParams] = useSearchParams();
    const auth = storage.getLocal("user");
    let warehouseid = searchParams.get("warehouseid");

    console.log("CompId", auth.CompId);

    console.log("CompId", phoneNumberStatus);
    const nav = useNavigate();

    useEffect(() => {
        fetchWarehouseApi();
        if (warehouseid != null) {
            setViewWarehouse(true);
            setTownGeogId(targetObject?.MASK_GEOG_ID);
        }
        handleState();
    }, []);

    useEffect(() => {
        setTownValue("");
        setDistrictValue("");
        setstateValue("");
        setConfirmTown(false);
    }, []);

    const fetchWarehouseApi = () => {
        setSaveloader(true);
        createAPIEndpoint()
            .get(`${Endpoint.GetAllWarehouseDetails}?CompId=${auth.CompId}`)
            .then((response) => {
                setwareHouseDataAll(response.data);
                setSaveloader(false);
            })
            .catch((error) => {
                console.log("error", error);
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
    
                console.log(error);
                setSaveloader(false);
            });
    };
    console.log(ErrorLable);

    const initialValues = {
        code: "",
        description: "",
        address1: "",
        address2: "",
        town: "",
        state: "",
        district: "",
        zip_code: "",
        contact_person: "",
        phone_number: "",
        remarks: "",
        active: "",
        type: "",
        branch: "",
        geog_id: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (data) => {
            console.log("data", data);
            submitWareHouseData(data);
            // formik.resetForm();
        },

        validate: (data) => {
            const errors = {};
            //only character no number or special character
            let nameregex = /^[a-zA-Z\s]+$/;
            //mobile number
            const mobilenoRegex = new RegExp(/^[6-9]\d{9}$/);
            //allow only Spec char allowed [.],[,],[/],[-]
            const Addressrgx = /^[A-Za-z0-9\s.,,/\\-]*$/;
            //code validation
            const codevalidation = /^[a-zA-Z0-9]+$/;
            //validation

            //code
            if (!data.code) {
                errors.code = "code is required";
                setCodeStatus(true);
            } else if (data.code.length > 15) {
                errors.code = "code limit should be less than 15";
                setCodeStatus(true);
            } 
            else {
                if (!codevalidation.test(data.code)) {
                    errors.code =
                            "Invalid Description special characters are not allowed.";
                    setErrorLable(true);
                    setCodeStatus(true);
                } else {
                    setCodeStatus(false);
                }
            }
            //description

            if (!data.description) {
                errors.description = "description is required";
                setDescriptionStatus(true);
                setErrorLable(true);
            } else if (data.description.length > 60) {
                errors.description = "description limit should be less than 60";
                setDescriptionStatus(true);
                setErrorLable(true);
            } else {
                if (!nameregex.test(data.description)) {
                    errors.description =
                        "Invalid Description Numbers and special characters are not allowed.";
                    setErrorLable(true);
                    setDescriptionStatus(true);
                } else {
                    setDescriptionStatus(false);
                }
            }
            //address1
            if (!data.address1) {
                errors.address1 = "address1 is required";
                setAddress1Status(true);
                setErrorLable(true);
            } else if (data.address1.length > 70) {
                errors.address1 = "address1 limit should be less than 70";
                setAddress1Status(true);
                setErrorLable(true);
            } else if (!Addressrgx.test(data.address1)) {
                errors.address1 = "No special character is allowed";
                setErrorLable(true);
                setAddress1Status(true);
            } else {
                setAddress1Status(false);
            }
            //address2
            if (data.address2) {
                if (data.address2.length > 70) {
                    errors.address2 = "address2 limit should be less than 70";
                    setErrorLable(true);
                    setAddress2Status(true);
                } else if (!Addressrgx.test(data.address2)) {
                    errors.address2 = "No special character is allowed";
                    setErrorLable(true);
                    setAddress2Status(true);
                } else {
                    setAddress2Status(false);
                }
            } else {
                setAddress2Status(false);
            }
            //town
            if (!data.town) {
                errors.town = "town is required";
                setTownStatus(true);
            } else {
                setTownStatus(false);
            }
            //contact_person
            if (!data.contact_person) {
                errors.contact_person = "Contact Person is required";
                setContact_personStatus(true);
                setErrorLable(true);
            } else if (data.contact_person.length > 20) {
                errors.contact_person = "Contact Person limit should be less than 20";
                setContact_personStatus(true);
                setErrorLable(true);
            } else if (!nameregex.test(data.contact_person)) {
                errors.contact_person = "Invaild!! No special character or number";
                setContact_personStatus(true);
                setErrorLable(true);
            } else {
                setContact_personStatus(false);
            }
            //phone_number
            if (!data.phone_number) {
                errors.phone_number = "Mobile Number is required";
                setPhoneNumberStatus(true);
            } else if (!mobilenoRegex.test(data.phone_number)) {
                errors.phone_number = "Invalid!! Phone Number";
                setPhoneNumberStatus(true);
            } else {
                setPhoneNumberStatus(false);
            }

            //remarks
            if (data.remarks) {
                if (data.remarks.length > 100) {
                    errors.remarks = "Remarks limit should be less than 100";
                    setRemarksStatus(true);
                } else {
                    setRemarksStatus(false);
                }
            }
            //active
            if (!data.active) {
                errors.active = "Active field is mandatory";
                setActiveStatus(true);
            } else {
                setActiveStatus(false);
            }

            console.log(errors);
            return errors;
        },
    });

    //mapping with warehouse ID
    const targetObject = wareHouseDataAll.find(
        (item) => item.CODE === warehouseid
    );
    console.log("formik00000", targetObject);
    if (viewWarehouse) {
        // debugger;
        formik.initialValues.code = targetObject ? targetObject.CODE : "";
        formik.initialValues.description = targetObject
            ? targetObject.DESCRIPTION
            : "";
        formik.initialValues.address1 = targetObject
            ? targetObject.MASK_WH_ADD1
            : "";
        formik.initialValues.address2 = targetObject
            ? targetObject.MASK_WH_ADD2
            : "";
        //   formik.initialValues.town = townList.filter((val) => {
        //     return (
        //         val.NAME == customerDetailsById.BillTown &&
        //     val.GEOG_ID == customerDetailsById.BillGeogId
        //     );
        // })[0];
        formik.initialValues.town = targetObject ? targetObject.MASK_TOWN : "";
        formik.initialValues.state = targetObject ? targetObject.MASK_STATE : "";
        formik.initialValues.district = targetObject
            ? targetObject.MASK_DISTRICT
            : "";
        formik.initialValues.zip_code = targetObject
            ? targetObject.MASK_PINCODE
            : "";

        formik.initialValues.contact_person = targetObject
            ? targetObject.MASK_CONTACT_PERSON
            : "";
        formik.initialValues.phone_number = targetObject
            ? targetObject.MASK_OTH_COMM_NUM
            : "";
        formik.initialValues.remarks = targetObject
            ? targetObject.MASK_REMARKS
            : "";
        formik.initialValues.active = targetObject
            ? targetObject.MASK_ACTIVE ?? "" === "Y"
                ? true
                : false
            : "";

        formik.initialValues.type = targetObject ? targetObject.MASK_TYPE : "";
        formik.initialValues.branch = targetObject
            ? targetObject.MASK_BRANCH_NAME
            : "";
        formik.initialValues.geog_id = targetObject?.MASK_GEOG_ID;
    } else {
        formik.initialValues.code = "";
        formik.initialValues.description = "";
        formik.initialValues.address1 = "";
        formik.initialValues.address2 = "";
        formik.initialValues.town = "";
        formik.initialValues.state = "";
        formik.initialValues.district = "";
        formik.initialValues.zip_code = "";
        formik.initialValues.contact_person = "";
        formik.initialValues.phone_number = "";
        formik.initialValues.remarks = "";
        formik.initialValues.active = true;
        // setTownGeogId("");
    }

    const submitWareHouseData = (data) => {
        setSaveloader(true);
        console.log("targetObject inside submit000", data.geog_id);
        console.log("targetObject inside submit001", townGeogId);
        console.log("targetObject inside submit", targetObject);
        const objUpload = {
            userId: auth.UserId,
            compId: auth.CompId,
            active_Status: data.active === true ? "Y" : "N",
            wH_Id: viewWarehouse ? targetObject.MASK_WH_ID ?? "" : "",
            wH_Code: data.code ?? "",
            // wH_CompId: viewWarehouse ? targetObject.MASK_COMP_ID ?? "" : "",
            wH_CompId: auth.CompId,
            description: data.description ?? "",
            contact_Person: data.contact_person ?? "",
            add1: data.address1 ?? "",
            add2: data.address2 ?? "",
            phoneNum: data.phone_number ?? "",
            pinCode: data.zip_code ?? "",
            remarks: data.remarks ?? "",
            iuKey: viewWarehouse ? "2" : "1",
            GeogId : townGeogId,
        };

        console.log("API CALLING DATA", objUpload);
        // return;  
        createAPIEndpoint()
            .post(`${Endpoint.SaveWareHouseMaster}`, JSON.stringify(objUpload), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                if (res.data.Status === "Success") {
                    toast.success(res.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    nav("/masters/warehousemaster");
                    fetchWarehouseApi();
                } else if(res.data.Status === "Warning") {
                    createAPIEndpointMessage()
                        .get(`${EndpointMessage.GetMessageByMsgIdSQL}?MsgId=${res.data.Message}`)
                        .then((response)=>{
                            toast.warning(response.data, {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 2000,
                            });
                        })
                        .catch((error)=>{
                            toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,           
                                closeButton: false,           
                                autoClose: 3000,
                            });
                            console.log(error);
                        });
                    
                }
                setSaveloader(false);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
  
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
                console.log(error);
                setSaveloader(false);
            });
    };


    console.log("load", saveloader);
    if (saveloader) {
        return <Loader />;
    } else {
        return (
            <>
                <div className="col-12 pt-3 pb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/masters/warehousemaster">Warehouse Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/masters/warehousemaster">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="">
                        <form onSubmit={formik.handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (codeStatus || descriptionStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Description <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Description</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Code <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="code"
                                                    name="code"
                                                    value={formik.values.code}
                                                    className="form-control"
                                                    autoFocus
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    disabled={viewWarehouse}
                                                />

                                                {formik.touched.code && formik.errors.code && (
                                                    <small className="p-error">{formik.errors.code}</small>
                                                )}
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Description <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="description"
                                                    name="description"
                                                    className="form-control"
                                                    value={formik.values.description}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.description &&
                                                    formik.errors.description && (
                                                    <small className="p-error">
                                                        {formik.errors.description}
                                                    </small>
                                                )}
                                            </div>

                                            {viewWarehouse && (
                                                <>
                                                    <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                                                        <label className="form-label">
                                                            Type <span className="mandatory">*</span>
                                                        </label>
                                                        <InputText
                                                            id="type"
                                                            name="type"
                                                            className="form-control"
                                                            value={formik.values.type}
                                                            disabled
                                                        />
                                                    </div>
                                                    {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-3 mb-3">
                                                        <label className="form-label">
                                                            Branch <span className="mandatory">*</span>
                                                        </label>
                                                        <InputText
                                                            id="branch"
                                                            name="branch"
                                                            className="form-control"
                                                            value={formik.values.branch}
                                                            disabled
                                                        />
                                                    </div> */}
                                                </>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (address1Status || townStatus || address2Status) ? (
                                                <div style={{ color: "red" }}>
                                                Address <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Address</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Address Line 1 <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="address1"
                                                    name="address1"
                                                    className="form-control"
                                                    value={formik.values.address1}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />

                                                {formik.touched.address1 && formik.errors.address1 && (
                                                    <small className="p-error">
                                                        {formik.errors.address1}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Address Line 2</label>
                                                <InputText
                                                    id="address2"
                                                    name="address2"
                                                    className="form-control"
                                                    value={formik.values.address2}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.address2 && formik.errors.address2 && (
                                                    <small className="p-error">
                                                        {formik.errors.address2}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Town <span className="mandatory">*</span>
                                                </label>
                                                <div
                                                    className="input-group"
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    <InputText
                                                        class="form-control"
                                                        placeholder="Select"
                                                        type="text"
                                                        value={
                                                            !confirmTown
                                                                ? formik.values.town
                                                                : (formik.values.town = townValue)
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue("town", e.target.value);
                                                        }}
                                                        style={{ flex: "1" }}
                                                        disabled
                                                    />
                                                    <div className="input-group-prepend">
                                                        <span
                                                            className="input-group-text ms-2"
                                                            onClick={() => {
                                                                setTownPopup(true);
                                                            }}
                                                        >
                                                            <Fa.FaSearch />
                                                        </span>
                                                    </div>
                                                </div>
                                                {formik.touched.town && formik.errors.town && (
                                                    <small className="p-error">{formik.errors.town}</small>
                                                )}
                                                {/* {!manualSegmentEnterBtn?getFormErrorMessage("segment"):null} */}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">State</label>
                                                <InputText
                                                    class="form-control"
                                                    id="state"
                                                    name="state"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.state
                                                            : (formik.values.state = stateValue)
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("state", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">District</label>
                                                <InputText
                                                    class="form-control"
                                                    id="district"
                                                    name="district"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.district
                                                            : (formik.values.district = districtValue)
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("district", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Pin Code <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="zip_code"
                                                    name="zip_code"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.zip_code
                                                            : (formik.values.zip_code = zipCode.slice(0, 6))
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("zip_code", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 && contact_personStatus ? (
                                            <div style={{ color: "red" }}>
                                                Contact Information <Fi.FiAlertTriangle />
                                            </div>
                                        ) : (
                                            <>Contact Information</>
                                        )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Contact Person <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="contact_person"
                                                    name="contact_person"
                                                    className="form-control"
                                                    value={formik.values.contact_person}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.contact_person &&
                                                    formik.errors.contact_person && (
                                                    <small className="p-error">
                                                        {formik.errors.contact_person}
                                                    </small>
                                                )}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Mobile Number <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="phone_number"
                                                    name="phone_number"
                                                    className="form-control"
                                                    value={formik.values.phone_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />

                                                {formik.touched.phone_number &&
                                                    formik.errors.phone_number && (
                                                    <small className="p-error">
                                                        {formik.errors.phone_number}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (activeStatus || remarksStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Other Details <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Other Details</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-9 col-md-9 col-sm-12 mb-3">
                                                <label className="form-label">Remarks</label>
                                                <InputText
                                                    id="remarks"
                                                    name="remarks"
                                                    value={formik.values.remarks}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />

                                                {formik.touched.remarks && formik.errors.remarks && (
                                                    <small className="p-error">{formik.errors.remarks}</small>
                                                )}
                                            </div>
                                            <div className="col-lg-3 col-md-3 col-sm-12 mb-3 mt-3">
                                                <label className="form-label d-inline">Active</label>
                                                <InputSwitch
                                                    className="switch-pos ms-3"
                                                    id="active"
                                                    name="active"
                                                    // className="d-inline"
                                                    type="switch"
                                                    checked={formik.values.active}
                                                    onChange={formik.handleChange}
                                                />
                                                {formik.touched.active && formik.errors.active && (
                                                    <small className="p-error">{formik.errors.active}</small>
                                                )}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div>
                                <div className="float-end my-4">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </form>
                        <GeoTownModal />
                    </div>
                </div>
            </>
        );
    }
}

export default WarehouseMasterNew;
