import Axios from "../../api/Axios";
const BASE_URL = "Default/";
export const Endpoint = {
    DefaultSettings: "DefaultSettings",
    PopulateGridData: "PopulateGridData",
    SaveDefaultSettings: "SaveDefaultSettings",
};


export const createAPIDefaultEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};