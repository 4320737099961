import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import { useReceiptsContext } from "../../context/ReceiptsContext";
import {
    createAPIReceiptsEndpoint,
    Endpoint,
} from "../../Services/Receipts/ReceiptsService";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import storage from "../../Services/Storage";
import { Dropdown } from "primereact/dropdown";
// import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import InvoiceesData from "./InvoiceesData";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import { InputText } from 'primereact/inputtext';
import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import format from "date-fns/format";
import Loader from "../../Components/Loader";
import { Dialog } from "primereact/dialog";

let remainingAdvanceValue = 0;

const NewReceipt = () => {
    const [partynamesugg, setPartynamesugg] = useState([]);
    const [partyname, setPartyname] = useState([]);
    const [onchangepartyname, setOnchangepartyname] = useState([]);
    const [modeofpayment, setModeofpayment] = useState([]);
    // const [closebalance, setClosebalance] = useState([]);
    // const [cheqinhand, setCheqinhand] = useState([]);
    // const [netbalance, setNetbalance] = useState([]);
    // const [advancerecpt, setAdvancerecpt] = useState([]);
    const [partycode, setPartycode] = useState([]);
    const [selectedDate, setSelectedDate] = useState(
        new Date().toISOString().slice(0, 10)
    );
    const [selectedModeOfPayment, setSelectedModeOfPayment] = useState([]);
    const [cashModeOn, setCashModeOn] = useState(false);
    const [otherdetls, setOtherdetls] = useState("");
    const [accountsNameSugg, setaccountsNameSugg] = useState([]);
    const [accountName, setAccountName] = useState([]);
    const [onchangeAccountname, setOnchangeAccountname] = useState([]);
    const [chequeDetails, setChequeDetails] = useState([]);
    // const [totDocumentAmt, setTotDocumentAmt] = useState(0);
    const [strChqInHandAcct, setStrChqInHandAcct] = useState("");
    const [viewDocumnetDetailsData, setViewDocumnetDetailsData] = useState([]);

    const [receiptNum, setReceiptNum] = useState("");
    const [viewAdvance, setViewAdvance] = useState("");
    const [viewTotamt, setviewTotamt] = useState("");
    const [viewadjustedNum, setViewadjustedNum] = useState("");
    const [viewTotalDocamt, setViewTotalDocamt] = useState("");
    const [viewVoucdt, setViewVoucdt] = useState("");
    const [viewMop, setViewMop] = useState("");
    const [getReceiptChqDetailGrid, setgetReceiptChqDetailGrid] = useState([]);
    const [showChkGrid, setShowChkGrid] = useState(false);
    const [saleDiscAcct, setSaleDiscAcct] = useState([]);
    const [saleDiscAcctName, setSaleDiscAcctName] = useState([]);
    const [cancelPopup, setCancelPopup] = useState(false);
    const [cancelPopupMsg, setCancelPopupMsg] = useState(false);

    const [totalamt, setTotalamt] = useState(0.0);
    const [viewReceiptStatus, setviewReceiptStatus] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const auth = storage.getLocal("user");
    const [searchParams] = useSearchParams();
    let receiptNo = searchParams.get("RCVHID");
    // const CustInfo_URL = "SalesOrder/GetCustomerInformation?";

    const {
        setAllInvoiceData,
        selectedInvoicesData,
        setselectedInvoicesData,
        setInvoicePopupShow,
    } = useReceiptsContext();

    const nav = useNavigate();

    useEffect(() => {
    // populateAllData();
    // setselectedInvoicesData([]);
        remainingAdvanceValue = 0;
        if (receiptNo != null) {
            //   console.log("receiptNo", receiptNo);
            setviewReceiptStatus(true);
            viewReceiptDetails(receiptNo);
            CheckAccoutForPosting();
        } else {
            populateAllData();
            CheckAccoutForPosting();
            setselectedInvoicesData([]);
        }
    }, []);

    const CheckAccoutForPosting = () => {
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.CheckAccoutForPosting}`)
            .then((response) => {
                // console.log("CheckAccoutForPosting", response.data);
                setSaleDiscAcct(response.data.strSaleDiscAcct);
                setSaleDiscAcctName(response.data.strSaleDiscAcctName);
                setStrChqInHandAcct(response.data.strChqInHandAcct);
            })
            .catch(() => {});
    };

    const viewReceiptDetails = async (receiptNo) => {
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.GetReceiptDetail}?MASK_RCVH_ID=${receiptNo}`)
            .then((response) => {
                // console.log("GetReceiptDetail0000", response.data);
                if (response.data[0].RCVH_MODE_ID === "CHQ") {
                    GetReceiptChqDetail(receiptNo);
                }
                setPartycode(response.data[0].PARTY_CODE);
                // financialinformation(response.data[0].PARTY_ID);
                setOnchangepartyname(response.data[0].PARTY_NAME);
                setReceiptNum(response.data[0].RCVH_NO);
                setViewMop(response.data[0].MOP_DESC);
                setOtherdetls(response.data[0].RCVH_OTH_DETL);
                // console.log("response.data[0]", response.data[0].Column1);
                setViewVoucdt(response.data[0].RCVH_DATE);
                setViewAdvance(Number(response.data[0].ADV_AMT).toFixed(2));
                setviewTotamt(Number(response.data[0].TOTAL_AMT).toFixed(2));
                setViewadjustedNum("0.00");
                setViewTotalDocamt("0.00");
                setSaveLoader(false);
                GetReceiptDocDetailsData();
            })
            .catch(() => {
                setSaveLoader(false);
            });
    };

    const GetReceiptDocDetailsData = () => {
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.GetReceiptDocDetail}?strRcptHdrId=${receiptNo}`)
            .then((response) => {
                // console.log("GetReceiptDocDetail", response.data);
                setViewDocumnetDetailsData(response.data);
                setSaveLoader(false);
            })
            .catch(() => {
                setSaveLoader(false);
            });
    };

    const GetReceiptChqDetail = (receiptNo) => {
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.GetReceiptChqDetail}?strRcptHdrId=${receiptNo}`)
            .then((response) => {
                // console.log("GetReceiptChqDetail", response.data);
                setgetReceiptChqDetailGrid(response.data);
                setShowChkGrid(true);
                setSaveLoader(false);
            })
            .catch(() => {
                setSaveLoader(false);
            });
    };

    //   console.log("partycode", partycode);
    //   console.log("partycode", selectedModeOfPayment);
    //   console.log("partycode", viewVoucdt);

    const formatDatetoDDMMYYYY = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };

    // const financialinformation = (partyId) => {
    //     setSaveLoader(true);
    //     createAPIReceiptsEndpoint()
    //         .get(
    //             `${Endpoint.GetCustFinancialInfo}?Party_Id=${partyId}&CompId=${auth.CompId}`
    //         )
    //         .then((response) => {
    //             console.log("GetCustFinancialInfo", response.data);
    //             setClosebalance(Number(response.data.Closingbal).toFixed(2));
    //             setCheqinhand(Number(response.data.ChqsInhand).toFixed(2));
    //             setNetbalance(Number(response.data.NetBal).toFixed(2));
    //             setAdvancerecpt(Number(response.data.AvailableCreditlimit).toFixed(2));
    //             setSaveLoader(false);
    //         })
    //         .catch(() => {
    //             setSaveLoader(false);
    //         });
    // };

    const populateAllData = () => {
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.PopulatePartyData}?BranchId=000&CompId=${auth.CompId}`)
            .then((response) => {
                // console.log("partydata", response.data);
                // const formattedData = response.data.map(
                //     (item) => `${item.PARTY_CODE}  ${item.PARTY_NAME}`
                // );
                // console.log("formattedData", formattedData);
                setPartynamesugg(response.data);
                setSaveLoader(false);
            })
            .catch(() => {
                setSaveLoader(false);
            });
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.PopulateMOPData}`)
            .then((response) => {
                // console.log("mop", response.data);
                setModeofpayment(response.data);
                setSelectedModeOfPayment(response.data[1]);
                setSaveLoader(false);
            })
            .catch(() => {
                setSaveLoader(false);
            });
    };

    const searchEmployee = (e) => {
        setTimeout(() => {
            let _filteredemployee;
            if (!e.query.trim().length) {
                _filteredemployee = [...partynamesugg];
            } else {
                _filteredemployee = partynamesugg.filter((employee) => {
                    return (
                        employee.PARTY_CODE.toLowerCase().includes(e.query.toLowerCase()) ||
            employee.PARTY_NAME.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }

            setPartyname(_filteredemployee);
        }, 250);
    };

    const searchAccount = (e) => {
        setTimeout(() => {
            let _filteredemployee;
            if (!e.query.trim().length) {
                _filteredemployee = [...accountsNameSugg];
            } else {
                _filteredemployee = accountsNameSugg.filter((employee) => {
                    return (
                        employee.BANK_CODE.toLowerCase().includes(e.query.toLowerCase()) ||
            employee.BANK_NAME.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }

            setAccountName(_filteredemployee);
        }, 250);
    };

    const employeeTemplate = (employee) => {
        return (
            <>
                {employee.PARTY_CODE} - {employee.PARTY_NAME}
            </>
        );
    };

    const accountTemplate = (employee) => {
        return (
            <>
                {employee.BANK_CODE} - {employee.BANK_NAME}
            </>
        );
    };

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };

    useEffect(() => {
        if (selectedModeOfPayment?.CODE == "CASH") {
            //   console.log("i am selected", selectedModeOfPayment.CODE);

            setCashModeOn(false);

            //   console.log("selectedModeOfPayment", selectedModeOfPayment.MASK_MOP_ID);

            //   console.log("selectedDate", selectedDate);
            //   console.log("onchangepartyname", onchangepartyname);
            callAllAccountData();
        } else {
            setCashModeOn(true);
            setOnchangeAccountname("");
            setTotalamt("");
            callAllAccountData();
        }
    }, [selectedModeOfPayment]);

    //   console.log("selectedInvoicesDatainside", selectedInvoicesData);

    let sumPayableAmount = 0;
    let sumofAdjusted = 0;
    if (selectedInvoicesData.length > 0) {
    // console.log("havedata");
    // console.log("selectedInvoicesData", selectedInvoicesData);

        sumPayableAmount = selectedInvoicesData.reduce((acc, current) => {
            return Number(acc) + Number(current.PAYABLE_AMOUNT);
        }, 0);
        sumPayableAmount = Number(sumPayableAmount).toFixed(2);

        sumofAdjusted = selectedInvoicesData.reduce((acc, current) => {
            return Number(acc) + Number(current.amount);
        }, 0);

        remainingAdvanceValue = Number(totalamt - sumofAdjusted).toFixed(2);
    }

    const callAllAccountData = async () => {
        setSaveLoader(true);
        const formatteddate = formatDatetoDDMMYYYY(selectedDate);
        // const custinfourl = `${CustInfo_URL}CICode=${auth.CICode}&strCustId=${customerId[0].MASK_CUST_ID}`;
        // const custinfores = await Axios.get(custinfourl);
        // console.log("custinfores",custinfores);
        createAPIReceiptsEndpoint()
            .get(
                `${Endpoint.PopulateAccountsData}?Mode=${selectedModeOfPayment.MASK_MOP_ID}&Voucher_Date=${formatteddate}&CurrId=INR&CompId=${auth.CompId}`
            )
            .then((response) => {
                // console.log("PopulateAccountsData", response.data);
                setaccountsNameSugg(response.data);
                setSaveLoader(false);
            })
            .catch(() => {
                setSaveLoader(false);
            });
    };

    const handleChange = async (e) => {
        setOnchangepartyname(e.target.value);
        // console.log("abc", e.target.value.MASK_PARTY_ID);
        setPartycode(e.target.value.PARTY_CODE);
    // setSaveLoader(true);
    // createAPIReceiptsEndpoint()
    //     .get(
    //         `${Endpoint.GetCustFinancialInfo}?Party_Id=${e.target.value.MASK_PARTY_ID}&CompId=${auth.CompId}`
    //     )
    //     .then((response) => {
    //         console.log("mop", response.data);
    //         setClosebalance(Number(response.data.Closingbal).toFixed(2));
    //         setCheqinhand(Number(response.data.ChqsInhand).toFixed(2));
    //         setNetbalance(Number(response.data.NetBal).toFixed(2));
    //         setAdvancerecpt(Number(response.data.AvailableCreditlimit).toFixed(2));
    //         setSaveLoader(false);
    //         // setModeofpayment(response.data);
    //     })
    //     .catch(() => {setSaveLoader(false); });
    // console.log("hello", e.target.value);
    };

    const handleTotalAmt = (e) => {
        const value = e.target.value;
        if (/^\d*\.?\d{0,2}$/.test(value) || value === "") {
            setTotalamt(value);
            remainingAdvanceValue = value;
        }
    };

    const handleAccountChange = (e) => {
    // console.log("accountname", e.target.value);
        setOnchangeAccountname(e.target.value);
    };
    // adjustDocumentForCheque
    const adjustDocumentForCheque = () => {
        const formatteddate = formatDatetoDDMMYYYY(selectedDate);

        if (onchangepartyname == "") {
            toast.warn("Please Enter  Customer Name", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else if (totalamt <= 0) {
            toast.warn("Please Validate the Data", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else {
            setSaveLoader(true);
            createAPIReceiptsEndpoint()
                .get(
                    `${Endpoint.AdjustDocument}?PartyId=${onchangepartyname.MASK_PARTY_ID}&VouDate=${formatteddate}&RcptMode=${selectedModeOfPayment.MASK_MOP_ID}`
                )
                .then((response) => {
                    //   console.log("AdjustDocument000", response.data);
                    //   console.log("AdjustDocument", response.data.Table);
                    setSaveLoader(false);
                    setAllInvoiceData(response.data.Table);
                })
                .catch(() => {
                    setSaveLoader(false);
                });

            setInvoicePopupShow(true);
        }
    };

    const adjustDocument = () => {
    // console.log("hello world");
        const formatteddate = formatDatetoDDMMYYYY(selectedDate);
        if (onchangepartyname == "") {
            toast.warn("Please Enter  Customer Name", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else if (totalamt <= 0) {
            toast.warn("Total Amount Can't be Null", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else if (onchangeAccountname == "") {
            toast.warn("Please Select Account Name", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else {
            // setInvoicePopupShow(true);
            setSaveLoader(true);
            createAPIReceiptsEndpoint()
                .get(
                    `${Endpoint.AdjustDocument}?PartyId=${onchangepartyname.MASK_PARTY_ID}&VouDate=${formatteddate}&RcptMode=${selectedModeOfPayment.MASK_MOP_ID}`
                )
                .then((response) => {
                    //   console.log("AdjustDocument", response.data);
                    setAllInvoiceData(response.data.Table);
                    setSaveLoader(false);
                    setInvoicePopupShow(true);
                })
                .catch(() => {
                    setSaveLoader(false);
                });
        }
    };

    const validate = (values) => {
        let isAnyRowFilled = false;
        const errors = {};

        values.forEach((row, index) => {
            if (row.ChequeNo || row.ChequeDate || row.DrawnOn || row.PaymentAmount) {
                isAnyRowFilled = true;
                if (!row.ChequeNo) {
                    errors[index] = "ChequeNo is required for Row number " + (index + 1);
                } else if (!row.ChequeDate) {
                    errors[index] =
            "ChequeDate is required for Row number " + (index + 1);
                } else if (!row.DrawnOn) {
                    errors[index] = "DrawnOn is required for Row number " + (index + 1);
                } else if (!row.PaymentAmount) {
                    errors[index] =
            "PaymentAmount is required for Row number " + (index + 1);
                }
            }
        });

        if (!isAnyRowFilled) {
            errors[0] = "Please enter atleast one Cheque Details";
            toast.warn(`${errors[0]}`, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        }

        return errors;
    };

    const formik = useFormik({
        initialValues: [
            {
                index: 1,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 2,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 3,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 4,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 5,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 6,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 7,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 8,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 9,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
            {
                index: 10,
                ChequeNo: "",
                ChequeDate: "",
                DrawnOn: "",
                PaymentAmount: "",
            },
        ],
        validate,
        onSubmit: (values) => {
            //   console.log("Formsubmitted:", values);
            //   console.log("errors", formik.errors);
            //   const errors = validate(values);
            if (onchangepartyname == "") {
                toast.warn("Please Enter Customer Name", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            }
            //   else if (Object.keys(errors).length > 0) {
            //     // If there are errors, display them
            //     console.log("Validation errors:", errors);
            //     // Optionally, you can set the formik errors object to display errors next to fields
            //     // formik.setErrors(errors);
            //   }
            else {
                // adjustDocumentForCheque(values);
                setChequeDetails(values);
                const TotalAmountChk = values.reduce((acc, current) => {
                    return Number(acc) + Number(current.PaymentAmount);
                }, 0);
                remainingAdvanceValue = TotalAmountChk;
                setTotalamt(TotalAmountChk);
                toast.success("Validation completed successfully.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            }
        },
    });

    const onAmountChange = (val, rowData) => {
        const updatedData = selectedInvoicesData.map((item) => {
            if (item.DOCUMENT_NO === rowData.DOCUMENT_NO) {
                return { ...item, amount: val };
            }
            return item;
        });
        setselectedInvoicesData(updatedData);
    };

    const handleRemoveRow = (rowData) => {
        const newData = selectedInvoicesData.filter(
            (item) => item.DOCUMENT_NO !== rowData.DOCUMENT_NO
        );
        remainingAdvanceValue = totalamt;
        setselectedInvoicesData(newData);
    };

    const renderAction = (rowData) => {
        return (
            <>
                <div className="action-icon">
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancel"
                    >
                        <button
                            className="grid-icon"
                            onClick={() => {
                                handleRemoveRow(rowData);
                            }}
                        >
                            <Fa.FaTimes className="cancel" />
                        </button>
                    </div>
                </div>
            </>
        );
    };

    const handleotherDetails = (e) => {
        setOtherdetls(e.target.value);
    };

    const formatteddate = (datestring) => {
        let parts = datestring.split("-");
        let date = new Date(parts[2], parts[1] - 1, parts[0]);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;
        let formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    };

    const saveRecieptData = () => {
    // console.log("selectedInvoicesData", selectedInvoicesData);
    // console.log("underrecieptdata", onchangepartyname);
    // console.log("underrecieptdata", chequeDetails);
    // console.log("underrecieptdata", sumPayableAmount);
    // console.log("sumofAdjusted", sumofAdjusted);
    // console.log("underrecieptdata", remainingAdvanceValue);
    // console.log("totalamt", totalamt);
    // console.log("underrecieptdata", onchangeAccountname);
    // console.log("underrecieptdata", selectedModeOfPayment);
    // console.log("underrecieptdata", otherdetls);
    // console.log("underrecieptdata", selectedDate);

        function checkAmountVsPayable(invoice) {
            let amount = parseFloat(invoice.amount);
            let payableAmount = parseFloat(invoice.PAYABLE_AMOUNT);
            if (amount > payableAmount) {
                return true;
            }
            return false;
        }

        let objDocdetals = [];
        let isAmountGreaterThanPayable = false;
        if (selectedInvoicesData.length > 0) {
            // function checkAmountVsPayable(invoice) {
            //     let amount = parseFloat(invoice.amount);
            //     let payableAmount = parseFloat(invoice.PAYABLE_AMOUNT);
            //     if (amount > payableAmount) {
            //         return true;
            //     }
            //     return false;
            // }
            selectedInvoicesData.forEach((invoice) => {
                if (checkAmountVsPayable(invoice)) {
                    isAmountGreaterThanPayable = true;
                }
            });
        }
        objDocdetals = selectedInvoicesData.map((val) => ({
            masK_DOCUMENT_ID: "",
            documenT_NO: val.DOCUMENT_NO,
            documenT_DATE: formatteddate(val.DOCUMENT_DATE).toString(),
            documenT_AMOUNT: val.MASK_DOCUMENT_AMT.toString(),
            payable: val.PAYABLE_AMOUNT.toString(),
            amount: Number(val.amount).toString(),
            masK_DOCUMENT_TYPE: val.MASK_DOC_TYPE,
            masK_DOC_SR_NO: val.PAYTERM_SERIAL_NUMBAR.toString(),
            masK_EXCHANGE_RATE: val.MASK_EXCHANGE_RATE.toString(),
            masK_CN_AMT: val.MASK_CN_AMT.toString(),
            masK_ACPR_ID: "",
        }));

        if (selectedModeOfPayment.MASK_MOP_ID == "CASH") {
            if (onchangepartyname == "") {
                toast.warn("Please enter Customer Name ", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            } else if (onchangeAccountname == "") {
                toast.warn("Please enter Account", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            } else if (totalamt <= 0) {
                toast.warn("Please enter Total Amount", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            } else if (isAmountGreaterThanPayable) {
                toast.warn("Amount is greater than payable amount for invoice", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            } else {
                const payload = {
                    userID: auth.UserId,
                    compId: auth.CompId,
                    partyCode: onchangepartyname.MASK_PARTY_ID,
                    mode: selectedModeOfPayment.MASK_MOP_ID,
                    vouDate: format(new Date(selectedDate), "dd/MM/yyyy"),
                    account: onchangeAccountname.MASK_ACCT_ID ?? "", //csah then ""
                    totalAmt: totalamt.toString(),
                    adjAmt: sumofAdjusted.toString(),
                    advAmt: remainingAdvanceValue.toString(),
                    rcptNo: "",
                    chqInHandAcct: strChqInHandAcct,
                    othDtl: otherdetls,
                    reptAcctId: strChqInHandAcct,
                    branchId: "000",
                    objDocDetail: objDocdetals,
                    objChqDetailGrid: [],
                };
                callingSaveApi(payload);
            }
        } else {
            if (onchangepartyname == "") {
                toast.warn("Please enter Customer Name ", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            } else if (totalamt <= 0) {
                toast.warn("Please Validate the Data", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            } else if (isAmountGreaterThanPayable) {
                toast.warn("Amount is greater than payable amount for invoice", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            } else {
                // console.log("apiCallforReceipt.........");
                // console.log("objDocdetals", objDocdetals);
                // console.log("chequeDetails", chequeDetails);
                // objDocdetals

                const totalAdjustedAmt = objDocdetals.reduce((acc, current) => {
                    return Number(acc) + Number(current.amount);
                }, 0);
                // console.log("totalAdjustedAmt", totalAdjustedAmt);
                //         let remainingAmount = totalAdjustedAmt;
                // updatedselectedreceivableDocumentData.forEach((item) => {
                //     if (remainingAmount >= item.PAYABLE_AMOUNT) {
                //         item.amount = item.PAYABLE_AMOUNT;
                //         remainingAmount -= item.PAYABLE_AMOUNT;
                //     } else {
                //         item.amount = remainingAmount;
                //         remainingAmount = 0;
                //     }
                // });
                let filteredChqkDetails = [];
                chequeDetails.forEach((val) => {
                    if (Number(val.PaymentAmount) > 0) {
                        filteredChqkDetails.push(val);
                    }
                    return null;
                });
                // console.log(filteredChqkDetails);

                let objChqDetailGrid = filteredChqkDetails.map((val) => ({
                    chequE_NUMBER: val.ChequeNo,
                    chequE_DATE: format(new Date(val.ChequeDate), "dd/MM/yyyy"),
                    drawN_ON: val.DrawnOn,
                    accounT_NUMBER: "",
                    branch: "",
                    paymenT_AMOUNT: val.PaymentAmount,
                    advancE_AMOUNT: Number(0).toString(),
                    masK_BANK_RECO_TAG: "",
                }));

                let remainingAmount = totalAdjustedAmt;
                for (let i = 0; i < objChqDetailGrid.length; i++) {
                    let paymentAmount = parseFloat(objChqDetailGrid[i].paymenT_AMOUNT);
            
                    if (paymentAmount > remainingAmount) {
                        let insertedamt = Number(paymentAmount - remainingAmount);
                        objChqDetailGrid[i].advancE_AMOUNT = Number(insertedamt).toString();
                        remainingAmount = 0; 
                    } else {
                        remainingAmount -= paymentAmount;
                    }
                }

                // console.log("objChqDetailGrid",objChqDetailGrid);
                // let strconverted = objChqDetailGrid.map((val) => ({
                //     chequE_NUMBER: val.chequE_NUMBER,
                //     chequE_DATE: val.chequE_DATE,
                //     drawN_ON: val.drawN_ON,
                //     accounT_NUMBER: "",
                //     branch: "",
                //     paymenT_AMOUNT: val.paymenT_AMOUNT,
                //     advancE_AMOUNT: val.advancE_AMOUNT,
                //     masK_BANK_RECO_TAG: "",
                //   }));

                // console.log("strconverted",strconverted);
                // console.log("objChqDetailGrid",objChqDetailGrid);
                const payload = {
                    userID: auth.UserId,
                    compId: auth.CompId,
                    partyCode: onchangepartyname.MASK_PARTY_ID,
                    mode: selectedModeOfPayment.MASK_MOP_ID,
                    vouDate: format(new Date(selectedDate), "dd/MM/yyyy"),
                    account: onchangeAccountname.MASK_ACCT_ID ?? "", //csah then ""
                    totalAmt: totalamt.toString(),
                    adjAmt: sumofAdjusted.toString(),
                    advAmt: remainingAdvanceValue.toString(),
                    rcptNo: "",
                    chqInHandAcct: strChqInHandAcct,
                    othDtl: otherdetls,
                    reptAcctId: strChqInHandAcct,
                    branchId: "000",
                    objDocDetail: objDocdetals,
                    objChqDetailGrid: objChqDetailGrid,
                };

                // console.log("payload", payload);
                callingSaveApi(payload);
            }
        }
        // let filteredChqkDetails = [];
        // chequeDetails.map((val) => {
        //     if (Number(val.PaymentAmount) > 0) {
        //         filteredChqkDetails.push(val);
        //     }
        // });
        // console.log(filteredChqkDetails);

        // let objChqDetailGrid = filteredChqkDetails.map((val) => ({
        //     chequE_NUMBER: val.ChequeNo,
        //     chequE_DATE: format(new Date(val.ChequeDate), "dd/MM/yyyy"),
        //     drawN_ON: val.DrawnOn,
        //     accounT_NUMBER: "",
        //     branch: "",
        //     paymenT_AMOUNT: val.PaymentAmount,
        //     advancE_AMOUNT: "0",
        //     masK_BANK_RECO_TAG: "",
        // }));

        // let objDocdetals = selectedInvoicesData.map((val) => ({
        //     masK_DOCUMENT_ID: "",
        //     documenT_NO: val.DOCUMENT_NO,
        //     documenT_DATE: formatteddate(val.DOCUMENT_DATE).toString(),
        //     documenT_AMOUNT: val.MASK_DOCUMENT_AMT.toString(),
        //     payable: val.PAYABLE_AMOUNT.toString(),
        //     amount: val.amount.toString(),
        //     masK_DOCUMENT_TYPE: val.MASK_DOC_TYPE,
        //     masK_DOC_SR_NO: val.PAYTERM_SERIAL_NUMBAR.toString(),
        //     masK_EXCHANGE_RATE: val.MASK_EXCHANGE_RATE.toString(),
        //     masK_CN_AMT: val.MASK_CN_AMT.toString(),
        //     masK_ACPR_ID: "",
        // }));

        // console.log("objChqDetailGrid", objChqDetailGrid);
        // console.log("objDocdetals", objDocdetals);

        // const payload = {
        //     userID: auth.UserId,
        //     compId: auth.CompId,
        //     partyCode: onchangepartyname.MASK_PARTY_ID,
        //     mode: selectedModeOfPayment.MASK_MOP_ID,
        //     vouDate: format(new Date(selectedDate), "dd/MM/yyyy"),
        //     account: onchangeAccountname.MASK_ACCT_ID ?? "", //csah then ""
        //     totalAmt: totalamt.toString(),
        //     adjAmt: sumofAdjusted.toString(),
        //     advAmt: remainingAdvanceValue.toString(),
        //     rcptNo: "",
        //     chqInHandAcct: strChqInHandAcct,
        //     othDtl: otherdetls,
        //     reptAcctId: strChqInHandAcct,
        //     branchId: "000",
        //     objDocDetail: objDocdetals,
        //     objChqDetailGrid: objChqDetailGrid,
        // };

    // console.log("payload", payload);
    };

    const callingSaveApi = (payload) => {
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .post(`${Endpoint.SaveReceipt}`, JSON.stringify(payload), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                // console.log("Ab000", res.data);
                toast.success(
                    `Receipts Created successfully with ReceiptNo: ${res.data.ReceiptNo}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    }
                );
                setSaveLoader(false);
                nav("/creditmanagement/receipts");
            })
            .catch((error) => {
                // console.log("error", error);
                if (error.response.data.Status == "Warning") {
                    toast.warn(`${error.response.data.Message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    //   console.log("erroe");
                }
                setSaveLoader(false);
            });
    };

    //   console.log("afteramountinvoicedata", selectedInvoicesData.length);

    //   console.log("receiptNum", receiptNum);

    const handleCancelReceipt = () => {
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .get(`${Endpoint.CancelCheck}?RCVHId=${receiptNo}&RcptNo=${receiptNum}`)
            .then((response) => {
                // console.log("CancelCheck", response.data);
                if (response.data.Status == "Success") {
                    setCancelPopupMsg(response.data.Message);
                    setCancelPopup(true);
                } else {
                    toast.warn("Something Went Wrong", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                }
                setSaveLoader(false);
            })
            .catch((error) => {
                toast.error(error.response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 8000,
                });
                setSaveLoader(false);
            });
    };

    const handleClickYes = () => {
        callingConfirmCncel();
    };
    const handleClickNo = () => {
        setCancelPopup(false);
    };

    const callingConfirmCncel = () => {
        setSaveLoader(true);
        createAPIReceiptsEndpoint()
            .get(
                `${Endpoint.ConfirmCancel}?RCVHId=${receiptNo}&SaleDiscAcct=${saleDiscAcct}&SaleDiscAcctName=${saleDiscAcctName}&UserId=${auth.UserId}`
            )
            .then(() => {
                // console.log("response", response);
                toast.success("Record Deleted successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                setSaveLoader(false);
                setCancelPopup(false);
                nav("/creditmanagement/receipts");
            })
            .catch(() => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                // console.log(error);
            });
    };

    if (saveLoader) {
        return (
            <>
                <Loader />
            </>
        );
    }
    return (
        <>
            <div className="col-12 pt-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mx-1">
                                <li className="breadcrumb-item">
                                    <Link to="/creditmanagement/receipts">Receipts</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Details</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end">
                            <Link to="/creditmanagement/receipts">
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary me-3"
                                >
                                    <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                  Records
                                </button>
                            </Link>
                            {viewReceiptStatus && (
                                <button
                                    type="submit"
                                    className="btn btn-cancel-red me-3"
                                    onClick={handleCancelReceipt}
                                >
                  Cancel
                                </button>
                            )}
                            {!viewReceiptStatus && (
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={saveRecieptData}
                                    // disabled={selectedInvoicesData.length>0?false:false}
                                >
                  Save
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <section className="sales-box">
                    <form>
                        <div className="row">
                            {/* <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                                    Closing Balance <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="closebal"
                                    name="closebal"
                                    value={closebalance}
                                    className={"form-control"}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                                    Cheques in Hand <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="chqhand"
                                    name="chqhand"
                                    value={cheqinhand}
                                    className={"form-control"}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                                    Net Balance <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="netbal"
                                    name="netbal"
                                    value={netbalance}
                                    className={"form-control"}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                                    Advance Receipt <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="advrecpt"
                                    name="advrecpt"
                                    value={advancerecpt}
                                    className={"form-control"}
                                    disabled
                                />
                            </div> */}
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Customer Code <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="partycode"
                                    name="partycode"
                                    value={partycode}
                                    className={"form-control"}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Customer Name <span className="mandatory">*</span>
                                </label>
                                <div className="d-block">
                                    <AutoComplete
                                        field="PARTY_NAME"
                                        value={onchangepartyname}
                                        suggestions={partyname}
                                        completeMethod={searchEmployee}
                                        onChange={(e) => {
                                            handleChange(e);
                                        }}
                                        dropdown={true}
                                        itemTemplate={employeeTemplate}
                                        emptyMessage="No Customer Name found."
                                        showEmptyMessage
                                        disabled={viewReceiptStatus}
                                    />
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Receipt No. <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    id="partycode"
                                    name="partycode"
                                    value={receiptNum}
                                    className={"form-control"}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Voucher Date <span className="mandatory">*</span>
                                </label>
                                {!viewReceiptStatus && (
                                    <input
                                        type="date"
                                        className="form-control"
                                        defaultValue={selectedDate}
                                        max={selectedDate}
                                        min="2010-01-01"
                                        name="date-field"
                                        onChange={handleDateChange}
                                    />
                                )}
                                {viewReceiptStatus && (
                                    <InputText
                                        clasclassName="form-control"
                                        type="text"
                                        value={viewVoucdt}
                                        disabled
                                    />
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Mode <span className="mandatory">*</span>
                                </label>
                                {!viewReceiptStatus && (
                                    <Dropdown
                                        inputId="payment_mode"
                                        name="payment_mode"
                                        value={selectedModeOfPayment}
                                        options={modeofpayment}
                                        optionLabel="DESCRIPTION"
                                        // placeholder="Select"
                                        // className={classNames({
                                        //     "p-invalid": isFormFieldInvalid("payment_mode"),
                                        // })}
                                        onChange={(e) => {
                                            setSelectedModeOfPayment(e.value);
                                        }}
                                        disabled={
                                            selectedInvoicesData.length > 0
                                                ? true
                                                : false || viewReceiptStatus
                                        }
                                    />
                                )}
                                {viewReceiptStatus && (
                                    <InputText
                                        clasclassName="form-control"
                                        type="text"
                                        value={viewMop}
                                        disabled
                                    />
                                )}
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Other Details</label>
                                <InputText
                                    id="closebal"
                                    name="closebal"
                                    value={otherdetls}
                                    className={"form-control"}
                                    onChange={handleotherDetails}
                                    disabled={viewReceiptStatus}
                                />
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">
                  Account <span className="mandatory">*</span>
                                </label>
                                <AutoComplete
                                    field="BANK_NAME"
                                    value={onchangeAccountname}
                                    suggestions={accountName}
                                    completeMethod={searchAccount}
                                    onChange={(e) => {
                                        handleAccountChange(e);
                                    }}
                                    dropdown={true}
                                    itemTemplate={accountTemplate}
                                    emptyMessage="No Account found."
                                    showEmptyMessage
                                    disabled={cashModeOn || viewReceiptStatus}
                                />
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Advance</label>
                                <InputText
                                    id="closebal"
                                    name="closebal"
                                    // value={remainingAdvanceValue}
                                    value={
                                        viewReceiptStatus ? viewAdvance : remainingAdvanceValue
                                    }
                                    className={"form-control"}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Total Amount</label>
                                <InputText
                                    id="closebal"
                                    name="closebal"
                                    // value={totalamt}
                                    value={viewReceiptStatus ? viewTotamt : totalamt}
                                    className={"form-control"}
                                    onChange={handleTotalAmt}
                                    disabled={
                                        cashModeOn || selectedInvoicesData.length > 0
                                            ? true
                                            : false || viewReceiptStatus
                                    }
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Adjusted</label>
                                <InputText
                                    id="closebal"
                                    name="closebal"
                                    value={viewReceiptStatus ? viewadjustedNum : sumofAdjusted}
                                    className={"form-control"}
                                    disabled
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">Total Document Amount</label>
                                <InputText
                                    id="closebal"
                                    name="closebal"
                                    // value={sumPayableAmount}
                                    value={viewReceiptStatus ? viewTotalDocamt : sumPayableAmount}
                                    className={"form-control"}
                                    disabled
                                />
                            </div>

                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="float-end mt-2">
                                    {!cashModeOn && (
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() => {
                                                adjustDocument();
                                            }}
                                        >
                      Adjust Document
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                {!viewReceiptStatus && (
                    <>
                        {cashModeOn && (
                            <section>
                                {Object.keys(formik.errors).length > 0 && (
                                    <div style={{ color: "red", textAlign: "center" }}>
                                        {Object.values(formik.errors).map((error, index) => (
                                            <div key={index}>{error}</div>
                                        ))}
                                    </div>
                                )}
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-end">
                                        <button
                                            type="submit"
                                            className="btn btn-outline-primary me-3"
                                            disabled={Object.keys(formik.errors).length !== 0}
                                        >
                      Validate
                                        </button>
                                        <button
                                            type="button"
                                            onClick={adjustDocumentForCheque}
                                            className="btn btn-primary"
                                            disabled={Object.keys(formik.errors).length !== 0}
                                        >
                      Adjust Document
                                        </button>
                                    </div>
                                    <section className="DataTable py-4">
                                        <DataTable
                                            value={formik.values}
                                            editable={true}
                                            editMode="cell"
                                            className="p-datatable-inline"
                                            tableStyle={{ minWidth: "60rem" }}
                                            disabled={selectedInvoicesData.length > 0 ? true : false}
                                        >
                                            <Column field="index" header="Row No"></Column>
                                            <Column
                                                field="ChequeNo"
                                                header="Cheque/Draft No"
                                                body={(rowData) => (
                                                    <InputText
                                                        style={{ width: "150px" }}
                                                        value={rowData.ChequeNo}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const regex = /^\d*\.?\d{0,2}$/;
                                                            if (regex.test(inputValue) || inputValue === "") {
                                                                formik.setFieldValue(
                                                                    `[${formik.values.indexOf(
                                                                        rowData
                                                                    )}].ChequeNo`,
                                                                    inputValue
                                                                );
                                                            }
                                                        }}
                                                        disabled={
                                                            selectedInvoicesData.length > 0 ? true : false
                                                        }
                                                    />
                                                )}
                                            />
                                            <Column
                                                field="ChequeDate"
                                                header="Cheque/Draft Date"
                                                body={(rowData) => (
                                                    <div className="card flex justify-content-center Small_Calender">
                                                        <Calendar
                                                            id="buttondisplay"
                                                            maxDate={new Date()}
                                                            minDate={new Date("01/01/2001")}
                                                            value={rowData.ChequeDate}
                                                            onChange={(e) =>
                                                                formik.setFieldValue(
                                                                    `[${formik.values.indexOf(
                                                                        rowData
                                                                    )}].ChequeDate`,
                                                                    e.target.value
                                                                )
                                                            }
                                                            disabled={
                                                                selectedInvoicesData.length > 0 ? true : false
                                                            }
                                                            showIcon
                                                        />
                                                    </div>
                                                )}
                                            />
                                            <Column
                                                field="DrawnOn"
                                                header="Drawn On"
                                                body={(rowData) => (
                                                    <InputText
                                                        style={{ width: "150px" }}
                                                        value={rowData.DrawnOn}
                                                        onChange={(e) =>
                                                            formik.setFieldValue(
                                                                `[${formik.values.indexOf(rowData)}].DrawnOn`,
                                                                e.target.value
                                                            )
                                                        }
                                                        disabled={
                                                            selectedInvoicesData.length > 0 ? true : false
                                                        }
                                                    />
                                                )}
                                            />
                                            <Column
                                                field="PaymentAmount"
                                                header="Payment Amount"
                                                body={(rowData) => (
                                                    <InputText
                                                        style={{ width: "150px" }}
                                                        value={rowData.PaymentAmount}
                                                        onChange={(e) => {
                                                            const inputValue = e.target.value;
                                                            const regex = /^\d*\.?\d{0,2}$/;
                                                            if (regex.test(inputValue) || inputValue === "") {
                                                                formik.setFieldValue(
                                                                    `[${formik.values.indexOf(
                                                                        rowData
                                                                    )}].PaymentAmount`,
                                                                    inputValue
                                                                );
                                                            }
                                                        }}
                                                        // onChange={(e) =>
                                                        //   formik.setFieldValue(
                                                        //     `[${formik.values.indexOf(rowData)}].PaymentAmount`,
                                                        //     e.target.value
                                                        //   )
                                                        // }
                                                        disabled={
                                                            selectedInvoicesData.length > 0 ? true : false
                                                        }
                                                    />
                                                )}
                                            />
                                        </DataTable>
                                    </section>
                                    {/* {Object.keys(formik.errors).length > 0 && (
                <div style={{ color: 'red' }}>
                    {Object.values(formik.errors).map((error, index) => (
                        <div key={index}>{error}</div>
                    ))}
                </div>
            )} */}
                                    {/* <button
                      type="submit"
                      // title="Cancel"
                      className="btn btn-outline-primary me-3"
                    >
                      Validate
                    </button> */}
                                    {/* <button type="submit">Submit</button> */}
                                </form>
                            </section>
                        )}
                    </>
                )}

                {!viewReceiptStatus && (
                    <section className="DataTable">
                        <DataTable
                            value={selectedInvoicesData}
                            paginator
                            rows={10}
                            filterDisplay="row"
                            showGridlines
                            stripedRows
                            scrollHeight="80vh"
                            emptyMessage="No Invoice found."
                            tableStyle={{ minWidth: "60rem" }}
                            editable={true}
                            editMode="row"
                        >
                            <Column
                                field="DOCUMENT_NO"
                                header="Documnet Number"
                                style={{ minWidth: "8rem" }}
                            ></Column>
                            <Column
                                field="DOCUMENT_DATE"
                                header="Document Date"
                                style={{ minWidth: "12rem" }}
                            ></Column>
                            <Column
                                field="MASK_DOCUMENT_AMT"
                                header="Document Amount "
                                style={{ minWidth: "12rem" }}
                            ></Column>
                            <Column
                                field="PAYABLE_AMOUNT"
                                header="Payable"
                                style={{ minWidth: "12rem" }}
                            ></Column>
                            {/* <Column field="amount" header="Amount"><InputText/></Column> */}
                            <Column
                                field="amount"
                                header="Amount"
                                body={(rowData) => (
                                    <InputText
                                        value={rowData.amount}
                                        // onChange={(e) => onAmountChange(e, rowData)}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            const regex = /^\d+$/;
                                            if (regex.test(inputValue) || inputValue === "") {
                                                onAmountChange(inputValue, rowData);
                                            }
                                        }}
                                    />
                                )}
                            />
                            <Column body={renderAction} header="Action "></Column>
                        </DataTable>
                    </section>
                )}

                {showChkGrid && (
                    <section className="DataTable">
                        <DataTable
                            value={getReceiptChqDetailGrid}
                            // filterDisplay="row"
                            showGridlines
                            stripedRows
                            scrollHeight="80vh"
                            emptyMessage="No Invoice found."
                            tableStyle={{ minWidth: "60rem" }}
                        >
                            <Column
                                field="CHEQUE_NUMBER"
                                header="Cheque/Draft No"
                                style={{ minWidth: "8rem" }}
                            ></Column>
                            <Column
                                field="CHEQUE_DATE"
                                header="Cheque/Draft Date"
                                style={{ minWidth: "12rem" }}
                            ></Column>
                            <Column
                                field="DRAWN_ON"
                                header="Drawn On "
                                style={{ minWidth: "12rem" }}
                            ></Column>
                            <Column
                                field="PAYMENT_AMOUNT"
                                header="Payment Amount"
                                style={{ minWidth: "12rem" }}
                            />
                        </DataTable>
                    </section>
                )}

                {viewReceiptStatus && (
                    <section className="DataTable">
                        <DataTable
                            value={viewDocumnetDetailsData}
                            // filterDisplay="row"
                            showGridlines
                            stripedRows
                            scrollHeight="80vh"
                            emptyMessage="No Invoice found."
                            tableStyle={{ minWidth: "60rem" }}
                        >
                            <Column
                                field="DOCUMENT_NO"
                                header="Documnet Number"
                                style={{ minWidth: "8rem" }}
                            ></Column>
                            <Column
                                field="DOCUMENT_DATE"
                                header="Document Date"
                                style={{ minWidth: "8rem" }}
                            ></Column>
                            <Column
                                field="DOCUMENT_AMOUNT"
                                header="Document Amount "
                                style={{ minWidth: "8rem" }}
                            ></Column>
                            {/* <Column
              field="PAYABLE"
              header="Payable"
              style={{ minWidth: "12rem" }}
            ></Column> */}
                            <Column
                                field="AMOUNT"
                                header="Amount"
                                style={{ minWidth: "8rem" }}
                            />
                        </DataTable>
                    </section>
                )}
                <InvoiceesData />
            </div>
            <Dialog
                header="Confirmation"
                visible={cancelPopup}
                style={{ width: "50vw" }}
                closable={false}
                draggable={false}
                resizable={false}
            >
                <div className="text-center py-2">{cancelPopupMsg}</div>
                <div className="float-end">
                    <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={handleClickYes}
                    >
            Yes
                    </button>
                    <button
                        type="button"
                        className="btn btn-cancel-red"
                        onClick={handleClickNo}
                    >
            No
                    </button>
                </div>
            </Dialog>
        </>
    );
};

export default NewReceipt;
