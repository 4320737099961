import { Dialog } from "primereact/dialog";
import React from "react";

const PrintInvoicePopUp = (props) => {
    // const{invoicePopup, setInvoicePopup} = useGlobalContext1();
    console.log(props.show,"lanscapestate");
    const clickClose = () => {
        props.hideShow(false);
    };
    return (
        <>
            <Dialog header="Information" visible={props.show} style={{ width: "50vw" }}
                closable={false}
                resizable={false}
                draggable={false}>
                <div className="col-md-12 col-lg-12 col-sm-12 text-center">
                    <p>Please rotate your phone to see the <br></br>{props.name}</p>
                </div>
                <div className="float-end">
                    <button type="button" className="btn btn-cancel-red" onClick={() => clickClose()}>Close</button>
                </div>
            </Dialog>
        </>
    );
};

export default PrintInvoicePopUp;