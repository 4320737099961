import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
    createAPIEndpoint,
    Endpoint,
} from "../../Services/WareHouse/WarehouseService";
import Loader from "../../Components/Loader";
import { useWareHouseMasterContext } from "../../context/WareHouseContext";
import storage from "../../Services/Storage";
import { toast } from "react-toastify";

function WarehouseMaster() {
    const { setwareHouseDataAll, wareHouseDataAll } = useWareHouseMasterContext();

    const [warehouseloader, setWarehouseloader] = useState(false);

    const nav = useNavigate();

    useEffect(() => {
        fetchwaredata();
    }, []);
    const auth = storage.getLocal("user");
    const fetchwaredata = async () => {
        setWarehouseloader(true);
        createAPIEndpoint()
            .get(`${Endpoint.GetAllWarehouseDetails}?CompId=${auth.CompId}`)
            .then((response) => {
                setwareHouseDataAll(response.data);
                setWarehouseloader(false);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {

                    position: toast.POSITION.TOP_CENTER,
    
                    pauseOnFocusLoss: false,
    
                    closeButton: false,
    
                    autoClose: 3000,
    
                });
    
                console.log(error);
                setWarehouseloader(false);
            });
    };

    const toNewWareHouse = (rowdata) => {
        return (
            
            <div className="action-icon">
                <button
                    className="grid-icon-img"
                    title="View/Edit Warehouse Details"
                    onClick={() => {
                         
                        nav(`/masters/warehousemaster/details?warehouseid=${rowdata.CODE}`);
                    }}
                >
                    <img alt='InnoRise' src={require("../../Assets/Images/ViewEdit.png")} />
                </button>
            </div>
        );
    };

    useEffect(() => {
        fetchwaredata();
    }, []);
  
    const NewWarehouese = () => {
        nav("/masters/warehousemaster/details");
    };
    return (
        <>
            <div className="col-12 pt-3 pb-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Warehouse Master</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <button type="submit" className="btn btn-primary" onClick={NewWarehouese} >
                                        Add New
                            </button>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="DataTable">
                            {warehouseloader ? (
                                <Loader />
                            ) : (
                                <DataTable
                                    value={wareHouseDataAll}
                                    tableStyle={{ minWidth: "50rem" }}
                                    paginator
                                    rows={10}
                                    showGridlines
                                    stripedRows
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    scrollable
                                >
                                    <Column field="CODE" header="Warehouse Code"></Column>
                                    <Column field="DESCRIPTION" header="Warehouse Description"></Column>
                                    <Column body={toNewWareHouse} header="Action"></Column>
                                </DataTable>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );

}

export default WarehouseMaster;
