import React, { useContext } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import storage from "../Services/Storage";
import format from "date-fns/format";
import { Checkbox } from "primereact/checkbox";
import * as Fa from "react-icons/fa";
import { toast } from "react-toastify";
import { RadioButton } from "primereact/radiobutton";
import {
    createAPIEndpointSalesReturn,
    EndpointSalesReturn,
} from "../Services/SalesReturnAdd/SalesReturnServices";
import { createAPIEndpoint, Endpoint } from "../Services/FOCservice";

//for checking response on default dates
let dummydata = [
    {
        PRODUCT_CODE: "00000",
        MASK_PRODUCT_NAME: "abhi LG 2",
        STATUS: "Active",
    },
    {
        PRODUCT_CODE: "00000",
        MASK_PRODUCT_NAME: "OPTITEMP LG 2",
        STATUS: "Active",
    },
    {
        PRODUCT_CODE: "00000",
        MASK_PRODUCT_NAME: "OPTITEMP LG 2",
        STATUS: "Active",
    },
];
//for checking get invoice button response

let saveObj ={};
let salRetDetails=[];
const SalesReturnContext = React.createContext();

const SalesReturnProvider = ({ children }) => {
    // const [abc, setAbc] = useState("abhirup");
    const [creditNotesTable, setCreditNotesTable] = useState([]);
    //this is for dummy data
    const [allCreditNoteData, setAllCreditNoteData] = useState([]);
    const todaydate = new Date();
    const currentDate = format(todaydate, "yyyy-MM-dd");
    const sevenDays = todaydate.setDate(todaydate.getDate() - 7);
    const formattedSevenDaysAgo = format(sevenDays, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(formattedSevenDaysAgo);
    const [toDate, setToDate] = useState(currentDate);

    // const [customerNamesugg, setCustomerNamesugg] = useState("");
    const [waredatasugg, setWaredatasugg] = useState("");
    const [resondessugg, setResondessugg] = useState("");
    const [prodcutsugg, setProdcutsugg] = useState("");
    // const [prodcutquan, setProdcutquan] = useState("");
    // const [salreturn, setSalreturn] = useState("");
    const [procodeheader, setProcodeheader] = useState("");

    const [invoicePopup1, setInvoicePopup1] = useState(false);
    const [customernamedisable, setCustomernamedisable] = useState(false);
    // Ersad
    const[customerName,setCustomerName]=useState([]);
    const [warehouse,setWarehouse] = useState([]);
    const [reasonDesc, setReasonDesc] = useState([]);
    const [custNameSuggest, setCustNameSuggest] = useState(null);
    const [reasonDescSuggest, setSeasonDescSuggest] = useState(null);
    const [warehouseSuggest, setWarehouseSuggest] = useState(null);
    const [invoiceData,setInvoiceData]=useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [totalInvQty, setTotalInvQty] = useState("");
    const [invSearchData,setInvSearchData] = useState("");
    const [populateDataTable,setPopulateDataTable] = useState([]);
    const [disableProduct,setDisableProduct] = useState(false);
    const [branchId,setBranchId] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [invoiceDataLoad, setinvoiceDataLoad] = useState(false);
    const [modalPopup, setModalPopup] = useState(false);
    const [viewSalesReturn,setViewSalesReturn] =useState(false);
    const [printInvoicePopUp,setPrintInvoicePopUp] =useState(false);
    const [printInvData,setPrintInvData] =useState([]);
    const [dtProductList,setDtProductList] =useState([]);


    const auth = storage.getLocal("user");
    const nav = useNavigate();
    const creditNotesColumn = [
        {
            name: (
                <div className="header-text">
                    <div className="d-flex justify-content-center align-items-center">
                        <Checkbox
                            // checked={
                            //   selectAll || selectedDrCr.length == filterDRCRData.length
                            // }
                            // onChange={handleChangeSelect}
                            className="me-2"
                        />{" "}
            SELECT All
                    </div>
                </div>
            ),
            cell: (row) => (
                <div className="action-icon">
                    {row.DIS_STATUS !== "Cancelled" && (
                        <div
                            className="d-inline"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Print"
                        >
                            <Checkbox
                                // value={row.MASK_DRCR_HDR_ID}
                                // onChange={(e) => onChange(e)}
                                // checked={selectedDrCr.some(
                                //   (item) => item === row.MASK_DRCR_HDR_ID
                                // )
                                // }
                            ></Checkbox>
                        </div>
                    )}
                </div>
            ),
            // selector: (row) => row.checked,
        },

        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            CREDIT NOTE NO.
                        {/* <Fa.FaSort /> */}
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="MASK_PRODUCT_NAME"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),

            selector: (row) => row.MASK_PRODUCT_NAME,
            //   sortable: searchBox1,
        },
        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            CREDIT NOTE DATE
                        {/* <Fa.FaSort /> */}
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="MASK_PRODUCT_NAME"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),

            selector: (row) => row.MASK_PRODUCT_NAME,
            //   sortable: searchBox1,
        },

        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            CUSTOMER NAME
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="STATUS"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),
            selector: (row) => row.STATUS,
        },
        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            CUSTOMER TYPE
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="STATUS"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),
            selector: (row) => row.STATUS,
        },

        {
            name: "ACTION",
            cell: (row) => (
                <div className="action-icon">
                    <div>
                        <button
                            className="grid-icon"
                            onClick={() => {
                                // nav(`/productpages?mask_pro_id=${encodeURIComponent(row.MASK_PROD_ID)}`);
                                nav(
                                    `/salesmanagement/salesreturn/details?mask_pro_id=${encodeURIComponent(
                                        row.PRODUCT_CODE
                                    )}`
                                );
                            }}
                        >
                            <Fa.FaRegFileAlt />
                        </button>
                    </div>
                </div>
            ),
        },
    ];

    const invoiceDataColumn = [
        {
            name: (
                <div className="header-text">
                    <div className="d-flex justify-content-center align-items-center">
            Select
                    </div>
                </div>
            ),
            cell: () => (
                <div className="d-inline-flex pe-1">
                    <RadioButton />
                    <label className="ms-1"></label>
                </div>
            ),
            // selector: (row) => row.checked,
        },

        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            DOCUMENT NO.
                        {/* <Fa.FaSort /> */}
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="MASK_PRODUCT_NAME"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),

            selector: (row) => row.INVH_NO,
            //   sortable: searchBox1,
        },

        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            DOCUMENT DATE
                        {/* <Fa.FaSort /> */}
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="MASK_PRODUCT_NAME"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),

            selector: (row) => row.INVH_DATE,
            //   sortable: searchBox1,
        },
    ];

    const productDetailsclmn = [
        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            PRODUCT CODE
                        {/* <Fa.FaSort /> */}
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="PRODUCT_CODE"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),

            selector: (row) => row.PRODUCT_CODE,
            // sortable: searchBox1,
        },

        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            PRODUCT DESCRIPTION
                        {/* <Fa.FaSort /> */}
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="MASK_PRODUCT_NAME"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),

            selector: (row) => row.MASK_PRODUCT_NAME,
            // sortable: searchBox1,
        },

        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            PRODUCT LOT NO.
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="STATUS"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),
            selector: (row) => row.STATUS,
        },
        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            PRODUCT MRP
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="STATUS"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),
            selector: (row) => row.STATUS,
        },
        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            PRODUCT PRICE
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="STATUS"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),
            selector: (row) => row.STATUS,
        },
        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            TOTAL QUANTITY
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="STATUS"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),
            selector: (row) => row.STATUS,
        },
        {
            name: (
                <div>
                    <div
                        // onMouseOver={() => setSearchBox1(true)}
                        // onMouseOut={() => setSearchBox1(false)}
                    >
            EXTENDED PRICE
                    </div>
                    <div>
                        {/* <input
              type="text"
              className="filter-textbox"
              id="STATUS"
              onChange={(e) => {
                handleChange(e);
              }}
            /> */}
                    </div>
                </div>
            ),
            selector: (row) => row.STATUS,
        },
    ];
    const findDateSalesReturn = (e, fromDate, toDate) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        getCreditNotes(fromDateRange, toDateRange);

        e.preventDefault();
    };
    //Function API calling for getCreditNotesData for selected dates
    const getCreditNotes = async (fromDateRange, toDateRange) => {
        try {
            setinvoiceDataLoad(true);
            createAPIEndpointSalesReturn()
                .get(`${EndpointSalesReturn.GetSalesReturnRecord}?CompId=${auth.CompId}&UserId=${auth.UserId}&FinYearStart=${fromDateRange}&FinYearEnd=${toDateRange}`)
                .then((res)=>{
                    console.log("res.d",res.data);
                    setCreditNotesTable(res.data.Data);
                    setinvoiceDataLoad(false);
                });
            //for checking with dummy data
            // setAllCreditNoteData(dummydata1);
        } catch (error) {
            console.log(error);
            setinvoiceDataLoad(false);
        }
    };
    //Function API calling for getting default dates
    const getDefaultCreData = async () => {
        try {
            // const URL = `${Base_SALESRETURN_URL}/GetSalesReturnRecord?CICode=${auth.CICode}&UserId=${auth.UserId}&FinYearStart=${formattedSevenDaysAgo}&FinYearEnd=${currentDate}`;
            // const res = await Axios.get(URL);
            // setCreditNotesTable(res.data);

            //for checking with dummy data
            setAllCreditNoteData(dummydata);
        } catch (error) {
            console.log(error);
        }
    };

    // ----------------------HANDEL FUNCTION START

    const handleCustData = (e) => {
        fetchInvoiceData(e.value.MASK_CUST_ID);
    // fetchResonDescr();
    };

    const handleWareData = () => {
        fetchWareHouse();
    };

    const handleReasonData = () => {
        fetchResonDescr();
    // fetchProductCode(invhdrid);
    // fetchResonDescr();
    };

    // const handleData = (invhdrid) => {
    //   fetchResonDescr();
    //   // fetchResonDescr();
    //   fetchProductCode(invhdrid);
    // };

    const handleProductCode = (val) => {
    // console.log("iddddddddddd",val);
    // fetchProductQunt(val);
        fetchProductCode(val);
        fetchResonDescr();
    };

    const addToCart = async (prdDtl)=>{
        setIsLoading(true);
        const res = await createAPIEndpointSalesReturn()
            .post(`${EndpointSalesReturn.AddtoCart}`,
                JSON.stringify(prdDtl[0]),
                {
                    headers: { "Content-Type": "application/json" },
                });
        const maskProdId = res?.data?.objSalRetDetails?.PRODUCT_CODE;
        let isDuplicateID = populateDataTable.some(item => {
            if (item?.PRODUCT_CODE === maskProdId) {
                if (item?.PROD_BATCH_NO === res?.data?.objSalRetDetails?.PROD_BATCH_NO)
                    return true;
                else
                    return false;
            }
            else
                return false;
        });
        console.log("isDuplicateId", isDuplicateID);

        if (isDuplicateID) {
            toast.error(
                "Product already added to the cart.",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                }

            );
            isDuplicateID = false;
        }
        else {
            toast.success(
                "Product added to the cart.",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                }

            );
            setPopulateDataTable((prevState) => [...prevState, res.data.objSalRetDetails]);
        }
        setIsLoading(false);
    };

    const popupgenerate = ()=>{
        setInvoicePopup1(true);
        handleProductCode(procodeheader);
    };

    // const searchState = (event) => {
    //   let _items = [...customerNamesugg];
    //   setCustomerNamesugg(
    //     event.query
    //       ? [...customerNamesugg].map((item) => event.query + "-" + item)
    //       : _items
    //   );
    // };

    const searchState1 = (event) => {
        let _items = [...waredatasugg];
        setWaredatasugg(
            event.query
                ? [...waredatasugg].map((item) => event.query + "-" + item)
                : _items
        );
    };

    const searchState2 = (event) => {
        let _items = [...resondessugg];
        setResondessugg(
            event.query
                ? [...resondessugg].map((item) => event.query + "-" + item)
                : _items
        );
    };

    const searchState3 = (event) => {
        let _items = [...prodcutsugg];
        setProdcutsugg(
            event.query
                ? [...prodcutsugg].map((item) => event.query + "-" + item)
                : _items
        );
    };

    //-----------------------ITEM TEMPLATE START----------------------------

    const itemTemplatecust = (filterval) => {
       
        return (
        
            <>{filterval.CODE} : {filterval.NAME}</>
        );
    };

    const itemTemplateware = (filterval) => {
        
        return (
            <>{filterval.CODE} : {filterval.DESCRIPTION}</>
        );
    };

    const itemTemplatereson = (filterval) => {
        
        return (
            <>{filterval.CODE} : {filterval.DESCRIPTION}</>
        );
    };

    const itemTemplateprod = (filterval) => {
        return (
            
            <>
                {filterval.CODE}-{filterval.DESCRIPTION}-{filterval.PROD_BATCH_NO}
            </>
        );
    };
    //-----------------------ITEM TEMPLATE END----------------------------

    //-----------------------FETCHING API CALL----------------------------
    // const fetchCustData = async () => {
    //   try {
    //     const URL = `${Base_SALESRETURN_URL}/GetCustomerData?CICode=${auth.CICode}`;
    //     const res = await Axios.get(URL);
    //     console.log("customerName", res.data);
    //     setCustomerNamesugg(res.data);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const printInvoiceCall = async (row) => {
        setinvoiceDataLoad(true);
        const objInvoice = {
            CICode: auth.CICode,
            InvoiceId: row,
            CompId: auth.CompId,
            EInvPaymentUPI: auth.EInvPaymentUPI,
            InvoiceDate: currentDate,
            DistTag: auth.DistTag,
            EInvApplicability: auth.EInvApplicability,
            EInvB2CQR: auth.EInvB2CQR,
        };
        console.log("Secong",objInvoice);
        createAPIEndpointSalesReturn()
            .post(`${EndpointSalesReturn.PrintSalesReturn}`,
                JSON.stringify(objInvoice),
                {
                    headers: { "Content-Type": "application/json" },
                })
            .then((res)=>{
                setPrintInvData(res.data);
                setDtProductList(res.data.dtProductList);
                setPrintInvoicePopUp(true);
                setinvoiceDataLoad(false);
            })
            .catch((err)=>{
                console.log("Error",err);
                setinvoiceDataLoad(false);
            });
        
       
    };

    const fetchWareHouse = async () => {
        try {
            createAPIEndpointSalesReturn()
                .get(`${EndpointSalesReturn.GetWareHouse}?userId=${auth.UserId}&CompId=${auth.CompId}`)
                .then((res)=>{
                    setWarehouse(res.data.Data);
                });
        } catch (error) {
            console.log("fetchWareHouse", error);
        }
    };

    const fetchInvoiceData = async () => {
        // try {
        //     const URL = `${Base_SALESRETURN_URL}/GetInvoiceData?CICode=${auth.CICode}&CompId=${auth.CompId}&CustId=${custid}&UserId=${auth.UserId}`;
        //     const res = await axios.get(URL);
        //     console.log("fetchInvoiceData", res.data);
        //     setInvoicesugg(res.data);
        //     setInvoicePopup1(true);
        // } catch (error) {
        //     toast.error(error.response.data, {
        //         position: toast.POSITION.TOP_CENTER,
        //         pauseOnFocusLoss: false,
        //         closeButton: false,
        //         autoClose: 2000,
        //     });
        //     console.log("fetchInvoiceData", error.response);
        // }
    };

    const fetchResonDescr = async () => {
        try {
            await createAPIEndpointSalesReturn()
                .get(`${EndpointSalesReturn.GetReasonData}?CICode=${auth.CICode}`)
                .then((res)=>{
                    setReasonDesc(res.data.Data);
                });
        }catch (error) 
        {
            console.log("fetchResonDescr", error);
        }
    };

    const fetchProductCode = async (invhdrid) => {
        try {
            // const URL = `${Base_SALESRETURN_URL}/GetProductData?CICode=${auth.CICode}&strInvHdrId=${invhdrid}&m_strHold=${mhold}`;
            // const res = await axios.get(URL);
            // console.log("fetchProductCode", res.data);
            // setProdcutsugg(res.data);
            // setInvoicePopup1(true);

            // setCustomerNamesugg(res.data);
            createAPIEndpointSalesReturn()
                .get(`${EndpointSalesReturn.GetProductData}?CICode=${auth.CICode}&strInvHdrId=${invhdrid}`)
                .then((res)=>{
                    setProdcutsugg(res.data);
                // setInvoicePopup1(true);
                });
        } 
        
        catch (error) {
            console.log("fetchProductCode", error);
        }
    };

    // const fetchProductQunt = async (invdtid) => {
    //     try {
    //         const URL = `${Base_SALESRETURN_URL}/GetSelectedProductOrderQty?CICode=${auth.CICode}&InvoiceDetailId=${invdtid}`;
    //         const res = await Axios.get(URL);
    //         console.log("fetchProductQunt", res.data);
    //         // setProdcutquan(res.data);
    //     } catch (error) {
    //         console.log("fetchProductCode", error);
    //     }
    // };

    // const fetchSalretDetails = async (invhdr) => {
    //     try {
    //         const URL = `${Base_SALESRETURN_URL}/GetSalRetDetails?CICode=${auth.CICode}&strInvHdrId=${invhdr}`;
    //         const res = await Axios.get(URL);
    //         console.log("fetchSalretDetails", res.data);
    //         // setSalreturn(res.data);
    //     } catch (error) {
    //         console.log("fetchSalretDetails", error);
    //     }
    // };


    //-----------------------FETCHING API END----------------------------
    // Added by Ersad
    async function onPageLoadAddSalesReturn() {
        createAPIEndpointSalesReturn()
            .get(`${EndpointSalesReturn.GetCustomerData}?CompId=${auth.CompId}`)
            .then((res) =>{
                console.log("Response",res.data.Data);
                setCustomerName(res.data.Data);})
            .catch(() => console.log("Error in customer dropdown"));

        // createAPIEndpointSalesReturn()
        //     .getDefaultValue(`${EndpointSalesReturn.GetDefaultValue}?CompId=${auth.CompId}&defId=SAL/RET`)
        //     .then((res)=>{
        //         setSalesAcctId(res.data.Id);
        //     });
        // createAPIEndpointSalesReturn()
        //     .getDefaultValue(`${EndpointSalesReturn.GetDefaultValue}?CompId=${auth.CompId}&defId=SALDISC`)
        //     .then((res)=>{
        //         setDiscAcctId(res.data.Id);
        //     });
        // createAPIEndpointSalesReturn()
        //     .getDefaultValue(`${EndpointSalesReturn.GetDefaultValue}?CompId=${auth.CompId}&defId=ROUNDOFF`)
        //     .then((res)=>{
        //         setRoundOffAcctId(res.data.Id);
        //     });
        fetchResonDescr();

        fetchWareHouse();


    }
    // function CustomerNameSugg(e) {
    //   console.log("customername select ", e);
    // }
    function CustomerNamefilter(e)
    {
        setTimeout(() => {
            let _filteredData;
            if (!e.query.trim().length) {
                if(customerName.length>0){
                    _filteredData = [...customerName];
                }
            } else {
                _filteredData = customerName?.filter((val) => {
                    return (
                        val.CODE.toLowerCase().includes(e.query.toLowerCase()) ||
          val.NAME.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }
            setCustNameSuggest(_filteredData);
        }, 250);
    }
    function ReasonDescFilter(e) {
        setTimeout(() => {
            let _filteredData;
            if (!e.query.trim().length) {
                _filteredData = [...reasonDesc];
            } else {
                _filteredData = reasonDesc.filter((val) => {
                    return (
                        val.CODE.toLowerCase().includes(e.query.toLowerCase()) ||
          val.DESCRIPTION.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }
            setSeasonDescSuggest(_filteredData);
        }, 250);
    }
    function WarehouseFilter(e) {
        setTimeout(() => {
            let _filteredData;
            if (!e.query.trim().length) {
                _filteredData = [...warehouse];
            } else {
                _filteredData = warehouse.filter((val) => {
                    return (
                        val.CODE.toLowerCase().includes(e.query.toLowerCase()) ||
          val.DESCRIPTION.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }
            setWarehouseSuggest(_filteredData);
        }, 250);
    }
    async function handleCustomerSelect(value)
    {
        if(value.MASK_CUST_ID)
        {
            setinvoiceDataLoad(true);
            createAPIEndpointSalesReturn().get(
                `${EndpointSalesReturn.GetInvoiceData}?CICode=${auth.CICode}&CompId=${auth.CompId}&CustId=${value.MASK_CUST_ID}&UserId=${auth.UserId}`
            ).then((val)=>
            {
                if(val.data.Status=="Success"){
                    setInvoiceData(val.data.Data);
                    setInvoicePopup1(true);
                
                }
                else if(val.data.Status=="Warning")
                {
                    toast.error(
                        "No GST Invoice found. Please select different customer.",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setinvoiceDataLoad(false);
            })
                .catch((err)=>{
                    setInvoiceData(err.response.data);
                    setinvoiceDataLoad(false);
                    console.log("Error in Invoice Data",err);
                    setInvoiceData([]);
                });
           
        }
    }

    const customerInfo =async (whid)=>{
        await createAPIEndpoint()
            .getSegTaxID(`${Endpoint.GetBranchByWH}?CICode=${auth.CICode}&strWareHouseId=${whid}`)
            .then((res) => {
                setBranchId(res.data);
            });   
    };

    const TotalInvoice = (value)=>{
        setinvoiceDataLoad(true);
        createAPIEndpointSalesReturn()
            .get(`${EndpointSalesReturn.GetSelectedProductOrderQty}?CICode=${auth.CICode}&InvoiceDetailId=${value.MASK_INVD_ID}`)
            .then((res)=>{
                setTotalInvQty(res.data.Data[0].QTY);
                setinvoiceDataLoad(false);
            })
            .catch((err)=>{
                console.log("Error",err);
                setinvoiceDataLoad(false);
            });
            
    };

    
    const newSaveSalesReturn = (data) =>{
        setinvoiceDataLoad(true);
        salRetDetails = populateDataTable.map((val)=>({
            masK_INVD_ID: val.MASK_INVD_ID,
            division: val.DIVISION,
            masK_PROD_ID: val.MASK_PROD_ID,
            producT_CODE: val.PRODUCT_CODE,
            proD_SMALL_DESC: val.PROD_SMALL_DESC,
            proD_BATCH_NO: val.PROD_BATCH_NO,
            pricE_LIST_NO: val.PRICE_LIST_NO,
            casE_TYPE: val.CASE_TYPE,
            cdR_DISC: val.CDR_DISC,
            euS_DISC_PERC: val.EUS_DISC_PERC,
            euS_DISC: val.EUS_DISC,
            casE_QTY: val.CASE_QTY,
            pacK_QTY: val.PACK_QTY,
            toT_INV_QTY: val.TOT_INV_QTY,
            proD_PRICE: val.PROD_PRICE,
            proD_BASE_PRICE: val.PROD_BASE_PRICE,
            extendeD_PRICE: val.EXTENDED_PRICE,
            masK_PROD_VAL: val.MASK_PROD_VAL,
            masK_INVD_PROD_STK_TYPE_ID: val.MASK_INVD_PROD_STK_TYPE_ID,
            masK_INVD_PACK_UOM_CODE: val.MASK_INVD_PACK_UOM_CODE,
            masK_TRADE_DISC: val.MASK_TRADE_DISC,
            masK_VAL_BASE_CRNC: val.MASK_VAL_BASE_CRNC,
            masK_SPLCON_TAX: val.MASK_SPLCON_TAX,
            masK_TOT_TAX: val.MASK_TOT_TAX,
            masK_VAT_TAX: val.MASK_VAT_TAX,
            masK_CASE_FACTOR:val.MASK_CASE_FACTOR ,
            masK_INVD_LOC_ID: val.MASK_INVD_LOC_ID,
            masK_SODT_ID: val.MASK_SODT_ID,
            masK_OTHER_CHARES: val.MASK_OTHER_CHARES,
            masK_IUKEY: val.MASK_IUKEY,
            masK_INVD_TOT_INV_QTY:val.MASK_INVD_TOT_INV_QTY ,
            masK_INVD_RET_TAX:val.MASK_INVD_RET_TAX ,
            masK_INVD_PROD_BUY_PRICE:val.MASK_INVD_PROD_BUY_PRICE ,
            masK_INVD_EUS_DISC_PERC_TAG:val.MASK_INVD_EUS_DISC_PERC_TAG ,
            masK_INVD_MOP_VAL: val.MASK_INVD_MOP_VAL,
            masK_INVD_TRANS_VAL: val.MASK_INVD_TRANS_VAL,
            masK_INVD_CDR_DISC_PERC: val.MASK_INVD_CDR_DISC_PERC,
            masK_INVD_CDR_DISC_TAG: val.MASK_INVD_CDR_DISC_TAG,
            masK_ACT_CDR_DISC: val.MASK_ACT_CDR_DISC,
            masK_ACT_EUS_DISC:val.MASK_ACT_EUS_DISC ,
            masK_ACT_BASE_PRICE: val.MASK_ACT_BASE_PRICE,
            masK_ACT_TRADE_DISC: val.MASK_ACT_TRADE_DISC,
            masK_ACT_TAX:val.MASK_ACT_TAX ,
            masK_ACT_VAT_TAX: val.MASK_ACT_VAT_TAX,
            masK_ACT_RET_TAX: val.MASK_ACT_RET_TAX,
            masK_ACT_MOP_VAL: val.MASK_ACT_MOP_VAL ,
            masK_INVD_TOT_RET_QTY: val.MASK_INVD_TOT_RET_QTY ,
            masK_INVH_ID: val.MASK_INVH_ID,
            masK_DIVN_ID: val.MASK_DIVN_ID,
            masK_EVAL_BY_LOT_TAG: val.MASK_EVAL_BY_LOT_TAG,
            masK_EUS_DISC_APP_TO: val.MASK_EUS_DISC_APP_TO,
            masK_CFOP_ID: val.MASK_CFOP_ID,
            cfoP_CODE:val.CFOP_CODE ,
            masK_SIT_TRIB: val.MASK_SIT_TRIB,
            masK_SCHM_ID: val.MASK_SCHM_ID,
            masK_FISCAL_CODE: val.MASK_FISCAL_CODE,
            cfoP_DESCRIPTION:val.CFOP_DESCRIPTION ,
            masK_PRODUCT_MRP: val.MASK_PRODUCT_MRP,
        }));

        saveObj = {
            ciCode: auth.CICode,
            iuKey: "1",
            compId: auth.CompId,
            wareHouseId: data.warehouse.MASK_WH_ID,
            creditNoteDate: data.credit_note_date,
            invH_NO: selectedData.INVH_NO,
            invH_DATE: selectedData.INVH_DATE,
            masK_INVH_TYPE: selectedData.MASK_INVH_TYPE,
            othTaxCriteria: invSearchData.MASK_TAX_FORM_ID, 
            creditNoteId:"",
            creditNoteNo:  data.credit_note_no,
            soHdrId: invSearchData.MASK_SOHD_ID,
            employee: invSearchData.MASK_SOHD_CREATE_EMP_ID, //id 4
            payTermId: invSearchData.MASK_PAY_TERM_ID, 
            payTermExplanation: invSearchData.MASK_PAY_TERM_EXPL, 
            vanRouteId: invSearchData.MASK_VAN_RT_ID, 
            currencyId: invSearchData.MASK_CURRENCY_ID, 
            exRate: invSearchData.MASK_INVH_EXCH_RATE, 
            tradeDiscPercent: invSearchData.MASK_INVH_TRADE_DISC_PERC, 
            custId: data.customer_name?.MASK_CUST_ID,
            customerName: data.customer_name?.NAME,
            custType: invSearchData.MASK_CUST_TYPE, 
            billAdLine1: invSearchData.MASK_CBILL_ADD1, 
            billAdLine2: invSearchData.MASK_CBILL_ADD2??"",  
            custBillGeogId: invSearchData.MASK_CBILL_ADD_GEOG_ID, 
            billPinCode: invSearchData.MASK_CBILL_ADD_PIN, 
            shipAdLine1: invSearchData.MASK_CSHIP_ADD1,
            shipAdLine2: invSearchData.MASK_CSHIP_ADD2??"",
            custShipGeogId: invSearchData.MASK_CSHIP_ADD_GEOG_ID,
            shipPinCode: invSearchData.MASK_CSHIP_ADD_PIN,
            challanNo: invSearchData.MASK_INVH_CHALLAN_NO,//
            challanDate: invSearchData.MASK_INVH_CHALLAN_DATE,//
            roundingOffAmt: "0",//not done
            baseCurrTotAmt: data.total_amount.toString(),
            totAmount: data.total_amount.toString(),
            invPrintNo: invSearchData.MASK_INVH_PRINT_NO,//
            print: data.print_message,
            invBillingInstr: invSearchData.MASK_INVH_BILLING_INSTRUCTION, 
            registCode: invSearchData.MASK_INVH_REGIST_CODE==null?"":invSearchData.MASK_INVH_REGIST_CODE, 
            registNumber: invSearchData.MASK_INVH_REGIST_NUMBER==null?"":invSearchData.MASK_INVH_REGIST_NUMBER, 
            invGatePassNo: invSearchData.MASK_INVH_GATE_PASS_NO, //
            invDispReasId: invSearchData.MASK_INVH_DISPUTE_REASON_ID,// 
            invDispEmpId: invSearchData.MASK_INVH_DISP_EMP_ID,//
            chqsinHand: invSearchData.MASK_CHQS_IN_HAND.toString(),//
            closingBalance: invSearchData.MASK_CLOSING_BALANCE.toString(), //
            invResolutionDate: invSearchData.MASK_INVH_RESOLUTION_DATE, //
            invTargetDate: invSearchData.MASK_INVH_TARGET_DATE, //
            modeOfPayment: invSearchData.MASK_MOP_ID,
            locationId: invSearchData.MASK_LOC_ID,//
            modeOfOrder: invSearchData.MASK_ORDM_ID,//
            taxArea: invSearchData.MASK_TAX_AREA_ID,
            hdrStatus: "OP",//
            invHdrId: selectedData.MASK_INVH_ID,
            reasonCode: data.reason_description.CODE,
            reason: data.reason_description.DESCRIPTION,
            salesAcctId: "", 
            discAcctId: "", 
            roundOffAcctId: "", 
            cfopId: invSearchData.MASK_CFOP_ID,
            transportChargeId: invSearchData.MASK_TRANSCHARGE_ID==null?"": invSearchData.MASK_TRANSCHARGE_ID,//
            mopDiscPercent: invSearchData.MASK_MOP_DISC_PER,
            transportChargeRate: invSearchData.MASK_TRANSCHARGE_RATE,
            customerPoNo: invSearchData.MASK_INVH_CUST_PO_NO,//
            customerPoDate: invSearchData.MASK_INVH_CUST_PO_DATE,
            transporterId: invSearchData.MASK_TRANSPORTER_ID,//
            bankId: invSearchData.MASK_BANK_ID,
            salesManPercentage:invSearchData.MASK_SALESMAN_PERC ,//
            salesAreaId: invSearchData.MASK_SALES_AREA_ID,
            userId: auth.UserId,
            custShipSrlNo: invSearchData.MASK_SALES_AREA_ID1,
            custBillName: invSearchData.MASK_CBILL_CONT_FNAME, 
            totNoPackages: invSearchData.MASK_TOT_NO_OF_PACKAGES.toString(),
            partyAd1: invSearchData.MASK_PARTY_ADD1,
            partyAd2: invSearchData.MASK_PARTY_ADD2??"",
            partyGeogId:invSearchData.MASK_PARTY_ADD_GEOG_ID ,
            partyPin: invSearchData.MASK_PARTY_ADD_PIN,
            delInstr: invSearchData.MASK_DELIVERY_INSTR,
            especie: invSearchData.MASK_ESPECIE,
            serie: invSearchData.MASK_SERIE,
            cifFob: invSearchData.MASK_CIF_FOB,
            retRoundOffAmt: "0",
            branchId: branchId,
            invAutoGen: "",
            baseWareHouseId: data.warehouse.MASK_WH_ID,
            diffAmt: invSearchData.MASK_RET_AMT, 
            totalAmount: data.total_amount.toString(),
            salRetDetails: salRetDetails
        };
        console.log("payload",saveObj);
        //   return
        createAPIEndpointSalesReturn()
            .post(`${EndpointSalesReturn.SaveSalesReturn}`,
                JSON.stringify(saveObj),
                {
                    headers: { "Content-Type": "application/json" },
                })
            .then((res)=>{
                if(res.data.Message=="Partial/total document adjustment/receipt has been made against this invoice.Do you want to return this invoice?"){
                    setModalPopup(true);
                    setinvoiceDataLoad(false);
                }
                else if(res.data.Status=="Warning"){
                    setinvoiceDataLoad(false);
                    toast.warn(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                }
                else{
                    setinvoiceDataLoad(false);
                    toast.success(
                        `${res.data.Message}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }

                    );
                    nav("/salesmanagement/salesreturn");
                    printInvoiceCall(res.data.CreditNoteId);
                    console.log("responser000",res.data);
                }
            })
            .catch((err)=>{
                console.log("Error",err);
                setinvoiceDataLoad(false);
                toast.error(
                    `${err.response.data.Message}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
                );
            });

    };

    const handleConfirmModal = () =>{
        setinvoiceDataLoad(true);
        createAPIEndpointSalesReturn()
            .post(`${EndpointSalesReturn.ConfirmAdjust}`,
                JSON.stringify(saveObj),
                {
                    headers: { "Content-Type": "application/json" },
                })
            .then((res)=>{
                toast.success(
                    `${res.data.Message}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
                );
                nav("/salesmanagement/salesreturn");
                printInvoiceCall(res.data.CreditNoteId);
                setinvoiceDataLoad(false);
            })
            .catch((err)=>{
                console.log("Error",err);
                setinvoiceDataLoad(false);
                toast.error(
                    `${err.response.data.Message}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
                );
            });
    };

    return (
        <SalesReturnContext.Provider
            value={{
                creditNotesColumn,
                getCreditNotes,
                getDefaultCreData,
                creditNotesTable,
                setCreditNotesTable,
                allCreditNoteData,
                setAllCreditNoteData,
                fromDate,
                toDate,
                formattedSevenDaysAgo,
                currentDate,
                productDetailsclmn,
                setFromDate,
                setToDate,
                //fetchCustData,
                fetchWareHouse,
                handleCustData,
                //searchState,
                itemTemplatecust,
                waredatasugg,
                handleWareData,
                searchState1,
                itemTemplateware,
                resondessugg,
                handleReasonData,
                searchState2,
                itemTemplatereson,
                prodcutsugg,
                // handleReasonData,
                searchState3,
                itemTemplateprod,
                invoiceDataColumn,
                invoicePopup1,
                setInvoicePopup1,
                customernamedisable,
                fetchProductCode,
                handleProductCode,
                procodeheader,
                setProcodeheader,
                popupgenerate,
                onPageLoadAddSalesReturn,
                custNameSuggest,
                CustomerNamefilter,
                reasonDescSuggest,
                ReasonDescFilter,
                WarehouseFilter,
                warehouseSuggest,
                handleCustomerSelect,
                invoiceData,
                setInvoiceData,
                selectedData, 
                setSelectedData,
                findDateSalesReturn,
                totalInvQty, 
                setTotalInvQty,
                TotalInvoice,
                setWarehouse,
                invSearchData,
                setInvSearchData,
                addToCart,
                populateDataTable,
                setPopulateDataTable,
                setCustomernamedisable,
                newSaveSalesReturn,
                setDisableProduct,
                disableProduct,
                customerInfo,
                isLoading,
                setIsLoading,
                invoiceDataLoad,
                modalPopup, 
                setModalPopup,
                handleConfirmModal,
                viewSalesReturn,
                setViewSalesReturn,
                printInvData,
                printInvoicePopUp,
                setPrintInvoicePopUp,
                printInvoiceCall,
                setinvoiceDataLoad,
                dtProductList,
                setDtProductList
            }}
        >
            {children}
        </SalesReturnContext.Provider>
    );
};

export const useSalesReturnContext = () => {
    return useContext(SalesReturnContext);
};

export { SalesReturnProvider, SalesReturnContext };
