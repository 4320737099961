import React from "react";

const FieldLevelSettings = () => {
    return (
        <>
            <main>
                <div className="col-12">
                    <div className="float-end">
                        <button type="submit" className="btn btn-primary">Save</button>
                    </div>
                    <h3 className="py-2">Field Level Settings</h3>
                    <section className="">
                        <table className="table table-bordered">
                            <thead>
                                <tr className="table-success">
                                    <th scope="col">Select All</th>
                                    <th scope="col">Page Name</th>
                                    <th scope="col">Field Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>checkbox</td>
                                    <td>Customer Care</td>
                                    <td>Credit Limit</td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
            </main>
        </>
    );
};

export default FieldLevelSettings;