import React, { useEffect, useState } from "react";
import { FileUpload } from "primereact/fileupload";
import * as XLSX from "xlsx";
import { read, utils } from "xlsx";
import storage from "../../Services/Storage";
import {
    createAPIEndpointDSR,
    Endpoint,
} from "../../Services/DSRMapping/DSRmappingService";
import { toast } from "react-toastify";
import { useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../Components/Loader";
import * as Fa from "react-icons/fa";

function DSRDealerMapping() {
    const [dropdownselected, setDropdownselected] = useState("CSV");
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [loadingExceltemplate, setLoadingExceltemplate] = useState(false);
    const [tableloading, setTableloading] = useState(false);
    const [errorexceldata, setErrorexceldata] = useState([]);
    const [showdatatbl, setShowdatatbl] = useState([]);
    const [errorxlsbtn, setErrorxlsbtn] = useState(false);
    const auth = storage.getLocal("user");
    const fileUploadRef = useRef(null);
    let header = [
        {
            "Customer Code": null,
            "Customer Name": null,
            "SE Code": null,
            "SE Name": null,
            // "DFMR Employee Code": null,
            // "DFMR Employee Name": null,
            // "DFMR SE Beat": null,
            // "SSR Employee Code": null,
            // "SSR Employee Name": null,
            // "SSR SE Beat": null,
        },
    ];
    let date = new Date();
    let day = date.getDate();

    // Template Download
    const handleExportTemplate = () => {
        setLoadingExceltemplate(true);

        let year = date.getFullYear().toString().substring(2);
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let uniqueNo = `SEDealerMappingTemplate${day}${month}${year}`;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(header);
        XLSX.utils.book_append_sheet(wb, ws, uniqueNo);

        if (dropdownselected === "CSV") {
            XLSX.writeFile(wb, `${uniqueNo}.csv`);
            setLoadingExceltemplate(false);
        } else {
            XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
            setLoadingExceltemplate(false);
        }
    };

    const handleExportExcel = () => {
        setLoadingExcel(true);
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];

        let exactmonths = months[date.getMonth()];
        let year = date.getFullYear();
        let uniqueNo = `SEDealerMapping_${day}${exactmonths}${year}`;

        createAPIEndpointDSR()
            .get(`${Endpoint.ExportData}?CICode=${auth.CICode}&strCompID=${auth.CompId}`)
            .then((res) => {
                const wb = XLSX.utils.book_new();
                const ws = XLSX.utils.json_to_sheet(res.data);
                XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                setLoadingExcel(false);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setLoadingExcel(false);
            });
    };

    const handleExportErrorxls = () => {
        var months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let exactmonths = months[date.getMonth()];
        let year = date.getFullYear();
        let uniqueNo = `export_errorfile${day}${exactmonths}${year}`;
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(errorexceldata);
        XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
        XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
    };

    const handleFileUpload = (event) => {
        let parsedData = [];
        let fileTypes = [
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv",
        ];

        const selectedFile = event.files[0];

        let filename_withextension = selectedFile.name;

        // let file_extension = filename_withextension.split(".").pop();
        // let filename_withoutextension = filename_withextension.slice(0,filename_withextension.lastIndexOf("."));

        if (selectedFile && fileTypes.includes(selectedFile.type)) {
            const reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);

            reader.onload = (e) => {
                const fileData = e.target.result;
                const workbook = read(fileData, { type: "binary" });
                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];
                parsedData = utils.sheet_to_json(sheet);
                 

                const actualheaderclmn = [
                    "A1",
                    "B1",
                    "C1",
                    "D1",
                    // "E1",
                    // "F1",
                    // "G1",
                    // "H1",
                    // "I1",
                    // "J1",
                ];

                 
                const headingsClmnAll = [];
                for (const key in sheet) {
                    headingsClmnAll.push(key);
                }

                //  
                let headerclmn = [];
                for (let i = 0; i <= 4; i++) {
                    headerclmn.push(headingsClmnAll[i]);
                }
                 

                let chceckClmnInclude = actualheaderclmn.every((element) =>
                    headingsClmnAll.includes(element)
                );

                 

                //checking headers name
                // actual headers name that should be in the excel
                const actualheaders = [
                    "Customer Code",
                    "Customer Name",
                    "SE Code",
                    "SE Name",
                    // "DFMR Employee Code",
                    // "DFMR Employee Name",
                    // "DFMR SE Beat",
                    // "SSR Employee Code",
                    // "SSR Employee Name",
                    // "SSR SE Beat",
                ];
                const allfieldname = [];
                for (const key in sheet) {
                    allfieldname.push(sheet[key].v);
                }
                //exctract headers name from the all fields name
                let headernames = [];
                for (let i = 0; i <= 4; i++) {
                    headernames.push(allfieldname[i]);
                }
                 
                //compare header are present in excel or not
                let chceckHeaderInclude = actualheaders.every((element) =>
                    headernames.includes(element)
                );

                 
                 
                 
                 

                if (chceckClmnInclude && chceckHeaderInclude) {
                    if (parsedData != null && parsedData.length >= 1) {
                        let modelexcel = parsedData.map((val) => ({
                            party_Code: val["Customer Code"]?.toString() ?? "",
                            party_Name: val["Customer Name"]?.toString() ?? "",
                            dsR_Employee_Code: val["SE Code"]?.toString() ?? "",
                            dsR_Employee_Name: val["SE Name"]?.toString() ?? "",
                            // dfmR_Employee_Code: val["DFMR Employee Code"]?.toString() ?? "",
                            // dfmR_Employee_Name: val["DFMR Employee Name"]?.toString() ?? "",
                            // dfmR_SE_Beat: val["DFMR SE Beat"]?.toString() ?? "",
                            // ssR_Employee_Code: val["SSR Employee Code"]?.toString() ?? "",
                            // ssR_Employee_Name: val["SSR Employee Name"]?.toString() ?? "",
                            // ssR_SE_Beat: val["SSR SE Beat"]?.toString() ?? "",
                        }));

                        let objUpload = {
                            ciCode: auth.CICode,
                            userId: auth.UserId,
                            compId: auth.CompId,
                            fileNameWithExtention: filename_withextension.toString(),
                            // fileExtension: file_extension.toString(),
                            // fileNameWithOutExtention: filename_withoutextension.toString(),
                            objExcelDtl: modelexcel,
                        };
                         
                        createAPIEndpointDSR()
                            .post(`${Endpoint.UploadData}`, JSON.stringify(objUpload), {
                                headers: { "Content-Type": "application/json" },
                            })
                            .then((res) => {
                                if (res.data.Status == "Success") {
                                    toast.success("Data Uploaded Successfully", {
                                        position: toast.POSITION.TOP_CENTER,
                                        pauseOnFocusLoss: false,
                                        closeButton: false,
                                        autoClose: 5000,
                                    });
                                    setErrorxlsbtn(false);
                                    fileUploadRef.current.clear();
                                } else if (res.data.Status === "Warning") {
                                    toast.warning(res.data.Message, {
                                        position: toast.POSITION.TOP_CENTER,
                                        pauseOnFocusLoss: false,
                                        closeButton: false,
                                        autoClose: 4000,
                                    });

                                     

                                    if (res.data.dtErr) {
                                        toast.warning("Error File is avaliable to Download", {
                                            position: toast.POSITION.TOP_CENTER,
                                            pauseOnFocusLoss: false,
                                            closeButton: false,
                                            autoClose: 4000,
                                        });
                                        setErrorexceldata(res.data.dtErr);
                                        setErrorxlsbtn(true);
                                    }
                                }
                            })
                            .catch((error) => {
                                toast.error(error.message, {
                                    position: toast.POSITION.TOP_CENTER,
                                    pauseOnFocusLoss: false,
                                    closeButton: false,
                                    autoClose: 4000,
                                });
                            });
                    } else {
                        toast.warning("Can't Insert an Empty file", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 4000,
                        });
                    }
                } else {
                    toast.warning("please insert a valid file", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 4000,
                    });
                }
            };
        } else {
            toast.warning(
                "Please specify a valid file. Can upload only Excel and CSV file",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 4000,
                }
            );
        }
    };

    const handletabledata = () => {
        setTableloading(true);
        createAPIEndpointDSR()
            .get(`${Endpoint.ExportData}?CICode=${auth.CICode}&strCompID=${auth.CompId}`)
            .then((res) => {
                if(res.data){
                    setShowdatatbl(res.data.length <= 5 ? res.data : res.data.slice(0, 5));
                    setTableloading(false);
                }
                else{
                    setShowdatatbl([]);
                    setTableloading(false);
                }
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setTableloading(false);
            });

    };

    useEffect(() => {
        handletabledata();
    }, []);

    return (
        <>
            <div className="col-12 pt-3 pb-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">SE Dealer Mapping</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <button type="submit" className="btn btn-outline-primary me-3" onClick={handleExportTemplate} >
                                {loadingExceltemplate
                                    ? "Downloading..."
                                    : "Download Template"} <Fa.FaDownload className='ms-2' />
                            </button>
                            {errorxlsbtn && (
                                <button type="submit" className="btn btn-outline-secondery me-3" onClick={handleExportErrorxls} >
                                    Export Error File <Fa.FaFileExport className='ms-2' />
                                </button>
                            )}
                            <button type="submit" className="btn btn-primary" onClick={handleExportExcel} >
                                {loadingExcel ? "Loading..." : "Export"} <Fa.FaFileExport className='ms-2' />
                            </button>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="sales-box">
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="mb-3">
                                    <select className="form-select" aria-label="Default select example" defaultValue={dropdownselected}
                                        onChange={(event) =>
                                            setDropdownselected(event.target.value)
                                        }
                                    >
                                        <option value="Excel">XLSX</option>
                                        <option value="CSV">CSV</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-6 col-sm-12">

                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="UploadSection">
                                <FileUpload
                                    ref={fileUploadRef}
                                    customUpload
                                    uploadHandler={handleFileUpload}
                                    single="true"
                                    accept=".xlsx, .xls, .csv"
                                    onClear={() => {
                                        setErrorxlsbtn(false);
                                    }}
                                    onRemove={() => {
                                        setErrorxlsbtn(false);
                                    }}
                                    emptyTemplate={
                                        <p className="m-0">
                                            Drag and drop files here to upload.
                                        </p>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="DataTable pt-1">
                        <h3>Records</h3>
                        {tableloading ? <Loader /> :
                            <DataTable
                                value={showdatatbl}
                                showGridlines
                                stripedRows
                                emptyMessage="No Data found."
                                tableStyle={{ minWidth: "40rem" }}>
                                <Column field="Customer Code" header="Customer Code" />
                                <Column field="Customer Name" header="Customer Name" />
                                <Column field="DSR Employee Code" header="SE Code" />
                                <Column field="DSR Employee Name" header="SE Name" />
                            </DataTable>}
                    </div>
                </section>
            </div>
        </>
    );
}

export default DSRDealerMapping;
