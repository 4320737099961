import Axios from "../api/Axios";
const BASE_URL = "FreeInvoice/";
const Default_BASE_URL = "Default/";
const SaleOrder_BASE_URL = "SalesOrder/";
const Invoice_URL = "Invoice/";



export const Endpoint = {
    PopulateCustomer: "PopulateCustomer",
    PopulateMOP: "PopulateMOP",
    PopulatePayTerm: "PopulatePayTerm",
    PopulateSE: "PopulateSE",
    PopulateWH: "PopulateWH",
    PopulateSchemeCode: "PopulateSchemeCode",
    PopulateProduct: "PopulateProduct",
    GetDefaultValue: "DefaultVal",
    GetSegmentCODE:"GetSegmentCODE",
    GetCustomerInformation:"GetCustomerInformation",
    GetCustomerInfo:"GetCustomerInfo",
    GetRegistDesc:"GetRegistDesc",
    SaveFOC :"SaveFOC",
    GetBranchByWH:"GetBranchByWH",
    PrintInvoice:"PrintInvoice"
};

export const createAPIEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
        getDefaultValue:(Endpoint)=>Axios.get(Default_BASE_URL+Endpoint),
        getSegTaxID:(Endpoint)=>Axios.get(SaleOrder_BASE_URL+Endpoint),
        print:(Endpoint,data)=>Axios.post(Invoice_URL + Endpoint, data)
    };
};