import React, { useState, useEffect } from "react";
import * as Ci from "react-icons/ci";
import { Link } from "react-router-dom";
import { InputSwitch } from "primereact/inputswitch";
import { useSearchParams } from "react-router-dom";
import { useGRNContext } from "../../context/GRNContext";
// import DataTable from "react-data-table-component";
import { DataTable } from "primereact/datatable";
//import * as Fa from "react-icons/fa";
import Loader from "../../Components/Loader";
import { Column } from "primereact/column";
import { Checkbox } from "primereact/checkbox";
import * as Fa from "react-icons/fa";
import { toast } from "react-toastify";

function GRN() {
    const [checked, setChecked] = useState(false);
    const [searchParams] = useSearchParams();
    const [grnDatas, setGrnDatas] = useState({});

    const {
        filterGRNDatas,
        productGRNData,
        productListGRN,
        netVolume,
        loading,
        saveGRNCall,
    } = useGRNContext();
    let grnId = searchParams.get("grnId");
    //setSearchParams(grnId);

    useEffect(() => {
        grnDetailsPageLoad();
    }, [1]);

     
    let filterData = [];
    const grnDetailsPageLoad = () => {
        filterData = filterGRNDatas.filter((item) => item.GRN_NO == grnId);
   
        if (filterData.length > 0){
            setGrnDatas(filterData[0]);
            if (filterData[0].MASK_STATUS === "CN") {
                toast.warning("This GIT cannot inward as \"This invoice has been cancelled from distributor end\"", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
            }
        
        } 
        productListGRN(filterData[0].MASK_GRN_ID);

         

        // if (filterData.MASK_STATUS === "CN") {
        //   toast.success("New Customer has been created Successfully with", {
        //     position: toast.POSITION.TOP_CENTER,
        //     pauseOnFocusLoss: false,
        //     closeButton: false,
        //     autoClose: 2000,
        //   });
        // }
         
    };
    const toggleChange = (value) => {
        setChecked(value);
    };

    const saveGRN = () => {
        saveGRNCall(grnDatas, productGRNData);
    };

    const renderAction = (row) => {
        return (
            <Checkbox
                disabled
                checked={row.MASK_GRND_SCHM_PROCESS_TAG == "N" ? false : true}
            ></Checkbox>
        );
    };

    return (
        <div>
            {" "}
            {loading == true ? (
                <Loader />
            ) : (
                <div className="col-12 pt-3 pb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/purchasemanagement/goodsreceiptnote">Goods Receipt Note</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                         Details
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/purchasemanagement/goodsreceiptnote">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" />Back to All Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <section className="sales-box">
                        <h4>GRN Details</h4>
                        <form>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">GRN Number </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.GRN_NO}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label"> GRN Date </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.GRN_DATE}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Warehouse</label>
                                        {/* <select disabled
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                >
                                    <option value="Normal">{grnDatas.MASK_WH_DESC}</option>

                                </select> */}
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.MASK_WH_DESC}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Supplier Name</label>
                                        {/* <select disabled
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                >
                                    <option value="Normal">W1(Thane)</option>
                                    <option value="Normal">W2(Thane)</option>
                                </select> */}
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.SUPP_NAME}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">
                      Supplier Invoice Number
                                        </label>
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.MASK_GRN_SUPP_INV_NO}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Supplier Invoice Date</label>
                                        {/* <input
                                    type="Date"
                                    className="form-control"
                                /> */}
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.MASK_GRN_SUPP_INV_DATE}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Mode of Payment</label>
                                        {/* <select disabled
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                >
                                    <option value="Normal">Cash</option>
                                    <option value="Normal">W2(Thane)</option>
                                </select> */}
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.MASK_MOP_DESC}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Payment Term</label>
                                        {/* <select disabled
                                    className="form-select form-select-lg mb-3"
                                    aria-label=".form-select-lg example"
                                >
                                    <option value="Normal">Longterm</option>
                                    <option value="Normal">W2(Thane)</option>
                                </select> */}
                                        <input
                                            type="Text"
                                            disabled
                                            value={grnDatas.MASK_PAY_TERM_DESC}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">
                      Supplier Invoice Amount
                                        </label>
                                        <input
                                            disabled
                                            type="text"
                                            value={Number(
                                                grnDatas.MASK_GRN_TOT_SUPP_INV_AMT
                                            ).toLocaleString("en-IN", {
                                                style: "currency",
                                                currency: "INR",
                                            })}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">Total Amount</label>
                                        <input
                                            disabled
                                            type="text"
                                            value={Number(grnDatas.MASK_GRN_TOT_AMT).toLocaleString(
                                                "en-IN",
                                                { style: "currency", currency: "INR" }
                                            )}
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {grnDatas.MASK_GRN_TYPE == "G" ? (
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="gitLoc">
                                                <label className="d-inline green-color">
                                                    {"GIT Location Transfer"}
                                                </label>
                                                <div className="mt-n2 d-inline">
                                                    <InputSwitch
                                                        checked={checked}
                                                        onChange={(e) => toggleChange(e.value)}
                                                        disabled={
                                                            grnDatas.MASK_STATUS === "CN" ? true : false
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12"></div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="float-end">
                                                <button
                                                    disabled={!checked}
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        saveGRN();
                                                    }}
                                                >
                          Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </form>
                    </section>

                    <section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>
                            <div className="DataTable pb-4 pt-1">
                                <DataTable
                                    value={productGRNData}
                                    dataKey="INVH_NO"
                                    paginator
                                    rows={10}
                                    // filterDisplay="row"
                                    showGridlines
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    stripedRows
                                    globalFilterFields={["PROD_CODE", "DESCRIPTION"]}
                                    emptyMessage="No customers found."
                                    tableStyle={{ minWidth: "60rem" }}
                                >
                                    <Column field="PROD_CODE" header="Product Code" />
                                    <Column field="PROD_BATCH_NO" header="Product Lot Number" />
                                    <Column
                                        field="PROD_SMALL_DESC"
                                        header="Product Description"
                                    />
                                    <Column
                                        field={
                                            productGRNData.TOT_RECV_QTY !== ".00" ||
                      productGRNData.TOT_RECV_QTY !== "0.00"
                                                ? "TOT_RECV_QTY"
                                                : null
                                        }
                                        header="Order Quantity"
                                        className="text-end Right_Column"
                                    />
                                    <Column
                                        field="EXTENTED_PRICE"
                                        header="Price(₹)"
                                        className="text-end Right_Column"
                                    />
                                    <Column field={renderAction} header="Free Given" />

                                    {/* <Column field="CUST_NAME" header="Customer Name" />
                                        <Column field="CUST_TYPE" header="Customer Type" />
                                        <Column field="OTP_STATUS" header="OTP Status" /> */}
                                </DataTable>
                            </div>
                        </div>
                        <div className="row me-2 grn-summery mb-4">
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="total-panel">
                                    <div className="total-box">
                                        <Ci.CiBeaker1 className="icon-sz" /> Net Volume(EA/Ltrs/Kg):
                                    </div>
                                    <div className="total-box">
                                        <span>{netVolume}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="total-panel">
                                    <div className="total-box">
                                        <Ci.CiBag1 className="icon-sz" /> GST:
                                    </div>
                                    <div className="total-box">
                                        <span>
                                            {productGRNData
                                                .map((a) => Number(a.MASK_GRND_TOT_VAT))
                                                .reduce((a, b) => a + b, 0)
                                                .toLocaleString("en-IN", {
                                                    style: "currency",
                                                    currency: "INR",
                                                })}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <div className="total-panel">
                                    <div className="total-box">
                                        <Ci.CiCalculator1 className="icon-sz" /> Net Amount:
                                    </div>
                                    <div className="total-box">
                                        <span>
                                            {(
                                                productGRNData
                                                    .map((a) => Number(a.EXTENTED_PRICE))
                                                    .reduce((a, b) => a + b, 0) -
                        productGRNData
                            .map((a) => Number(a.CDR_DISC))
                            .reduce((a, b) => a + b, 0) +
                        productGRNData
                            .map((a) => Number(a.MASK_GRND_TOT_TAX))
                            .reduce((a, b) => a + b, 0) +
                        productGRNData
                            .map((a) => Number(a.GRND_SPLCON_TAX))
                            .reduce((a, b) => a + b, 0) -
                        productGRNData
                            .map((a) => Number(a.MASK_GRND_TRADE_DISC))
                            .reduce((a, b) => a + b, 0) +
                        productGRNData
                            .map((a) => Number(a.MASK_GRND_TOT_VAT))
                            .reduce((a, b) => a + b, 0) -
                        productGRNData
                            .map((a) => Number(a.MASK_GRND_MOP_DISC))
                            .reduce((a, b) => a + b, 0)
                                            ).toLocaleString("en-IN", {
                                                style: "currency",
                                                currency: "INR",
                                            })}
                                        </span>
                                    </div>
                                </div>
                                <p className="small-text">[Extended Price + GST]</p>
                            </div>
                        </div>
                    </section>
                </div>
            )}
        </div>
    );
}

export default GRN;
