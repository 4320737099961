import React, { useContext, useState } from "react";
import { useGlobalContext1 } from "./SalesDetailsContext";
import Axios from "../api/Axios";
import { toast } from "react-toastify";
import storage from "../Services/Storage";

let CartVolume = [];
const reallocation = "SalesOrder/ReAllocation";
const addToCart = "SalesOrder/AddToCart";
let UOM;
let pdtCode;
let pdtLot;
let pdtOrder;
let pdtBack;
const ReallocateContext = React.createContext();
const ReallocateProvider = ({ children }) => {
    const auth = storage.getLocal("user");
    const [reallocateDetails, setReallocateDetails] = useState([]);
    const [filteredReallocate, setFilteredReallocate] = useState();
    const [selectedSalesDetails, setSelectedSalesDetails] = useState([]);
    const [releaseQtyAdd, setReleaseQtyAdd] = useState(0);
    const [selectRow, setSelectRow] = useState("");
    const [ReallocateLoader, setReallocateLoader] = useState(false);
    const [addToCartLoader, setAddToCartLoader] = useState(false);
    const [promoCode, setPromoCode] = useState([0]);
    const [orderQty, setOrderQty] = useState([]);
    const [releaseQty, setReleaseQty] = useState([]);
    const [soDetailsTable, setSoDetailsTable] = useState([]);
    const [defVal, setDefVal] = useState([]);
    const [manualReallocateBtn, setManualReallocateBtn] = useState(false);
    const [prodRow, setProdRow] = useState();
    const [suggestedProductList, setSuggestedProductList] = useState([]);
    const [reallocateToggel, setReallocateToggel] = useState(false);
    const GetNetVolltr_URL = "SalesOrder/GetNetVolltr?";
    const GetNetVolwt_URL = "SalesOrder/GetNetVolwt?";  

    const {
        filteredSalesDetails,
        salesOrderHdr,
        setFilteredSalesDetails,
        custId,
        payload,
        cartVal,
        setCartVal,
        // SONo,
        // setSONo,
        // priceCalculation,
        // setPriceCalculation,
        setAddToCart,
        setPriceCalculation,
        setOrderSummary,
        setManualSOLoader,
        setDisableRelease,
        setVolume,
        setWeight
    } = useGlobalContext1();
    const [modalShow, setModalShow] = useState(false);

    const GetAllSalesOrderReallocate = () => {
        setFilteredReallocate(filteredSalesDetails);
    };

    const handleEdit = (ref, row) => {
         
         
        setSelectRow(row);
        reallocate(row);
    };

    const reallocate = async (row) => {
        setManualReallocateBtn(false);
        setReallocateLoader(true);
        setReallocateToggel(false);
        if (
            row.MASK_SODT_PROD_STK_TYPE.toString() === "1" ||
            row.MASK_SODT_PROD_STK_TYPE.toUpperCase() === "INVENTORY"
        ) {
            UOM = "2";
        } else {
            UOM = "1";
        }
        let sODetailsData = {
            // BASE_PROD_ALLOC: "0",
            // BASE_PROD_CODE: null,
            // BASE_PROD_LOT: null,
            DESCRIPTION: row.PROD_SMALL_DESC,
            COMP_ID: row.COMP_ID,
            MASK_SODT_ID: row.MASK_SODT_ID,
            DIVISION: row.MASK_DIVN_ID,
            MASK_PROD_ID: row.MASK_PROD_ID,
            STOCK_TYPE: row.MASK_SODT_PROD_STK_TYPE,
            PRODUCT_CODE: row.PRODUCT_CODE,
            PROD_SMALL_DESC: row.PROD_SMALL_DESC,
            PROD_LOT_NO: row.PROD_LOT_NO,
            PRICE_LIST_NO: row.PRICE_LIST_NO,
            CASE_TYPE: row.CASE_TYPE,
            CDR_DISC: row.CDR_DISC,
            EUS_DISC_PERC: row.EUS_DISC_PERC.toString(),
            EUS_DISC: row.EUS_DISC.toString(),
            CASE_ORD_QTY: row.CASE_ORD_QTY.toString(),
            PACK_ORD_QTY: row.PACK_ORD_QTY.toString(),
            TOT_ORD_QTY: row.TOT_ORD_QTY.toString(),
            PROD_PRICE: row.PROD_PRICE.toString(),
            BASE_PRICE: row.BASE_PRICE.toString(),
            CASE_REL_QTY: row.CASE_REL_QTY.toString(),
            PACK_REL_QTY: row.PACK_REL_QTY.toString(),
            TOT_REL_QTY: Math.floor(row.MASK_TOT_REL_QTY).toString(),
            TOT_INV_QTY: row.TOT_INV_QTY.toString(),
            MASK_PROD_VAL: row.MASK_PROD_VAL,
            MASK_SODT_PROD_STK_TYPE: row.MASK_SODT_PROD_STK_TYPE,
            MASK_PACK_UOM_CODE: row.MASK_PACK_UOM_CODE,
            MASK_TOT_BACK_QTY: row.MASK_TOT_BACK_QTY.toString(),
            MASK_TOT_DROP_QTY: row.MASK_TOT_DROP_QTY.toString(),
            MASK_TRADE_DISC: row.MASK_TRADE_DISC.toString(),
            MASK_VAL_BASE_CRNC: row.MASK_VAL_BASE_CRNC.toString(),
            MASK_SPLCON_TAX: row.MASK_SPLCON_TAX.toString(),
            MASK_TOT_TAX: row.MASK_TOT_TAX.toString(),
            MASK_SODT_TOT_VAT: row.MASK_SODT_TOT_VAT.toString(),
            MASK_CASE_FACTOR: row.MASK_CASE_FACTOR.toString(),
            MASK_STATUS: row.MASK_STATUS,
            UPDATE_COUNT: row.MASK_UPDATE_COUNT.toString(),
            MASK_EXPORTED: row.MASK_EXPORTED,
            MASK_FIRST_ENTERED_BY: row.MASK_FIRST_ENTERED_BY,
            MASK_FIRST_ENTERED_ON: row.MASK_FIRST_ENTERED_ON,
            MASK_LAST_MODIFIED_BY: row.MASK_LAST_MODIFIED_BY,
            MASK_LAST_MODIFIED_ON: row.MASK_LAST_MODIFIED_ON,
            MASK_IUKEY: row.MASK_IUKEY,
            MASK_SEQUENCE: row.MASK_SEQUENCE?row.MASK_SEQUENCE.toString():row.MASK_SEQUENCE,
            EXTENDED_PRICE: row.EXTENDED_PRICE.toString(),
            MASK_SODT_EUS_DISC_PERC_TAG: row.MASK_SODT_EUS_DISC_PERC_TAG,
            MASK_SODT_TRANS_VAL: row.MASK_SODT_TRANS_VAL.toString(),
            MASK_SODT_CDR_DISC_PERC: row.MASK_SODT_CDR_DISC_PERC.toString(),
            MASK_SODT_CDR_DISC_PERC_TAG: row.MASK_SODT_CDR_DISC_PERC_TAG,
            MASK_SODT_PRICE_OR_TAG: row.MASK_SODT_PRICE_OR_TAG,
            MASK_SODT_UPPER_BAND: row.MASK_SODT_UPPER_BAND,
            MASK_SODT_LOWER_BAND: row.MASK_SODT_LOWER_BAND,
            MASK_SODT_PRL_PRICE: row.MASK_SODT_PRL_PRICE,
            MASK_SOHD_ID: row.MASK_SOHD_ID,
            MASK_DIVN_ID: row.MASK_DIVN_ID,
            MASK_EVAL_BY_LOT_TAG: row.MASK_EVAL_BY_LOT_TAG,
            MASK_EUS_DISC_APP_TO: row.MASK_EUS_DISC_APP_TO,
            MASK_PRODUCT_MRP: row.MASK_PRODUCT_MRP.toString(),
            MASK_TOT_REL_QTY: row.MASK_TOT_REL_QTY.toString(),
            MASK_OLD_STK_ONLY: row.MASK_OLD_STK_ONLY,
            RBO_REAS_CODE: row.RBO_REAS_CODE,
            STOCK_TYP: row.STOCK_TYPE === "Inventory" ? "1" : "2",
            // DESCRIPTION: row.DESCRIPTION,
            TOT_AVL_QTY: null,
            FREE_SCHM_CODE: row.FREE_SCHM_CODE,
            BASE_PROD_CODE: row.BASE_PROD_CODE,
            BASE_PROD_LOT: row.BASE_PROD_LOT,
            BASE_PROD_ALLOC: row.BASE_PROD_ALLOC,
            MASK_UPDATE_COUNT: row.MASK_UPDATE_COUNT.toString(),
        };
        let obj = {
            CICode: auth.CICode,
            CompId: auth.CompId,
            CustId: payload ? custId[0].MASK_CUST_ID : salesOrderHdr.MASK_CUST_ID,
            LotNo: row.PROD_LOT_NO,
            OddLotNo: "",
            ComboProduct: row.PRODUCT_CODE,
            ComboOddLotProduct: "",
            OrderQty: row.PACK_ORD_QTY.toString(),
            OddOrderQty: "",
            ReallocateProdId: row.PRODUCT_CODE,
            ComboFreeProduct: "",
            OEMORDER: "TATA",
            StockType: row.MASK_SODT_PROD_STK_TYPE,
            Tag: "N",
            UserId: auth.UserId,
            PrintFormatCode: auth.PrintFormatCode,
            UOM: UOM,
            UOMCode: row.MASK_PACK_UOM_CODE,
            CurrencyId: salesOrderHdr.MASK_CURRENCY_ID,
            CurrencyCode: "2",
            strDivnId: row.MASK_DIVN_ID,
            SODetailsData: [sODetailsData],
        };
        try {
            const res = await Axios.post(reallocation, JSON.stringify(obj), {
                headers: { "Content-Type": "application/json" },
            });

            //
            if (
                res.data.SODetailsData.PromoCode ===
                "No other promo code is mapped with this base code" ||
                res.data.SODetailsData.PromoCode === null ||
                res.data.SODetailsData.PromoCode === "0"
            ) {
                setPromoCode(res.data.SODetailsData.PromoCode);
            } else {
                setPromoCode(res.data.SODetailsData.PromoCode.split(",").join(", "));
            }
            setModalShow(true);
            setReallocateLoader(false);
            const reallocationFilter = res.data.SalesOrder.filter(
                (val) => Number(val.BALANCED_QTY) >= 0
            );
            setSelectedSalesDetails(row);
            reallocationFilter.sort((a, b) => {
                return b.PACK_REL_QTY - a.PACK_REL_QTY;
            });
            console.log("reallocationFilter",reallocationFilter);
            setReallocateDetails(reallocationFilter);
            pdtCode = row.PRODUCT_CODE;
            pdtLot = row.PROD_LOT_NO;
            pdtOrder = row.TOT_ORD_QTY;
            pdtBack = row.MASK_TOT_BACK_QTY;
            //   setCartVal((current) =>
            //     current.filter((productDtl) => {
            //       return (
            //         productDtl.PRODUCT_CODE !== row.PRODUCT_CODE &&
            //         productDtl.PROD_LOT_NO !== row.PROD_LOT_NO
            //       );
            //     })
            //   );
        } catch (error) {
            setReallocateLoader(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
             
        }
    };

    const handleReallocateReleaseQty = async () => {
        setAddToCartLoader(true);
        setManualSOLoader(true);
        //  
        // alert(custid);
        let packRelQty = reallocateDetails
            .map((a) => Number(a.PACK_REL_QTY))
            .reduce((a, b) => a + b);
        if (reallocateToggel) {
            if (Number(selectedSalesDetails.OrderQty) < packRelQty) {
                toast.warn(
                    "Release quantity should not be more than order quantity",
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
                );
                setAddToCartLoader(false);
                setManualSOLoader(false);
                setReleaseQty("");
                setOrderQty("");
                setSuggestedProductList("");
                return;
            }
        } else {
            if (Number(selectedSalesDetails.TOT_ORD_QTY) < packRelQty) {
                toast.warn(
                    "Release quantity should not be more than order quantity",
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
                );
                setAddToCartLoader(false);
                setManualSOLoader(false);
                return;
            }
            else {
                if (
                    Number(selectedSalesDetails.TOT_ORD_QTY) <
                    packRelQty + Number(selectedSalesDetails.TOT_INV_QTY)
                ) {
                    toast.warn(
                        `Please change Release quantity, already Invoiced quantity :${selectedSalesDetails.TOT_INV_QTY}`,
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }
                    );
                    setAddToCartLoader(false);
                    setManualSOLoader(false);
                    return;
                }
            }
        }

        //     if (
        //         filteredSalesDetails[0].MASK_SODT_PROD_STK_TYPE.toString() === "1" ||
        //   filteredSalesDetails[0].MASK_SODT_PROD_STK_TYPE.toUpperCase() ===
        //     "INVENTORY"
        //     ) {
        //         UOM = "2";
        //     } else {
        //         UOM = "1";
        //     }
        let obj = {
            AddToCart: payload ? "Manual" : "",
            CICode: auth.CICode,
            CustId: payload ? custId[0].MASK_CUST_ID : salesOrderHdr.MASK_CUST_ID,
            // salesOrderHdr.MASK_CUST_ID,
            soHrdId: salesOrderHdr.MASK_SO_ID,
            UserId: auth.UserId,
            OrderQty: selectedSalesDetails.TOT_ORD_QTY ? selectedSalesDetails.TOT_ORD_QTY.toString() : orderQty,
            InvQty: selectedSalesDetails.TOT_INV_QTY ? selectedSalesDetails.TOT_INV_QTY.toString() : "0",
            CompId: auth.CompId,
            TradeDiscPer: "0",
            PrintFormatCode: auth.PrintFormatCode,
            UOM: "2",
            strDivnId: payload ? defVal.Id : selectedSalesDetails.MASK_DIVN_ID,
            StockType: payload ? "1" : selectedSalesDetails.MASK_SODT_PROD_STK_TYPE,
            SubProd: reallocateDetails.map((a) => ({
                // ...a,
                MASK_PROD_ID:a.MASK_PROD_ID,
                PRODUCT_CODE:a.PRODUCT_CODE,
                MASK_PROD_PACK_DESC:a.MASK_PROD_PACK_DESC,
                PROD_BATCH_NO:a.PROD_BATCH_NO,
                BALANCED_QTY: a.BALANCED_QTY === "" ? "0" : a.BALANCED_QTY.toString(),
                PACK_REL_QTY:a.PACK_REL_QTY,
                PROD_MRP:a.PROD_MRP,
                PROD_UNIT_PRICE:a.PROD_UNIT_PRICE.toString(),
                // BALANCED_QTY: a.BALANCED_QTY === "" ? "0" : a.BALANCED_QTY.toString(),
            })),
            SODetailsData: manualReallocateBtn ? soDetailsTable.map((a) => ({ 
                MASK_SODT_ID:a.MASK_SODT_ID,
                DIVISION:a.DIVISION ,
                MASK_PROD_ID:a.MASK_PROD_ID ,
                STOCK_TYPE:a.STOCK_TYPE,
                PRODUCT_CODE:a.PRODUCT_CODE ,
                PROD_SMALL_DESC:a.PROD_SMALL_DESC ,
                PROD_LOT_NO:a.PROD_LOT_NO ,
                PRICE_LIST_NO:a.PRICE_LIST_NO ,
                CASE_TYPE: a.CASE_TYPE,
                CDR_DISC:a.CDR_DISC ,
                EUS_DISC_PERC:a.EUS_DISC_PERC.toString(),
                EUS_DISC:a.EUS_DISC.toString() ,
                CASE_ORD_QTY:a.CASE_ORD_QTY.toString() ,
                PACK_ORD_QTY:a.PACK_ORD_QTY.toString() ,
                TOT_ORD_QTY:a.TOT_ORD_QTY.toString() ,
                PROD_PRICE:a.PROD_PRICE.toString(),
                BASE_PRICE:a.BASE_PRICE.toString() ,
                CASE_REL_QTY:a.CASE_REL_QTY.toString(),
                PACK_REL_QTY:a.PACK_REL_QTY.toString() ,
                TOT_REL_QTY:a.TOT_REL_QTY.toString() ,
                TOT_INV_QTY:a.TOT_INV_QTY.toString(),
                MASK_PROD_VAL:a.MASK_PROD_VAL.toString() ,
                MASK_SODT_PROD_STK_TYPE:a.MASK_SODT_PROD_STK_TYPE ,
                MASK_PACK_UOM_CODE:a.MASK_PACK_UOM_CODE ,
                MASK_TOT_BACK_QTY:a.MASK_TOT_BACK_QTY.toString() ,
                MASK_TOT_DROP_QTY:a.MASK_TOT_DROP_QTY.toString() ,
                MASK_TRADE_DISC:a.MASK_TRADE_DISC.toString() ,
                MASK_VAL_BASE_CRNC:a.MASK_VAL_BASE_CRNC.toString(),
                MASK_SPLCON_TAX:a.MASK_SPLCON_TAX.toString() ,
                MASK_TOT_TAX:a.MASK_TOT_TAX.toString() ,
                MASK_SODT_TOT_VAT:a.MASK_SODT_TOT_VAT.toString(),
                MASK_CASE_FACTOR:a.MASK_CASE_FACTOR.toString() ,
                MASK_STATUS:a.MASK_STATUS,
                MASK_UPDATE_COUNT:a.MASK_UPDATE_COUNT.toString() ,
                MASK_EXPORTED:a.MASK_EXPORTED,
                MASK_FIRST_ENTERED_BY:a.MASK_FIRST_ENTERED_BY ,
                MASK_FIRST_ENTERED_ON:a.MASK_FIRST_ENTERED_ON ,
                MASK_LAST_MODIFIED_BY:a.MASK_LAST_MODIFIED_BY ,
                MASK_LAST_MODIFIED_ON:a.MASK_LAST_MODIFIED_ON ,
                MASK_IUKEY:a.MASK_IUKEY ,
                MASK_SEQUENCE:a.MASK_SEQUENCE!=null?a.MASK_SEQUENCE.toString():a.MASK_SEQUENCE ,
                EXTENDED_PRICE:a.EXTENDED_PRICE.toString(),
                MASK_SODT_EUS_DISC_PERC_TAG:a.MASK_SODT_EUS_DISC_PERC_TAG ,
                MASK_SODT_TRANS_VAL:a.MASK_SODT_TRANS_VAL.toString() ,
                MASK_SODT_CDR_DISC_PERC:a.MASK_SODT_CDR_DISC_PERC.toString() ,
                MASK_SODT_CDR_DISC_PERC_TAG:a.MASK_SODT_CDR_DISC_PERC_TAG ,
                MASK_SODT_PRICE_OR_TAG:a.MASK_SODT_PRICE_OR_TAG ,
                MASK_SODT_UPPER_BAND:a.MASK_SODT_UPPER_BAND ,
                MASK_SODT_LOWER_BAND:a.MASK_SODT_LOWER_BAND ,
                MASK_SODT_PRL_PRICE:a.MASK_SODT_PRL_PRICE ,
                MASK_SOHD_ID:a.MASK_SOHD_ID ,
                MASK_DIVN_ID:a.MASK_DIVN_ID ,
                MASK_EVAL_BY_LOT_TAG:a.MASK_EVAL_BY_LOT_TAG ,
                MASK_EUS_DISC_APP_TO:a.MASK_EUS_DISC_APP_TO ,
                MASK_PRODUCT_MRP:a.MASK_PRODUCT_MRP.toString() ,
                MASK_TOT_REL_QTY: a.MASK_TOT_REL_QTY.toString(),
                MASK_OLD_STK_ONLY:a.MASK_OLD_STK_ONLY ,
                RBO_REAS_CODE:a.RBO_REAS_CODE ,
                BASE_PROD_ALLOC:a.BASE_PROD_ALLOC ,
                STOCK_TYP:a.STOCK_TYP ,
                DESCRIPTION:a.DESCRIPTION ,
                TOT_AVL_QTY:a.TOT_AVL_QTY})): [selectedSalesDetails].map((a) => ({
                MASK_SODT_ID:a.MASK_SODT_ID,
                DIVISION:a.DIVISION ,
                MASK_PROD_ID:a.MASK_PROD_ID ,
                STOCK_TYPE:a.STOCK_TYPE,
                PRODUCT_CODE:a.PRODUCT_CODE ,
                PROD_SMALL_DESC:a.PROD_SMALL_DESC ,
                PROD_LOT_NO:a.PROD_LOT_NO ,
                PRICE_LIST_NO:a.PRICE_LIST_NO ,
                CASE_TYPE: a.CASE_TYPE,
                CDR_DISC:a.CDR_DISC ,
                EUS_DISC_PERC:a.EUS_DISC_PERC.toString(),
                EUS_DISC:a.EUS_DISC.toString() ,
                CASE_ORD_QTY:a.CASE_ORD_QTY.toString() ,
                PACK_ORD_QTY:a.PACK_ORD_QTY.toString() ,
                TOT_ORD_QTY:a.TOT_ORD_QTY.toString() ,
                PROD_PRICE:a.PROD_PRICE.toString(),
                BASE_PRICE:a.BASE_PRICE.toString() ,
                CASE_REL_QTY:a.CASE_REL_QTY.toString(),
                PACK_REL_QTY:a.PACK_REL_QTY.toString() ,
                TOT_REL_QTY:a.TOT_REL_QTY.toString() ,
                TOT_INV_QTY:a.TOT_INV_QTY.toString(),
                MASK_PROD_VAL:a.MASK_PROD_VAL.toString() ,
                MASK_SODT_PROD_STK_TYPE:a.MASK_SODT_PROD_STK_TYPE ,
                MASK_PACK_UOM_CODE:a.MASK_PACK_UOM_CODE ,
                MASK_TOT_BACK_QTY:a.MASK_TOT_BACK_QTY.toString() ,
                MASK_TOT_DROP_QTY:a.MASK_TOT_DROP_QTY.toString() ,
                MASK_TRADE_DISC:a.MASK_TRADE_DISC.toString() ,
                MASK_VAL_BASE_CRNC:a.MASK_VAL_BASE_CRNC.toString(),
                MASK_SPLCON_TAX:a.MASK_SPLCON_TAX.toString() ,
                MASK_TOT_TAX:a.MASK_TOT_TAX.toString() ,
                MASK_SODT_TOT_VAT:a.MASK_SODT_TOT_VAT.toString(),
                MASK_CASE_FACTOR:a.MASK_CASE_FACTOR.toString() ,
                MASK_STATUS:a.MASK_STATUS,
                MASK_UPDATE_COUNT:a.MASK_UPDATE_COUNT.toString(),
                MASK_EXPORTED:a.MASK_EXPORTED,
                MASK_FIRST_ENTERED_BY:a.MASK_FIRST_ENTERED_BY ,
                MASK_FIRST_ENTERED_ON:a.MASK_FIRST_ENTERED_ON ,
                MASK_LAST_MODIFIED_BY:a.MASK_LAST_MODIFIED_BY ,
                MASK_LAST_MODIFIED_ON:a.MASK_LAST_MODIFIED_ON ,
                MASK_IUKEY:a.MASK_IUKEY ,
                MASK_SEQUENCE:a.MASK_SEQUENCE!=null?a.MASK_SEQUENCE.toString():a.MASK_SEQUENCE ,
                EXTENDED_PRICE:a.EXTENDED_PRICE.toString(),
                MASK_SODT_EUS_DISC_PERC_TAG:a.MASK_SODT_EUS_DISC_PERC_TAG ,
                MASK_SODT_TRANS_VAL:a.MASK_SODT_TRANS_VAL.toString() ,
                MASK_SODT_CDR_DISC_PERC:a.MASK_SODT_CDR_DISC_PERC.toString() ,
                MASK_SODT_CDR_DISC_PERC_TAG:a.MASK_SODT_CDR_DISC_PERC_TAG ,
                MASK_SODT_PRICE_OR_TAG:a.MASK_SODT_PRICE_OR_TAG ,
                MASK_SODT_UPPER_BAND:a.MASK_SODT_UPPER_BAND ,
                MASK_SODT_LOWER_BAND:a.MASK_SODT_LOWER_BAND ,
                MASK_SODT_PRL_PRICE:a.MASK_SODT_PRL_PRICE ,
                MASK_SOHD_ID:a.MASK_SOHD_ID ,
                MASK_DIVN_ID:a.MASK_DIVN_ID ,
                MASK_EVAL_BY_LOT_TAG:a.MASK_EVAL_BY_LOT_TAG ,
                MASK_EUS_DISC_APP_TO:a.MASK_EUS_DISC_APP_TO ,
                MASK_PRODUCT_MRP:a.MASK_PRODUCT_MRP.toString() ,
                MASK_TOT_REL_QTY: a.MASK_TOT_REL_QTY.toString(),
                MASK_OLD_STK_ONLY:a.MASK_OLD_STK_ONLY ,
                RBO_REAS_CODE:a.RBO_REAS_CODE ,
                BASE_PROD_ALLOC:a.BASE_PROD_ALLOC ,
                STOCK_TYP:a.STOCK_TYP ,
                DESCRIPTION:a.DESCRIPTION ,
                TOT_AVL_QTY:a.TOT_AVL_QTY
                // ,
                // MASK_UPDATE_COUNT: a.MASK_UPDATE_COUNT.toString(),
                // BASE_PROD_CODE: a.BASE_PROD_CODE,
                // BASE_PROD_LOT: a.BASE_PROD_LOT,
            })),
            OEMORDER: "",
        };

         
        // return;
        try {
            const res = await Axios.post(addToCart, JSON.stringify(obj), {
                headers: { "Content-Type": "application/json" },
            });
            if (res.data.SODetailsData !== null) {
                // setCartVal((current) =>
                //   current.filter((productDtl) => {
                //     return (
                //       productDtl.PRODUCT_CODE !== pdtCode &&
                //       productDtl.PROD_LOT_NO !== pdtLot
                //     );
                //   })
                // );
                if (payload) {
                    //             setCartVal((current) =>
                    //     current.filter((productDtl) => {
                    //         return (
                    //             productDtl.PRODUCT_CODE + productDtl.PROD_LOT_NO !== pdtCode + pdtLot
                    //         );
                    //     })
                    // );
                    let cart;
                    if ((cartVal.length - 1) + res.data.SODetailsData.length > 24) {
                        toast.warn("Items should not exceeds 24 lines", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                        setManualSOLoader(false);
                        setReleaseQty("");
                        setOrderQty("");
                        setSuggestedProductList("");
                    } else {
                        if (cartVal.length !== 0) {
                            cart = cartVal.filter((val) => { return val.PRODUCT_CODE + val.PROD_LOT_NO + val.PACK_ORD_QTY + val.MASK_TOT_BACK_QTY !== pdtCode + pdtLot + pdtOrder + pdtBack; });
                            setCartVal(cart);
                        }
                        for (let i = 0; i < res.data.SODetailsData.length; i++) {
                            // let cart = cartVal;
                            // const index = cart.findIndex((num) => num.PRODUCT_CODE===selectedSalesDetails.PRODUCT_CODE && num.PROD_LOT_NO===selectedSalesDetails.PROD_LOT_NO );
                            // cart.splice(index);
                            //  
                            // cart.push(res.data.SODetailsData[i]);
                            setCartVal((current) => [...current, res.data.SODetailsData[i]]);
                            CartVolume.push(res.data.SODetailsData[i]);
                            netvol(CartVolume);
                            setPriceCalculation((current) => [...current, res.data.SODetailsData[i]]);
                            setOrderSummary(true);
                        }
                        // setCartVal(res.data.SODetailsData);
                        setFilteredSalesDetails(res.data.SODetailsData);
                        // for (let i = 0; i < (res.data.SODetailsData.length+cart.length); i++){
                        //     for(let j = i+1; j < (res.data.SODetailsData.length+cart.length); j++){
                        //         if(cartVal[i].PRODUCT_CODE + cartVal[i].PROD_LOT_NO === res.data.SODetailsData[j].PRODUCT_CODE + res.data.SODetailsData[j].PROD_LOT_NO){
                        //             let storeCart = res.data.SODetailsData[j];
                        //              
                        //             // let cart = cartVal.filter((a)=>{return a.PRODUCT_CODE + a.PROD_LOT_NO === })
                        //         }
                        //     }
                        // }
                        // ! ________________________________________________
                        // ! to find the duplicate row
                        // for(let i = 0; i < cart.length; i++){
                        //     let storeCart = res.data.SODetailsData.filter((val)=> val.PRODUCT_CODE + val.PROD_LOT_NO === cart[i].PRODUCT_CODE + cart[i].PROD_LOT_NO);
                        //      
                        //      
                        // }
                        toast.success("Product successfully added to cart", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                        setManualSOLoader(false);
                        setReleaseQty("");
                        setOrderQty("");
                        setSuggestedProductList("");
                    }
                } else {
                    if ((filteredSalesDetails.length - 1) + res.data.SODetailsData.length > 24) {
                        toast.warn("Order should not exceed 24 line items", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                        setAddToCartLoader(false);
                        setManualSOLoader(false);
                    } else {
                        let _filteredSalesDetails = filteredSalesDetails.filter(
                            (a) => a.PRODUCT_CODE + a.PROD_LOT_NO + a.PACK_ORD_QTY + a.MASK_TOT_BACK_QTY !== pdtCode + pdtLot + pdtOrder + pdtBack
                        );
                        // res.data.SODetailsData.forEach((a) => {
                        //     _filteredSalesDetails.push({ ...a });
                        // });
                        setFilteredSalesDetails(_filteredSalesDetails);
                        for (let i = 0; i < res.data.SODetailsData.length; i++) {
                            setFilteredSalesDetails((current) => [...current, res.data.SODetailsData[i]]);
                        }
                        let arrlen = _filteredSalesDetails.filter(
                            (val) => val.TOT_REL_QTY > 0
                        );
                        setReleaseQtyAdd(arrlen.length);
                    }
                    setAddToCartLoader(false);
                    setManualSOLoader(false);
                }
            } else setFilteredSalesDetails(filteredSalesDetails);

            //
            setModalShow(false);
            setAddToCart(true);
            setManualSOLoader(false);
            setReallocateToggel(false);
            setDisableRelease([]);
            setAddToCartLoader(false);
        } 
        catch (error) {
            setAddToCartLoader(false);
            setManualSOLoader(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
             
        }
    };

    const netvol = async (CartVolume) => {
        setWeight(0);
        setVolume(0);
        let sumwt = 0;
        let sumlt = 0;
        console.log("CartVolume",CartVolume);
        for (let i = 0; i < CartVolume.length; i++) {   
            const volltr = `${GetNetVolltr_URL}CICode=${auth.CICode}&strProdId=${CartVolume[i].MASK_PROD_ID}`;
            const volwt = `${GetNetVolwt_URL}CICode=${auth.CICode}&strProdId=${CartVolume[i].MASK_PROD_ID}`;
            const netwt = await Axios.get(volwt);
            const netltr = await Axios.get(volltr);
            sumwt =
                sumwt + parseFloat(netwt.data) * parseFloat(CartVolume[i].TOT_ORD_QTY);
            sumlt =
                sumlt + parseFloat(netltr.data) * parseFloat(CartVolume[i].TOT_ORD_QTY);
        }
        setWeight(sumwt.toFixed(2));
        setVolume(sumlt.toFixed(2));
    };


    return (
        <ReallocateContext.Provider
            value={{
                GetAllSalesOrderReallocate,
                filteredReallocate,
                reallocateDetails,
                setReallocateDetails,
                handleReallocateReleaseQty,
                modalShow,
                handleEdit,
                setModalShow,
                releaseQtyAdd,
                setReleaseQtyAdd,
                selectRow,
                ReallocateLoader,
                setReallocateLoader,
                addToCartLoader,
                promoCode,
                setPromoCode,
                orderQty,
                setOrderQty,
                releaseQty,
                setReleaseQty,
                setSoDetailsTable,
                defVal,
                setDefVal,
                setManualReallocateBtn,
                prodRow,
                setProdRow,
                suggestedProductList,
                setSuggestedProductList,
                setSelectedSalesDetails,
                setReallocateToggel,
                setSelectRow,
                netvol
            }}
        >
            {children}
        </ReallocateContext.Provider>
    );
};

export const useReallocateContext = () => {
    return useContext(ReallocateContext);
};

export { ReallocateProvider, ReallocateContext };
