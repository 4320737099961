import Axios from "../../api/Axios";
const BASE_URL = "Invoice/";
const SaleOrder_BASE_URL = "SalesOrder/";

export const EndpointInvoice = {
    ConfirmCancelInvoice: "ConfirmCancelInvoice",
    CancelInvoiceCheck: "CancelInvoiceCheck",
    GetBranchByWH:"GetBranchByWH",
    GetGetNonInvProductCount : "GetGetNonInvProductCount"
};

export const createAPIEndpointInvoice = () => {
    return { 
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        getBranch: (Endpoint) => Axios.get(SaleOrder_BASE_URL + Endpoint),
        post: (Endpoint,data)=> Axios.post(BASE_URL+Endpoint,data)
    };
};