import React, { useState } from "react";
import { useFormik } from "formik";
import Accordion from "react-bootstrap/Accordion";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { InputSwitch } from "primereact/inputswitch";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "primereact/utils";
import { initialValues } from "../../Validations/NewEmployeeValidation";
import { useEmployeeMasterContext } from "../../context/EmployeeMasterContext";
import { useEffect } from "react";
import * as Fa from "react-icons/fa";
import storage from "../../Services/Storage";
import {
    Endpoint,
    createAPIEndpoint,
} from "../../Services/EmployeeMaster/EmployeeMasterService";
import InformationModal from "./InformationModal";
import SearchGeographyModal from "./SearchGeographyModal";
import * as Fi from "react-icons/fi";
import Loader from "../../Components/Loader";
import { toast } from "react-toastify";

const NewEmployee = () => {
    const auth = storage.getLocal("user");
    const [designation, setDesignation] = useState();
    const [designationSuggestion, setDesignationSuggestion] = useState([]);
    // const [ledger, setLedger] = useState();
    // const [ledgerSuggestion, setLedgerSuggestion] = useState([]);
    const [designationStatus, setDesignationStatus] = useState(false);
    const [first_nameStatus, setFirst_nameStatus] = useState(false);
    const [last_nameStatus, setLast_nameStatus] = useState(false);
    const [codeStatus, setCodeStatus] = useState(false);
    // const [ledgerStatus, setLedgerStatus] = useState(false);
    const [addr_1Status, setAddr_1Status] = useState(false);
    const [townStatus, setTownStatus] = useState(false);
    const [emailStatus, setEmailStatus] = useState(false);
    const [mobileStatus, setMobileStatus] = useState(false);
    const [shortNameStatus, setShortNameStatus] = useState(false);
    const [middleNameStatus, setMiddleNameStatus] = useState(false);
    const [addr_2Status, setAddr_2Status] = useState(false);
    const [phnoStatus, setPhnoStatus] = useState(false);
    const [otherNumStatus, setOtherNumStatus] = useState(false);
    const [remarksStatus, setRemarksStatus] = useState(false);
    const [prefixStatus, setPrefixStatus] = useState(false);

    const nav = useNavigate();

    const {
        setPopup,
        setTownPopup,
        townValue,
        stateValue,
        districtValue,
        confirmTown,
        isEdit,
        selectedEmployeeData,
        parentCodeFlag,
        DFMR,
        setNewSaveData,
        deleteEmployee,
        zipCode,
        handleState,
        setEmployeeList,
        setEmployeeListDFMR,
        deleteLoader,
        newEmployeeSave,
        setDFMRFlag,
        setstateValue,
        setTownValue,
        setDistrictValue,
        setSEChange,
        setConfirmTown,
    } = useEmployeeMasterContext();

    const cancelNewEmployee = () => {
        nav("/masters/employeemaster");
    };

    const NewemployeeApi = async () => {
        createAPIEndpoint()
            .get(`${Endpoint.GetDesignation}?CICode=${auth.CICode}`)
            .then((res) => {
                setDesignationSuggestion(res.data.Data);

                if (parentCodeFlag || formik.initialValues.designation !== "") {
                    let desigFilter = res.data.Data.filter(
                        (val) => val.DESIG_NAME != "DFMR"
                    );
                    setDesignationSuggestion(desigFilter);
                }
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                console.log(error);
            });

        // createAPIEndpoint()
        //     .get(`${Endpoint.GetLedger}?CICode=${auth.CICode}`)
        //     .then((res) => {
        //         setLedgerSuggestion(res.data.Data);
        //
        //     })
        //     .catch((err) => {
        //
        //     });

        createAPIEndpoint()
            .get(`${Endpoint.GetEmployeeListDFRM}?CICode=${auth.CICode}`)
            .then((res) => {
                setEmployeeListDFMR(res.data.Data);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                console.log(error);
            });

        createAPIEndpoint()
            .get(`${Endpoint.GetEmployeeList}?CompId=${auth.CompId}`)
            .then((res) => {
                setEmployeeList(res.data.Data);
            })
            .catch((error) => {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                console.log(error);
            });
    };

    // const ledgerTemplate = (stateFilter) => {
    //     return (

    //         <>
    //             {stateFilter.LEDGER_NAME}
    //         </>
    //     );
    // };
    const designationTemplate = (stateFilter) => {
        return (
            <>
                {stateFilter.DESIG_CODE}-{stateFilter.DESIG_NAME}
            </>
        );
    };
    const searchDesignation = (e) => {
        setTimeout(() => {
            let _filteredDesignation;
            if (!e.query.trim().length) {
                _filteredDesignation = [...designationSuggestion];
            } else {
                _filteredDesignation = designationSuggestion.filter((designation) => {
                    return (
                        designation.DESIG_NAME.toLowerCase().includes(
                            e.query.toLowerCase()
                        ) ||
                        designation.DESIG_CODE.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }

            setDesignation(_filteredDesignation);
        }, 250);
    };
    // const searchLedger = (e) => {
    //     setTimeout(() => {
    //         let _filteredLedger;
    //         if (!e.query.trim().length) {
    //             _filteredLedger = [...ledgerSuggestion];
    //         } else {
    //             _filteredLedger = ledgerSuggestion.filter((ledger) => {
    //                 return ledger.LEDGER_NAME.toLowerCase().startsWith(e.query.toLowerCase());
    //             });
    //         }
    //         setLedger(_filteredLedger);
    //     }, 250);
    // };

    function handleSubmit(data) {
        // debugger;
        if (!isEdit) {
            setPopup(true);
            setNewSaveData(data);
        } else {
            newEmployeeSave(data);
        }
    }

    const formik = useFormik({
        initialValues: initialValues,

        validate: (data) => {
            // debugger
            const desigToCheck =
                typeof data.designation === "object"
                    ? data.designation.DESIG_NAME
                    : data.designation.trim();
            const emailRegex = new RegExp(
                /^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
                "gm"
            );
            const nameRegex = new RegExp(/^[A-Za-z\s]+$/);
            //allow only Spec char allowed [.],[,],[/],[-]
            const Addressrgx = /^[A-Za-z0-9\s.,,/\\-]*$/;
            // eslint-disable-next-line
            const codeRegex = /^[^0][\/\-a-zA-Z0-9\/-]*$/;
            let phnnorgx = /^(\d{3}-?)?\d{4}-?\d{4}$/;
            const mobilenoRegex = new RegExp(/^[6-9]\d{9}$/);

            let errors = {};

            if (!desigToCheck) {
                errors.designation = "Designation is required.";
                setDesignationStatus(true);
                // setErrorLable(true);
            } else {
                setDesignationStatus(false);
            }

            if (!data.first_name) {
                errors.first_name = "First name is required.";
                setFirst_nameStatus(true);
            } else if (data.first_name) {
                if (data.first_name.trim().length > 30) {
                    errors.first_name = "Name length should be under 30 limitation.";
                    setFirst_nameStatus(true);
                } else {
                    if (!nameRegex.test(data.first_name)) {
                        errors.first_name =
                            "Invalid name. Numbers and special characters are not allowed.";
                        setFirst_nameStatus(true);
                    } else {
                        setFirst_nameStatus(false);
                    }
                }
            }

            if (data.middle_name) {
                if (data.middle_name.trim().length > 30) {
                    errors.middle_name = "Name length should be under 30 limitation.";
                    setMiddleNameStatus(true);
                } else {
                    if (!nameRegex.test(data.middle_name)) {
                        errors.middle_name =
                            "Invalid name. Numbers and special characters are not allowed.";
                        setMiddleNameStatus(true);
                    } else {
                        setMiddleNameStatus(false);
                    }
                }
            }
            if (data.short_name) {
                if (data.short_name.trim().length > 15) {
                    errors.short_name = "Name length should be under 15 limitation.";
                    setShortNameStatus(true);
                } else {
                    setShortNameStatus(false);
                }
            }

            if (data.title) {
                if (data.title.trim().length > 10) {
                    errors.title = "Name length should be under 10 limitation.";
                    setPrefixStatus(true);
                } else {
                    setPrefixStatus(false);
                }
            }

            if (!data.last_name) {
                errors.last_name = "Last name is required.";
                setLast_nameStatus(true);
            } else if (data.last_name) {
                if (data.last_name.trim().length > 30) {
                    errors.last_name = "Name length should be under 30 limitation.";
                    setLast_nameStatus(true);
                } else {
                    if (!nameRegex.test(data.last_name)) {
                        errors.last_name =
                            "Invalid name. Numbers and special characters are not allowed.";
                        setLast_nameStatus(true);
                    } else {
                        setLast_nameStatus(false);
                    }
                }
            }
            if (!DFMR && formik.values.designation.DESIG_NAME !== "DFMR") {
                if (!data.code.trim()) {
                    errors.code = "Code is required.";
                    setCodeStatus(true);
                    // setErrorLable(true);
                } else if (data.code.trim()) {
                    if (data.code.trim().length > 15) {
                        errors.code = "Code limit reached.";
                    } else if (!codeRegex.test(data.code)) {
                        errors.code = "Invalid format.";
                        setCodeStatus(true);
                    } else {
                        setCodeStatus(false);
                    }
                } else {
                    setCodeStatus(false);
                }
            } else {
                setCodeStatus(false);
            }

            // if (!data.ledger) {
            //     errors.ledger = "Ledger is required.";
            //     setLedgerStatus(true);

            // }
            // else {
            //     setLedgerStatus(false);

            // }
            if (!data.addr_1.trim()) {
                errors.addr_1 = "Address line 1 is required.";
                setAddr_1Status(true);
                // setErrorLable(true);
            } else if (data.addr_1.trim().length > 70) {
                errors.addr_1 = "Address line 1 limit (70 letters) reached.";
                setAddr_1Status(true);
                // setErrorLable(true);
            } else if (!Addressrgx.test(data.addr_1)) {
                errors.addr_1 = "No special character is allowed";
                // setErrorLable(true);
                setAddr_1Status(true);
            } else {
                setAddr_1Status(false);
            }

            if (data.addr_2.trim().length > 70) {
                errors.addr_2 = "Address line 2 limit (70 letters) reached.";
                setAddr_2Status(true);
                // setErrorLable(true);
            } else if (!Addressrgx.test(data.addr_2)) {
                errors.addr_2 = "No special character is allowed";
                // setErrorLable(true);
                setAddr_2Status(true);
            } else {
                setAddr_2Status(false);
            }
            if (!data.town) {
                errors.town = "Town is required.";
                setTownStatus(true);
                // setErrorLable(true);
            } else {
                setTownStatus(false);
            }
            if (data.phone_num) {
                if (!phnnorgx.test(data.phone_num)) {
                    errors.phone_num = "Invalid Phone Number";
                    setPhnoStatus(true);
                } else {
                    setPhnoStatus(false);
                }
            } else {
                setPhnoStatus(false);
            }

            if (!DFMR && formik.values.designation.DESIG_NAME !== "DFMR") {
                // debugger;
                if (!data.email.trim()) {
                    errors.email = "Email is required.";
                    setEmailStatus(true);
                    // setErrorLable(true);
                } else if (
                    !emailRegex.test(data.email) ||
                    data.email.trim().length > 60
                ) {
                    errors.email = "invalid email";
                    setEmailStatus(true);
                    // setErrorLable(true);
                } else {
                    setEmailStatus(false);
                }
            } else {
                setEmailStatus(false);
            }

            if (!data.mob_num.trim()) {
                errors.mob_num = "Mobile number is required.";
                setMobileStatus(true);
                // setErrorLable(true);
            } else if (!mobilenoRegex.test(data.mob_num)) {
                errors.mob_num = "invalid mobile number";
                setMobileStatus(true);
                // setErrorLable(true);
            } else {
                setMobileStatus(false);
            }
            if (data.other_phone_num) {
                if (!mobilenoRegex.test(data.other_phone_num.trim())) {
                    errors.other_phone_num = "invalid mobile number";
                    setOtherNumStatus(true);
                    // setErrorLable(true);
                } else {
                    setOtherNumStatus(false);
                }
            }
            if(data.remarks !== null){
                if (data.remarks.trim().length > 100) {
                    errors.remarks = "Limit reached.";
                    setRemarksStatus(true);
                // setErrorLable(true);
                } else {
                    setRemarksStatus(false);
                }
            }
            //
            return errors;
        },
        onSubmit: (data) => {
            //
            handleSubmit(data);
        },
    });
    const isFormFieldInvalid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? (
            <small className="p-error">{formik.errors[name]}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };

    if (isEdit) {
        formik.initialValues.parent_code = selectedEmployeeData.PARENT_CODE;
        formik.initialValues.code = selectedEmployeeData.EMP_CODE;
        formik.initialValues.designation = selectedEmployeeData.DESIG_NAME;
        formik.initialValues.first_name = selectedEmployeeData.EMP_FNAME;
        formik.initialValues.middle_name = selectedEmployeeData.EMP_MNAME;
        formik.initialValues.last_name = selectedEmployeeData.EMP_LNAME;
        formik.initialValues.title = selectedEmployeeData.EMP_TITLE;
        formik.initialValues.short_name = selectedEmployeeData.EMP_SHORT_NAME;

        // formik.initialValues.ledger=selectedEmployeeData.LEDGER_ID;
        formik.initialValues.addr_1 = selectedEmployeeData.EMP_ADD1;
        formik.initialValues.addr_2 = selectedEmployeeData.EMP_ADD2;
        formik.initialValues.town = selectedEmployeeData.TOWN;
        formik.initialValues.state = selectedEmployeeData.STATE;
        formik.initialValues.district = selectedEmployeeData.DISTRICT;
        formik.initialValues.zip_code = selectedEmployeeData.EMP_PIN_CODE;
        formik.initialValues.std_code = selectedEmployeeData.PHONE_AREA_CODE;
        formik.initialValues.email = selectedEmployeeData.EMP_EMAILID;
        formik.initialValues.mob_num = selectedEmployeeData.MOBILE_ID;
        formik.initialValues.phone_num = selectedEmployeeData.EMP_PHONE_NUM;
        formik.initialValues.other_phone_num =
            selectedEmployeeData.EMP_OTH_COMM_NUM;
        formik.initialValues.remarks = selectedEmployeeData.EMP_REMARKS;
        formik.initialValues.permission_tag =
            selectedEmployeeData.APP_PERMISSION === "Y" ? true : false;
        formik.initialValues.status =
            selectedEmployeeData.STATUS === "Y" ? true : false;
    } else {
        formik.initialValues.parent_code = "";
        formik.initialValues.code = "";
        formik.initialValues.designation = "";
        formik.initialValues.first_name = "";
        formik.initialValues.middle_name = "";
        formik.initialValues.last_name = "";
        formik.initialValues.title = "";
        formik.initialValues.short_name = "";
        // formik.initialValues.ledger="";
        formik.initialValues.addr_1 = "";
        formik.initialValues.addr_2 = "";
        formik.initialValues.town = "";
        formik.initialValues.state = "";
        formik.initialValues.district = "";
        formik.initialValues.zip_code = "";
        formik.initialValues.std_code = "";
        formik.initialValues.email = "";
        formik.initialValues.mob_num = "";
        formik.initialValues.phone_num = "";
        formik.initialValues.other_phone_num = "";
        formik.initialValues.remarks = "";
        formik.initialValues.permission_tag = true;
        formik.initialValues.status = true;
    }

    if (formik.values.designation.DESIG_NAME === "DFMR") {
        setDFMRFlag(true);
    } else {
        setDFMRFlag(false);
    }
    useEffect(() => {
        setSEChange(false);
        NewemployeeApi();
        handleState();
        setConfirmTown(false);
        setstateValue("");
        setDistrictValue("");
        setTownValue("");
    }, []);

    // useEffect(()=>{

    //     if(formik.submitCount==1 && errorLable){
    //         toast.error(
    //             "Please fill all the mandatory field marked as (*)",
    //             {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 pauseOnFocusLoss: false,
    //                 closeButton: false,
    //                 autoClose: 2000,
    //             }

    //         );
    //     }
    //     else if(formik.submitCount>0 && errorLable && Object.keys(formik.errors).length!==0){

    //         toast.error(
    //             "Please fill all the mandatory field marked as (*)",
    //             {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 pauseOnFocusLoss: false,
    //                 closeButton: false,
    //                 autoClose: 2000,
    //             }

    //         );
    //     }
    // },[formik.submitCount || errorLable]);

    useEffect(() => {
        if (Object.keys(formik.errors).length !== 0) {
            toast.error(
                "Please fill all the mandatory field marked as (*) with valid Data",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                }
            );
        }
    }, [formik.submitCount]);

    if (deleteLoader) {
        return <Loader />;
    } else {
        return (
            <main>
                <div className="col-12 pt-3 mb-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/masters/employeemaster"> Employee Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Details
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/masters/employeemaster">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <form onSubmit={formik.handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (first_nameStatus ||
                                                last_nameStatus ||
                                                designationStatus ||
                                                codeStatus ||
                                                shortNameStatus ||
                                                middleNameStatus ||
                                                prefixStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Name <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Name</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Parent Code <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="parent_code"
                                                    name="parent_code"
                                                    value={
                                                        parentCodeFlag
                                                            ? (formik.values.parent_code =
                                                                selectedEmployeeData.EMP_CODE)
                                                            : formik.values.parent_code
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("parent_code", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("parent_code"),
                                                    })}
                                                    disabled
                                                />
                                                {getFormErrorMessage("parent_code")}
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Code <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="code"
                                                    name="code"
                                                    value={
                                                        formik.values.designation.DESIG_NAME == "DFMR"
                                                            ? (formik.values.code = "")
                                                            : formik.values.code
                                                    }
                                                    disabled={
                                                        formik.values.designation.DESIG_NAME !== "DFMR" &&
                                                            !isEdit
                                                            ? false
                                                            : true
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("code", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("code"),
                                                    })}
                                                />
                                                {getFormErrorMessage("code")}
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Designation <span className="mandatory">*</span>
                                                </label>
                                                <div className="d-block">
                                                    <AutoComplete
                                                        field="DESIG_NAME"
                                                        name="designation"
                                                        // inputId="designation"
                                                        value={formik.values.designation}
                                                        suggestions={designation}
                                                        completeMethod={searchDesignation}
                                                        onChange={(e) => {
                                                            setSEChange(true);
                                                            formik.setFieldValue("designation", e.value);
                                                        }}
                                                        dropdown={true}
                                                        itemTemplate={designationTemplate}
                                                        emptyMessage="No results found."
                                                        showEmptyMessage
                                                        // forceSelection
                                                        disabled={
                                                            formik.initialValues.designation == "DFMR" &&
                                                                isEdit
                                                                ? true
                                                                : false
                                                        }
                                                        className={classNames({
                                                            "p-invalid": isFormFieldInvalid("designation"),
                                                        })}
                                                    />
                                                    {getFormErrorMessage("designation")}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Prefix</label>
                                                <InputText
                                                    class="form-control"
                                                    id="title"
                                                    name="title"
                                                    value={formik.values.title}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("title", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("title"),
                                                    })}
                                                />
                                                {getFormErrorMessage("title")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    First Name <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="first_name"
                                                    name="first_name"
                                                    value={formik.values.first_name}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("first_name", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("first_name"),
                                                    })}
                                                />
                                                {getFormErrorMessage("first_name")}
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Middle Name </label>
                                                <InputText
                                                    class="form-control"
                                                    id="middle_name"
                                                    name="middle_name"
                                                    value={formik.values.middle_name}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("middle_name", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("middle_name"),
                                                    })}
                                                />
                                                {getFormErrorMessage("middle_name")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Last Name <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="last_name"
                                                    name="last_name"
                                                    value={formik.values.last_name}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("last_name", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("last_name"),
                                                    })}
                                                />
                                                {getFormErrorMessage("last_name")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Short Name</label>
                                                <InputText
                                                    class="form-control"
                                                    id="short_name"
                                                    name="short_name"
                                                    value={formik.values.short_name}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("short_name", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("short_name"),
                                                    })}
                                                />
                                                {getFormErrorMessage("short_name")}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="1">
                                    <Accordion.Header>{formik.submitCount > 0 && (ledgerStatus) ? (
                                        <div style={{ color: "red" }}>
                                            Financial Information <Fi.FiAlertTriangle />
                                        </div>
                                    ) : (
                                        <>Financial Information</>
                                    )}</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12 mb-2">
                                                <label className="form-label">Ledger <span className="mandatory">*</span></label>
                                                <div className="d-block">
                                                    <AutoComplete 
                                                        field='LEDGER_NAME'
                                                        name="ledger"
                                                        value={formik.values.ledger}
                                                        suggestions={ledger}
                                                        completeMethod={searchLedger}
                                                        onChange={(e) => {
    
                                                            formik.setFieldValue(
                                                                "ledger",
                                                                e.value
                                                            );
                                                        }}
                                                        dropdown={true}
                                                        itemTemplate={ledgerTemplate}
                                                        // forceSelection
                                                        className={classNames({ "p-invalid": isFormFieldInvalid("ledger") })}
                                                    />
                                                    {getFormErrorMessage("ledger")}
                                                </div>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (addr_1Status || addr_2Status || townStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Address <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Address</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Address Line 1 <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="addr_1"
                                                    name="addr_1"
                                                    value={formik.values.addr_1}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("addr_1", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("addr_1"),
                                                    })}
                                                />
                                                {getFormErrorMessage("addr_1")}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">Address Line 2</label>
                                                <InputText
                                                    class="form-control"
                                                    id="addr_2"
                                                    name="addr_2"
                                                    value={formik.values.addr_2}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("addr_2", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("addr_2"),
                                                    })}
                                                />
                                                {getFormErrorMessage("addr_2")}
                                            </div>
                                            {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                                                <label className="form-label">Town <span className="mandatory">*</span></label>
                                                <div className="input-group mb-3">
                                                    <InputText class="form-control" type="text"
                                                        value={(!confirmTown ?
                                                            formik.values.town :
                                                            formik.values.town = townValue)}
                                                        disabled
                                                        className={classNames({ "p-invalid": isFormFieldInvalid("town") })} />
                                                        {getFormErrorMessage("town")}
                                                         
                                                    <div className="input-group-prepend">
                                                        <span onClick={() => { setTownPopup(true); }} className="input-group-text ms-2">
                                                            <Fa.FaSearch />
                                                            
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                                
                                            </div> */}
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Town <span className="mandatory">*</span>
                                                </label>
                                                <div
                                                    className="input-group"
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    <InputText
                                                        class="form-control"
                                                        type="text"
                                                        placeholder="Select"
                                                        value={
                                                            !confirmTown
                                                                ? formik.values.town
                                                                : (formik.values.town = townValue)
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue("town", e.target.value);
                                                        }}
                                                        disabled
                                                        style={{ flex: "1" }}
                                                        className={classNames({
                                                            "p-invalid": isFormFieldInvalid("town"),
                                                        })}
                                                    />

                                                    <div className="input-group-prepend">
                                                        <span
                                                            onClick={() => {
                                                                setTownPopup(true);
                                                            }}
                                                            className="input-group-text ms-2"
                                                        >
                                                            <Fa.FaSearch />
                                                        </span>
                                                    </div>
                                                </div>
                                                {getFormErrorMessage("town")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">State</label>
                                                <InputText
                                                    class="form-control"
                                                    id="state"
                                                    name="state"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.state
                                                            : (formik.values.state = stateValue)
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("state", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">District</label>
                                                <InputText
                                                    class="form-control"
                                                    id="district"
                                                    name="district"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.district
                                                            : (formik.values.district = districtValue)
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("district", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Pin Code <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="zip_code"
                                                    name="zip_code"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.zip_code
                                                            : (formik.values.zip_code = zipCode.slice(0, 6))
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("zip_code", e.target.value);
                                                    }}
                                                    disabled
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("zip_code"),
                                                    })}
                                                />
                                                {getFormErrorMessage("zip_code")}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (emailStatus ||
                                                mobileStatus ||
                                                otherNumStatus ||
                                                phnoStatus ||
                                                remarksStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Contact Information <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Contact Information</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">STD Code</label>
                                                <InputText
                                                    class="form-control"
                                                    id="std_code"
                                                    name="std_code"
                                                    value={formik.values.std_code}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("std_code", e.target.value);
                                                    }}
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Phone Number</label>
                                                <InputText
                                                    class="form-control"
                                                    id="phone_num"
                                                    name="phone_num"
                                                    value={formik.values.phone_num}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("phone_num", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("phone_num"),
                                                    })}
                                                />
                                                {getFormErrorMessage("phone_num")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Email{" "}
                                                    {!DFMR &&
                                                        formik.values.designation.DESIG_NAME !== "DFMR" ? (
                                                            <span className="mandatory">*</span>
                                                        ) : null}
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="email"
                                                    name="email"
                                                    value={formik.values.email}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("email", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("addr_1"),
                                                    })}
                                                />
                                                {getFormErrorMessage("email")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Mobile Number <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="mob_num"
                                                    name="mob_num"
                                                    value={formik.values.mob_num}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("mob_num", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("mob_num"),
                                                    })}
                                                />
                                                {getFormErrorMessage("mob_num")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Alternate Mobile Number
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="other_phone_num"
                                                    name="other_phone_num"
                                                    value={formik.values.other_phone_num}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "other_phone_num",
                                                            e.target.value
                                                        );
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("other_phone_num"),
                                                    })}
                                                />
                                                {getFormErrorMessage("other_phone_num")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Remarks</label>
                                                <InputText
                                                    class="form-control"
                                                    id="other_phone_num"
                                                    name="remarks"
                                                    value={formik.values.remarks}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("remarks", e.target.value);
                                                    }}
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("remarks"),
                                                    })}
                                                />
                                                {getFormErrorMessage("remarks")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mt-4">
                                                <label className="form-label">Active</label>
                                                <label className="d-inline ms-2">
                                                    <InputSwitch
                                                        className="switch-pos"
                                                        id="status"
                                                        name="status"
                                                        checked={formik.values.status}
                                                        onChange={(e) => {
                                                            formik.setFieldValue("status", e.value);
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                            {/* {!DFMR && formik.values.designation.DESIG_NAME!=="DFMR"? 
                                                <div className="col-lg-3 col-md-6 col-sm-12 mt-4">
                                                    <label className="form-label">App Persmission</label>
                                                    <label className="d-inline ms-2">
                                                        <InputSwitch className="switch-pos" 
                                                            id='permission_tag'
                                                            name='permission_tag'
                                                            checked ={formik.values.permission_tag}
                                                            onChange={(e)=>{formik.setFieldValue("permission_tag",e.value);}} />
                                                    </label>
                                                </div>:null } */}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div>
                                <div className="float-end my-4">
                                    <button
                                        type="submit"
                                        className="btn btn-primary me-3"
                                    >
                                        Save
                                    </button>
                                    {isEdit && (
                                        <button
                                            type="button"
                                            className="btn btn-cancel-red me-3"
                                            onClick={() => {
                                                deleteEmployee(selectedEmployeeData);
                                            }}
                                        >
                                            Delete
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-cancel"
                                        onClick={cancelNewEmployee}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </form>
                        <InformationModal />
                        <SearchGeographyModal />
                    </div>
                </div>
            </main>
        );
    }
};

export default NewEmployee;
