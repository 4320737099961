import Axios from "../../api/Axios";
const BASE_URL = "SalesReport/";


export const EndpointSalesReports = {
    DeliveryChallanExcel : "DeliveryChallanExcel",
    OpenSalesOrder : "OpenSalesOrder"
};

export const createAPIEndpointSalesReports= () => {
    return {get:(Endpoint)=>Axios.get(BASE_URL+Endpoint)};
};