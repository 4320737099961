import React from "react";
import * as Fa from "react-icons/fa";
// import { useNavigate } from "react-router-dom";

const Maintenance = () => {
    // const navlink = useNavigate();

    // const backToHome = ()=>{
    //     navlink("/")
    // }
    return (
    //     <div className="col-md-12 d-flex flex-column min-vh-100 justify-content-center align-items-center" >
    //     <div className="error-template text-center">
    //        
    //         <h2>InnoRise is under maintenance now.</h2>
    //         <p className="error-details">Operational hours : 8:30 AM to 11:30 PM</p>
    //         <div className="error-actions">
    //             <a>
    //                 <button className="btn btn-primary" type="button"><i className="fa fa-home" aria-hidden="true"></i> Take Me Home</button>
    //             </a>
    //         </div>
    //     </div>
    // </div>

        <div className="container">
            <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-6 col-md-6 col-sm-12 text-left">
                    <div className="error-template">
                        <h1 className="pb-4">Site is <img src={require("../../Assets/Images/construction.png")} style={{ width: "40px" }} /><br></br><span className="text-danger">Under Maintenance</span></h1>
                        <h6><b>Operational hours : </b></h6>
                        <p className="mb-0"><Fa.FaClock className="Ico"/>8:30 AM to 11:30 PM (Monday to Saturday)</p>
                        <p><Fa.FaClock className="Ico"/>8:30 AM to 4:30 PM (Sunday)</p>
                        <div className="error-actions py-2">         
                            <a>
                                <button className="btn btn-primary" type="button"><i className="fa fa-home" aria-hidden="true"></i> Take Me Home</button>
                            </a>   
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 text-end">
                    <img src={require("../../Assets/Images/maintaince.png")} style={{ width: "90%" }} />
                </div>
            </div>
        </div>
    );
};

export default Maintenance;