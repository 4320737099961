import { format, subDays } from "date-fns";
import { Dropdown } from "primereact/dropdown";
import React, { useState } from "react";
import * as Fa from "react-icons/fa";
import storage from "../../../Services/Storage";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import { EndpointOtherReports, createAPIEndpointOtherReports } from "../../../Services/ReportsAll/OthersReportService";

const NonInventory = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const [loadingExcel,setLoadingExcel] = useState(false);
    const options = ["Inward Report","Outward Report","Reconciliation Report"];
    const [reportType,setReportType] = useState("Inward Report");

    const auth = storage.getLocal("user");

    const handleDownload = () =>{
        setLoadingExcel(true);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        let type="";
        let uniqueNo = "NonInventoryReportExcel";
        if(reportType=="Inward Report"){
            type="IN";
        }
        else if(reportType=="Outward Report"){
            type="OUT";
        }
        else{
            type="REC";
        }
        createAPIEndpointOtherReports()
            .get(`${EndpointOtherReports.GetNonInventoryReport}?strFromDateString=${fromDateRange}&strToDateString=${toDateRange}&RptType=${type}&CompId=${auth.CompId}`)
            .then((val)=>{
                if(val.data.length>0){
                    var wb = XLSX.utils.book_new();
                    var ws = XLSX.utils.json_to_sheet(val.data);
                    XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                    XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                }
                else{
                    toast.warning(
                        "No Data Found between these dates",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setLoadingExcel(false);
            })
            .catch((error) => {
                setLoadingExcel(false);
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,    
                });

                console.log(error);
            });
    };

    return (
        <div className="col-12 pt-3">
            <h3 className="py-2">Non Inventory Report</h3>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">From Date</label>
                            <input type="date" className="form-control"
                                defaultValue={sevenDays}
                                max={toDate}
                                min="2010-01-01"
                                name="date-field"
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">To Date</label>
                            <input type="date" className="form-control"
                                defaultValue={currentDate}
                                max={currentDate}
                                min={fromDate}
                                name="date-field"
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">Report Type</label>
                            <Dropdown 
                                value={reportType}
                                onChange={(e)=>{setReportType(e.target.value);}}
                                options={options}
                            />
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <div className="float-end mt-4">
                                <button type="button" className="btn btn-primary me-3" onClick={()=>handleDownload()}>
                                    {loadingExcel ? "Loading..." : "Download Excel"}{" "}
                                    {loadingExcel ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <Fa.FaDownload className="ms-2" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default NonInventory;