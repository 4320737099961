import React, { useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useSalesReturnContext } from "../../context/SalesReturnContext";
import { MdCurrencyRupee } from "react-icons/md";
import { useGlobalContext } from "../../context/SalesOrderContext";
import PrintInvoicePopUp from "../../Components/PrintInvoicePopUp";

const SalesReturnPrint = () => {
    const { printInvoicePopUp,
        printInvData,
        dtProductList,
        setPrintInvoicePopUp } = useSalesReturnContext();
    const pdfExportComponent = useRef(null);
    const {orientation} = useGlobalContext();

    const printRef = useRef();
    function getUniqueNo() {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let dateunique = date.valueOf();
        let uniqueNo = `SalesReturnInvoice-${day}-${month}-${year}-${dateunique}`;
        return uniqueNo;
    }
    if(orientation!=="portrait-primary"){
        return (
            <Modal className="print-popup" show={printInvoicePopUp} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Body>
                    <div id="printablediv">
                        <PDFExport fileName={getUniqueNo()} paperSize="A4" forcePageBreak=".page-break" ref={pdfExportComponent}>
                            <div className="py-5 mt-4">
                                <div>
                                    <div className="">
                                        <p className="text-center" style={{ fontSize: "10px" }}><b>Credit Note</b></p>
                                    </div>
                                    <div className="parent  parent-invoice-terms" style={{ height: "1.5cm", marginLeft: "0.2rem" }}>
                                        <div className="childOne  invoice-terms">
                                            <p>
                                                <span>Credit Note No.</span> : {printInvData.clsLabelInvPrint?.INV_NO1}
                                            </p>
                                            <p>
                                                <span>Credit Note Date</span> : {printInvData.clsLabelInvPrint?.INV_DATE1}
                                            </p>
                                            <p>
                                                <span>Invoice No.</span> : {printInvData.clsLabelInvPrint?.PO_NO}
                                            </p>
                                            <p>
                                                <span>Invoice Date</span> : {printInvData.clsLabelInvPrint?.PO_DATE}
                                            </p>
                                            <p>
                                                <span>Mode of Payment</span> : {printInvData.clsLabelInvPrint?.MOP}
                                            </p>
                                            <p>
                                                <span>Payment Terms</span> : 14 Days Credit
                                            </p>
                                            <p>
                                                <span>Distributor GSTN</span> : {printInvData.clsLabelInvPrint?.DistGST1}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="parent parent-invoice-table-address" style={{ height: "1.2cm" }}>
                                        <table className="child invoice-table-address">
                                            <tr className="table-addresses">
                                                <th>Customer Address</th>
                                                <th>Delivery Address</th>
                                            </tr>
                                            <tr className="temp">
                                                <td width="200px">
                                                    <span>{printInvData.clsLabelInvPrint?.BillAdd1}</span>
                                                    <span>{printInvData.clsLabelInvPrint?.BillAdd2}</span>
                                                    <span>{printInvData.clsLabelInvPrint?.BillTown}</span>
                                                    <span>{printInvData.clsLabelInvPrint?.BillState}</span>
                                                </td>
                                                <td width="200px">
                                                    <span>{printInvData.clsLabelInvPrint?.ShipAdd1}</span>
                                                    <span>{printInvData.clsLabelInvPrint?.ShipAdd2}</span>
                                                    <span>{[printInvData.clsLabelInvPrint?.ShipAdd3]}</span>
                                                    <span>{printInvData.clsLabelInvPrint?.ShipAdd4}{" "}{printInvData.clsLabelInvPrint?.ShipPin}</span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>

                                    <div className="parent parent-invoice-table">
                                        <table className="invoice-table-details">
                                            <thead>
                                                <tr className="table-row-border">
                                                    <th width="20px">
                                                    Sr<br></br> No
                                                    </th>
                                                    <th width="100px">
                                                    Product Pack Description
                                                    </th>
                                                    <th className="RightAlignTD">HSN</th>
                                                    <th className="RightAlignTD">
                                                    Rate Per <br></br>Pack
                                                    </th>
                                                    <th className="RightAlignTD">MRP</th>
                                                    <th className="RightAlignTD">
                                                    No. of <br></br>Packs
                                                    </th>
                                                    <th className="RightAlignTD">
                                                    Quantity<br></br>(EA/Kg/Ltrs)
                                                    </th>
                                                    <th className="RightAlignTD">
                                                    Scheme<br></br>Disc/Rebate
                                                    </th>
                                                    <th className="RightAlignTD">
                                                    Coupon<br></br>Scheme Disc
                                                    </th>
                                                    <th className="RightAlignTD">
                                                    Taxable<br></br>Value
                                                    </th>
                                                    <th className="RightAlignTD">
                                                        {dtProductList[0]?.cgst_amt > 0 ? "CGST" : dtProductList[0]?.utgst_amt > 0 ? "UTGST" : dtProductList[0]?.igst_amt ? "IGST" : ""}<br></br>Rate%
                                                    </th>
                                                    <th className="RightAlignTD">
                                                    SGST Rs. <br></br>Rate%
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ borderBottom: "1px solid #fff" }}>
                                                <div className="MainValue">
                                                    {dtProductList?.map((val, index) => {
                                                        return (
                                                        //eslint-disable-next-block
                                                            <tr key={index}>
                                                                <td width="20px">{index + 1}</td>
                                                                <td width="100px">
                                                                    {val?.PRODUCT}
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                    {val?.hsn}
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                    {Number(val?.RATE).toFixed(2)}
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                    {Number(val?.MRP).toFixed(2)}
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                    {val?.PACK_QTY}
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                    {val?.TOT_QTY}
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                0.00
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                    {Number(val?.COUP_DISC).toFixed(2)}
                                                                </td>

                                                                <td className="RightAlignTD">
                                                                    {val?.AMOUNT.toFixed(2)}
                                                                </td>
                                                                <td className="RightAlignTD">

                                                                    {Number(val?.cgst_amt || val?.utgst_amt || val?.igst_amt).toFixed(2)}
                                                                    <br></br>
                                                                    {
                                                                    //eslint-disable-next-line
                                                                    Number(val?.cgst_value || val?.utgst_value || val?.igst_value).toFixed(2)
                                                                    }
                                                                </td>
                                                                <td className="RightAlignTD">
                                                                    {Number(val?.sgst_amt).toFixed(2)}
                                                                    <br></br>
                                                                    {Number(val?.sgst_value).toFixed(2)}
                                                                </td>
                                                            </tr>

                                                        );
                                                    })}
                                                </div>


                                                <div>
                                                    <>
                                                        <tr>
                                                            <td width="20px"></td>
                                                            <td width="100px"></td>
                                                            <td className="RightAlignTD"></td>
                                                            <td className="RightAlignTD"></td>
                                                            <td className="RightAlignTD"><b>Total</b></td>
                                                            <td className="RightAlignTD">{Number(printInvData.clsModelInvoicePrintDtl?.PACK_QTY).toFixed(0)}</td>
                                                            <td className="RightAlignTD">{Number(printInvData.clsModelInvoicePrintDtl?.SumQty).toFixed(0)}</td>
                                                            <td className="RightAlignTD">{Number(printInvData.clsModelInvoicePrintDtl?.SumSchmDisc).toFixed(2)}</td>
                                                            <td className="RightAlignTD">{Number(printInvData.clsModelInvoicePrintDtl?.SumCouponDisc).toFixed(2)}</td>
                                                            <td className="RightAlignTD">{Number(printInvData.clsModelInvoicePrintDtl?.SumInvAmt).toFixed(2)}</td>
                                                            <td className="RightAlignTD">{Number(printInvData.clsModelInvoicePrintDtl?.SumCGST).toFixed(2)}</td>
                                                            <td className="RightAlignTD">{Number(printInvData.clsModelInvoicePrintDtl?.SumSGST).toFixed(2)}</td>
                                                        </tr>
                                                    </>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div id="myFooter">
                                        <div className="row lastPart w-100">
                                            <div className="column1">
                                                <div className="rupeeBox">
                                                    <p className="signatureSp">
                                                    Receiver&#39;s Signature Date Stamp
                                                    </p>
                                                    <p>Scheme Benefit (inclusive of GST) = <MdCurrencyRupee className="ms-3"/></p>
                                                    <p>Amount Payable (in Words)</p>
                                                    <p><span>Rupee</span> : {printInvData.clsModelInvoicePrintDtl?.AmtInWord} </p>
                                                    <p className="boxxo">
                                                    Customer Service Toll Free No. :
                                                    1800222100/18002098100
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="column2">
                                                <div className="childOne invoice-terms-one">
                                                    <p>
                                                        <span>Gross Amount</span> : <span style={{ textAlign: "right" }}>{printInvData.clsModelInvoicePrintDtl?.GrossAmt}</span>
                                                    </p>
                                                    <p>
                                                        <span>Other Discount</span> : <span style={{ textAlign: "right" }}>{Number(printInvData.clsModelInvoicePrintDtl?.OthDisc).toFixed(2)}</span>
                                                    </p>
                                                    <p>
                                                        <span>Total Adjusted Amount</span> : <span style={{ textAlign: "right" }}>{Number(printInvData.clsModelInvoicePrintDtl?.AdjAmt).toFixed(2)}</span>
                                                    </p>
                                                    <p>
                                                        <span>GST</span> : <span style={{ textAlign: "right" }}>{Number(printInvData.clsModelInvoicePrintDtl?.TotalVat).toFixed(2)}</span>
                                                    </p>
                                                    <p>
                                                        <span>Grand Total</span> : <span style={{ textAlign: "right" }}>{printInvData.clsModelInvoicePrintDtl?.GrandTotal.toFixed(2)}</span>
                                                    </p>
                                                    <hr style={{ margin: "0rem" }}></hr>
                                                    {/* <p>
                                                    <span>Credit Adjustment</span> : <span style={{ textAlign: "right" }}>{Number(printInvData.clsModelInvoicePrintDtl?.TotCNadj).toFixed(2)}</span>
                                                </p> */}
                                                    <p>
                                                        <span>Amount Payable</span> : <span style={{ textAlign: "right" }}>{printInvData.clsModelInvoicePrintDtl?.TOTAL}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PDFExport>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-cancel-red me-3" onClick={() => { setPrintInvoicePopUp(false); }}>Close</button>
                    <button className="btn btn-primary"
                        ref={printRef}
                        onClick={() => {
                            if (pdfExportComponent.current) {
                                pdfExportComponent.current.save();
                            }
                        }}
                    >Download</button>
                </Modal.Footer>
            </Modal>
        );}else{
        return(<PrintInvoicePopUp show={printInvoicePopUp} hideShow = {setPrintInvoicePopUp} name = "Sales Return Print View"/>);
    }
};

export default SalesReturnPrint;