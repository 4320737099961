import Axios from "../../api/Axios";
const BASE_URL = "AdvanceAdjustment/";
export const Endpoint = {
    GetPartyName: "GetPartyName",
    GetPayableDocument: "GetPayableDocument",
    GetReceivableDocument: "GetReceivableDocument",
    GetAdvanceAdjustment: "GetAdvanceAdjustment",
    ViewAdvanceAdjustment: "ViewAdvanceAdjustment",
    SaveAdvanceAdjustment: "SaveAdvanceAdjustment",
    CancelAdvAdj: "CancelAdvAdj",

};

// /api/AdvanceAdjustment/GetPartyName
// /api/AdvanceAdjustment/GetPayableDocument
// /api/AdvanceAdjustment/GetReceivableDocument
// /api/AdvanceAdjustment/GetAdvanceAdjustment
// /api/AdvanceAdjustment/SaveAdvanceAdjustment
// /api/AdvanceAdjustment/ViewAdvanceAdjustment

export const createAPIAdvanceAdjEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};