import React, { useContext, useState } from "react";
import Axios from "../api/Axios";
import { toast } from "react-toastify";
import { useGlobalContext1 } from "./SalesDetailsContext";
import storage from "../Services/Storage";

const AutoAllocateContext = React.createContext();
let url = "SalesOrder/";
const AUTO_ALLOCATE_URL = `${url}autoAllocation?`;

const AutoAllocateProvider = ({ children }) => {
    const auth = storage.getLocal("user");
    const [autoAllocateReleaseQty, setAutoAllocateReleaseQty] = useState(0);
    const {
        filteredSalesDetails,
        salesOrderHdr,
        setFilteredSalesDetails,
        setLoading,
        setAdd,
        updateSales
    } = useGlobalContext1();

    const autoAllocate = async () => {
        // debugger;
        setLoading(true);
        let sODetailsData = filteredSalesDetails.map((a) => ({
            MASK_SODT_ID: a.MASK_SODT_ID,
            DIVISION: a.MASK_DIVN_ID,
            MASK_PROD_ID: a.MASK_PROD_ID,
            STOCK_TYPE: "1",
            PRODUCT_CODE: a.PRODUCT_CODE,
            PROD_SMALL_DESC: a.PROD_SMALL_DESC,
            PROD_LOT_NO: a.PROD_LOT_NO,
            PRICE_LIST_NO: a.PRICE_LIST_NO,
            CASE_TYPE: "",
            CDR_DISC: a.CDR_DISC,
            EUS_DISC_PERC: a.EUS_DISC_PERC.toString(),
            EUS_DISC: a.EUS_DISC.toString(),
            CASE_ORD_QTY: a.CASE_ORD_QTY.toString(),
            PACK_ORD_QTY: a.PACK_ORD_QTY.toString(),
            TOT_ORD_QTY: a.TOT_ORD_QTY.toString(),
            PROD_PRICE: a.PROD_PRICE.toString(),
            BASE_PRICE: a.BASE_PRICE.toString(),
            CASE_REL_QTY: a.CASE_REL_QTY.toString(),
            PACK_REL_QTY: a.PACK_REL_QTY.toString(),
            TOT_REL_QTY: a.TOT_REL_QTY.toString(),
            TOT_INV_QTY: a.TOT_INV_QTY.toString(),
            MASK_PROD_VAL: a.MASK_PROD_VAL,
            MASK_SODT_PROD_STK_TYPE: a.MASK_SODT_PROD_STK_TYPE,
            MASK_PACK_UOM_CODE: a.MASK_PACK_UOM_CODE,
            MASK_TOT_BACK_QTY: a.MASK_TOT_BACK_QTY.toString(),
            MASK_TOT_DROP_QTY: a.MASK_TOT_DROP_QTY.toString(),
            MASK_TRADE_DISC: a.MASK_TRADE_DISC.toString(),
            MASK_VAL_BASE_CRNC: a.MASK_VAL_BASE_CRNC.toString(),
            MASK_SPLCON_TAX: a.MASK_SPLCON_TAX.toString(),
            MASK_TOT_TAX: a.MASK_TOT_TAX.toString(),
            MASK_SODT_TOT_VAT: a.MASK_SODT_TOT_VAT.toString(),
            MASK_CASE_FACTOR: a.MASK_CASE_FACTOR.toString(),
            MASK_STATUS: a.MASK_STATUS,
            MASK_UPDATE_COUNT: a.MASK_UPDATE_COUNT.toString(),
            MASK_EXPORTED: a.MASK_EXPORTED,
            MASK_FIRST_ENTERED_BY: a.MASK_FIRST_ENTERED_BY,
            MASK_FIRST_ENTERED_ON: a.MASK_FIRST_ENTERED_ON,
            MASK_LAST_MODIFIED_BY: a.MASK_LAST_MODIFIED_BY,
            MASK_LAST_MODIFIED_ON: a.MASK_LAST_MODIFIED_ON,
            MASK_IUKEY: a.MASK_IUKEY,
            MASK_SEQUENCE: a.MASK_SEQUENCE!=null?a.MASK_SEQUENCE.toString():a.MASK_SEQUENCE,
            EXTENDED_PRICE: a.EXTENDED_PRICE.toString(),
            MASK_SODT_EUS_DISC_PERC_TAG: a.MASK_SODT_EUS_DISC_PERC_TAG,
            MASK_SODT_TRANS_VAL: a.MASK_SODT_TRANS_VAL.toString(),
            MASK_SODT_CDR_DISC_PERC: a.MASK_SODT_CDR_DISC_PERC.toString(),
            MASK_SODT_CDR_DISC_PERC_TAG: a.MASK_SODT_CDR_DISC_PERC_TAG,
            MASK_SODT_PRICE_OR_TAG: a.MASK_SODT_PRICE_OR_TAG,
            MASK_SODT_UPPER_BAND: a.MASK_SODT_UPPER_BAND,
            MASK_SODT_LOWER_BAND: a.MASK_SODT_LOWER_BAND,
            MASK_SODT_PRL_PRICE: a.MASK_SODT_PRL_PRICE,
            MASK_SOHD_ID: a.MASK_SOHD_ID,
            MASK_DIVN_ID: a.MASK_DIVN_ID,
            MASK_EVAL_BY_LOT_TAG: a.MASK_EVAL_BY_LOT_TAG,
            MASK_EUS_DISC_APP_TO: a.MASK_EUS_DISC_APP_TO,
            MASK_PRODUCT_MRP: a.MASK_PRODUCT_MRP.toString(),
            MASK_TOT_REL_QTY: a.MASK_TOT_REL_QTY.toString(),
            MASK_OLD_STK_ONLY: a.MASK_OLD_STK_ONLY,
            RBO_REAS_CODE: a.RBO_REAS_CODE,
            DESCRIPTION: a.DESCRIPTION,
            TOT_AVL_QTY: a.TOT_AVL_QTY,
            BASE_PROD_CODE: a.PRODUCT_CODE,
            BASE_PROD_LOT: a.PROD_LOT_NO,
            BASE_PROD_ALLOC: "0",
        }));
        const allocatePayload = {
            CICode: auth.CICode,
            soHrdId: salesOrderHdr.MASK_SO_ID,
            CompId: auth.CompId,
            CustId: salesOrderHdr.MASK_CUST_ID,
            OEMORDER: "2",
            StockType: "1",
            OptionCode: "2",
            Tag: "N",
            UserId: auth.UserId,
            PrintFormatCode: auth.PrintFormatCode,
            UOM: "2", //if inventory then UOM is 2
            // OEMORDER: "2",
            // StockType: "1", //1 is for inventory,
            UOMCode: filteredSalesDetails[0].MASK_PACK_UOM_CODE,
            CurrencyId: salesOrderHdr.MASK_CURRENCY_ID,
            CurrencyCode: "2",
            strDivnId: filteredSalesDetails[0].MASK_DIVN_ID,
            SODetailsData: sODetailsData,
        };

        // return;
        try {
             
            const res = await Axios.post(
                AUTO_ALLOCATE_URL,
                JSON.stringify(allocatePayload),
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            // debugger;
            if (res.data.Status == "Success") {
                setAdd(true);
                let salesOrder = [];
                salesOrder = res.data.SalesOrder;
                
                for (let i = 0; i < res.data.SODetailsData.SODetailsData.length; i++) {
                    for(let j = 0; j < salesOrder.length; j++){
                        if(res.data.SODetailsData.SODetailsData[i].MASK_PROD_ID == salesOrder[j].MASK_PROD_ID && res.data.SODetailsData.SODetailsData[i].PROD_LOT_NO == salesOrder[j].PROD_LOT_NO){
                            salesOrder[i].MASK_SODT_ID =
            res.data.SODetailsData.SODetailsData[i].MASK_SODT_ID;
                            salesOrder[i].MASK_IUKEY = "2";
                        }
                    }
                }
                setFilteredSalesDetails(salesOrder);
                let arrLen = salesOrder.filter((val) => val.TOT_REL_QTY > 0);
                setAutoAllocateReleaseQty(arrLen.length);
                toast.success("Auto Allocation Done Successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setLoading(false);
                // debugger;
                updateSales(salesOrder);
            }
        } catch (error) {
            setLoading(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
    };
    return (
        <AutoAllocateContext.Provider
            value={{
                autoAllocate,
                autoAllocateReleaseQty,
            }}
        >
            {children}
        </AutoAllocateContext.Provider>
    );
};
export const useGlobalContext3 = () => {
    return useContext(AutoAllocateContext);
};

export { AutoAllocateProvider, AutoAllocateContext };
