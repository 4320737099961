import Axios from "../../api/Axios";
const BASE_URL = "StockAdjustment/";


export const Endpoint = {
    GetAllWarehouseData: "GetAllWarehouseData",
    GetDefaultWHData: "GetDefaultWHData",
    GetAllReasonData: "GetAllReasonData",
    PopulateStockType: "PopulateStockType",
    GetProductList: "GetProductList",
    GetProductDisc: "GetProductDisc",
    GetProductLotList: "GetProductLotList",
    SaveStockAdjustment: "SaveStockAdjustment",
    GetAllSTKAdjstData : "GetAllSTKAdjstData",
    PrintSTKAdjstData : "PrintSTKAdjstData",
    DeleteStockAdjustment:"DeleteStockAdjustment",
    GetGRN: "GetGRN",
    ApproveStockAdjustment : "ApproveStockAdjustment"
};

export const createAPIEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};