import { useFormik } from "formik";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import {
    createAPIEndpointMaster,
    Endpoint,
} from "../../Services/ParamSetting/ParamMastService";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";
import storage from "../../Services/Storage";
const Master = () => {
    const [paramdata, setParamdata] = useState([]);
    const [showeditsection, setShoweditsection] = useState(false);
    const [fillerlendisable, setFillerlendisable] = useState(false);
    const [fillerdisable, setFillerdisable] = useState(false);
    const [parammasterloader, setParammasterloader] = useState(false);
    const [mastrowdata, setMastrowdata] = useState([]);
    let saved = false;
    const auth = storage.getLocal("user");
    //  
    const editdata = (rowdata) => {
        return (
            <div className="action-icon">
                <a href="#EditSection">
                    <button className="grid-icon-img"  title="View/Edit User Details"
                        onClick={() => {
                        //  
                            handleFormData(rowdata);
                            setShoweditsection(true);
                            setMastrowdata(rowdata);
                        }}
                    >
                        <img alt="InnoRise" src={require("../../Assets/Images/ViewEdit.png")} />
                    </button>
                </a>
            </div>
        );
    };

    const initialValues = {
        parameter: "",
        prefix: "",
        midnm: "",
        suffix: "",
        fillerlen: "",
        maxleng: "",
        selfgen: "",
        filler: "",
    };

    const self_generated = [
        {
            id: "1",
            name: "Yes",
            value: "Y",
        },
        {
            id: "2",
            name: "No",
            value: "N",
        },
    ];
    const fillers = [
        {
            id: "1",
            name: "Yes",
            value: "Y",
        },
        {
            id: "2",
            name: "No",
            value: "N",
        },
    ];

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (data) => {
            //  
            SubmitParamMaster(data);
            if(saved){
                formik.resetForm();
                saved = false;
            }
        },
    });

    const handleselfgenerated = (e) => {
        formik.setFieldValue("selfgen", e.target.value);
        if (e.target.value === "Y") {
            setFillerlendisable(true);
        } else {
            setFillerlendisable(false);
        }
    };

    const handlefiller = (e) => {
        formik.setFieldValue("filler", e.value);

        if (e.target.value === "N") {
            setFillerdisable(true);
        } else {
            setFillerdisable(false);
        }
    };

    const handleFormData = (rowdata) => {
        formik.setFieldValue("parameter", rowdata.PARAMETER_NAME);
        formik.setFieldValue("prefix", rowdata.PREFIX);
        formik.setFieldValue("midnm", rowdata.MIDNUM);
        formik.setFieldValue("suffix", rowdata.SUFFIX);
        formik.setFieldValue("fillerlen", rowdata.FILLER_LENGTH);
        formik.setFieldValue("maxleng", rowdata.MAX_LENGTH);
        if (rowdata.AUTO_GENERATE === "Yes") {
            formik.setFieldValue("selfgen", "Y");
            setFillerlendisable(true);
        } else {
            formik.setFieldValue("selfgen", "N");
        }

        if (rowdata.FILLER === "Yes") {
            formik.setFieldValue("filler", "Y");
        } else {
            formik.setFieldValue("filler", "N");
            setFillerdisable(true);
        }
    //  
    };

    useEffect(() => {
        GetallData();
    }, []);

    const GetallData = () => {
        setParammasterloader(true);
        createAPIEndpointMaster()
            .get(`${Endpoint.GetParameterData}?strlangid=${1}&CompId=${auth.CompId}`)
            .then((res) => {
                //  
                setParamdata(res.data);
                setParammasterloader(false);
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
                setParammasterloader(false);
            });
    };

    const SubmitParamMaster = (data) => {
    //  
    //  
        if(parseInt(data.fillerlen)==0 || !data.fillerlen){
            toast.error("Please set the 'Filler Length' ", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            saved = false;
        }
        else if(parseInt(data.fillerlen)>6){
            toast.error("Please match the format. Type number between 1-6 ", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            saved = false;
        }
        else if(data.filler=="N"&& data.selfgen=="N"){
            toast.warn("Please select Self-Generated and Filler as 'Yes' while saving the Parameter Settings.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            saved = false;
        }
        else if(data.filler=="N"){
            toast.warn("Please select Filler as 'YES' while saving the Parameter Settings.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            saved = false;
        }
        
        else if(data.selfgen=="N"){
            toast.warn("Please select Selft Generated as 'YES' while saving the Parameter Settings.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            saved = false;
        }
        else if(!data.midnm){
            toast.warn("Midnum cannot be left blank.", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
            saved = false;
        }
        else{
            let payloadData = {
                compId: auth.CompId,
                col_name: mastrowdata.MASK_COL_NAME ?? "",
                prefix: data.prefix ?? "",
                mid_num: data.midnm ?? "",
                suffix: data.suffix ?? "",
                self_Gen_tag: data.selfgen ?? "",
                filler: data.filler ?? "",
                filler_length: data.fillerlen ?? "",
                defined_By: mastrowdata.MASK_DEFINED_BY ?? "",
                status: mastrowdata.MASK_STATUS ?? "",
                userId: auth.UserId,
            };

            //  

            createAPIEndpointMaster()
                .post(`${Endpoint.SaveParamData}`, JSON.stringify(payloadData), {
                    headers: { "Content-Type": "application/json" },
                })
                .then((response) => {
                    toast.success(response.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 4000,
                    });
                    setShoweditsection(false);
                    GetallData();
                    saved = true;
                })
                .catch(() => {
                    toast.error("Something went wrong", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    setShoweditsection(false);
                    saved = false;
                });
        }
    };

    //  
    if (parammasterloader) {
        return( <Loader />);
    } else {
        return (
            <>
                <div className="col-12 pt-3">
                    <h3 className="mb-3">Parameter Settings (Master)</h3>
                    {showeditsection && (
                        <div className="">
                            <form onSubmit={formik.handleSubmit}>
                                <section className="sales-box" id="EditSection">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                            <label className="form-label">Parameter</label>
                                            <InputText
                                                id="parameter"
                                                name="parameter"
                                                className="form-control"
                                                value={formik.values.parameter}
                                                onChange={(e) => {
                                                    formik.setFieldValue("parameter", e.target.value);
                                                }}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                            <label className="form-label">Prefix</label>
                                            <InputText
                                                id="prefix"
                                                name="prefix"
                                                className="form-control"
                                                value={formik.values.prefix}
                                                onChange={(e) => {
                                                    formik.setFieldValue("prefix", e.target.value);
                                                }}
                                                disabled={fillerlendisable}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                            <label className="form-label">Midnum</label>
                                            <InputText
                                                id="midnm"
                                                name="midnm"
                                                className="form-control"
                                                value={formik.values.midnm}
                                                onChange={(e) => {
                                                    // formik.setFieldValue("midnm", e.target.value);
                                                    const inputValue = e.target.value;
                                                    if (/^\d*$/.test(inputValue)) {
                                                        formik.setFieldValue("midnm", inputValue);
                                                    }
                                                }}
                                                disabled={fillerlendisable}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                            <label className="form-label">Suffix</label>
                                            <InputText
                                                id="suffix"
                                                name="suffix"
                                                className="form-control"
                                                value={formik.values.suffix}
                                                onChange={(e) => {
                                                    formik.setFieldValue("suffix", e.target.value);
                                                }}
                                                disabled={fillerlendisable}
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                            <label className="form-label">Filler Length</label>
                                            <InputText
                                                id="fillerlen"
                                                name="fillerlen"
                                                className="form-control"
                                                value={formik.values.fillerlen}
                                                onChange={(e) => {
                                                    formik.setFieldValue("fillerlen", e.target.value);
                                                }}
                                                disabled={fillerdisable}
                                                pattern="^[0-9]*$"
                                                title="Type number between 1-6'"
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                            <label className="form-label">Max. Length</label>
                                            <InputText
                                                id="maxleng"
                                                name="maxleng"
                                                className="form-control"
                                                value={formik.values.maxleng}
                                                onChange={(e) => {
                                                    formik.setFieldValue("maxleng", e.target.value);
                                                }}
                                                disabled
                                            />
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                            <div className="mt-0">
                                                <label className="form-label d-block">
                          Self Generated
                                                </label>

                                                {self_generated.map((btn, i) => {
                                                    return (
                                                        <div key={i} className="d-inline-flex pe-3">
                                                            <RadioButton
                                                                {...btn}
                                                                checked={formik.values.selfgen === btn.value}
                                                                onChange={handleselfgenerated}
                                                            />
                                                            <label htmlFor={btn.inputId} className="ms-1">
                                                                {btn.name}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-12">
                                            <div className="mt-0">
                                                <label className="form-label d-block">Filler</label>
                                                {fillers.map((btn, i) => {
                                                    return (
                                                        <div key={i} className="d-inline-flex pe-3">
                                                            <RadioButton
                                                                {...btn}
                                                                checked={formik.values.filler === btn.value}
                                                                onChange={handlefiller}
                                                            />
                                                            <label htmlFor={btn.inputId} className="ms-1">
                                                                {btn.name}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12">
                                            <div className="float-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-cancel-red me-3"
                                                    onClick={() => setShoweditsection(false)}
                                                >
                          Cancel
                                                </button>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                >
                          Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </form>
                        </div>
                    )}
                    <section className="">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3 className="py-2">All Records</h3>
                            <div className="DataTable pb-4">
                                <DataTable
                                    value={paramdata}
                                    tableStyle={{ minWidth: "50rem" }}
                                    paginator
                                    rows={10}
                                    filterDisplay="row"
                                    showGridlines
                                    stripedRows
                                    // rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                >
                                    <Column field="PARAMETER_NAME" header="Parameters" filter filterMatchMode="contains" filterPlaceholder="Search Here..." style={{ minWidth: "12rem" }}></Column>
                                    <Column field="PREFIX" header="Prefix"></Column>
                                    <Column field="MIDNUM" header="Midnum"></Column>
                                    <Column field="SUFFIX" header="Suffix"></Column>
                                    <Column field="AUTO_GENERATE" header="Self Generated"></Column>
                                    <Column field="FILLER" header="Filler"></Column>
                                    <Column field="FILLER_LENGTH" header="Filler Length"></Column>
                                    <Column field="MAX_LENGTH" header="Max Length"></Column>
                                    <Column body={editdata} header="Action"></Column>
                                </DataTable>
                            </div>
                        </div>
                    </section>
                </div>
            </>
        );
    }
};

export default Master;
