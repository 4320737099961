

export const initialValues = {
    //name fields
    parent_code:"",
    code:"",
    designation:"",
    first_name:"",
    middle_name:"",
    last_name:"",
    title:"",
    short_name:"",

    //ledger
    ledger:"",

    //basic_address
    addr_1:"",
    addr_2:"",
    town:"",
    state:"",
    district:"",
    zip_code:"",

    //contact_information
    std_code:"",
    email:"",
    phone_num:"",
    mob_num:"",
    other_phone_num:"",
    remarks:"",
    status: "",
    permission_tag: "",
};


