const getLocal = (key) => JSON.parse(localStorage.getItem(key));

const getSession = (key) => JSON.parse(sessionStorage.getItem(key));

const setLocal = (key, value) =>
    localStorage.setItem(key, JSON.stringify(value));

const setSession = (key, value) =>
    sessionStorage.setItem(key, JSON.stringify(value));

const storage = { getLocal, setLocal, getSession, setSession };

export default storage;
