import React, { useContext } from "react";
import { useState } from "react";

const CNCreationContext = React.createContext();

const CNCreationProvider = ({ children }) => {
    const [CNGridData, setCNGridData] = useState([]);

    return (
        <CNCreationContext.Provider
            value={{
                CNGridData,
                setCNGridData,
            }}
        >
            {children}
        </CNCreationContext.Provider>
    );
};

export const useCNCreationContext = () => {
    return useContext(CNCreationContext);
};

export { CNCreationProvider, CNCreationContext };
