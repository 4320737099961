import React, { useEffect, useState } from "react";
import { useStockAdjustmentContext } from "../../context/StockAdjustmentContext";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import * as Fa from "react-icons/fa";
import DataTableModal from "./DataTableModal";
import { Dialog } from "primereact/dialog";
import { useFormik } from "formik";
import { StockAdjustmentAddRowInitVal } from "../../Validations/StockAdjustmentValidation";
// import { toast } from "react-toastify";
import { classNames } from "primereact/utils";
import { toast } from "react-toastify";


const InformationModal = () => {
    const {
        infoPopup,
        setInfoPopup,
        stockTypeData,
        setProductCodePopup,
        ProductCodeApi,
        defautStockType,
        // selectedProductCode,
        // setSelectedProductCode,
        productDetails,
        setProductDetails,
        // productLotselect,
        setProductLotselect,
        selectedLot,
        setSelectedLot,
        populateProductDataTable,
        setPopulateProductDataTable,
        GRNAPI,
        GRNdata,
        selectedGRN,
        setSelectedGRN,
        viewGRN,
        setviewGRN
    } = useStockAdjustmentContext();

    const [GRNError, setGRNError] = useState("");

    const stockTypeTemplate = (filtered) => {
        return (
            <>
                {filtered.STK_TYPE_CODE}{" "}-{" "}{filtered.STK_TYPE_DESC}
            </>
        );
    };
    const GRNTemplate = (filtered) => {
        return (
            <>
                {filtered.GRN_NO}
            </>
        );
    };

    const handlePopupSubmit = (data) => {
        if (data?.quantity.includes("-")) {
            if (selectedGRN.length == 0) {
                setGRNError("Please select GRN.");
                return;
            }
            else {
                setGRNError("");
                setviewGRN(false);
                setInfoPopup(false);
                let prdDtl = {
                    Stock_Type: data.stock_type.STK_TYPE_DESC,
                    pvcSTK_ADJ_HDR_ID: "",
                    pvcSTK_TYPE_ID:
                        data.stock_type.STK_TYPE_DESC == "Inventory" ? "1" : "7",
                    pvcSTK_PROD_ID: data.product_code,
                    pvcSTK_PROD_PRICE:
                        selectedGRN.length != 0 ? selectedGRN.PRICE.toString() : "",
                    pnmSTK_PHYSICAL_QTY: data.quantity,
                    pnmSTK_SYSTEM_QTY: data.available_stock,
                    pnmSTK_UNIT_PRICE: "",
                    pvcSTK_BATCH_NO: data.product_lot_no,
                    pvcLOCATION_ID: "N",
                    pvcDEFINED_BY: "E",
                    pvcSTATUS: "S",
                    pvcEXPORT_TAG: "N",
                    pnmSTK_ADJ_ITM_SLNO: "",
                    pvcCFOP_ID: selectedGRN.length != 0 ? selectedGRN.GRND_ID : "",
                    pnmSTK_CASE_FACTOR: "",
                    pvcPROD_SMALL_DESC: data.product_description,
                    iukey: "1",
                };

                const maskProdId = prdDtl.pvcSTK_PROD_ID;
                let isDuplicateID = populateProductDataTable.some(item => item.pvcSTK_PROD_ID === maskProdId && item.pvcSTK_BATCH_NO === prdDtl.pvcSTK_BATCH_NO);
                for (let i = 0; i < populateProductDataTable.length; i++) {

                    if (populateProductDataTable[i].pnmSTK_PHYSICAL_QTY.includes("-") !== prdDtl.pnmSTK_PHYSICAL_QTY.includes("-")) {
                        toast.error(
                            "Positive and negative stock adjustments are not allowed at the same time.",
                            {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 3500,
                            });
                        return;
                    }
                }
                if (isDuplicateID) {
                    toast.error(
                        "Product already added to the cart.",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        }

                    );
                    isDuplicateID = false;
                }
                else {
                    setPopulateProductDataTable((prevState) => [...prevState, prdDtl]);
                    setSelectedGRN([]);
                }
                setGRNError("");
            }
        }

        else {
            setviewGRN(false);
            setInfoPopup(false);
            let prdDtl = {
                Stock_Type: data.stock_type.STK_TYPE_DESC,
                pvcSTK_ADJ_HDR_ID: "",
                pvcSTK_TYPE_ID:
                    data.stock_type.STK_TYPE_DESC == "Inventory" ? "1" : "7",
                pvcSTK_PROD_ID: data.product_code,
                pvcSTK_PROD_PRICE:
                    selectedGRN.length != 0 ? selectedGRN.PRICE.toString() : "",
                pnmSTK_PHYSICAL_QTY: data.quantity,
                pnmSTK_SYSTEM_QTY: data.available_stock,
                pnmSTK_UNIT_PRICE: "",
                pvcSTK_BATCH_NO: data.product_lot_no,
                pvcLOCATION_ID: "N",
                pvcDEFINED_BY: "E",
                pvcSTATUS: "S",
                pvcEXPORT_TAG: "N",
                pnmSTK_ADJ_ITM_SLNO: "",
                pvcCFOP_ID: selectedGRN.length != 0 ? selectedGRN.GRND_ID : "",
                pnmSTK_CASE_FACTOR: "",
                pvcPROD_SMALL_DESC: data.product_description,
                iukey: "1",
            };

            const maskProdId = prdDtl.pvcSTK_PROD_ID;
            let isDuplicateID = populateProductDataTable.some(item => item.pvcSTK_PROD_ID === maskProdId && item.pvcSTK_BATCH_NO === prdDtl.pvcSTK_BATCH_NO);
            for (let i = 0; i < populateProductDataTable.length; i++) {

                if (populateProductDataTable[i].pnmSTK_PHYSICAL_QTY.includes("-") !== prdDtl.pnmSTK_PHYSICAL_QTY.includes("-")) {
                    toast.error(
                        "Positive and negative stock adjustments are not allowed at the same time.",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3500,
                        });
                    return;
                }
            }
            if (isDuplicateID) {
                toast.error(
                    "Product already added to the cart.",
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }

                );
                isDuplicateID = false;
            }
            else {
                setPopulateProductDataTable((prevState) => [...prevState, prdDtl]);
                setSelectedGRN([]);
            }
            setGRNError("");
        }


    };

    const formik = useFormik({

        initialValues: StockAdjustmentAddRowInitVal, enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            if (!data.product_code) {
                errors.product_code = "Please enter product code.";
            }
            if (!data.quantity && formik.initialTouched) {
                errors.quantity = "Enter quantity.";
            }
            else if (!(/^-?\d+$/.test(data.quantity))) {
                errors.quantity = "Please enter only numbers.";
            }
            return errors;
        },
        onSubmit: (data) => {
            formik.resetForm();


            handlePopupSubmit(data);
        },

    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    };



    useEffect(() => {

        const PopulateproductCode = () => {

            if (Object.keys(productDetails).length > 0) {
                formik.values.product_description = productDetails.DESCRIPTION;
                formik.values.product_code = productDetails.MASK_PROD_ID;
            }
            else {
                formik.setFieldValue("product_description", "");
                formik.setFieldValue("product_code", "");
            }
        };

        PopulateproductCode();
    }, [productDetails]);
    useEffect(() => {
        const PopulateproductCode = () => {


            if (Object.keys(selectedLot).length > 0) {
                formik.values.product_lot_no = selectedLot.BATCH_NO;
                formik.values.available_stock = selectedLot.AVAILABLE_STOCK;
            }
            else {
                formik.setFieldValue("product_lot_no", "");
                formik.setFieldValue("available_stock", "");
            }
        };
        PopulateproductCode();
    }, [selectedLot]);

    useEffect(() => {
        setSelectedLot([]);
    }, [formik.values.product_description]);
    useEffect(() => {
        setSelectedLot([]);
        setProductDetails([]);
    }, [populateProductDataTable.length]);

    if (infoPopup) {
        formik.initialValues.stock_type = defautStockType;
    }


    const handleProductlot = () => {
        formik.values.quantity = "";
        setviewGRN(false);
        setProductCodePopup(true);
        setProductLotselect(true);
    };



    const handleProductSelect = () => {
        formik.values.quantity = "";
        setviewGRN(false);
        setProductCodePopup(true);
        ProductCodeApi();
    };
    const handleGRN = (e) => {
        formik.setFieldValue("quantity", e.target.value);
        if ((e.target.value).toString().startsWith("-")) {
            setviewGRN(true);
            GRNAPI(formik.values.product_code, formik.values.product_lot_no);
        }
        else {
            setviewGRN(false);
        }
    };


    return (
        <div className="col-12 pt-3 pb-3">
            <Dialog
                header="Add Row"
                visible={infoPopup}
                style={{ width: "50vw" }}
                onHide={() => setInfoPopup(false)}
                closable={false}
                draggable={false}
                resizable={false}
            >
                <form onSubmit={formik.handleSubmit}>
                    <div className="row pt-2">
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label"> Row</label>
                            <InputText
                                className="form-control"
                                type="text"
                                placeholder="0"
                                value={formik.values.row = populateProductDataTable.length + 1}
                                disabled
                            />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">Stock Type</label>
                            <div className="d-block">
                                <Dropdown
                                    name="STK_TYPE_DESC"
                                    options={stockTypeData}
                                    optionLabel="STK_TYPE_DESC"
                                    value={formik.values.stock_type}
                                    onChange={(e) => {

                                        formik.setFieldValue("stock_type", e.target.value);
                                    }}
                                    itemTemplate={stockTypeTemplate}
                                    emptyMessage='No results found.'
                                    showEmptyMessage
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">UOM Type </label>
                            <InputText
                                class="form-control"
                                value={formik.values.stock_type.STK_TYPE_DESC == "Inventory" ? formik.values.uom_type = "Pack" : formik.values.uom_type = "Base"}
                            />
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">Product Code </label>
                            <div className="input-group">
                                <InputText
                                    class="form-control"
                                    type="text"
                                    style={{ width: "84%" }}
                                    value={formik.values.product_code}
                                    className={classNames({ "p-invalid": isFormFieldInvalid("product_code") })}
                                />

                                <div className="input-group-prepend">
                                    <span onClick={handleProductSelect} className="input-group-text ms-2">
                                        <Fa.FaSearch />
                                    </span>
                                </div>
                            </div>
                            {getFormErrorMessage("product_code")}
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">Product Description</label>
                            <div className="d-block">
                                <InputText
                                    class="form-control"
                                    value={formik.values.product_description}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">Product Lot Number</label>
                            <div className="input-group">
                                <InputText className="form-control" value={formik.values.product_lot_no} disabled />
                                {formik.values.product_code !== "" ? <div className="input-group-prepend">
                                    <span className="input-group-text ms-2" onClick={handleProductlot} >
                                        <Fa.FaSearch />
                                    </span>
                                </div> : null}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">Available Stock</label>
                            <div className="d-block">
                                <InputText
                                    class="form-control"
                                    value={formik.values.available_stock}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">Quantity(Pack/Base)</label>
                            <div className="d-block">
                                <InputText
                                    class="form-control"
                                    value={formik.values.quantity}
                                    onChange={(e) => handleGRN(e)}
                                    className={classNames({ "p-invalid": isFormFieldInvalid("quantity") })}
                                />
                                {getFormErrorMessage("quantity")}
                            </div>
                        </div>
                        {viewGRN && <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
                            <label className="form-label">GRN</label>
                            <div className="d-block">
                                <Dropdown
                                    name="GRN"
                                    options={GRNdata}
                                    optionLabel="GRN_NO"
                                    value={selectedGRN}
                                    onChange={(e) => {
                                        { setSelectedGRN(e.target.value); }

                                    }}
                                    itemTemplate={GRNTemplate}
                                    emptyMessage='No results found.'
                                    showEmptyMessage
                                />
                                {<span className="mandatory">{GRNError}</span>}
                            </div>
                        </div>}
                        <div className="col-12">
                            <div className="float-end">
                                <button type="submit" className="btn btn-primary me-3">Ok</button>
                                <button type="button" className="btn btn-cancel-red" onClick={() => { setGRNError(""); setInfoPopup(false); }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
            <DataTableModal />
        </div>

    );
};

export default InformationModal;