import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { InputText } from "primereact/inputtext";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import { Dropdown } from "primereact/dropdown";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import { Calendar } from "primereact/calendar";
import * as Fi from "react-icons/fi";
import {
    createAPICustomerMastEndpoint,
    Endpoint,
} from "../../Services/CustomerMast/CustomerMastService";
import {
    createAPISupplierMastEndpoint,
    EndpointSupplier,
} from "../../Services/SupplierMast/SuppliermastService";
import * as Fa from "react-icons/fa";
import storage from "../../Services/Storage";
import Axios from "../../api/Axios";
import { useEmployeeMasterContext } from "../../context/EmployeeMasterContext";
import SearchGeographyModal from "../EmployeeMaster/SearchGeographyModal";
import format from "date-fns/format";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";



function SupplierMasterNew() {
    //General
    const [codeStatus, setCodeStatus] = useState(false);
    const [nameStatus, setNameStatus] = useState(false);
    // const [expirydt, setExpirydtStatus] = useState(false);
    const [gstno, setGstnoStatus] = useState(false);
    //contact
    const [prefixStatus, setPrefixStatus] = useState(false);
    const [fnameStatus, setFnameStatus] = useState(false);
    const [midnameStatus, setMidnameStatus] = useState(false);
    const [lnameStatus, setLnameStatus] = useState(false);
    const [phnoStatus, setPhnoStatus] = useState(false);
    const [mobileStatus, setMobileStatus] = useState(false);
    const [cemailStatus, setCemailStatus] = useState(false);
    //address
    const [address1Status, setAddress1Status] = useState(false);
    const [address2Status, setAddress2Status] = useState(false);
    const [townStatus, setTownStatus] = useState(false);
    //financial
    const [creditHoldStatus, setCreditHoldStatus] = useState(false);
    const [taxFormStatus, setTaxFormStatus] = useState(false);
    const [paymentTermStatus, setPaymentTermStatus] = useState(false);
    const [taxAreaStatus, setTaxAreaStatus] = useState(false);
    const [paymentModeStatus, setPaymentModeStatus] = useState(false);
    const [purchaseExecStatus, setPurchaseExecStatus] = useState(false);
    const [creditlimStatus, setCreditlimStatus] = useState(false);
    const [discountStatus, setDiscountStatus] = useState(false);

    const [taxformData, setTaxformData] = useState([]);
    const [paytermData, setPaytermData] = useState([]);
    const [taxareaData, setTaxareaData] = useState([]);
    const [paymentmodeData, setPaymentmodeData] = useState([]);
    const [purchaseexcData, setPurchaseexcData] = useState([]);

    const [updateSupplier, setUpdateSupplier] = useState(false);
    const [saveloader, setSaveloader] = useState(false);
    const [supplierDetailsById, setSupplierDetailsById] = useState([]);
    const [convertExpirydt, setConvertExpirydt] = useState([]);
    const [convertcreatdt, setConvertcreatdt] = useState([]);
    // const [editedZipcode, setEditedZipcode] = useState([]);

    const GetTaxEnvSettings_URL = "CustomerMast/GetTaxEnvSettings?";
    const auth = storage.getLocal("user");
    const [searchParams] = useSearchParams();
    let supplierId = searchParams.get("suppliermastcode");
    const nav = useNavigate();
    let taxEnvSettings = "";
    const {
        setTownPopup,
        townValue,
        stateValue,
        districtValue,
        confirmTown,
        zipCode,
        setZipCode,
        handleState,
        setstateValue,
        setTownValue,
        setDistrictValue,
        setConfirmTown, 
        setAllsupplierdetails
    } = useEmployeeMasterContext();

    const initialValues = {
        //General Information
        code: "",
        name: "",
        creation_date: "",
        expiry_date: "",
        gst_no: "",
        //Contact Person
        prefix: "",
        cfname: "",
        cmname: "",
        clname: "",
        cphoneno: "",
        cmobileno: "",
        cemail: "",
        //Address
        bill_add1: "",
        bill_add2: "",
        town: "",
        state: "",
        district: "",
        zip_code: "",
        //Financial Information
        credit_hold: "",
        tax_form: "",
        payment_term: "",
        tax_area: "",
        payment_mode: "",
        purchase_excut: "",
        credit_limit: "",
        trade_discount: "0.00",
    };

    const formik = useFormik({
        initialValues: initialValues,
        // enableReinitialize: true,
        onSubmit: (data) => {
            //  
            supplierMasterDataSave(data);
        },
        validate: (data) => {
            const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
            //phone number regx (9089786745)
            const mobilenoRegex = new RegExp(/^[6-9]\d{9}$/);
            //space but no special character and number (Abhirup Basak)
            const nameRegex = new RegExp(/^[A-Za-z\s]+$/);
            //number regx only character and number.....no spl character (ANHJI0998N)
            const gstregex =
                /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/;
            //only number accept (12,909,6780)
            // const numberregx = /^\d+$/;
            //lan line number regx
            let phnnorgx = /^(\d{3}-?)?\d{4}-?\d{4}$/;
            // decimal regex
            const decRegex = /^\d+(\.\d{1,2})?$/;
            //prefix where - / @ allowed
            const prefixvalid = /^[a-zA-Z0-9\-_@]+$/;
            //allow only Spec char allowed [.],[,],[/],[-]
            const Addressrgx = /^[A-Za-z0-9\s.,,/\\-]*$/;
            let errors = {};
            // code no
            if (!data.code) {
                errors.code = "Code is required";
                setCodeStatus(true);
            } else {
                setCodeStatus(false);
            }
            //name
            if (!data.name && formik.initialTouched) {
                errors.name = "Please provide a valid Name";
                setNameStatus(true);
            } else if (data.name.trim().length > 50) {
                errors.name =
                    "Please enter a Name with a length of less than 50 characters";

                setNameStatus(true);
            } else {
                setNameStatus(false);
            }
            //GST No
            if (data.gst_no) {
                if (!gstregex.test(data.gst_no)) {
                    errors.gst_no = "Invalid GST Number";

                    setGstnoStatus(true);
                } else if (data.gst_no.length > 15) {
                    errors.gst_no = "Invalid GST Number length should be less than 15";
                    setGstnoStatus(true);
                } else {
                    setGstnoStatus(false);
                }
            } else {
                setGstnoStatus(false);
            }

            //prefix
            if (data.prefix) {
                if (!prefixvalid.test(data.prefix)) {
                    errors.prefix = "Invalid Prefix";

                    setPrefixStatus(true);
                } else if (data.prefix.length > 10) {
                    errors.prefix = "Invalid Prefix length should be less than 10";
                    setPrefixStatus(true);
                } else {
                    setPrefixStatus(false);
                }
            } else {
                setPrefixStatus(false);
            }

            //first name
            if (!data.cfname) {
                errors.cfname = "Please enter First Name";

                setFnameStatus(true);
            } else if (data.cfname) {
                if (data.cfname.trim().length > 30) {
                    errors.cfname = "Name length should be under 30 limitation";

                    setFnameStatus(true);
                } else {
                    if (!nameRegex.test(data.cfname)) {
                        errors.cfname =
                            "Invalid name. Numbers and special characters are not allowed";

                        setFnameStatus(true);
                    } else {
                        setFnameStatus(false);
                    }
                }
            }

            //middle name
            if (data.cmname) {
                if (!nameRegex.test(data.cmname)) {
                    errors.cmname = "Invalid Name. Only letters are allowed.";

                    setMidnameStatus(true);
                } else if (data.cmname.trim().length > 30) {
                    errors.cmname = "Name length should be under 30 limitation.";

                    setMidnameStatus(true);
                } else {
                    if (!nameRegex.test(data.cmname)) {
                        errors.cmname =
                            "Invalid name. Numbers and special characters are not allowed.";

                        setMidnameStatus(true);
                    } else {
                        setMidnameStatus(false);
                    }
                }
            }
            //last name
            if (!data.clname) {
                errors.clname = "Please enter Last Name";

                setLnameStatus(true);
            } else if (data.clname) {
                if (data.clname.trim().length > 30) {
                    errors.clname = "Last Name length should be under 30 limitation.";

                    setLnameStatus(true);
                } else {
                    if (!nameRegex.test(data.clname)) {
                        errors.clname =
                            "Invalid name. Numbers and special characters are not allowed.";

                        setLnameStatus(true);
                    } else {
                        setLnameStatus(false);
                    }
                }
            }

            //phone number
            if (data.cphoneno) {
                if (!phnnorgx.test(data.cphoneno)) {
                    errors.cphoneno = "Please enter Valid Phone Number";

                    setPhnoStatus(true);
                } else {
                    setPhnoStatus(false);
                }
            } else {
                setPhnoStatus(false);
            }

            //mobile no
            if (!data.cmobileno) {
                errors.cmobileno = "Please enter Mobile Number";
                setMobileStatus(true);
            } else {
                if (!mobilenoRegex.test(data.cmobileno)) {
                    errors.cmobileno = "Please enter Valid Mobile Number";

                    setMobileStatus(true);
                } else {
                    setMobileStatus(false);
                }
            }
            //mail
            if (data.cemail) {
                if (!emailRegex.test(data.cemail)) {
                    errors.cemail = "Invalid email format";

                    setCemailStatus(true);
                } else if (data.cemail.length > 60) {
                    errors.cemail = "Email length should be under 60 limitation.";

                    setCemailStatus(true);
                } else {
                    setCemailStatus(false);
                }
            } else {
                setCemailStatus(false);
            }

            //address 1
            if (!data.bill_add1) {
                errors.bill_add1 = "Please enter Address 1";

                setAddress1Status(true);
            } else if (data.bill_add1) {
                if (!Addressrgx.test(data.bill_add1)) {
                    errors.bill_add1 = "No special character is allowed";

                    setAddress1Status(true);
                } else if (data.bill_add1.length > 70) {
                    errors.bill_add1 = "Address1 length should be under 70 limitation.";

                    setAddress1Status(true);
                } else {
                    setAddress1Status(false);
                }
            }

            //address 2
            if (data.bill_add2) {
                if (data.bill_add2.length > 70) {
                    errors.bill_add2 = "Address 2 length should be  70.";
                    setAddress2Status(true);
                } else if (!Addressrgx.test(data.bill_add2)) {
                    errors.bill_add2 = "No special character is allowed";
                    setAddress2Status(true);
                } else {
                    setAddress2Status(false);
                }
            } else {
                setAddress2Status(false);
            }

            //town
            if (!data.town) {
                errors.town = "Please select Town";

                setTownStatus(true);
            } else {
                setTownStatus(false);
            }

            //credit hold
            if (!data.credit_hold) {
                errors.credit_hold = "Please choose Credit hold";

                setCreditHoldStatus(true);
            } else {
                setCreditHoldStatus(false);
            }
            //tax form
            if (!data.tax_form) {
                errors.tax_form = "Please choose Credit hold";

                setTaxFormStatus(true);
            } else {
                setTaxFormStatus(false);
            }

            //payment term
            if (!data.payment_term) {
                errors.payment_term = "Please select Payment Term.";

                setPaymentTermStatus(true);
            } else {
                setPaymentTermStatus(false);
            }

            //tax area
            if (!data.tax_area) {
                errors.tax_area = "Please select Tax Area.";

                setTaxAreaStatus(true);
            } else {
                setTaxAreaStatus(false);
            }

            //payment mode
            if (!data.payment_mode) {
                errors.payment_mode = "Please select Payment Mode.";

                setPaymentModeStatus(true);
            } else {
                setPaymentModeStatus(false);
            }
            if (!data.purchase_excut) {
                errors.purchase_excut = "Please select Payment Mode.";

                setPurchaseExecStatus(true);
            } else {
                setPurchaseExecStatus(false);
            }

            //credit limit
            if (data.credit_limit) {
                if (!decRegex.test(data.credit_limit)) {
                    errors.credit_limit =
                        "Invalid Credit limit. Only decimal upto two digits are allowed.";

                    setCreditlimStatus(true);
                } else if (data.credit_limit > 10000000.99) {
                    errors.credit_limit = "Invalid Credit limit.";

                    setCreditlimStatus(true);
                } else {
                    setCreditlimStatus(false);
                }
            } else {
                setCreditlimStatus(false);
            }

            //trade discount
            if (!data.trade_discount) {
                errors.trade_discount = "Please enter Trade Discount";

                setDiscountStatus(true);
            } else if (data.trade_discount > 99) {
                errors.trade_discount = "Trade Discount length should be maximum 99%.";

                setDiscountStatus(true);
            } else {
                if (!decRegex.test(data.trade_discount)) {
                    errors.trade_discount = "Invalid Trade Discount.";

                    setDiscountStatus(true);
                } else {
                    setDiscountStatus(false);
                }
            }

            return errors;
        },
    });

    useEffect(() => {
        fetchAllApiData();
        handleState();
        setConfirmTown(false);
        setstateValue("");
        setDistrictValue("");
        setTownValue("");
        if (supplierId != null) {
            getSuppliermasterById(supplierId);
            setUpdateSupplier(true);
        }
    }, []);

    const handleDateConversion = (str) => {
        const [day, month, year] = str.split("/");
        const date = new Date(+year, +month - 1, +day);
        const calenderdate = format(date, "yyyy-MM-dd");
        return calenderdate;
    };

    const getSuppliermasterById = (supplierId) => {
        //  
        createAPISupplierMastEndpoint()
            .get(`${EndpointSupplier.SupplierMasterView}?MASK_SUPP_ID=${supplierId}`)
            .then((response) => {
                setSupplierDetailsById(response.data.Data[0]);
                //  
                const credate = response.data.Data[0].PvcSUPP_CREATION_DATE ? handleDateConversion(response.data.Data[0].PvcSUPP_CREATION_DATE) : "";
                const expdate = response.data.Data[0].PvcSUPP_EXPIRY_DATE ? handleDateConversion(response.data.Data[0].PvcSUPP_EXPIRY_DATE) : "";
                setConvertExpirydt(expdate);
                setConvertcreatdt(credate);
                setZipCode(response.data.Data[0].PvcSUPP_BILL_ADD_GEOG_ID);

                //  
                //  
            })
            .catch(() => {

            });
    };

    if (updateSupplier) {
        formik.initialValues.code = supplierDetailsById.ViewCODE;
        formik.initialValues.name = supplierDetailsById.PvcPARTY_NAME;
        formik.initialValues.creation_date = new Date(convertcreatdt);
        formik.initialValues.expiry_date = supplierDetailsById.PvcSUPP_EXPIRY_DATE == "" ? "" : new Date(convertExpirydt);
        formik.initialValues.gst_no = supplierDetailsById.PvcGSTNO;

        formik.initialValues.prefix = supplierDetailsById.PvcPARTY_CONT_PREFIX;
        formik.initialValues.cfname = supplierDetailsById.PvcPARTY_CONT_FNAME;
        formik.initialValues.cmname = supplierDetailsById.PvcPARTY_CONT_MNAME;
        formik.initialValues.clname = supplierDetailsById.PvcPARTY_CONT_LNAME;
        formik.initialValues.cphoneno = supplierDetailsById.PvcPARTY_CONT_PHONE;
        formik.initialValues.cmobileno = supplierDetailsById.PvcPARTY_O_CONT_PHONE;
        formik.initialValues.cemail = supplierDetailsById.PvcPARTY_CONT_EMAIL;

        formik.initialValues.bill_add1 = supplierDetailsById.PvcSUPP_BILL_ADD_LINE1;
        formik.initialValues.bill_add2 = supplierDetailsById.PvcSUPP_BILL_ADD_LINE2;
        formik.initialValues.town = supplierDetailsById.PvcSUPP_BILL_TOWN;
        formik.initialValues.state = supplierDetailsById.PvcSUPP_STATE;
        formik.initialValues.district = supplierDetailsById.PvcSUPP_DISTRICT;
        formik.initialValues.zip_code = supplierDetailsById.PvcSUPP_BILL_ADD_PIN;

        formik.initialValues.credit_hold = supplierDetailsById.PvcSUPP_CREDIT_HOLD;
        formik.initialValues.tax_form = taxformData.filter((val) => {
            return val.MASK_TAX_FORM_ID == supplierDetailsById.PvcTAX_FORM_ID;
        })[0];
        formik.initialValues.payment_term = paytermData.filter((val) => {
            return val.MASK_PAY_TERM_ID == supplierDetailsById.PvcPAY_TERM_ID;
        })[0];
        formik.initialValues.tax_area = taxareaData.filter((val) => {
            return val.MASK_AREA_ID == supplierDetailsById.PvcAREA_ID;
        })[0];
        formik.initialValues.payment_mode = paymentmodeData.filter((val) => {
            return val.MASK_MOP_ID == supplierDetailsById.PvcMOP_ID;
        })[0];

        formik.initialValues.purchase_excut = purchaseexcData.filter((val) => {
            return val.MASK_EMP_ID == supplierDetailsById.PvcSUPP_PUR_EMP_ID;
        })[0];

        formik.initialValues.credit_limit =
            supplierDetailsById.PnmSUPP_CREDIT_LIMIT;
        formik.initialValues.trade_discount = supplierDetailsById.PnmSUPP_DOC_DISC;
    } else {
        formik.initialValues.code = "";
        formik.initialValues.name = "";
        formik.initialValues.creation_date = "";
        formik.initialValues.expiry_date = "";
        formik.initialValues.gst_no = "";
        formik.initialValues.prefix = "";
        formik.initialValues.cfname = "";
        formik.initialValues.cmname = "";
        formik.initialValues.clname = "";
        formik.initialValues.cphoneno = "";
        formik.initialValues.cmobileno = "";
        formik.initialValues.cemail = "";
        formik.initialValues.bill_add1 = "";
        formik.initialValues.bill_add2 = "";
        formik.initialValues.state = "";
        formik.initialValues.district = "";
        formik.initialValues.zip_code = "";
        formik.initialValues.town = "";

        formik.initialValues.credit_hold = "N";
        formik.initialValues.tax_form = "";
        formik.initialValues.payment_term = "";
        formik.initialValues.tax_area = "";


        formik.initialValues.purchase_excut = "";

        formik.initialValues.credit_limit = "";
        formik.initialValues.trade_discount = "0.00";
    }

    const fetchAllApiData = async () => {
        //taxEnvSettings
        const GetTaxEnvSettings_api = `${GetTaxEnvSettings_URL}CICode=${auth.CICode}`;
        const GetTaxEnvSettingsRes = await Axios.get(GetTaxEnvSettings_api);
        taxEnvSettings = GetTaxEnvSettingsRes.data.TaxSettings.toString();

        //Tax Form
        createAPICustomerMastEndpoint()
            .get(
                `${Endpoint.GetTaxForm}?CICode=${auth.CICode}&strExportable="Y"&strEnvOption=${taxEnvSettings}&Country="N"`
            )
            .then((response) => {
                setTaxformData(response.data);
                //  
            })
            .catch(() => {
                //  
            });

        //Payment Term
        createAPICustomerMastEndpoint()
            .get(`${Endpoint.GetPaymentTerm}?CICode=${auth.CICode}`)
            .then((response) => {
                setPaytermData(response.data);
                //  
            })
            .catch(() => {
                //  
            });

        //Tax Area
        createAPICustomerMastEndpoint()
            .get(
                `${Endpoint.GetTaxArea}?CICode=${auth.CICode}&strExportable="Y"&strEnvOption=${taxEnvSettings}`
            )
            .then((response) => {
                setTaxareaData(response.data);
            })
            .catch(() => {
                //  
            });

        //Payment Mode
        createAPICustomerMastEndpoint()
            .get(`${Endpoint.GetModeOfPayment}?CICode=${auth.CICode}`)
            .then((response) => {
                const modeOfPayment = response.data.filter((val) => {
                    return val.NAME == "Cash" || val.NAME == "Cheque";
                });
                setPaymentmodeData(modeOfPayment);
            })
            .catch(() => {
                //  
            });

        //Purchase Executive
        createAPICustomerMastEndpoint()
            .get(
                `${Endpoint.GetDistEmployee}?CICode=${auth.CICode}&CompId=${auth.CompId}`
            )
            .then((response) => {
                setPurchaseexcData(response.data);
            })
            .catch(() => {
                //  
            });
    };

    const TaxformTemplate = (taxfilter) => {
        return (
            <>
                {taxfilter.CODE} - {taxfilter.NAME}
            </>
        );
    };
    let expdate;
    const supplierMasterDataSave = (data) => {
        //  
        //  

        //  
        if (data.expiry_date) {
            expdate = format(new Date(data.expiry_date), "dd/MM/yyyy");
        }
        else {
            expdate = "";
        }
        let credate = format(new Date(data.creation_date), "dd/MM/yyyy");

        let UploadedPayLoad = {
            PvcSUPP_ID: updateSupplier ? supplierDetailsById.PvcSUPP_ID : data.code,
            PvcPARTY_ID: updateSupplier ? supplierDetailsById.PvcSUPP_ID : data.code,
            PvcCURRENCY_ID: "INR",
            PvcMOP_ID: data.payment_mode.MASK_MOP_ID,
            PvcPAY_TERM_ID: data.payment_term.MASK_PAY_TERM_ID,
            PvcTAX_FORM_ID: data.tax_form.MASK_TAX_FORM_ID,
            PvcAREA_ID: data.tax_area.MASK_AREA_ID,
            PnmSUPP_DOC_DISC: data.trade_discount ?? "",
            PnmSUPP_CREDIT_LIMIT: data.credit_limit ?? "",
            PvcSUPP_CREATION_DATE: credate,
            PvcSUPP_EXPIRY_DATE: expdate ?? "",
            PvcSUPP_BILL_ADD_LINE1: data.bill_add1,
            PvcSUPP_BILL_ADD_LINE2: data.bill_add2 ?? "",
            PvcSUPP_BILL_ADD_LINE3: "",
            PvcSUPP_BILL_ADD_LINE4: "",
            PvcSUPP_BILL_ADD_GEOG_ID: zipCode,
            PvcSUPP_BILL_ADD_PIN: data.zip_code,
            PvcREMARKS: "",
            PvcDEFINED_BY: "E",
            PvcENTERED_BY: auth.UserId,
            PvcSUPP_CREDIT_HOLD: data.credit_hold,
            PnmSUPP_MAX_ORD_VAL: "",
            PnmSUPP_MIN_ORD_VAL: "",
            PvcSUPP_PUR_EMP_ID: data.purchase_excut.MASK_EMP_ID ?? "",
            PvcSUPP_TYPE: "Y",
            PvcSUPP_BILLING_INST: "",
            PvcSUPP_DELIVERY_INST: "",
            PvcSUPP_DEL_TERMS: "",
            PvcSUPP_CIF_FOB: "",
            PvcZONE_ID: "",
            IUKEY: updateSupplier ? "2" : "1",
            PvcCOMP_ID: auth.CompId,
            PvcPARTY_NAME: data.name,
            PvcPARTY_CONT_PREFIX: data.prefix ?? "",
            PvcPARTY_CONT_FNAME: data.cfname ?? "",
            PvcPARTY_CONT_MNAME: data.cmname ?? "",
            PvcPARTY_CONT_LNAME: data.clname ?? "",
            PvcPARTY_STD_CODE: "",
            PvcPARTY_CONT_PHONE: data.cphoneno ?? "",
            PvcPARTY_CONT_MOBILE: data.cmobileno ?? "",
            PvcPARTY_CONT_EMAIL: data.cemail ?? "",
            PvcGSTNO: data.gst_no ?? "",
        };

        //  
        // return;
        createAPISupplierMastEndpoint()
            .post(
                `${EndpointSupplier.SupplierMasterSave}`,
                JSON.stringify(UploadedPayLoad),
                {
                    headers: { "Content-Type": "application/json" },
                }
            )
            .then((res) => {
                if (res.data.Status === "Sucess") {
                    toast.success(res.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                }
                else if (res.data.Status === "Warning") {
                    toast.warn(res.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                }
                setSaveloader(false);
            })
            .catch(() => {
                // console.log();
                setSaveloader(false);
            });
        nav("/masters/suppliermaster");
        createAPISupplierMastEndpoint()
            .get(`${EndpointSupplier.GetAllSupplierDetails}?CompId=${auth.CompId}`)
            .then((res) => {
                setAllsupplierdetails(res.data.Data);
             
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };

    const isFormFieldInvalid = (name) =>
        !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? (
            <small className="p-error">{formik.errors[name]}</small>
        ) : (
            <small className="p-error">&nbsp;</small>
        );
    };
    const radioBtns_credit_hold = [
        { id: "1", name: "Yes", value: "Y" },
        { id: "2", name: "No", value: "N" },
    ];
    if (saveloader) {
        return (
            <Loader />
        );
    } else {
        return (
            <>
                <div className="col-12 pt-3 pb-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/masters/suppliermaster">Supplier Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end mb-2">
                                <Link to="/masters/suppliermaster">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>



                    <div className="">
                        <form onSubmit={formik.handleSubmit}>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (codeStatus || nameStatus || gstno) ? (
                                                <div style={{ color: "red" }}>
                                                General Information <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>General Information</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Code <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="code"
                                                    name="code"
                                                    class="form-control"
                                                    value={formik.values.code}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("code", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("code"),
                                                        },
                                                        "form-control"
                                                    )}
                                                    // disabled
                                                    disabled={updateSupplier}
                                                />
                                                {getFormErrorMessage("code")}
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Name <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="name"
                                                    name="name"
                                                    class="form-control"
                                                    value={formik.values.name}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("name", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("name"),
                                                        },
                                                        "form-control"
                                                    )}

                                                />
                                                {getFormErrorMessage("name")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                                                    Creation Date <span className="mandatory">*</span>
                                                </label>

                                                <div className="card flex justify-content-center">
                                                    {" "}
                                                    <Calendar
                                                        inputId="creation_date"
                                                        name="creation_date"
                                                        value={
                                                            (formik.initialValues.creation_date = new Date())
                                                        }
                                                        className={classNames({
                                                            "p-invalid": isFormFieldInvalid("creation_date"),
                                                        })}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "creation_date",
                                                                e.target.value
                                                            );
                                                        }}
                                                        disabled
                                                        showIcon
                                                        dateFormat="dd/mm/yy"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Expiry Date</label>
                                                {/* <span className="mandatory">*</span> */}
                                                <div className="card flex justify-content-center">
                                                    {" "}
                                                    <Calendar
                                                        inputId="expiry_date"
                                                        name="expiry_date"
                                                        value={formik.values.expiry_date}
                                                        className={classNames({
                                                            "p-invalid": isFormFieldInvalid("expiry_date"),
                                                        })}
                                                        onChange={(e) => {
                                                            formik.setFieldValue(
                                                                "expiry_date",
                                                                e.target.value
                                                            );
                                                        }}
                                                        minDate={new Date()}
                                                        showIcon
                                                        dateFormat="dd/mm/yy"
                                                    />
                                                </div>
                                                {getFormErrorMessage("expiry_date")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">GST Number</label>
                                                <InputText
                                                    id="gst_no"
                                                    name="gst_no"
                                                    value={formik.values.gst_no}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "gst_no",
                                                            e.target.value.toUpperCase()
                                                        );
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("gst_no"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("gst_no")}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (prefixStatus ||
                                                fnameStatus ||
                                                midnameStatus ||
                                                lnameStatus ||
                                                phnoStatus ||
                                                mobileStatus ||
                                                cemailStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Contact Person <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Contact Person</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Prefix</label>
                                                <InputText
                                                    id="prefix"
                                                    name="prefix"
                                                    value={formik.values.prefix}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("prefix", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("prefix"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("prefix")}
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    First Name <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="cfname"
                                                    name="cfname"
                                                    value={formik.values.cfname}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("cfname", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("cfname"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("cfname")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Middle Name</label>
                                                <InputText
                                                    id="cmname"
                                                    name="cmname"
                                                    value={formik.values.cmname}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("cmname", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("cmname"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("cmname")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Last Name <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="clname"
                                                    name="clname"
                                                    value={formik.values.clname}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("clname", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("clname"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("clname")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Phone Number</label>
                                                <InputText
                                                    id="cphoneno"
                                                    name="cphoneno"
                                                    value={formik.values.cphoneno}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("cphoneno", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("cphoneno"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("cphoneno")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Mobile Number</label>
                                                <span className="mandatory">*</span>
                                                <InputText
                                                    id="cmobileno"
                                                    name="cmobileno"
                                                    value={formik.values.cmobileno}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "cmobileno",
                                                            e.target.value.replace(/ d/g, "")
                                                        );
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("cmobileno"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("cmobileno")}
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Company Email</label>
                                                <InputText
                                                    id="cemail"
                                                    name="cemail"
                                                    value={formik.values.cemail}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("cemail", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("cemail"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("cemail")}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (address1Status || address2Status || townStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Address <Fi.FiAlertTriangle />{" "}
                                                </div>
                                            ) : (
                                                <>Address</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Address Line 1 <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="bill_add1"
                                                    name="bill_add1"
                                                    value={formik.values.bill_add1}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("bill_add1", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("bill_add1"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("bill_add1")}
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                <label className="form-label">Address Line 2</label>
                                                <InputText
                                                    id="bill_add2"
                                                    name="bill_add2"
                                                    value={formik.values.bill_add2}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("bill_add2", e.target.value);
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("bill_add2"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("bill_add2")}
                                            </div>
                                            {/* <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="form-label">
                        Town <span className="mandatory">*</span>
                      </label>
                      <div className="d-block">
                        <AutoComplete
                          field="NAME"
                          virtualScrollerOptions={{
                            itemSize: 38,
                          }}
                          // value={
                          //   !manualTownEnter
                          //     ? supplierDetailsById.BillTown
                          //     : selectedTown
                          // }
                          value={formik.values.bill_town}
                          // suggestions={townSuggestion}
                          // completeMethod={townFilter}
                          // onChange={(e) => {
                          //   setSelectedTown(e.value);
                          //   formik.setFieldValue("bill_town", e.value);
                          //   townHandleChange(e);
                          // }}
                          dropdown={true}
                          // itemTemplate={TownTemplate}
                          // forceSelection
                          showEmptyMessage={true}
                        />
                        {getFormErrorMessage("bill_town")}
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="form-label">State</label>
                      <InputText
                        id="bill_state"
                        name="bill_state"
                        // value={
                        //   !manualTownEnter
                        //     ? formik.values.bill_state
                        //     : (formik.values.bill_state = billState)
                        // }
                        // onChange={(e) => {
                        //   setManualTownEnter(false);
                        //   formik.setFieldValue("bill_state", e.target.value);
                        // }}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldInvalid("bill_state"),
                          },
                          "form-control"
                        )}
                        disabled
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="form-label">District</label>
                      <InputText
                        id="bill_district"
                        name="bill_district"
                        // value={
                        //   !manualTownEnter
                        //     ? formik.values.bill_district
                        //     : (formik.values.bill_district = billDistrict)
                        // }
                        // onChange={(e) => {
                        //   formik.setFieldValue("bill_district", e.target.value);
                        // }}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldInvalid("bill_district"),
                          },
                          "form-control"
                        )}
                        disabled
                      />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12">
                      <label className="form-label">
                        Pin Code <span className="mandatory">*</span>
                      </label>
                      <InputText
                        id="bill_zipcode"
                        name="bill_zipcode"
                        // value={
                        //   !manualTownEnter
                        //     ? formik.values.bill_zipcode
                        //     : (formik.values.bill_zipcode = billZip)
                        // }
                        // onChange={(e) => {
                        //   formik.setFieldValue("bill_zipcode", e.target.value);
                        // }}
                        className={classNames(
                          {
                            "p-invalid": isFormFieldInvalid("bill_zipcode"),
                          },
                          "form-control"
                        )}
                        disabled
                      />
                      {getFormErrorMessage("bill_zipcode")}
                    </div> */}
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Town <span className="mandatory">*</span>
                                                </label>
                                                <div
                                                    className="input-group"
                                                    style={{ display: "flex", alignItems: "center" }}
                                                >
                                                    <InputText
                                                        class="form-control"
                                                        type="text"
                                                        placeholder="Select"
                                                        value={
                                                            !confirmTown
                                                                ? formik.values.town
                                                                : (formik.values.town = townValue)
                                                        }
                                                        onChange={(e) => {
                                                            formik.setFieldValue("town", e.target.value);
                                                        }}
                                                        disabled
                                                        style={{ flex: "1" }}
                                                        className={classNames({
                                                            "p-invalid": isFormFieldInvalid("town"),
                                                        })}
                                                    />

                                                    <div className="input-group-prepend">
                                                        <span
                                                            onClick={() => {
                                                                setTownPopup(true);
                                                            }}
                                                            className="input-group-text ms-2"
                                                        >
                                                            <Fa.FaSearch />
                                                        </span>
                                                    </div>
                                                </div>
                                                {getFormErrorMessage("town")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">State</label>
                                                <InputText
                                                    class="form-control"
                                                    id="state"
                                                    name="state"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.state
                                                            : (formik.values.state = stateValue)
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("state", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">District</label>
                                                <InputText
                                                    class="form-control"
                                                    id="district"
                                                    name="district"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.district
                                                            : (formik.values.district = districtValue)
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("district", e.target.value);
                                                    }}
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Pin Code <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    class="form-control"
                                                    id="zip_code"
                                                    name="zip_code"
                                                    value={
                                                        !confirmTown
                                                            ? formik.values.zip_code
                                                            : (formik.values.zip_code = zipCode.slice(0, 6))
                                                    }
                                                    onChange={(e) => {
                                                        formik.setFieldValue("zip_code", e.target.value);
                                                    }}
                                                    disabled
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("zip_code"),
                                                    })}
                                                />
                                                {getFormErrorMessage("zip_code")}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        {formik.submitCount > 0 &&
                                            (creditHoldStatus ||
                                                taxFormStatus ||
                                                paymentTermStatus ||
                                                taxAreaStatus ||
                                                paymentModeStatus ||
                                                purchaseExecStatus ||
                                                creditlimStatus ||
                                                discountStatus) ? (
                                                <div style={{ color: "red" }}>
                                                Financial Information <Fi.FiAlertTriangle />
                                                </div>
                                            ) : (
                                                <>Financial Information</>
                                            )}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <div className="mt-0">
                                                    <label className="form-label d-block">
                                                        Credit Hold <span className="mandatory">*</span>
                                                    </label>
                                                    {radioBtns_credit_hold.map((btn, i) => {
                                                        return (
                                                            <div key={i} className="d-inline-flex pe-3">
                                                                <RadioButton
                                                                    {...btn}
                                                                    checked={
                                                                        formik.values.credit_hold === btn.value
                                                                    }
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue(
                                                                            "credit_hold",
                                                                            e.value
                                                                        );
                                                                    }}
                                                                />
                                                                <label htmlFor={btn.inputId} className="ms-1">
                                                                    {btn.name}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}

                                                    {getFormErrorMessage("credit_hold")}
                                                </div>
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Tax Form <span className="mandatory">*</span>
                                                </label>{" "}
                                                <Dropdown
                                                    inputId="tax_form"
                                                    name="tax_form"
                                                    value={formik.values.tax_form}
                                                    options={taxformData}
                                                    optionLabel="NAME"
                                                    itemTemplate={TaxformTemplate}
                                                    placeholder="Select"
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("tax_form"),
                                                    })}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("tax_form", e.value);
                                                    }}
                                                // disabled
                                                />
                                                {getFormErrorMessage("tax_form")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Payment Term <span className="mandatory">*</span>
                                                </label>{" "}
                                                <Dropdown
                                                    inputId="payment_term"
                                                    name="payment_term"
                                                    value={formik.values.payment_term}
                                                    options={paytermData}
                                                    itemTemplate={TaxformTemplate}
                                                    optionLabel="NAME"
                                                    placeholder="Select"
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("payment_term"),
                                                    })}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("payment_term", e.value);
                                                    }}
                                                />
                                                {getFormErrorMessage("payment_term")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Tax Area <span className="mandatory">*</span>
                                                </label>{" "}
                                                <Dropdown
                                                    inputId="tax_area"
                                                    name="tax_area"
                                                    value={formik.values.tax_area}
                                                    options={taxareaData}
                                                    itemTemplate={TaxformTemplate}
                                                    optionLabel="NAME"
                                                    placeholder="Select"
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("tax_area"),
                                                    })}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("tax_area", e.value);
                                                    }}
                                                />
                                                {getFormErrorMessage("tax_area")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Payment Mode <span className="mandatory">*</span>
                                                </label>{" "}
                                                <Dropdown
                                                    inputId="payment_mode"
                                                    name="payment_mode"
                                                    value={formik.values.payment_mode}
                                                    options={paymentmodeData}
                                                    optionLabel="NAME"
                                                    placeholder="Select"
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("payment_mode"),
                                                    })}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("payment_mode", e.value);
                                                    }}
                                                />
                                                {getFormErrorMessage("payment_mode")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">Purchase Executive<span className="mandatory">*</span></label>{" "}
                                                <Dropdown
                                                    inputId="purchase_excut"
                                                    name="purchase_excut"
                                                    value={formik.values.purchase_excut}
                                                    options={purchaseexcData}
                                                    optionLabel="NAME"
                                                    placeholder="Select"
                                                    className={classNames({
                                                        "p-invalid": isFormFieldInvalid("purchase_excut"),
                                                    })}
                                                    onChange={(e) => {
                                                        formik.setFieldValue("purchase_excut", e.value);
                                                    }}
                                                />
                                                {getFormErrorMessage("purchase_excut")}
                                            </div>

                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Credit Limit (INR){" "}
                                                </label>
                                                <InputText
                                                    id="credit_limit"
                                                    name="credit_limit"
                                                    value={formik.values.credit_limit}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "credit_limit",
                                                            e.target.value
                                                        );
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("credit_limit"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("credit_limit")}
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12">
                                                <label className="form-label">
                                                    Discount (%) <span className="mandatory">*</span>
                                                </label>
                                                <InputText
                                                    id="trade_discount"
                                                    name="trade_discount"
                                                    value={formik.values.trade_discount}
                                                    onChange={(e) => {
                                                        formik.setFieldValue(
                                                            "trade_discount",
                                                            e.target.value
                                                        );
                                                    }}
                                                    className={classNames(
                                                        {
                                                            "p-invalid": isFormFieldInvalid("trade_discount"),
                                                        },
                                                        "form-control"
                                                    )}
                                                />
                                                {getFormErrorMessage("trade_discount")}
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <div className="float-end my-4">
                                <button type="submit" className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <SearchGeographyModal />
            </>
        );
    }
}

export default SupplierMasterNew;
