import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSalesReturnContext } from "../../context/SalesReturnContext";

const InformationModal = () => {
    const {
        modalPopup, 
        setModalPopup,
        handleConfirmModal
    } = useSalesReturnContext();

    

    return (
        <div>
            <>
                <div className="col-12 pt-3 pb-3">
                    <Modal show={modalPopup} aria-labelledby="contained-modal-title-vcenter" centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">Information</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="col-md-12 col-lg-12 col-sm-12 py-3">
                                <div className="text-center">
                                    <p className="mb-0">
                                        Partial/total document adjustment/receipt has been made against this invoice.
                                        <br></br>
                                        Do you want to return this invoice?
                                    </p>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className="float-end">
                                <button type="button" className="btn btn-primary me-3" onClick={handleConfirmModal}>Yes</button>
                                <button type="button" className="btn btn-canecl-red" onClick={()=>setModalPopup(false)}>No</button>
                            </div>
                        </Modal.Footer>
                    </Modal>
                </div>
            </>
        </div>
    );
};

export default InformationModal;