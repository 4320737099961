import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import {
    createAPIEndpointUser,
    Endpoint,
} from "../../Services/User/UserService";
import { toast } from "react-toastify";
import storage from "../../Services/Storage";

const User = () => {
    const [userdata,setUserdata]= useState([]);
    const auth = storage.getLocal("user");


    const nav = useNavigate();
    const touserdetails = (rowdata) => {
        return (
            
            <div className="action-icon">
                <button
                    className="grid-icon-img"
                    title="View/Edit User Details"
                    onClick={() => {
                         
                        nav(`/userdetails?userid=${encodeURIComponent(rowdata.USER_ID)}`);
                    }}
                >
                    <img alt='InnoRise' src={require("../../Assets/Images/ViewEdit.png")} />
                </button>
            </div>
        );
    };

    const statusShow = (rowdata) => {
        if(rowdata.MASK_STATUS==="Y"){
            return ("Active");
        }
        else{
            return("Inactive");
        }
    };


    
    useEffect(()=>{
        renderAlluserData();
    },[]);

    const renderAlluserData = ()=>{
        createAPIEndpointUser()
            .get(`${Endpoint.AllUserManagementData}?CompId=${auth.CompId}`)
            .then((res) => {
                setUserdata(res.data.Data);
                 
            })
            .catch((error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            });
    };


    return (
        <>
            <main>
                <div className="col-12 pt-3">
                    <div className="float-end">
                        <Link to="/userdetails">
                            <button type="submit" className="btn btn-primary">Add New User</button>
                        </Link>
                    </div>
                    
                    <section className="">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3 className="py-2">User Management</h3>
                            <div className="DataTable pb-4">
                            
                                <DataTable
                                    value={userdata}
                                    tableStyle={{ minWidth: "50rem" }}
                                    paginator
                                    rows={10}
                                    showGridlines
                                    stripedRows
                                    scrollHeight="80vh"
                                    filterDisplay="row"
                                    scrollable
                                >
                                    <Column field="USER_ID" header="User Id" filter filterMatchMode="contains" filterPlaceholder="Search Here..." style={{ minWidth: "12rem" }}></Column>
                                    <Column field="EMPLOYEE" header="Employee" filter filterMatchMode="contains" filterPlaceholder="Search Here..." style={{ minWidth: "12rem" }}></Column>
                                    {/* <Column field="MASK_STATUS" header="Status"></Column> */}
                                    <Column body={statusShow} header="Status"></Column>
                                    <Column field="MASK_LAST_LOGIN" header="Last Login"></Column>
                                    <Column field="MASK_LOGIN_COUNT" header="No. of Login"></Column>
                                    {/* <Column field="Login" header="User Group"></Column> */}
                                    <Column body={touserdetails} header="Action"></Column>
                                </DataTable>
                            
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
};

export default User;