import Axios from "../../api/Axios";
const BASE_URL = "DocumentAdjustment/";
export const Endpoint = {
    GetPartyName: "GetPartyName",
    GetPayableDocument: "GetPayableDocument",
    GetReceivableDocument: "GetReceivableDocument",
    GetDocumentAdjustment: "GetDocumentAdjustment",
    ViewDocumentAdjustment: "ViewDocumentAdjustment",
    SaveDocumentAdjustment: "SaveDocumentAdjustment",
};

// /api/DocumentAdjustment/GetPartyName
// /api/DocumentAdjustment/GetPayableDocument
// /api/DocumentAdjustment/GetReceivableDocument
// /api/DocumentAdjustment/SaveDocumentAdjustment
// /api/DocumentAdjustment/GetDocumentAdjustment
// /api/DocumentAdjustment/ViewDocumentAdjustment

export const createAPIDocumentEndpoint = () => {
    return {
        get: (Endpoint) => Axios.get(BASE_URL + Endpoint),
        post: (Endpoint, data) => Axios.post(BASE_URL + Endpoint, data),
    };
};